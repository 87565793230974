import * as React from "react";
import { useTranslation } from "react-i18next";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";

export default function HeaderOptionsSchedule({ addSchedule, onClose }) {
  const { t } = useTranslation();

  return (
    <>
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton
          onClick={addSchedule}
          variant="gradient"
          color="success"
          sx={{
            width: 125,
          }}
        >
          {t("add")}
        </MDButton>
        <MDButton
          onClick={onClose}
          variant="gradient"
          color="error"
          style={{ marginLeft: 20 }}
          sx={{
            width: 125,
          }}
        >
          {t("close")}
        </MDButton>
      </MDBox>
    </>
  );
}
