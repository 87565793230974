import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import CPaper from "../../../components/Custom/CPaper";
import MDInput from "../../../components/MDInput";

export default function PaymentsDetails({
  open,
  close,
  data,
  searched,
  onSearch,
  stripeId,
  setStripeId,
                                          stripePaymentMethodId,
                                          setStripePaymentMethodId,
  loading,
}) {
  const { t } = useTranslation();

  return (
    <CDialog title="payments" close={() => close(false)} open={open}>
      <br />
      <br />
      <CPaper style={{ marginBottom: 25, padding: 25 }}>
        <MDInput
          size="small"
          fullWidth
          value={stripeId}
          onChange={(e) => setStripeId(e.target.value)}
          name="stripeId"
          label="stripe Id"
          type="text"
          InputProps={{
            endAdornment: (
              <MDButton
                disabled={stripeId.length === 0 && stripePaymentMethodId.length > 0}
                loading={loading}
                style={{ width: 200 }}
                size="small"
                onClick={onSearch}
                variant="gradient"
                color="success"
              >
                {t("search")}
              </MDButton>
            ),
          }}
        />
        <br/>
        <br/>
        <h4 style={{textAlign:'center'}}>Or</h4>
        <br/>
        <MDInput
          size="small"
          fullWidth
          value={stripePaymentMethodId}
          onChange={(e) => setStripePaymentMethodId(e.target.value)}
          name="stripePaymentMethodId"
          label="stripe payment method Id"
          type="text"
          InputProps={{
            endAdornment: (
              <MDButton
                disabled={stripePaymentMethodId.length === 0 && stripeId.length > 0}
                loading={loading}
                style={{ width: 200 }}
                size="small"
                onClick={onSearch}
                variant="gradient"
                color="success"
              >
                {t("search")}
              </MDButton>
            ),
          }}
        />
        {searched > 0 ? <span>Remaining driver: {searched}</span> : null}
        <br />

        {Array.isArray(data) &&
          data.map((d) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <br />
              <hr color="#eee" style={{ marginBottom: 10 }} />
              <span>
                Driver: <b>{d.driver} </b>
              </span>
              <span>
                Amount: <b>{d.amount}</b>
              </span>
              <span>
                Credit card: <b>{d.creditCardNumber}</b>
              </span>
              <span>
                Stripe Id: <b>{d.stripeId}</b>
              </span>
              <span>
                Stripe Payment Method: <b>{d.stripePaymentMethodId}</b>
              </span>
              <span>
                Stripe Status: <b>{d.stripeStatus}</b>
              </span>
            </div>
          ))}
      </CPaper>
      <br />
      <br />

      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton
          onClick={() => close(false)}
          variant="gradient"
          color="error"
          style={{ marginLeft: 20 }}
        >
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
