import { Card } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import MDButton from "../../MDButton";
import { useStyles, useClassName } from "./styles";
import MDBox from "../../MDBox";
import { useMaterialUIController } from "../../../context";
import MDTypography from "../../MDTypography";

export default function CAlert({ open, title, subtitle, close, isConfirm, children, disabled }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const styles = useStyles();
  const classes = useClassName();
  const { t } = useTranslation();
  return (
    open && (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <Card style={{ ...styles.main, backgroundColor: darkMode ? "#2c3c58" : "#fff" }}>
          <MDBox component="form" role="form">
            <MDBox mb={2} mt={2}>
              <MDTypography variant="h4">{t(title)}</MDTypography>
              <MDTypography variant="h6">{t(subtitle)}</MDTypography>
              {children && children}
              <IconButton onClick={() => close(false)} className={classes.btnClose}>
                <CloseIcon />
              </IconButton>
            </MDBox>
            <MDBox
              mt={children ? 0 : 1}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {isConfirm && (
                <MDButton
                  disabled={disabled}
                  style={{ marginRight: 20 }}
                  onClick={isConfirm}
                  variant="gradient"
                  size="medium"
                  color="success"
                >
                  {t("accept")}
                </MDButton>
              )}

              <MDButton onClick={() => close(false)} variant="gradient" size="medium" color="error">
                {t("close")}
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Backdrop>
    )
  );
}
