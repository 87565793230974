import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import Swal from "sweetalert2";
import { firebase, firestore } from "../../../../firebase";
import { onOrganizeOrders } from "./func";
import { AlertModel } from "../../../../utilities/model";
import {
  getTimeSubscribe,
  getUser,
  getHours,
  getDiffTime,
  fromEtaToMinutes,
  getCheckField,
  fromBoolToString,
  getSeconds,
  CaptureException,
  isUserPermission,
  DuplicateOrder,
  DeleteOrder,
  ReAssignOrder,
  CompleteOrder,
} from "../../../../utilities/func";
import { getOriginMarkerPosition, postOrderSelected } from "../../../../redux/actions";

export default function useData() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dashboardSettings = useSelector(({ settings }) => settings);
  const userInfo = useSelector(({ user }) => user);
  const [orders, setOrders] = React.useState([]);
  const [appointments, setAppointments] = React.useState([]);
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [loading, setLoading] = React.useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [openCAlert, setOpenCAlert] = React.useState(false);
  const [openPayment, setOpenPayment] = React.useState(false);
  const [cAlertInfo, setCAlertInfo] = React.useState({ title: "", subtitle: "" });
  const [actionsType, setActionsType] = React.useState("");
  const [orderSelected, setOrderSelected] = React.useState({});
  const [etaInfo, setEtaInfo] = React.useState({ minutes: "", seconds: "", mi: "", km: "" });
  const [driversOrder, setDriversOrder] = React.useState([]);
  const [openDriversOrder, setOpenDriversOrder] = React.useState(false);
  const [showOrder, setShowOrder] = React.useState(false);
  const [showEtaOrder, setShowEtaOrder] = React.useState(false);
  const [orderErrors, setOrderErrors] = React.useState({});
  const [isOrderError, setIsOrderError] = React.useState(false);
  const [clientRecordModal, setClientRecordModal] = React.useState(false);
  const [clientRecord, setClientRecord] = React.useState({
    cancelled_orders: 0,
    completed_orders: 0,
  });
  const [searchOrder, setSearchOrder] = React.useState("");
  const [tabValue, setTabValue] = React.useState(2);
  const [fare, setFare] = React.useState("");
  const [phoneBlacklist, setPhoneBlacklist] = React.useState([]);

  React.useEffect(() => {
    const timeAgo = DateTime.now().minus({ hours: 12 });
    const orderOnSnapshot = firestore()
      .collection("orders")
      .limit(50)
      .where("status", "in", [
        "unassigned",
        "unaccepted",
        "assigned",
        "dispatch_failed",
        "inProcess",
      ])
      .where("createdAt", ">=", timeAgo.toJSDate())
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const list = [];
        querySnapshot.docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          if (data.errored) {
            setOrderErrors((prevState) => ({ ...prevState, [data.id]: true }));
            setIsOrderError(true);
          }
          list.push(data);
        });
        setOrders(list);
      });

    const appointmentsOnSnapshot = firestore()
      .collection("appointments")
      .limit(15)
      .where("status", "==", "pending")
      .onSnapshot((querySnapshot) => {
        const list = [];
        querySnapshot.docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          list.push(data);
        });
        setAppointments(list);
      });

    const phoneBlacklistOnSnapshot = firestore()
      .collection("phone_blacklist")
      .orderBy("createdAt", "desc")
      .limit(25)
      .onSnapshot((querySnapshot) => {
        const list = [];
        querySnapshot.docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          list.push(data);
        });
        setPhoneBlacklist(list);
      });

    return () => {
      appointmentsOnSnapshot();
      orderOnSnapshot();
      phoneBlacklistOnSnapshot();
    };
  }, []);

  React.useEffect(() => {
    if (searchOrder.length) {
      setTabValue(4);
    }
  }, [searchOrder.length]);

  const onReset = () => {
    setActionsType("");
    setOrderSelected({});
    setCAlertInfo({ title: "", subtitle: "" });
    setEtaInfo({ minutes: "", seconds: "", mi: "", km: "" });
    setOpenCAlert(false);
    setLoading(false);
    setOpenDriversOrder(false);
    setShowEtaOrder(false);
    setShowOrder(false);
    setClientRecordModal(false);
    setClientRecord({ cancelled_orders: 0, completed_orders: 0 });
    setDriversOrder([]);
  };

  const onRestoreOrder = (data, id) => {
    let order = { ...data };
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    const db = firestore().collection("orders");
    db.doc(id)
      .delete()
      .then(() => {
        delete order.dispatch_retries;
        delete order.max_retries_reached;
        delete order.selectionStartedAt;
        delete order.id;
        order.createdAt = timestamp;
        order.isCancellable = false;
        order.is_cancellable = false;
        order.refreshBy = getUser();
        db.add(order)
          .then(() => {
            setLoadingSuccess(true);
          })
          .catch((e) => {
            CaptureException(e, "Error-DAO-01");
            setCAlert({ open: true, type: "error", ms: e.message });
          })
          .finally(() => {
            onReset();
          });
      })
      .catch((e) => {
        onReset();
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const onDeleteOrder = (data, id, isAppointment) => {
    let hasPermission = isUserPermission(dashboardSettings, userInfo, "cancelOrder");
    if (!hasPermission) {
      setLoading(false);
      return;
    }
    DeleteOrder(data, id, isAppointment)
      .then(() => {
        setLoadingSuccess(true);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-02");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        onReset();
      });
  };

  const onReAssignOrder = async (data, id) => {
    let hasPermission = isUserPermission(dashboardSettings, userInfo, "reAssignOrder");

    let isRetainSpot = false
    if (dashboardSettings.showOrderPassAction){
      const result = await Swal.fire({
        title: t('would you like to keep turn of driver?'),
        showCancelButton: true,
        confirmButtonText: t('yes'),
        cancelButtonText: t('no'),
        confirmButtonColor:'#058600',
        cancelButtonColor:'#9f0000',
      })
      if (result.isConfirmed) {
        isRetainSpot = true
      }
    }

    if (!hasPermission) {
      setLoading(false);
      return;
    }

    ReAssignOrder(data, id, isRetainSpot)
      .then(() => {
        setLoadingSuccess(true);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-04");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => onReset());
  };

  const onCompleteOrder = (data, id) => {
    CompleteOrder(data, id, fare)
      .then(() => {
        setLoadingSuccess(true);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-05");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => onReset());
  };

  const onDriverOrder = (order, id) => {
    firestore()
      .collection("orders")
      .doc(id)
      .collection("subscribed_drivers")
      .orderBy("createdAt", "asc")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let dataDoc = doc.data();
          let obj = {};
          let { lastAssignedOrder = {} } = dataDoc;
          let assignedAt = getSeconds(lastAssignedOrder, "assignedAt");

          obj.subscribedAt = getTimeSubscribe(dataDoc);
          obj.lastAssigned = assignedAt ? getDiffTime(order, assignedAt) : "--:--";
          obj.orderId = id;
          obj.invoiceId = order.invoiceId;
          obj.dNumber = dataDoc.dNumber;
          obj.discardReason = dataDoc?.discardReason ?? "";
          obj.retainSpot = fromBoolToString(dataDoc, "retainSpot", t);
          obj.startedAt = getHours(order, "selectionStartedAt");
          obj.eta = fromEtaToMinutes(dataDoc);
          obj.etaS = dataDoc.eta ? dataDoc.eta : "--:--";
          obj.selected_driver = getCheckField(order, "assignedDriver", "dNumber");
          obj.isDriver = dataDoc.driverID === getCheckField(order, "assignedDriver", "id");
          data.push(obj);
        });
        setOpenDriversOrder(true);
        setDriversOrder(data);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-06");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSearchEtaOrder = async (data) => {
    try {
      let order = { ...data };
      let query = await firestore().collection("drivers").doc(order.assignedDriver.id).get();
      const driver = query.data();

      const { coordinates } = driver;

      let latitudeD = coordinates.latitude;
      let longitudeD = coordinates.longitude;
      let latitudeC = order.from.coordinates.latitude;
      let longitudeC = order.from.coordinates.longitude;

      // Aregar al setting
      let mapbox_map_key =
        "pk.eyJ1IjoiamFtZWppYSIsImEiOiJja2lxa2o1NzYwMDNjMnFxanE2eGNyNTgzIn0.hWpQbvuGLy4tPv4jUDhMtg";

      let tripDataTime = {};
      let meter;
      let time;
      let mi;
      const mapbox_url = `https://api.mapbox.com/directions/v5/mapbox/driving/${longitudeC}%2C${latitudeC}%3B${longitudeD}%2C${latitudeD}?alternatives=false&geometries=geojson&steps=false&access_token=${mapbox_map_key}`;

      try {
        const mapboxRequest = await fetch(mapbox_url);
        tripDataTime = await mapboxRequest.json();
        let mapbox = tripDataTime.routes[0];
        meter = mapbox ? mapbox.distance : 0;
        time = mapbox ? mapbox.duration : 0;
        mi = meter ? (meter * 0.00062137).toFixed(2) : "--:--";
      } catch (e) {
        mi = 0;
        time = 0;
      }
      setEtaInfo({
        minutes: fromEtaToMinutes({ eta: time }),
        seconds: (time && Number(time).toFixed(2)) || "--:--",
        mi: mi || "--:--",
        km: meter ? (meter / 1000).toFixed(2) : "--:--",
      });
      setShowEtaOrder(true);
      setLoading(false);
    } catch (e) {
      CaptureException(e, "Error-DAO-07");
      setLoading(false);
      setCAlert({ open: true, type: "error", ms: e.message });
    }
  };

  const onDuplicateOrder = (order) => {
    try {
      DuplicateOrder(order)
        .then(() => {
          setLoadingSuccess(true);
          onReset();
        })
        .catch((e) => {
          CaptureException(e, "AO");
          setCAlert({ open: true, type: "error", ms: e.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      CaptureException(e, "AO");
      setLoading(false);
      setCAlert({ open: true, type: "error", ms: e.message });
    }
  };

  const onBlockPhone = (order) => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    if (!order.client) {
      return Swal.fire("Error", "Client no found");
    }
    setLoading(true);
    const { email, phoneNumber } = order.client;
    return firestore()
      .collection("phone_blacklist")
      .doc(phoneNumber)
      .set({
        phone: phoneNumber,
        email,
        orderId: order.id,
        createdAt: timestamp,
      })
      .then(() => {
        setLoading(false);
        Swal.fire(t("successful_operation"));
      })
      .catch((e) => {
        setLoading(false);
        Swal.fire("Error", e);
      });
  };

  const onActions = async (type, id, isAppointment) => {
    try {
      let selected;
      let action;
      if (isAppointment) {
        let appointmentData = appointments.find((appointment) => appointment.id === id);
        selected = appointmentData?.order;
        selected.appointment = DateTime.fromSeconds(appointmentData?.date?.seconds)
          .toISO()
          .substring(0, 16);
        selected.offset = appointmentData?.offset;
        selected.isAppointment = true
      } else {
        selected = orders.find((order) => order.id === id);
        selected.isAppointment = false
      }

      selected.id = id;
      setActionsType(type);
      setOrderSelected(selected);
      switch (type) {
        case "restore":
          onRestoreOrder(selected, id);
          break;
        case "reassign":
          setCAlertInfo({ title: "reassign_ms", subtitle: "execute_action_ms" });
          setOpenCAlert(true);
          break;
        case "duplicate":
          setCAlertInfo({ title: "duplicate_order", subtitle: "execute_action_ms" });
          setOpenCAlert(true);
          break;
        case "complete":
          setCAlertInfo({ title: "complete_ms", subtitle: "execute_action_ms" });
          setOpenCAlert(true);
          break;
        case "block_phone":
          action = await Swal.fire({
            title: t("block_phone"),
            text: t("execute_action_ms"),
            showCancelButton: true,
            cancelButtonColor: "#c9c9c9",
            confirmButtonColor: "#2bb400",
          });
          if (action.isConfirmed) {
            onBlockPhone(selected);
          }

          break;
        case "payment":
          setOpenPayment(true);
          break;
        case "edit":
          dispatch(
            getOriginMarkerPosition({
              lat: selected.from.coordinates.latitude,
              lng: selected.from.coordinates.longitude,
            })
          );
          dispatch(postOrderSelected(selected));
          break;
        case "drivers":
          setLoading(true);
          onDriverOrder(selected, id);
          break;
        case "delete":
          setCAlertInfo({ title: "delete_ms", subtitle: "execute_action_ms" });
          setOpenCAlert(true);
          break;
        case "show":
          setShowOrder(true);
          break;
        case "map":
          dispatch(
            getOriginMarkerPosition({
              lat: selected.from.coordinates.latitude,
              lng: selected.from.coordinates.longitude,
            })
          );
          break;
        case "eta":
          setLoading(true);
          onSearchEtaOrder(selected).finally(() => {});
          break;
        case "clientRecord":
          setClientRecord((prevState) => ({ ...prevState, ...(selected.client.stats ?? {}) }));
          setClientRecordModal(true);
          break;
        default:
          break;
      }
    }catch (e){
      Swal.fire("Error", e.message);
    }

  };

  const onConfirmAction = () => {
    let selected = { ...orderSelected };
    switch (actionsType) {
      case "duplicate":
        setLoading(true);
        onDuplicateOrder(selected, selected.id);
        setOpenCAlert(false);
        break;
      case "complete":
        setLoading(true);
        onCompleteOrder(selected, selected.id);
        setOpenCAlert(false);
        break;
      case "delete":
        setLoading(true);
        onDeleteOrder(selected, selected.id, selected.isAppointment);
        setOpenCAlert(false);
        break;
      case "reassign":
        setLoading(true);
        onReAssignOrder(selected, selected.id);
        setOpenCAlert(false);
        break;
      default:
        break;
    }
  };

  const onUpdateClientRecord = () => {
    setLoading(true);
    const {
      client: { id },
    } = orderSelected;
    firestore()
      .collection("clients")
      .doc(id)
      .update({
        "stats.cancelled_orders": clientRecord.cancelled_orders
          ? Number(clientRecord.cancelled_orders)
          : 0,
        "stats.completed_orders": clientRecord.completed_orders
          ? Number(clientRecord.completed_orders)
          : 0,
      })
      .then(() => {
        setLoadingSuccess(true);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-04");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(onReset);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const onOrderError = (data, type) => {
    setLoading(true);

    if (type === "duplicate") {
      onDuplicateOrder(data);
      onDeleteOrder(data, data.id, false);
    }

    if (type === "delete") {
      onDeleteOrder(data, data.id, false);
    }
  };

  const organizeOrders = React.useMemo(
    () => onOrganizeOrders(orders, searchOrder, phoneBlacklist),
    [orders, searchOrder, phoneBlacklist]
  );

  return {
    appointments,
    organizeOrders,
    onActions,
    loading,
    setLoading,
    loadingSuccess,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    openCAlert,
    setOpenCAlert,
    onConfirmAction,
    cAlertInfo,
    driversOrder,
    openDriversOrder,
    onReset,
    showOrder,
    orderSelected,
    etaInfo,
    showEtaOrder,
    searchOrder,
    setSearchOrder,
    tabValue,
    handleChangeTab,
    fare,
    setFare,
    actionsType,
    openPayment,
    setOpenPayment,
    orderErrors,
    isOrderError,
    setIsOrderError,
    onOrderError,
    clientRecordModal,
    clientRecord,
    setClientRecord,
    onUpdateClientRecord,
  };
}
