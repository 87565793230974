import ActiveActions from "./active.actions";
import CChipByStatus from "../../../components/Custom/CChipByStatus";

export const activeColumns = [
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "actions",
    field: "actions",
    sortable: false,
    renderCell: ({ row }) => <ActiveActions onClick={row.func} data={row} />,
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "status",
    field: "status",
    renderCell: ({ row }) => <CChipByStatus status={row.status} />,
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "amount_paid",
    field: "amountPaid",
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "full_name",
    field: "displayName",
  },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "dNumber", field: "dNumber" },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "phoneNumber",
    field: "phoneNumber",
  },
];
