export const ORIGIN_MARKER_POSITION = "ORIGIN_MARKER_POSITION";
export function success(payload) {
  return {
    type: ORIGIN_MARKER_POSITION,
    payload,
  };
}

export function getOriginMarkerPosition(payload) {
  return async function f(dispatch) {
    dispatch(success(payload));
  };
}
