import React from "react";
import { jsPDF } from "jspdf";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import CsvDownloader from "react-csv-downloader";
import { DateTime } from "luxon";
import ReportsLineChart from "../../../examples/Charts/LineCharts/ReportsLineChart";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import { RangeDateData } from "../../../utilities/data";

function createHeaders(keys) {
  let result = [];
  for (let i = 0; i < keys.length; i += 1) {
    result.push({
      id: keys[i],
      name: keys[i],
      prompt: keys[i],
      width: 65,
      align: "center",
      padding: 0,
    });
  }
  return result;
}

const dateFormat = (date) => DateTime.fromFormat(date, "yLLdd").toFormat("LLL dd");
const getDayFormat = (date) => DateTime.fromJSDate(date).toFormat("dd");
const getMonthAndYear = (date) => DateTime.fromJSDate(date).toFormat("LLL y");

export default function DispatcherWorkdays({ data = [], filterValues }) {
  const { t } = useTranslation();

  const PdfFile = ({ downloadData }) => {
    try {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        floatPrecision: 16,
        compress: true,
      });

      let from;
      let to;

      if (filterValues.range) {
        from = new Date(filterValues.from);
        to = new Date(filterValues.to);
      } else {
        from = RangeDateData(filterValues.date).from;
        to = RangeDateData(filterValues.date).to;
      }

      let fromLabel = getDayFormat(from);
      let toLabel = getDayFormat(to);
      let monthLabel = getMonthAndYear(to);

      doc.text(
        `${t("dispatcher")}: ${toLabel} - ${fromLabel} ${monthLabel}`,
        105,
        10,
        null,
        null,
        "center"
      );

      let parserData = downloadData.map((d) => ({
        dispatcher: d.dispatcher,
        " orders ": d.qty.toString(),
        ...d,
      }));

      let parserDataRemovingSomeKeys = parserData.map((item) => {
        // eslint-disable-next-line no-param-reassign
        delete item.dates;
        // eslint-disable-next-line no-param-reassign
        delete item.driverId;
        // eslint-disable-next-line no-param-reassign
        delete item.qty;
        // eslint-disable-next-line no-param-reassign
        delete item.percentage;
        // eslint-disable-next-line no-param-reassign
        delete item.fare;
        // eslint-disable-next-line no-param-reassign
        delete item.driver;
        return item;
      });

      let dataSummary = [
        {
          " Orders ": _.sumBy(downloadData, (o) => o.qty).toString(),
        },
      ];

      const headersSummary = createHeaders([" Orders "]);

      let h = Object.keys(parserDataRemovingSomeKeys[0]);

      const headers = createHeaders(h);

      let fontSize = 7.5;
      doc.table(3, 12, dataSummary, headersSummary, { autoSize: false, fontSize });
      doc.table(3, 35, parserDataRemovingSomeKeys, headers, { autoSize: true, fontSize });
      doc.save(`dispatcher.pdf`);
    } catch (e) {
      //
    }
  };

  const reducerDriver = (info) => {
    const result = Object.values(info.filter((x) => x.driver !== "")).reduce(
      (acc, { dispatcherId, dispatcher, date }) => {
        acc[dispatcherId] = {
          dispatcher,
          qty: (acc[dispatcherId] ? acc[dispatcherId].qty || 0 : 0) + 1,
          dates: {
            [dateFormat(date)]: "X",
            ...(acc[dispatcherId] ? acc[dispatcherId].dates : {}),
          },
          ...(acc[dispatcherId] ? acc[dispatcherId].dates : {}),
        };
        return acc;
      },
      {}
    );
    return _.orderBy(Object.values(result), ["dispatcher"], ["asc"]);
  };

  const driversResult = React.useMemo(() => {
    let customFilter = data.filter((x) => x.source !== "App");

    console.log(data);

    const result = Object.values(customFilter).reduce((acc, { dispatcherId, dispatcher }) => {
      acc[dispatcherId] = {
        dispatcherId,
        qty: (acc[dispatcherId] ? acc[dispatcherId].qty || 0 : 0) + 1,
        dispatcher,
      };
      return acc;
    }, {});

    let groupByDriver = _.groupBy(customFilter, "dispatcher");

    let getDays = _.uniqBy(customFilter, "date").map((d) => ({
      id: dateFormat(d.date),
      displayName: dateFormat(d.date),
    }));

    let dateToObject = _.fromPairs(_.map(getDays, (i) => [i.id, "-"]));

    let getGroupByDriverByDate = Object.entries(groupByDriver).map((r) => ({
      driver: r[0],
      ...dateToObject,
      ...reducerDriver(r[1])[0],
    }));

    const columns = [
      { id: "dispatcher", displayName: "dispatcher" },
      { id: "qty", displayName: "Orders" },
      ...getDays,
    ];

    return {
      chartData: _.orderBy(Object.values(result), ["dispatcher"], ["asc"]),
      downloadData: getGroupByDriverByDate.map((r) => ({
        ...r,
      })),
      columns,
    };
  }, [data]);

  return (
    <>
      <ReportsLineChart
        cDescription="error"
        progress={100}
        title="dispatcher"
        description="It's recommended that this report have a maximum of 7 days so that the information can be appreciated when it is downloaded as a pdf."
        chart={{
          labels: driversResult ? _.map(driversResult.chartData, "dispatcher") : [],
          datasets: {
            label: t("orders"),
            data: driversResult ? _.map(driversResult.chartData, "qty") : [],
          },
        }}
      />

      <MDBox m={1} mt={5} display="flex" flexDirection="row" justifyContent="flex-end">
        <MDButton
          onClick={() => PdfFile(driversResult)}
          style={{ width: 200 }}
          variant="gradient"
          size="medium"
          color="error"
        >
          {t("download")} PDF
        </MDButton>

        <CsvDownloader
          bom={false}
          filename={t("dispatcher")}
          columns={driversResult.columns}
          datas={driversResult.downloadData ?? []}
          prefix={false}
          suffix={false}
        >
          <MDButton
            style={{ width: 200, marginLeft: 10 }}
            variant="gradient"
            size="medium"
            color="success"
          >
            {t("download")} Excel
          </MDButton>
        </CsvDownloader>
      </MDBox>
    </>
  );
}
