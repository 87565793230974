import { Card, CardContent } from "@mui/material";
import * as React from "react";
import CCardHeader from "../../../../components/Custom/CCardHeader";
import { useStyles } from "../../styles";
import CTabs from "./components/CTabs";
import Menu from "./components/Menu";
import Voices from "./components/Voices";
import Chat from "./chat";
import useData from "./data";

export default function MiniChat() {
  const {
    driverChat,
    selectedDriver,
    valueTab,
    handleChangeTab,
    audioRecord,
    onCloseChat,
    currentDriver,
  } = useData();
  const styles = useStyles();

  return (
    <Card style={styles.card}>
      <CCardHeader value="isMiniChat" title="chat" />
      <CardContent style={styles.cardContent}>
        <div style={{ overflow: "auto" }}>
          <CTabs driversChat={driverChat} handleChange={handleChangeTab} value={valueTab} />
        </div>

        {valueTab === 0 && <Menu selectedDriver={selectedDriver} />}
        {valueTab === 1 && <Voices data={audioRecord} />}
        {valueTab > 1 && <Chat onCloseChat={onCloseChat} driverId={currentDriver.id} />}
      </CardContent>
    </Card>
  );
}
