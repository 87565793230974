import React from "react";
import ApiClient from "api";
import { useSelector } from "react-redux";
import mapboxgl from "!mapbox-gl";
import { useMaterialUIController } from "../../../../../context";
import DriverInfo from "../../../../../components/DriverInfo";
import SearchDriverModal from "./SearchDriverModal";
import { AlertModel, DriverModel } from "../../../../../utilities/model";
import { firestore, auth, firebase } from "../../../../../firebase";
import { LogsUsers, driverColor, CaptureException } from "../../../../../utilities/func";
import CAlertSnackbar from "../../../../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../../../../components/Custom/CAlerts/CLoadingSnackbar";
import Header from "./Header";
import CAlert from "../../../../../components/Custom/CAlerts/CAlert";
import DriversLocation from "./DriversLocation";

export default function MapWithMarkers({ drivers = [], center, mapBoxApiKey }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, darkMode } = controller;


  const OriginMarkerPosition = useSelector(({ originMarkerPosition }) => originMarkerPosition);
  const Schedule = useSelector(({ schedule }) => schedule);

  const mapDrivers = React.useRef({});
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [driverModel, setDriverModel] = React.useState(new DriverModel());
  const [driverFilter, setDriverFilter] = React.useState("");
  const [driverSearch, setDriverSearch] = React.useState(null);
  const [openDriverInfo, setOpenDriverInfo] = React.useState(false);
  const [loadingRestart, setLoadingRestart] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [openDriverLocation, setOpenDriverLocation] = React.useState(false);
  const [openCAlert, setOpenCAlert] = React.useState(false);
  const map = React.useRef(null);
  const mapContainer = React.useRef(null);
  const [loadingPaymentStatusChange, setLoadingPaymentStatusChange] = React.useState(false);


  const zoomToDriver = () => {
    try {
      const found = drivers.find((driver) => driver.dNumber === driverFilter);
      if (found && found.coordinates && found.drivingStatus !== "offline") {
        map.current.flyTo({
          center: [found.coordinates.longitude, found.coordinates.latitude],
          zoom: 17,
          speed: 8,
          curve: 1,
          easing(easing) {
            return easing;
          },
        });
      }
    } catch (e) {
      CaptureException(e, "Error-DMA-01");
    }
  };

  const zoomToOrder = ({ coordinates }) => {
    try {
      const marker = new mapboxgl.Marker({
        color: "#000",
      })
        .setLngLat([coordinates.lng, coordinates.lat])
        .addTo(map.current);

      setTimeout(() => {
        if (marker) {
          marker?.remove();
        }
      }, 10000);

      map.current.flyTo({
        center: [coordinates.lng, coordinates.lat],
        zoom: 15,
        speed: 8,
        curve: 1,
        easing(easing) {
          return easing;
        },
      });
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    if (OriginMarkerPosition && OriginMarkerPosition.lng) {
      zoomToOrder({
        coordinates: { lng: OriginMarkerPosition.lng, lat: OriginMarkerPosition.lat },
      });
    }
  }, [OriginMarkerPosition]);

  React.useEffect(() => {
    zoomToDriver();
  }, [drivers, center, driverFilter]);

  React.useEffect(() => {
    if (miniSidenav) {
      setTimeout(() => {
        map.current?.resize();
      }, 250);
    }
  }, [miniSidenav]);

  React.useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      accessToken: mapBoxApiKey,
      attributionControl: false,
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [center.lng, center.lat],
      zoom: 12,
    });

    map.current.on("load", () => {
      try {
        if (drivers.length) {
          const driverList = [];
          drivers.forEach((d) => {
            if (d.coordinates && d.drivingStatus !== "offline") {
              if (d.coordinates.longitude) {
                driverList.push([d.coordinates.longitude, d.coordinates.latitude]);
              }
            }
          });
          if (map.current && driverList.length > 1) {
            map.current.fitBounds(driverList);
          }
          if (map.current && driverList.length === 1 && driverList[0]) {
            map.current.flyTo({
              center: driverList[0],
              zoom: 15,
              speed: 5,
              curve: 1,
              easing(easing) {
                return easing;
              },
            });
          }
        }
      } catch (e) {
        CaptureException(e, "Error-DMA-03");
      }
    });
  }, []);

  React.useEffect(()=>{
    if (map.current){
      map.current?.setStyle(darkMode? "mapbox://styles/mapbox/navigation-night-v1" : "mapbox://styles/mapbox/streets-v11");
    }
  },[map.current, darkMode])

  React.useEffect(() => {
    if (!map.current) return;
    map.current.on("click", (e) => {
      if (e.originalEvent.target) {
        const driver = e.originalEvent.target.getAttribute("driver");
        if (driver) {
          setOpenDriverInfo(true);
          const selected = [...drivers].find((d) => d.id === driver);
          if (selected) {
            setDriverModel((prevState) => {
              const { payment = {} } = selected;
              const { status: paymentStatus = "N/A" } = payment;
              return { ...prevState, ...selected, paymentStatus };
            });
          }
        }
      }
    });
  }, [drivers]);

  React.useEffect(() => {
    try {
      if (drivers && mapDrivers.current) {
        // eslint-disable-next-line no-restricted-syntax
        for (const marker of drivers) {
          if (marker.drivingStatus && marker.drivingStatus !== "offline") {
            if (!mapDrivers.current[marker.id]) {
              const el = document.createElement("div");
              el.style.backgroundColor = driverColor(marker.drivingStatus);
              el.style.color = "#000";
              el.style.padding = "4px";
              el.style.borderRadius = "4px";
              el.style.borderColor = marker?.appState === 'background' ? "#fff" : "#000";
              el.style.borderStyle = "solid";
              el.style.borderWidth = "3px";
              el.style.textAlign = "center";
              el.style.fontWeight = "bold";
              el.style.fontSize = "16px";
              el.style.cursor = "pointer";
              el.textContent = marker.dNumber;
              el.setAttribute("driver", marker.id);
              mapDrivers.current[marker.id] = new mapboxgl.Marker(el);
            }
          }

          if (marker.drivingStatus && marker.drivingStatus !== "offline" && marker.coordinates) {
            if (mapDrivers.current[marker.id]) {
              mapDrivers.current[marker.id]
                .setLngLat([marker.coordinates.longitude, marker.coordinates.latitude])
                .addTo(map.current);
              const element = mapDrivers.current[marker.id].getElement();
              element.style.backgroundColor = driverColor(marker.drivingStatus);
              element.style.borderColor = marker?.appState === 'background' ? "#fff" : "#000";
            }
          }

          if (marker.drivingStatus && marker.drivingStatus === "offline") {
            if (mapDrivers.current[marker.id]) {
              mapDrivers.current[marker.id].remove();
              delete mapDrivers.current[marker.id];
            }
          }
        }
      }
    } catch (e) {
      CaptureException(e, "Error-DMA-04");
    }
  }, [mapDrivers.current, drivers]);

  const saveDriverData = async () => {
    setLoading(true);
    setOpenDriverInfo(false);

    let newData = {}

    if (driverModel.withSchedule){
      if (Schedule){
        newData.workingHours = Schedule.schedule
      }
    }

    try {
      await ApiClient.put(`drivers/${driverModel.id}`, {
        dNumber: driverModel.dNumber,
        driverId: driverModel.driverId,
        email: driverModel.email,
        firstName: driverModel.firstName,
        lastName: driverModel.lastName,
        license: driverModel.license,
        licenseState: driverModel.licenseState,
        phoneNumber: driverModel.phoneNumber,
        status: driverModel.isStatus ? "disabled" : "active",
        vehicleType: driverModel.vehicleType,
      });
      const status = driverModel.isStatus ? "offline" : driverModel.drivingStatus;
      await firestore()
        .collection("drivers")
        .doc(driverModel.id)
        .update({
          updatedBy: auth().currentUser.uid,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          drivingStatus: driverModel.isDrivingStatus ? "offline" : status,
          autoMarkedAsPaid: driverModel.autoMarkedAsPaid,
          withSchedule: driverModel.withSchedule,
          hasAutoOrders: driverModel.hasAutoOrders,
          hasPayment: driverModel.hasPayment,
          hasPaymentReader: driverModel.hasPaymentReader,
          isGeofence: driverModel.isGeofence,
          isVisibleUpdated: driverModel.isVisibleUpdated,
          "payment.amount": driverModel.amount ? Number(driverModel.amount) : 0,
          hasPendingPayment: driverModel.hasPendingPayment ?? false,
          ...newData,
        });
      LogsUsers({ data: driverModel, type: "driver" });
      setLoading(false);
      setLoadingSuccess(true);
      setDriverModel(new DriverModel());
    } catch (e) {
      setCAlert({ open: true, type: "error", ms: e.message });
      setLoading(false);
      setOpenDriverInfo(false);
      CaptureException(e, "Error-DMA-05");
    }
  };

  const onClickDeactivation = async () => {
    setLoading(true);
    setOpenDriverInfo(false);
    await ApiClient.post(`drivers/${driverModel.id}/deactivate`, {
      activateAfter: driverModel.suspendTime,
    })
      .then(() => {
        LogsUsers({ data: driverModel, type: "deactivate" });
        setLoading(false);
        setLoadingSuccess(true);
      })
      .catch((e) => {
        setLoading(false);
        setCAlert({ open: true, type: "error", ms: e.message });
        setLoading(false);
        setOpenDriverInfo(false);
        CaptureException(e, "Error-DMA-06");
      });
  };

  const onChange = (e, select, sName) => {
    const { name, value, checked, type } = e.target;
    const Value = type === "checkbox" ? checked : value;
    if (sName) {
      return setDriverModel((prevState) => ({ ...prevState, [sName]: select }));
    }
    return setDriverModel((prevState) => ({ ...prevState, [name]: Value }));
  };

  const onSearchDriver = () => {
    if (driverSearch) {
      const selected = [...drivers].find((d) => d.id === driverSearch.id);
      if (selected) {
        setOpenSearch(false);
        setDriverModel((prevState) => {
          const { payment = {} } = selected;
          const { status: paymentStatus = "N/A" } = payment;
          return { ...prevState, ...selected, paymentStatus };
        });
        setOpenDriverInfo(true);
      }
    }
  };

  const onChangeDriver = (e, select) => {
    setDriverSearch(select);
  };

  const onLogoffInactiveDrivers = () => {
    setLoading(true);
    setOpenCAlert(false);
    ApiClient.post("drivers/logoff-inactive")
      .then(() => {
        setLoadingSuccess(true);
      })
      .catch((e) => {
        CaptureException(e, "Error-DMA-06");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => setLoading(false));
  };

  const onRestart = async () => {
    setLoadingRestart(true);
    let ref = await firestore().collection("drivers").doc(driverModel.id);
    ref.update({
      restartBy: auth().currentUser.uid,
      isRestart: true,
    });

    setLoadingSuccess(true);
    setLoadingRestart(false);
  };

  const onPaymentStatusChange = async (paymentStatus) => {
    setLoadingPaymentStatusChange(true);

    let ref = firestore().collection("drivers").doc(driverModel.id);

    try {
      await ref.set(
        {
          payment: { status: paymentStatus },
        },
        { merge: true }
      );
      setDriverModel((prevState) => ({ ...prevState, paymentStatus }));
      setLoadingSuccess(true);
    } catch (e) {
      setLoadingSuccess(false);
    } finally {
      setLoadingPaymentStatusChange(false);
    }
  };

  return (
    <div>
      <Header
        driverFilter={driverFilter}
        setDriverFilter={setDriverFilter}
        drivers={drivers}
        setOpenSearch={setOpenSearch}
        setOpenDriverLocation={setOpenDriverLocation}
        onLogoffInactiveDrivers={() => setOpenCAlert(true)}
      />

      <div
        id="markers"
        ref={mapContainer}
        style={{ height: 480, width: "100%", borderRadius: 5 }}
      />

      {openDriverInfo && (
        <DriverInfo
          onClickDeactivation={onClickDeactivation}
          open={openDriverInfo}
          close={setOpenDriverInfo}
          data={driverModel}
          onChange={onChange}
          onClick={saveDriverData}
          loadingRestart={loadingRestart}
          onRestart={onRestart}
          loadingPaymentStatusChange={loadingPaymentStatusChange}
          onPaymentStatusChange={onPaymentStatusChange}
        />
      )}

      {openSearch && (
        <SearchDriverModal
          value={driverSearch}
          onChange={onChangeDriver}
          open={openSearch}
          onClick={onSearchDriver}
          close={setOpenSearch}
          options={drivers}
        />
      )}

      {openDriverLocation && (
        <DriversLocation open={openDriverLocation} close={setOpenDriverLocation} options={drivers} />
      )}

      <CAlertSnackbar alert={cAlert} close={setCAlert} />
      <CLoadingSnackbar open={loading} />
      <CAlert
        close={setOpenCAlert}
        open={openCAlert}
        title="logoff_inactive_drivers"
        subtitle="logoff_inactive_drivers_ms"
        isConfirm={onLogoffInactiveDrivers}
      />
      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />
    </div>
  );
}
