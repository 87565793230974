import { TextField } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import CAutocomplete from "../../../components/Custom/CAutocomplete";
import { dateOptions } from "../../../utilities/data";

export default function HeaderOptions({
  filterValues,
  onChangeFilter,
  onFilterData,
  filtersOn,
  onCancelFilter,
}) {
  const { t } = useTranslation();

  const marginRight = 10;

  const isDisabled = React.useMemo(() => {
    if (filterValues.range) {
      return !(filterValues.to && filterValues.from);
    }

    if (filterValues.invoiceId) return false;

    return !filterValues.date;
  }, [filterValues]);

  return (
    <>
      <MDBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
      >
        <TextField
          style={{ marginRight }}
          size="small"
          value={filterValues.phoneNumber}
          onChange={(e) => onChangeFilter(e.target.value, "phoneNumber")}
          label={t("phoneNumber")}
          sx={{ width: 200 }}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          style={{ marginRight }}
          size="small"
          value={filterValues.email}
          onChange={(e) => onChangeFilter(e.target.value, "email")}
          label={t("email")}
          sx={{ width: 200 }}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          style={{ marginRight }}
          size="small"
          value={filterValues.invoiceId}
          onChange={(e) => onChangeFilter(e.target.value, "invoiceId")}
          label={t("invoice_id")}
          sx={{ width: 200 }}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <div style={{ width: 200, marginRight }}>
          <CAutocomplete
            value={filterValues.date}
            isLabel
            name="date"
            placeholder="date"
            options={dateOptions}
            onChange={(e, v, n) => onChangeFilter(v, n)}
          />
        </div>

        <MDButton
          disabled={isDisabled}
          onClick={onFilterData}
          style={{ width: 150 }}
          variant="gradient"
          size="medium"
          color="info"
        >
          {t("accept")}
        </MDButton>

        {filtersOn && (
          <MDButton
            onClick={onCancelFilter}
            style={{ width: 150, marginLeft: marginRight }}
            variant="gradient"
            size="medium"
            color="error"
          >
            {t("cancel")}
          </MDButton>
        )}
      </MDBox>
    </>
  );
}
