import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CPaper from "../../../../../components/Custom/CPaper";
import TSText from "../../../../../components/Custom/TSText";
import { getCheckField } from "../../../../../utilities/func";
import MDTypography from "../../../../../components/MDTypography";

export default function Address({ data, type }) {
  const { t } = useTranslation();
  return getCheckField(data, type, "address") ? (
    <CPaper style={{ marginTop: 10 }}>
      <MDTypography variant="h4">{t(type)}:</MDTypography>
      <Grid container>
        <Grid item xs={12} sm={4} lg={4}>
          <TSText
            variant="h6"
            title={getCheckField(data, type, "streetNumber")}
            subtitle="street_number"
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <TSText variant="h6" title={getCheckField(data, type, "street")} subtitle="street" />
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <TSText variant="h6" title={getCheckField(data, type, "city")} subtitle="city" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TSText variant="h6" title={getCheckField(data, type, "address")} subtitle="address" />
        </Grid>
      </Grid>
    </CPaper>
  ) : null;
}
