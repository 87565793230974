import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as React from "react";
import CTable from "../../components/Custom/CTable";
import useData from "./data";
import { activeColumns } from "./components/columns";
import HeaderOptions from "./components/HeaderOptions";
import CAlertSnackbar from "../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../components/Custom/CAlerts/CLoadingSnackbar";
import ChangePassword from "./components/ChangePassword";
import UserModal from "./components/UserModal";

function Users() {
  const {
    tableData,
    loading,
    loadingSuccess,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    showPassword,
    onChange,
    password,
    setPassword,
    changePassword,
    showNew,
    setShowNew,
    model,
    onReset,
    onActionData,
  } = useData();

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <CTable
          canSearch
          columns={activeColumns}
          data={tableData}
          pageSize={25}
          headerOptions={<HeaderOptions addDriver={() => setShowNew(true)} />}
        />
        <Footer />
      </DashboardLayout>

      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />

      <CLoadingSnackbar open={loading} />

      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />

      <ChangePassword
        open={showPassword}
        close={onReset}
        password={password}
        setPassword={setPassword}
        onClick={changePassword}
      />

      <UserModal
        model={model}
        open={showNew}
        close={onReset}
        onClick={changePassword}
        onChange={onChange}
        onCreate={onActionData}
      />
    </>
  );
}

export default Users;
