import * as Sentry from "@sentry/react";
import { firestore } from "../../firebase";

export const SETTINGS_SUCCESS = "SETTINGS_SUCCESS";

export function Success(payload) {
  return {
    type: SETTINGS_SUCCESS,
    payload,
  };
}

export function getSettings() {
  return async function f(dispatch) {
    try {
      firestore()
        .collection("settings")
        .doc("dashboard")
        .onSnapshot((querySnapshot) => {
          if (querySnapshot.data() !== undefined) {
            const data = querySnapshot.data();
            dispatch(Success(data));
          }
        });

      // firestore()
      //   .collection("settings")
      //   .doc("settings")
      //   .onSnapshot((querySnapshot) => {
      //     if (querySnapshot.data() !== undefined) {
      //       const data = querySnapshot.data();
      //       dispatch(Success(data.dashboard));
      //       firestore()
      //         .collection("settings")
      //         .doc("dashboard")
      //         .set({ ...data.dashboard }, { merge: true })
      //         .catch((e) => {});
      //     }
      //   });
    } catch (e) {
      Sentry.captureException(e);
    }
  };
}
