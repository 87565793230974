import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import CDialog from "../../../../../components/Custom/CDialog";
import MDBox from "../../../../../components/MDBox";
import CPaper from "../../../../../components/Custom/CPaper";
import TSText from "../../../../../components/Custom/TSText";
import TSTextClipboard from "../../../../../components/Custom/TSTextClipboard";
import { getDate, getCheckField } from "../../../../../utilities/func";
import Address from "./Address";
import Client from "./Client";
import Info from "./Info";

export default function DriverOrderModal({ open, close, data }) {
  const { t } = useTranslation();

  return (
    <CDialog maxWidth="xl" title="order" subtitle={data.id} close={close} open={open}>
      <CPaper style={{ marginTop: 10 }}>
        <Grid container>
          <Grid item xs={12} sm={4} lg={3}>
            <TSTextClipboard
              variant="h6"
              text={data.invoiceId}
              copy={data.orderId}
              subtitle="invoice_id"
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TSText variant="h6" title={getDate(data)} subtitle="date" />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TSText
              variant="h6"
              title={getCheckField(data, "assignedDriver", "dNumber")}
              subtitle="dNumber"
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TSText variant="h6" title={data.notes} subtitle="notes" />
          </Grid>
        </Grid>
      </CPaper>
      <br />
      <Address data={data} type="from" />
      <br />
      <Address data={data} type="to" />
      <br />
      <Client data={data} type="client" />
      <br />
      <Info data={data} type="info" />
      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton onClick={() => close(false)} variant="gradient" color="error">
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
