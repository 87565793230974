import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Typography, ListItemIcon } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import RestoreIcon from "@mui/icons-material/Restore";
import PeopleIcon from "@mui/icons-material/People";
import DeleteIcon from "@mui/icons-material/Delete";
import Directions from "@mui/icons-material/Directions";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AddCardIcon from "@mui/icons-material/AddCard";
import DifferenceIcon from "@mui/icons-material/Difference";
import PhoneLockedIcon from "@mui/icons-material/PhoneLocked";
import MapIcon from "@mui/icons-material/Map";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "../../context";

export default function CMenu({
  onClick,
  title,
  isCancellable,
  status,
  isAppointment,
  isPaymentCard,
  isSubscribed,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type) => {
    setAnchorEl(null);
    if (typeof type === "string" && type !== "close") {
      onClick(type);
    }
  };

  return (
    <>
      <IconButton
        style={{ padding: 0, margin: 0 }}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        aria-controls={open ? "basic-menu" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: "#fff" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem disableRipple divider>
          <Typography variant="inherit">{title}</Typography>
        </MenuItem>

        {!isAppointment && status === "unassigned" && isCancellable && (
          <MenuItem onClick={() => handleClose("restore")} disableRipple>
            <ListItemIcon>
              <RestoreIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("restore")}</Typography>
          </MenuItem>
        )}

        {!isAppointment && status !== "unassigned" && (
          <MenuItem onClick={() => handleClose("reassign")} disableRipple>
            <ListItemIcon>
              <CarRepairIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("reassign")}</Typography>
          </MenuItem>
        )}

        {!isAppointment && status !== "unassigned" && (
          <MenuItem onClick={() => handleClose("complete")} disableRipple>
            <ListItemIcon>
              <DoneAllIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("complete_order")}</Typography>
          </MenuItem>
        )}

        {isPaymentCard && !isAppointment && status !== "unassigned" && (
          <MenuItem onClick={() => handleClose("payment")} disableRipple>
            <ListItemIcon>
              <AddCardIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("payment_with_card")}</Typography>
          </MenuItem>
        )}

        {isAppointment && (
          <MenuItem onClick={() => handleClose("edit")}>
            <ListItemIcon>
              <EditIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("edit")}</Typography>
          </MenuItem>
        )}

        {!isAppointment && isCancellable && (
          <MenuItem onClick={() => handleClose("edit")}>
            <ListItemIcon>
              <EditIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("edit")}</Typography>
          </MenuItem>
        )}

        <MenuItem onClick={() => handleClose("block_phone")}>
          <ListItemIcon>
            <PhoneLockedIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("block_phone")}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleClose("duplicate")}>
          <ListItemIcon>
            <DifferenceIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("duplicate_order")}</Typography>
        </MenuItem>

        {!isAppointment && isSubscribed && (
          <MenuItem onClick={() => handleClose("drivers")}>
            <ListItemIcon>
              <PeopleIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("drivers")}</Typography>
          </MenuItem>
        )}

          <MenuItem onClick={() => handleClose("delete")}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("cancel_order")}</Typography>
          </MenuItem>

        <MenuItem onClick={() => handleClose("show")}>
          <ListItemIcon>
            <VisibilityIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("show")}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleClose("map")}>
          <ListItemIcon>
            <MapIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("see_map")}</Typography>
        </MenuItem>

        {!isAppointment && status === "assigned" && (
          <MenuItem onClick={() => handleClose("eta")}>
            <ListItemIcon>
              <Directions fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("eta")}</Typography>
          </MenuItem>
        )}

        <MenuItem onClick={() => handleClose("close")}>
          <ListItemIcon>
            <CloseIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("close")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
