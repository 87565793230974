import * as Sentry from "@sentry/react";

export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";

export function Success(payload) {
  return {
    type: USER_INFO_SUCCESS,
    payload,
  };
}

export function getUserInfo(data) {
  return async function f(dispatch) {
    try {
      dispatch(Success(data));
    } catch (e) {
      Sentry.captureException(e);
    }
  };
}
