import { isNaN } from "lodash";
import { firestore } from "../../../../firebase";

const checkActiveCallByPhone = async (number) => {
  try {
    let startOfDay = new Date();
    let endOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    endOfDay.setHours(23, 59, 59, 59);
    const querySnapshot = await firestore()
      .collection("orders")
      .where("client.phoneNumber", "==", number)
      .where("status", "in", ["assigned", "inProcess", "unassigned"])
      .where("createdAt", ">=", startOfDay)
      .where("createdAt", "<=", endOfDay)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();
    let data = {};
    querySnapshot.forEach((docs) => {
      data = docs.data();
      data.id = docs.id;
    });
    return data;
  } catch (e) {
    return null;
  }
};

const checkActiveCallByAddress = async (address) => {
  try {
    let startOfDay = new Date();
    let endOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    endOfDay.setHours(23, 59, 59, 59);
    const querySnapshot = await firestore()
      .collection("orders")
      .where("from.street", "==", address.street)
      .where("from.streetNumber", "==", address.streetNumber)
      .where("status", "in", ["assigned", "inProcess", "unassigned"])
      .where("createdAt", ">=", startOfDay)
      .where("createdAt", "<=", endOfDay)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();
    let data = {};
    querySnapshot.forEach((docs) => {
      data = docs.data();
      data.id = docs.id;
    });
    return data;
  } catch (e) {
    return null;
  }
};

const getClientByPhone = async (phoneNumber) => {
  const clientsRef = firestore().collection("clients");
  const clientSnapshot = await clientsRef.where("phoneNumber", "==", phoneNumber).limit(1).get();

  let client = null;

  clientSnapshot.forEach((doc) => {
    const {
      firstName,
      lastName,
      email,
      displayName = "",
      stats = {},
      blocked = false,
      blockedNotes = "",
    } = doc.data();
    client = {
      id: doc.id,
      firstName,
      lastName,
      displayName,
      email,
      phoneNumber,
      stats,
      blocked,
      blockedNotes,
    };
  });

  if (client) {
    const completedOrdersRef = firestore()
      .collection("clients")
      .doc(client.id)
      .collection("orders");

    const ordersSnapshot = await completedOrdersRef
      .where("status", "==", "completed")
      .orderBy("createdAt", "desc")
      .limit(10)
      .get();

    const lastAddresses = [];

    ordersSnapshot.forEach((doc) => {
      const { from } = doc.data();
      lastAddresses.push({
        ...from,
      });
    });

    client.lastAddresses = lastAddresses;
  }

  return client;
};

const getLocationsFields = (addressInfo) => {
  let {
    coordinates = {},
    address = "",
    city = "",
    streetNumber = "",
    street_number = "",
    street = "",
    suite = "",
  } = addressInfo;

  const latitude = coordinates && coordinates.latitude ? coordinates.latitude : "";
  const longitude = coordinates && coordinates.longitude ? coordinates.longitude : "";

  street = street || address.split(",").reverse()[3];
  if (street) {
    const stArr = street.split(" ");
    if (!isNaN(Number(stArr[0]))) street = stArr.slice(1).join(" ");
  }

  return {
    latitude,
    longitude,
    address,
    city,
    streetNumber: street_number || streetNumber,
    street,
    suite,
  };
};

const getStreet = (address) => {
  let split = address.trim().split(",");

  if (split.length === 1) return split[0];

  let street = split.reverse()[3];
  if (street) {
    let st_arr = street.split(" ");
    if (!isNaN(Number(st_arr[0]))) street = st_arr.slice(1).join(" ");
  }
  return street;
};

const maskString = (value) => {
  if (!value) {
    return "";
  }

  let v = value.toString();

  if (v.length < 3) {
    return "";
  }
  let toShow = v.length - 2;

  let result = v.slice(0, toShow);
  // eslint-disable-next-line no-plusplus
  for (let i = toShow - 1; i < v.length - 1; i++) {
    result += "*";
  }
  return result;
};

export {
  checkActiveCallByPhone,
  checkActiveCallByAddress,
  getClientByPhone,
  getLocationsFields,
  getStreet,
  maskString,
};
