import React from "react";
import CDialog from "../../../components/Custom/CDialog";
import HeaderOptionsSchedule from "./HeaderOptionsSchedule";
import { schedulesColumns } from "./columns";
import CTable from "../../../components/Custom/CTable";

export default function ScheduleModal({ open, close, scheduleList, addSchedule }) {
  return (
    <CDialog title="schedules" close={close} open={open} fullScreen>
      <br />
      <HeaderOptionsSchedule
        addSchedule={() => addSchedule(true)}
        onClose={() => close(false)}
      />
      <br />
      <CTable
        canSearch={false}
        columns={schedulesColumns}
        data={scheduleList}
        pageSize={5}
        hideFooterPagination
        hideFooter
        height={300}
      />

    </CDialog>
  );
}
