import React from "react";
import { useSelector } from "react-redux";
import PlacesAutocompleteControlled from "./PlacesAutocompleteControlled";
import GooglePlacesAutocompleteControlled from "./GooglePlacesAutocompleteControlled";

const PlacesAutocomplete = (props) => {
  const dashboardSettings = useSelector(({ settings }) => settings);

  const autocompleteSettings = {
    autocompleteType: dashboardSettings.autocompleteType,
    autocompleteCountry: dashboardSettings.autocompleteCountry,
    autocompleteLimit: dashboardSettings.autocompleteLimit,
    mapCenter: dashboardSettings.mapCenter,
    hereApiKey: dashboardSettings.hereApiKey,
    mapBoxApiKey: dashboardSettings.mapBoxApiKey,
    mapBoxBbox: dashboardSettings.mapBoxBbox,
    geoapifyApiKey: dashboardSettings.geoapifyApiKey,
    geoapifyFilter: dashboardSettings.geoapifyFilter,
    gmapsApiKey: dashboardSettings.gmapsApiKey,
  };

  if (dashboardSettings.autocompleteType === "gmaps") {
    return <GooglePlacesAutocompleteControlled {...props} {...autocompleteSettings} />;
  }

  return <PlacesAutocompleteControlled {...props} {...autocompleteSettings} />;
};

export default PlacesAutocomplete;
