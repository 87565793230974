import { Paper } from "@mui/material";
import React from "react";

import { useMaterialUIController } from "../../context";

export default function CPaper({ children, style, elevation = 5 }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Paper
      elevation={elevation}
      style={{ backgroundColor: darkMode ? "#202940" : "#fff", padding: 10, ...style }}
    >
      {children}
    </Paper>
  );
}
