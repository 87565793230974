import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import MDInput from "../../../components/MDInput";
import MDBox from "../../../components/MDBox";
import CDialog from "../../../components/Custom/CDialog";
import MDTypography from "../../../components/MDTypography";

const base64ToFile = (base64, type) => {
  let mime = `data:${type};base64,`;
  return `${mime}${base64}`;
};

export default function ShowModal({
  open,
  close,
  model,
  ticketListMessage,
  onChange,
  onResponseTicket,
}) {
  const { t } = useTranslation();

  return (
    <CDialog title="help_support" subtitle={model.invoiceId} close={close} open={open}>
      <MDBox display="flex" flexDirection="column">
        <MDInput
          fullWidth
          value={model.message || ""}
          onChange={onChange}
          name="message"
          style={{ marginBottom: 10, marginTop: 10 }}
          label={`${t("message")}*`}
          type="text"
        />
        <br />
        <FormControl>
          <MDTypography variant="h5">{t("status")}</MDTypography>
          <MDTypography variant="small">{t("status_info")}.</MDTypography>
          <RadioGroup value={model.ticketStatus} onChange={onChange} row name="ticketStatus">
            <FormControlLabel value="open" control={<Radio />} label={t("open_ticket")} />
            <FormControlLabel value="closed" control={<Radio />} label={t("closed_ticket")} />
          </RadioGroup>
        </FormControl>
        <br />
        <MDButton
          onClick={onResponseTicket}
          disabled={!(model.message && model.message.length !== 0)}
          variant="gradient"
          color="success"
        >
          {t("send")}
        </MDButton>
        <br />
      </MDBox>
      <MDTypography variant="h5" mb={2}>
        {t("messages")}:
      </MDTypography>
      {ticketListMessage.map((complaints) => {
        let wtIs = complaints.source !== "client";

        return (
          <Card
            style={{
              marginBottom: 15,
              backgroundColor: wtIs ? "rgba(13,204,0,0.1)" : "rgba(255,0,0,0.1)",
            }}
            key={complaints.id}
          >
            <CardHeader
              title={`${t("by")} : ${t(wtIs ? "admin" : "client")}`}
              subheader={complaints.createdAt}
            />
            <CardContent>
              <MDTypography variant="small">{t("message")}:</MDTypography>
              <MDTypography variant="h5">{complaints.message}</MDTypography>

              {complaints.photos && (
                <ImageList
                  sx={{ width: 500, height: "auto", maxHeight: 400, marginTop: 3 }}
                  cols={3}
                  rowHeight={164}
                >
                  {complaints.photos.map((item) => (
                    <ImageListItem key={item.fileName}>
                      <img
                        src={base64ToFile(item.base64, item.type)}
                        srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.fileName}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              )}
            </CardContent>
          </Card>
        );
      })}
      <br />
    </CDialog>
  );
}
