import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import MDTypography from "../../../components/MDTypography";

export default function Header({ analytics }) {
  const { t } = useTranslation();
  const dashboardSettings = useSelector(({ settings }) => settings);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <MDBox mb={2}>
      <Card sx={{ borderRadius: 1 }}>
        <MDBox
          py={1}
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
        >
          {dashboardSettings.isFare && (
            <MDBox alignItems="center" display="flex" flexDirection="column">
              <MDTypography variant="h3">{formatter.format(analytics.money)}</MDTypography>
              <MDTypography>{t("money")}</MDTypography>
            </MDBox>
          )}

          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">
              {analytics.completed +
                analytics.reassigned +
                analytics.cancelled +
                analytics.assigned +
                analytics.unassigned +
                analytics.inProcess}
            </MDTypography>
            <MDTypography>{t("orders")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">{analytics.app}</MDTypography>
            <MDTypography>{t("mobile")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">{analytics.completed}</MDTypography>
            <MDTypography>{t("completed")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">{analytics.reassigned}</MDTypography>
            <MDTypography>{t("reassigned")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">{analytics.passOrder}</MDTypography>
            <MDTypography>{t("pass_order")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">
              {analytics.assigned + analytics.unassigned + analytics.inProcess}
            </MDTypography>
            <MDTypography>{t("open")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">{analytics.cancelled}</MDTypography>
            <MDTypography>{t("failed")}</MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  );
}
