import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as React from "react";
import { useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import { CaptureException } from "../../utilities/func";

function Zones() {
  const drivers = useSelector(({ driverList }) => driverList);
  const dashboardSettings = useSelector(({ settings }) => settings);

  const { mapBoxApiKeyForMap } = dashboardSettings;
  const center = dashboardSettings.mapCenter;

  const mapContainer = React.useRef(null);
  const map = React.useRef(null);

  React.useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      accessToken: mapBoxApiKeyForMap,
      attributionControl: false,
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [center.lng, center.lat],
      zoom: 12,
    });
    map.current.on("load", () => {
      try {
        if (drivers.length) {
          const driverList = [];
          drivers.forEach((d) => {
            if (d.coordinates && d.drivingStatus !== "offline") {
              if (d.coordinates.longitude) {
                driverList.push([d.coordinates.longitude, d.coordinates.latitude]);
              }
            }
          });
          if (map.current && driverList.length > 1) {
            map.current.fitBounds(driverList);
          }
          if (map.current && driverList.length === 1 && driverList[0]) {
            map.current.flyTo({
              center: driverList[0],
              zoom: 15,
              speed: 5,
              curve: 1,
              easing(easing) {
                return easing;
              },
            });
          }
        }
      } catch (e) {
        CaptureException(e, "Error-DMA-03");
      }
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div
        id="markers"
        ref={mapContainer}
        style={{ height: "80vh", width: "100%", borderRadius: 5 }}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default Zones;
