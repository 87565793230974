import React from "react";
import { useTranslation } from "react-i18next";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import ClipboardText from "./ClipboardText";

export default function TSTextClipboard({ subtitle = "", variant = "h4", text, copy }) {
  const { t } = useTranslation();
  return (
    <MDBox>
      <ClipboardText variant={variant} text={text} copy={copy} />
      <MDTypography variant="text">{t(subtitle)}</MDTypography>
    </MDBox>
  );
}
