import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";

export default function Footer({
  onCancel,
  onCreate,
  phoneNumber,
  orderModel,
  isAppointment,
  appointmentDate,
  isDestination,
                                 loading,
}) {
  const { t } = useTranslation();

  const isDisabled = React.useMemo(() => {
    let { address } = orderModel.from;
    return !((phoneNumber.length > 0 ? phoneNumber.length === 10 : true) && !!address.length);
  }, [phoneNumber, orderModel.from.address]);



  const isTo = React.useMemo(() => {
    let { address } = orderModel.to;
    if (isDestination) {
      return !address.length;
    }
    return false;
  }, [isDestination, orderModel.to.address]);

  const isBadDate = React.useMemo(() => {
    if (isAppointment) {
      if (!appointmentDate.length) {
        return true;
      }
      const appointmentDatetime = new Date(appointmentDate);
      return !(appointmentDatetime.getTime() > Date.now());
    }
    return false;
  }, [appointmentDate, isAppointment]);


  const onCreateOrder = () => {
    onCreate("create")
  }

  return (
    <MDBox
      mt={2}
      flexDirection="row"
      display="flex"
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid container alignItems="center" justifyContent="space-around" spacing={1}>
        <Grid item xs={12} md={5} lg={5} xl={5}>
          <MDButton
            fullWidth
            disabled={isDisabled || isBadDate || isTo || loading}
            loading={loading}
            onClick={onCreateOrder}
            variant="gradient"
            size="medium"
            color={orderModel.id ? "info" : "success"}
          >
            {orderModel.id ? t("update") : t("create")}
          </MDButton>
        </Grid>

        <Grid item xs={12} md={5} lg={5} xl={5}>
          <MDButton fullWidth onClick={onCancel} variant="gradient" size="medium" color="error">
            {t("cancel")}
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}
