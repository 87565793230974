import Swal from "sweetalert2";
import _ from "lodash";

export default async function SwalWithField({ title, confirmText, cancelText, fields = [] }) {
  const result = await Swal.fire({
    title,
    html: `
        <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start; width: 100%; padding: 10px">
         ${fields
           .map(
             (field) =>
               `  <div style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; margin-bottom: 20px">
                    <label for="${field.ph}" style="margin-bottom: 3px">${field.ph}:</label>
                   <input required type="${field.type}" id="swal-${field.name}" placeholder="${field.phValue}" class="swal2-input" style="width: 100%; margin: 0; ">
                 </div>`
           )
           .join("")}
       </div>
      `,
    preConfirm: () => {
      fields.forEach((field) => {
        if (!document.getElementById(`swal-${field.name}`).value) {
          Swal.showValidationMessage(`Request failed: ${field.ph} is required`);
        }
      });
    },
    showCloseButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonColor: "#01579b",
    cancelButtonColor: "#78909c",
    focusConfirm: true,
    confirmButtonText: confirmText,
    cancelButtonText: cancelText,
  });

  const data = fields.map((field) => ({
    key: field.name,
    value: document.getElementById(`swal-${field.name}`).value,
  }));

  return {
    ...result,
    data: _.fromPairs(_.map(data, (i) => [i.key, i.value])),
  };
}
