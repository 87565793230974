import React from "react";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import { Grid } from "@mui/material";
import MDTypography from "../MDTypography";

export default function TSSwitch({ subtitle, value, onChange, name, direction = "column" }) {
  const { t } = useTranslation();

  return (
    <Grid container direction={direction} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={4} lg={3}>
        <Switch
          inputProps={{ "aria-label": `controlled-${name}` }}
          checked={value}
          name={name}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={3}>
        <MDTypography variant="text">{t(subtitle)}</MDTypography>
      </Grid>
    </Grid>
  );
}
