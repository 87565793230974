import React from "react";
import { jsPDF } from "jspdf";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import CsvDownloader from "react-csv-downloader";
import { DateTime } from "luxon";
import ReportsLineChart from "../../../examples/Charts/LineCharts/ReportsLineChart";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import { RangeDateData } from "../../../utilities/data";

function createHeaders(keys) {
  let result = [];
  for (let i = 0; i < keys.length; i += 1) {
    result.push({
      id: keys[i],
      name: keys[i],
      prompt: keys[i],
      width: 65,
      align: "center",
      padding: 0,
    });
  }
  return result;
}

const dateFormat = (date) => DateTime.fromFormat(date, "yLLdd").toFormat("LLL dd");
const getDayFormat = (date) => DateTime.fromJSDate(date).toFormat("dd");
const getMonthAndYear = (date) => DateTime.fromJSDate(date).toFormat("LLL y");

export default function CreditCard({ data = [], filterValues }) {
  const { t } = useTranslation();

  const PdfFile = ({ downloadData, columns }) => {
    try {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        orientation: "l",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        floatPrecision: 16,
        compress: true,
      });

      let from;
      let to;

      if (filterValues.range) {
        from = new Date(filterValues.from);
        to = new Date(filterValues.to);
      } else {
        from = RangeDateData(filterValues.date).from;
        to = RangeDateData(filterValues.date).to;
      }

      let fromLabel = getDayFormat(from);
      let toLabel = getDayFormat(to);
      let monthLabel = getMonthAndYear(to);

      doc.text(`${t("creditCard")}: ${toLabel} - ${fromLabel} ${monthLabel}`, 3, 10);

      let parseData = [...downloadData].map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.fare = item.fare.toString();
        // eslint-disable-next-line no-param-reassign
        item.client = item.client.length ? item.client : " ";
        // eslint-disable-next-line no-param-reassign
        item.driver = item.driver.length ? item.driver : " ";
        // eslint-disable-next-line no-param-reassign
        item.source = item.source.length ? item.source : " ";
        // eslint-disable-next-line no-param-reassign
        item.to = item.to.length ? item.to : " ";
        // eslint-disable-next-line no-param-reassign
        item.creditCard = item.creditCard.length ? item.creditCard : " ";
        // eslint-disable-next-line no-param-reassign
        delete item.driverId;
        // eslint-disable-next-line no-param-reassign
        delete item.id;
        // eslint-disable-next-line no-param-reassign
        delete item.paymentAmount;
        // eslint-disable-next-line no-param-reassign
        delete item.date;
        return item;
      });

      let h = _.map(columns, "id");
      const headers = createHeaders(h);

      let fontSize = 7.5;
      doc.table(3, 15, parseData, headers, { autoSize: true, fontSize });
      doc.save(`creditCard.pdf`);
    } catch (e) {
      //
    }
  };

  const driversResult = React.useMemo(() => {
    let customFilter = data.filter((x) => x.has_creditCard);

    let groupByDate = _.groupBy(customFilter, "date");
    const groupByDateCountOrder = Object.entries(groupByDate).map(([key, value]) => ({
      date: dateFormat(key),
      data: value.length,
    }));

    const columns = [
      { id: "createdAt", displayName: t("createdAt") },
      { id: "invoiceId", displayName: t("invoice_id") },
      { id: "driver", displayName: t("driver") },
      { id: "fare", displayName: t("fare") },
      { id: "creditCard", displayName: t("creditCard") },
      { id: "client", displayName: t("client") },
      { id: "phone", displayName: t("phoneNumber") },
      { id: "from", displayName: t("from_address") },
      { id: "to", displayName: t("to_address") },
      { id: "source", displayName: t("source") },
      { id: "status", displayName: t("status") },
    ];

    return {
      chartData: groupByDateCountOrder,
      downloadData: [...customFilter],
      columns,
    };
  }, [data]);

  return (
    <>
      <ReportsLineChart
        cDescription="text"
        progress={100}
        title="creditCard"
        description="Credit Card details"
        chart={{
          labels: driversResult ? _.map(driversResult.chartData, "date") : [],
          datasets: {
            label: t("creditCard"),
            data: driversResult ? _.map(driversResult.chartData, "data") : [],
          },
        }}
      />

      <MDBox m={1} mt={5} display="flex" flexDirection="row" justifyContent="flex-end">
        <MDButton
          onClick={() => PdfFile(driversResult)}
          style={{ width: 200 }}
          variant="gradient"
          size="medium"
          color="error"
        >
          {t("download")} PDF
        </MDButton>

        <CsvDownloader
          bom={false}
          filename={t("creditCard")}
          columns={driversResult.columns}
          datas={driversResult.downloadData ?? []}
          prefix={false}
          suffix={false}
        >
          <MDButton
            style={{ width: 200, marginLeft: 10 }}
            variant="gradient"
            size="medium"
            color="success"
          >
            {t("download")} Excel
          </MDButton>
        </CsvDownloader>
      </MDBox>
    </>
  );
}
