import Profile from "views/profile";
import Setup from "views/setup";
import Users from "views/users";
import HelpSupport from "views/helpSupport";
import Map from "views/map";
import Zones from "views/zones";
import Reports from "views/reports";
import Clients from "views/clients";
import Drivers from "views/drivers";
import Orders from "views/orders";
import CreditCardOrderPayment from "views/creditCardOrderPayment";
import Icon from "@mui/material/Icon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Dashboard from "views/dashboard";
import Payments from "views/payments";

const routes = [
  {
    type: "navLink",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <Dashboard />,
    roles: ["admin", "dispatcher"],
  },
  {
    type: "navLink",
    name: "Orders",
    key: "orders",
    route: "/orders",
    icon: <Icon fontSize="medium">format_list_numbered</Icon>,
    component: <Orders />,
    roles: ["admin", "dispatcher"],
  },
  {
    type: "navLink",
    name: "Help support",
    key: "help_support",
    route: "/help_support",
    icon: <SupportAgentIcon />,
    component: <HelpSupport />,
    roles: ["admin", "dispatcher"],
  },
  {
    type: "navLink",
    name: "Credit Card Payment",
    key: "credit_card_payment",
    route: "/credit_card_payment",
    icon: <Icon fontSize="medium">add_card</Icon>,
    component: <CreditCardOrderPayment />,
    roles: ["admin", "dispatcher"],
  },
  {
    type: "navLink",
    name: "Map",
    key: "map",
    route: "/map",
    icon: <Icon fontSize="medium">map</Icon>,
    component: <Map />,
    roles: ["admin", "dispatcher"],
  },
  {
    type: "navLink",
    name: "Zones",
    key: "zones",
    route: "/zones",
    icon: <Icon fontSize="medium">add_location_alt</Icon>,
    component: <Zones />,
    roles: ["admin", "dispatcher"],
  },
  {
    type: "navLink",
    name: "Drivers",
    key: "drivers",
    route: "/drivers",
    icon: <Icon fontSize="medium">directions_car</Icon>,
    component: <Drivers />,
    roles: ["admin", "dispatcher"],
  },
  {
    type: "navLink",
    name: "Client",
    key: "client",
    route: "/client",
    icon: <Icon fontSize="medium">person_add</Icon>,
    component: <Clients />,
    roles: ["admin", "dispatcher"],
  },
  {
    type: "navLink",
    name: "Staff",
    key: "staff",
    route: "/staff",
    icon: <Icon fontSize="medium">people</Icon>,
    component: <Users />,
    roles: ["admin", "dispatcher"],
  },
  {
    type: "navLink",
    name: "Payments",
    key: "payments",
    route: "/payments",
    icon: <AttachMoneyIcon />,
    component: <Payments />,
    roles: ["admin"],
  },
  {
    type: "navLink",
    name: "Reports",
    key: "reports",
    route: "/reports",
    icon: <Icon fontSize="medium">bar_chart</Icon>,
    component: <Reports />,
    roles: ["admin", "dispatcher"],
  },
  {
    type: "navLink",
    name: "Setup",
    key: "setup",
    route: "/setup",
    icon: <Icon fontSize="medium">tune</Icon>,
    component: <Setup />,
    roles: ["admin"],
  },
  {
    type: "navLink",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    component: <Profile />,
    roles: ["admin", "dispatcher"],
  },
];

export default routes;
