import { ButtonGroup, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";
import CAutocomplete from "../../../components/Custom/CAutocomplete";
import { dateOptions } from "../../../utilities/data";

export default function HeaderOptions({
  onClick,
  value,
  filterValues,
  onChangeFilter,
  onFilterData,
}) {
  const { t } = useTranslation();
  const dashboardSettings = useSelector(({ settings }) => settings);
  const drivers = useSelector(({ driverList }) => driverList);

  const marginRight = 10;

  const isDisabled = React.useMemo(() => {
    if (filterValues.range) {
      return !(filterValues.to && filterValues.from);
    }
    return !filterValues.date;
  }, [filterValues]);

  return (
    <>
      <Grid item>
        {value === "orders" && (
          <Grid
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <FormGroup row>
              {dashboardSettings.isPaymentCard && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterValues.credit}
                      onChange={(e) => onChangeFilter(e.target.checked, "credit")}
                    />
                  }
                  label={t("credit_card")}
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterValues.isPassOrder}
                    onChange={(e) => onChangeFilter(e.target.checked, "isPassOrder")}
                  />
                }
                label={t("pass_order")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterValues.voucher}
                    onChange={(e) => onChangeFilter(e.target.checked, "voucher")}
                  />
                }
                label={t("voucher")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterValues.range}
                    onChange={(e) => onChangeFilter(e.target.checked, "range")}
                  />
                }
                label={t("range")}
              />
            </FormGroup>

            {filterValues.range ? (
              <>
                <TextField
                  style={{ marginRight }}
                  size="small"
                  value={filterValues.from}
                  onChange={(e) => onChangeFilter(e.target.value, "from")}
                  label={t("from")}
                  type="datetime-local"
                  sx={{ width: 150 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  style={{ marginRight }}
                  size="small"
                  value={filterValues.to}
                  onChange={(e) => onChangeFilter(e.target.value, "to")}
                  label={t("to")}
                  type="datetime-local"
                  sx={{ width: 150 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            ) : (
              <div style={{ width: 125, marginRight }}>
                <CAutocomplete
                  value={filterValues.date}
                  isLabel
                  name="date"
                  placeholder="date"
                  options={dateOptions}
                  onChange={(e, v, n) => onChangeFilter(v, n)}
                />
              </div>
            )}

            <div style={{ width: 125, marginRight }}>
              <CAutocomplete
                disableClearable={false}
                value={filterValues.driver}
                isLabel
                label="dNumber"
                name="driver"
                placeholder="drivers"
                options={drivers}
                onChange={(e, v, n) => onChangeFilter(v, n)}
              />
            </div>

            <MDButton
              disabled={isDisabled}
              onClick={onFilterData}
              style={{ width: 125 }}
              variant="gradient"
              size="medium"
              color="info"
            >
              {t("accept")}
            </MDButton>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <ButtonGroup color="white" variant="contained">
          <Button
            onClick={() => onClick("orders")}
            sx={{
              borderRadius: 1,
              width: 125,
              color: "#000",
              background: value === "orders" ? "rgba(62,255,140,0.5)" : "#fff",
            }}
          >
            {t("orders")}
          </Button>
          <Button
            onClick={() => onClick("appointment")}
            sx={{
              borderRadius: 1,
              width: 125,
              color: "#000",
              background: value === "appointment" ? "rgba(62,255,140,0.5)" : "#fff",
            }}
          >
            {t("appointment")}
          </Button>
        </ButtonGroup>
      </Grid>
    </>
  );
}
