import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
// import DashboardIcon from "@mui/icons-material/Dashboard";
import AddCardIcon from "@mui/icons-material/AddCard";
import Breadcrumbs from "examples/Breadcrumbs";
import Alert from "@mui/material/Alert";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import * as React from "react";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import packageInfo from "../../../../package.json";
import { postDashboardGrid } from "../../../redux/actions";
import { emptyCache } from "../../../utilities/func";
// import { dashboardUrl } from "../../../config";

function DashboardNavbar({ absolute, light, isMini, isDashboard, setOpenPayment }) {
  const { t } = useTranslation();
  const dispatchRedux = useDispatch();
  const dashboardSettings = useSelector(({ settings }) => settings);
  const DashboardGrid = useSelector(({ dashboardGrid }) => dashboardGrid);
  const [navbarType, setNavbarType] = useState("sticky");
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const onDashboardGrid = (value) => {
    dispatchRedux(postDashboardGrid({ ...DashboardGrid, [value]: true }));
  };

  const onDashboardSetting = (value) => {
    dispatchRedux(postDashboardGrid({ ...DashboardGrid, [value]: !DashboardGrid[value] }));
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      {dashboardSettings.version && dashboardSettings.version > packageInfo.version && (
        <Alert
          variant="filled"
          severity="warning"
          style={{ cursor: "pointer" }}
          onClick={emptyCache}
        >
          {t("version_available")}
        </Alert>
      )}

      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs
            icon="home"
            title={t(route[route.length - 1].toLowerCase())}
            route={route}
            light={light}
          />
          <IconButton
            style={{ marginTop: 5 }}
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
          {isDashboard && (
            <>
              {!DashboardGrid.isCreateOrder && (
                <IconButton
                  onClick={() => onDashboardGrid("isCreateOrder")}
                  size="large"
                  color="info"
                  disableRipple
                >
                  <Icon sx={iconsStyle}>map</Icon>
                </IconButton>
              )}
              {!DashboardGrid.isActiveCalls && (
                <IconButton
                  style={{ marginTop: 5 }}
                  onClick={() => onDashboardGrid("isActiveCalls")}
                  size="large"
                  disableRipple
                >
                  <Icon sx={iconsStyle}>contact_phone</Icon>
                </IconButton>
              )}
              {!DashboardGrid.isOrderHistory && (
                <IconButton
                  onClick={() => onDashboardGrid("isOrderHistory")}
                  size="large"
                  disableRipple
                >
                  <Icon sx={iconsStyle}>done_all</Icon>
                </IconButton>
              )}
              {!DashboardGrid.isMiniChat && (
                <IconButton
                  onClick={() => onDashboardGrid("isMiniChat")}
                  size="large"
                  disableRipple
                >
                  <Icon sx={iconsStyle}>chat</Icon>
                </IconButton>
              )}
              {!DashboardGrid.isRequestAlert && (
                <IconButton
                  onClick={() => onDashboardGrid("isRequestAlert")}
                  size="large"
                  disableRipple
                >
                  <Icon sx={iconsStyle}>notification_important</Icon>
                </IconButton>
              )}
              {!DashboardGrid.isActiveOrders && (
                <IconButton
                  onClick={() => onDashboardGrid("isActiveOrders")}
                  size="large"
                  disableRipple
                >
                  <Icon sx={iconsStyle}>format_list_bulleted</Icon>
                </IconButton>
              )}
            </>
          )}
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox
              color={light ? "white" : "inherit"}
              flexDirection="row"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <IconButton
                size="medium"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              {/* <IconButton */}
              {/*  size="large" */}
              {/*  disableRipple */}
              {/*  color="inherit" */}
              {/*  onClick={() => { */}
              {/*    let win = window.open(dashboardUrl, "_self"); */}
              {/*    win.focus(); */}
              {/*  }} */}
              {/* > */}
              {/*  <DashboardIcon sx={iconsStyle} /> */}
              {/* </IconButton> */}

              {isDashboard && (
                <Tooltip placement="bottom-end" title={t("isPaymentCard")}>
                  <IconButton
                    size="large"
                    disableRipple
                    color="inherit"
                    onClick={() => setOpenPayment(true)}
                  >
                    <AddCardIcon sx={iconsStyle} />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip placement="bottom-end" title={t("mute")}>
                <IconButton
                  size="large"
                  disableRipple
                  color="inherit"
                  onClick={() => onDashboardSetting("isMute")}
                >
                  {DashboardGrid.isMute ? (
                    <VolumeOffIcon sx={iconsStyle} />
                  ) : (
                    <VolumeUpIcon sx={iconsStyle} />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip placement="bottom-end" title={t("dashboard_options")}>
                <IconButton
                  size="medium"
                  disableRipple
                  color="inherit"
                  onClick={handleConfiguratorOpen}
                >
                  <Icon sx={iconsStyle}>settings</Icon>
                </IconButton>
              </Tooltip>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
