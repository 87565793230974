import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import CAutocomplete from "../../../components/Custom/CAutocomplete";
import { dateOptions } from "../../../utilities/data";
import CPaper from "../../../components/Custom/CPaper";

export default function Header({ filterValues, onChangeFilter, onFilterData }) {
  const { t } = useTranslation();
  const dashboardSettings = useSelector(({ settings }) => settings);
  const drivers = useSelector(({ driverList }) => driverList);

  const marginRight = 10;

  const isDisabled = React.useMemo(() => {
    if (filterValues.range) {
      return !(filterValues.to && filterValues.from);
    }
    return !filterValues.date;
  }, [filterValues]);

  return (
    <CPaper>
      <MDBox
        py={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        alignContent="center"
        justifyContent="space-around"
      >
        {dashboardSettings.isPaymentCard && (
          <FormControlLabel
            control={
              <Checkbox
                checked={filterValues.credit}
                onChange={(e) => {
                  onChangeFilter(e.target.checked, "credit");
                  onChangeFilter(false, "voucher");
                }}
              />
            }
            label={t("credit_card_only")}
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={filterValues.voucher}
              onChange={(e) => {
                onChangeFilter(e.target.checked, "voucher");
                onChangeFilter(false, "credit");
              }}
            />
          }
          label={t("voucher_only")}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filterValues.range}
              onChange={(e) => onChangeFilter(e.target.checked, "range")}
            />
          }
          label={t("range")}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={filterValues.allDrivers}
              onChange={(e) => onChangeFilter(e.target.checked, "allDrivers")}
            />
          }
          label={t("all_drivers")}
        />

        {filterValues.range ? (
          <>
            <TextField
              style={{ marginRight }}
              size="small"
              value={filterValues.from}
              onChange={(e) => onChangeFilter(e.target.value, "from")}
              label={t("from")}
              type="datetime-local"
              sx={{ width: 200 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              style={{ marginRight }}
              size="small"
              value={filterValues.to}
              onChange={(e) => onChangeFilter(e.target.value, "to")}
              label={t("to")}
              type="datetime-local"
              sx={{ width: 200 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </>
        ) : (
          <div style={{ width: 150, marginRight }}>
            <CAutocomplete
              value={filterValues.date}
              isLabel
              name="date"
              placeholder="date"
              options={dateOptions}
              onChange={(e, v, n) => onChangeFilter(v, n)}
            />
          </div>
        )}

        {!filterValues.allDrivers && (
          <div style={{ width: 150, marginRight }}>
            <CAutocomplete
              disableClearable={false}
              value={filterValues.driver}
              isLabel
              label="dNumber"
              name="driver"
              placeholder="drivers"
              options={drivers}
              onChange={(e, v, n) => onChangeFilter(v, n)}
            />
          </div>
        )}

        <MDButton
          disabled={isDisabled}
          onClick={onFilterData}
          style={{ width: 150 }}
          variant="gradient"
          size="medium"
          color="info"
        >
          {t("accept")}
        </MDButton>
      </MDBox>
    </CPaper>
  );
}
