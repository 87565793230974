import * as React from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../../components/MDInput";
import { useClassNameSelect } from "./styles";

export default function CAutocomplete({ value, onChange, options, label }) {
  const { t } = useTranslation();
  const classes = useClassNameSelect();

  return (
    <Autocomplete
      fullWidth
      style={{ marginBottom: 20 }}
      classes={classes}
      value={value}
      onChange={onChange}
      options={options}
      getOptionLabel={(option) => t(option)}
      renderInput={(params) => <MDInput {...params} label={t(label)} />}
    />
  );
}
