import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";

export default function ChangePassword({ open, close, onClick, password, setPassword }) {
  const { t } = useTranslation();
  return (
    <CDialog title="change_password" close={close} open={open}>
      <br />
      <MDInput
        fullWidth
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        name="password"
        style={{ marginRight: 3 }}
        placeholder={t("password")}
        type="text"
      />
      <br />
      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton
          disabled={password.length < 6}
          style={{ marginRight: 20 }}
          onClick={onClick}
          variant="gradient"
          color="success"
        >
          {t("save")}
        </MDButton>
        <MDButton onClick={() => close(false)} variant="gradient" color="error">
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
