import React, { useState } from "react";
import MicIcon from "@mui/icons-material/Mic";
import IconButton from "@mui/material/IconButton";
import { useLongPress } from "use-long-press";
import { ReactMic } from "react-mic";
import { firebase, firestore } from "../../firebase";
import { getUser } from "../../utilities/func";
import CLoadingSnackbar from "./CAlerts/CLoadingSnackbar";
import CAlertSnackbar from "./CAlerts/CAlertSnackbar";
import { AlertModel } from "../../utilities/model";

export default function CMicAudio({ driverId }) {
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [recording, setRecording] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [base64data, setBase64data] = React.useState(null);

  const uploadAudio = () => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    setBase64data(null);
    firestore()
      .collection("audio")
      .add({
        createdAt: timestamp,
        adminName: getUser().displayName,
        userId: driverId,
        source: "admin",
        audio: base64data,
      })
      .then(() => {
        setLoadingSuccess(true);
        setLoading(false);
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const record = async () => {
    setLoading(true);
    if (!recording) {
      setRecording(true);
    } else {
      setRecording(false);
    }
  };

  const onStop = ({ blob }) => {
    setLoading(false);
    try {
      setRecording(false);
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        let base64 = reader.result.replace("data:audio/wav;base64,", "");
        setBase64data(base64);
      };
    } catch (e) {
      setCAlert({ open: true, type: "error", ms: e.message });
    }
  };

  const callback = React.useCallback(() => record(), []);
  const bind = useLongPress(callback, {
    onFinish: () => setRecording(false),
    onCancel: () => setRecording(false),
    threshold: 200,
    detect: "both",
  });

  React.useMemo(() => {
    if (base64data && !recording) {
      uploadAudio();
    }
  }, [driverId, base64data, recording]);

  return (
    <>
      <IconButton color="secondary" {...bind()}>
        <MicIcon />
      </IconButton>

      <div style={{ display: "none" }}>
        <ReactMic
          record={recording}
          className="sound-wave"
          mimeType="audio/wav"
          onStop={onStop}
          strokeColor="#000000"
          backgroundColor="#FF4081"
        />
      </div>
      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="message_sent"
        duration={1500}
      />
      <CLoadingSnackbar open={loading} title="recording_audio" />
      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />
    </>
  );
}
