import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { useClassName } from "./CButton/styles";
import { postDashboardGrid } from "../../redux/actions";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

export default function CCardHeader({ value, title, clearCall }) {
  const { t } = useTranslation();
  const classes = useClassName();
  const dispatch = useDispatch();
  const DashboardGrid = useSelector(({ dashboardGrid }) => dashboardGrid);
  return (
    <>
      <MDBox style={{ position: "absolute", top: -25 }}>
        <MDTypography style={{ paddingLeft: 10, fontSize: 15 }} variant="h6">
          {t(title)}
        </MDTypography>
      </MDBox>
      <IconButton
        size="small"
        onClick={() => dispatch(postDashboardGrid({ ...DashboardGrid, [value]: false }))}
        className={classes.headerIcon}
      >
        <CloseIcon />
      </IconButton>
      {clearCall ? (
        <Tooltip placement="bottom-end" title={t("clear_call")}>
          <IconButton size="small" onClick={clearCall} className={classes.headerSecondIcon}>
            <PhoneDisabledIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
}
