import * as React from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import { CircularProgress, FormHelperText, IconButton, Stack } from "@mui/material";
import Icon from "@mui/material/Icon";
import ApiMapProvider from "./ApiMapProvider";
import MDInput from "../../MDInput";
import MDTypography from "../../MDTypography";
import { useClassName } from "./styles";
import { AddressModel } from "../../../utilities/model";
import CIcon from "../CIcon";
import MDBox from "../../MDBox";
import { onBlockAddressUtil, getBlockAddressUtil } from "../../../utilities/func";

export default function PlacesAutocompleteControlled(properties) {
  const { t } = useTranslation();
  const { onSelectPlace, autocompleteValue, setAutocompleteValue, disabled } = properties;
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isBlock, setIsBlock] = React.useState(false);
  const [blockAddressNotes, setBlockAddressNotes] = React.useState("");

  const classes = useClassName();

  const getAddress = useDebouncedCallback((search) => {
    if (search.length >= 3) {
      ApiMapProvider({
        search,
        properties,
      }).then((result) => {
        setOptions(result);
      });
    }
  }, 200);

  React.useEffect(() => {
    if (inputValue === "") {
      setOptions(autocompleteValue ? [autocompleteValue] : []);
      return undefined;
    }
    getAddress(inputValue);
    return () => {};
  }, [autocompleteValue, inputValue]);

  const onBlockAddress = async () => {
    await onBlockAddressUtil(
      isBlock,
      autocompleteValue,
      setLoading,
      setIsBlock,
      setBlockAddressNotes
    );
  };

  const getBlockAddress = async (address) => {
    await getBlockAddressUtil(address, setLoading, setIsBlock, setBlockAddressNotes);
  };

  return (
    <>
      <MDBox alignItems="center" display="flex" flexDirection="row">
        <Autocomplete
          disabled={disabled}
          classes={classes}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          size="small"
          fullWidth
          includeInputInList
          filterSelectedOptions
          value={autocompleteValue}
          onChange={(event, newValue) => {
            setAutocompleteValue(newValue);
            if (newValue) {
              onSelectPlace(newValue);
            } else {
              onSelectPlace(new AddressModel());
            }

            getBlockAddress(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <MDInput
              {...params}
              fullWidth
              placeholder={t("address")}
              InputLabelProps={{ shrink: true }}
              autoComplete="off"
            />
          )}
          getOptionLabel={(option) => option.address}
          isOptionEqualToValue={(option, optionValue) => option.id === optionValue.id}
          renderOption={(props, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={props.id}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box sx={{ color: "text.secondary", mr: 1 }}>
                    <CIcon name="location_on" />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Stack direction="column">
                    <MDTypography style={{ fontSize: 15, fontWeight: "bold" }} variant="spam">
                      {option.address}
                    </MDTypography>
                    <MDTypography style={{ fontSize: 14 }} variant="spam">
                      {option.city}
                    </MDTypography>
                  </Stack>
                </Grid>
              </Grid>
            </li>
          )}
        />
        {autocompleteValue && (
          <>
            {loading && <CircularProgress size={25} style={{ marginLeft: 5 }} color="success" />}
            {!loading && (
              <IconButton onClick={onBlockAddress}>
                <Icon color={isBlock ? "error" : "success"}>
                  {isBlock ? "lock_outlined" : "lock_open_outlined"}
                </Icon>
              </IconButton>
            )}
          </>
        )}
      </MDBox>

      {blockAddressNotes && (
        <FormHelperText style={{ fontWeight: "bold" }} error>
          {t("notes")}: {blockAddressNotes}
        </FormHelperText>
      )}
    </>
  );
}
