import * as React from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip, Stack } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MapIcon from "@mui/icons-material/Map";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MDInput from "../../../components/MDInput";
import { useMaterialUIController } from "../../../context";
import { memo } from "react";
import Snackbar from "@mui/material/Snackbar";
import { DateTime } from "luxon";

const input = {
  inputProps: {
    autoComplete: ""
  },
  fullWidth: true
};


const CIconButton = ({ checkApi, apis, darkMode, validList }) => {

  return (
    <IconButton
      size="large"
      style={{ padding: 0, margin: 0, paddingRight: 20 }}
      onClick={() => checkApi(apis)}
    >
      {validList[apis] === undefined ? (
        <MapIcon style={{ color: darkMode ? "#fff" : "#5b5b5b" }} />
      ) : null}
      {validList[apis] !== undefined && validList[apis] === 0 ? (
        <HighlightOffIcon style={{ color: "#ff0000" }} />
      ) : null}
      {validList[apis] && validList[apis] > 0 ? (
        <CheckCircleIcon style={{ color: "#0ba800" }} />
      ) : null}
    </IconButton>
  );

};

const InputApiKey = memo(function RoutingApi({ apis, index, validList, onChangeMapApi, checkApi, listType }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const copyTextToClipboard = async (textValue) => {
    if ("clipboard" in navigator) {
      setOpen(true);
      return navigator.clipboard.writeText(textValue);
    }
    return document.execCommand("copy", true, textValue);
  };

  const day = DateTime.now().day;

  return (
    <>
      <div
        style={{
          padding: 20,
          flexDirection: "row",
          display: "flex",
          backgroundColor: index + 1 === day ? "#e5e5e5" : "transparent"
        }}
      >


        <Stack direction={"column"} sx={{ width: "100%" }} spacing={2}>
          <label htmlFor="">{`${t("day")} - ${index + 1}`}</label>
          <Stack direction={"row"} alignItems={'center'} spacing={3} sx={{ width: "100%" }}>
            <MDInput
              {...input}
              value={apis[0]}
              onChange={(e) => onChangeMapApi(index, e.target.value, listType, 0)}
              name="companyName"
              label={`${t("hour")} - 1`}
              InputProps={{
                endAdornment: <CIconButton {...{ checkApi, apis:apis[0], darkMode, validList }} />
              }}
            />
            <MDInput
              {...input}
              value={apis[1]}
              onChange={(e) => onChangeMapApi(index, e.target.value, listType, 1)}
              name="companyName"
              label={`${t("hour")} - 2`}
              InputProps={{
                endAdornment: <CIconButton {...{ checkApi, apis:apis[1], darkMode, validList }} />
              }}
            />
            <MDInput
              {...input}
              value={apis[2]}
              onChange={(e) => onChangeMapApi(index, e.target.value, listType, 2)}
              name="companyName"
              label={`${t("hour")} - 3`}
              InputProps={{
                endAdornment: <CIconButton {...{ checkApi, apis:apis[2], darkMode, validList }} />
              }}
            />
          </Stack>

        </Stack>

      </div>
      <Snackbar
        autoHideDuration={1500}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={() => setOpen(false)}
        message={t("copy_clipboard_ms")}
      />
    </>
  );
});

export default InputApiKey;
