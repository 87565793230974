import * as React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import CPaper from "../../../../../components/Custom/CPaper";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";

export default function RenderRequest({ data, onCheckAlert }) {
  const { t } = useTranslation();
  return (
    <>
      {data.map((driver) => {
        let { id, dNumber, displayName } = driver;
        let createdAt = DateTime.fromSeconds(driver.createdAt.seconds).toFormat("ff");
        return (
          <CPaper key={id} style={{ margin: 5, marginTop: 12 }}>
            <MDBox
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDTypography style={{ fontSize: 15 }}>{createdAt}</MDTypography>
              <MDButton
                onClick={() => onCheckAlert(id)}
                variant="gradient"
                style={{ borderRadius: 0 }}
                size="medium"
                color="info"
              >
                {t("accept")}
              </MDButton>
            </MDBox>

            <MDBox
              alignItems="flex-start"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <MDTypography style={{ fontSize: 15 }}>
                {t("dNumber")}: {dNumber}
              </MDTypography>
              <MDTypography style={{ fontSize: 15 }}>
                {t("full_name")}: {displayName}
              </MDTypography>
            </MDBox>
          </CPaper>
        );
      })}
    </>
  );
}
