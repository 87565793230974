import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Typography, ListItemIcon } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PeopleIcon from "@mui/icons-material/People";
import { useTranslation } from "react-i18next";
import AddCardIcon from "@mui/icons-material/AddCard";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import ReceiptIcon from "@mui/icons-material/Receipt";
import NavigationIcon from "@mui/icons-material/Navigation";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PhoneLockedIcon from "@mui/icons-material/PhoneLocked";
import { useMaterialUIController } from "../../../context";

export default function OrdersActions({
  onClick,
  title,
  isSubscribed,
  status,
  isPaymentCard,
  isCancellable,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const handleClose = (type) => {
    setAnchorEl(null);
    if (typeof type === "string" && type !== "close") {
      onClick(type);
    }
  };

  return (
    <>
      <IconButton
        style={{ padding: 0, margin: 0 }}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        aria-controls={open ? "basic-menu" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: darkMode ? "#fff" : "#000" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem disableRipple divider>
          <Typography variant="inherit">{title}</Typography>
        </MenuItem>

        {status !== "unassigned" && (
          <MenuItem onClick={() => handleClose("reassign")} disableRipple>
            <ListItemIcon>
              <CarRepairIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("reassign")}</Typography>
          </MenuItem>
        )}

        {status !== "unassigned" && (
          <MenuItem onClick={() => handleClose("complete")} disableRipple>
            <ListItemIcon>
              <DoneAllIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("complete_order")}</Typography>
          </MenuItem>
        )}

        {isPaymentCard && status !== "unassigned" && (
          <MenuItem onClick={() => handleClose("payment")} disableRipple>
            <ListItemIcon>
              <AddCardIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("payment_with_card")}</Typography>
          </MenuItem>
        )}

        {isSubscribed && (
          <MenuItem onClick={() => handleClose("drivers")}>
            <ListItemIcon>
              <PeopleIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("drivers")}</Typography>
          </MenuItem>
        )}

        {isCancellable && status !== "cancelled" && status !== "completed" && (
          <MenuItem onClick={() => handleClose("delete")}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("cancel_order")}</Typography>
          </MenuItem>
        )}

        <MenuItem onClick={() => handleClose("show")}>
          <ListItemIcon>
            <VisibilityIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("show")}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleClose("navigation")}>
          <ListItemIcon>
            <NavigationIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("navigation")}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleClose("block_phone")}>
          <ListItemIcon>
            <PhoneLockedIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("block_phone")}</Typography>
        </MenuItem>

        {status === "completed" && (
          <MenuItem onClick={() => handleClose("fare")}>
            <ListItemIcon>
              <AttachMoneyIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("fare")}</Typography>
          </MenuItem>
        )}

        {status === "completed" && (
          <MenuItem onClick={() => handleClose("receipt")}>
            <ListItemIcon>
              <ReceiptIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("receipt")}</Typography>
          </MenuItem>
        )}

        <MenuItem onClick={() => handleClose("close")}>
          <ListItemIcon>
            <CloseIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("close")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
