import React from "react";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import CDialog from "../../../../../components/Custom/CDialog";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import CPaper from "../../../../../components/Custom/CPaper";
import TSText from "../../../../../components/Custom/TSText";
import { getCheckField, getDateFormatT } from "../../../../../utilities/func";

export default function ActiveOrderModal({ open, close, data, onClick, onDelete }) {
  const { t } = useTranslation();
  let dNumber = getCheckField(data, "assignedDriver", "dNumber");
  let address = getCheckField(data, "from", "address");
  return (
    <CDialog title="active_order" close={() => close(false)} open={open}>
      <MDTypography variant="h6">{t("customer_already_has_order")}:</MDTypography>
      <br />
      <Grid container>
        <Grid item xs={12} sm={4} lg={3}>
          <MDBox display="flex" flexDirection="column">
            <MDTypography variant="h5" color="error">
              {t("time")}: {getDateFormatT(data.createdAt)}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <MDBox display="flex" flexDirection="column">
            <MDTypography variant="h5">
              {t("driver")}: {dNumber}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>

      <CPaper style={{ marginTop: 10, padding: 15, paddingTop: 20, paddingBottom: 20 }}>
        <Grid container>
          <Grid item xs={12} sm={4} lg={4}>
            <TSText title={getCheckField(data, "client", "displayName")} subtitle="client" />
          </Grid>
          <Grid item xs={12} sm={4} lg={4}>
            <TSText title={getCheckField(data, "client", "phoneNumber")} subtitle="phoneNumber" />
          </Grid>
          <Grid item xs={12} sm={4} lg={4}>
            <TSText title={data.notes ? data.notes : "N/A"} subtitle="notes" />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <TSText title={address} subtitle="address" />
          </Grid>
        </Grid>
      </CPaper>

      <br />
      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        {onDelete && (
          <MDButton
            style={{ marginRight: 20 }}
            onClick={() => onDelete(data)}
            variant="gradient"
            color="warning"
          >
            {t("delete")} {t("active_order")}
          </MDButton>
        )}

        <MDButton style={{ marginRight: 20 }} onClick={onClick} variant="gradient" color="success">
          {t("duplicate_order")}
        </MDButton>
        <MDButton onClick={() => close(false)} variant="gradient" color="error">
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
