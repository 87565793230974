import React from "react";
import { Paper, Tooltip } from "@mui/material";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MDBox from "../../../../../components/MDBox";
import MDInput from "../../../../../components/MDInput";
import { useMaterialUIController } from "../../../../../context";
import { useStyles } from "../../../styles";
import CIcon from "../../../../../components/Custom/CIcon";
import { onCheckIfExist } from "../../../../../utilities/func";

export default function Header({
  driverFilter,
  setDriverFilter,
  drivers,
  setOpenSearch,
  onLogoffInactiveDrivers,
                                 setOpenDriverLocation,
}) {
  const userInfo = useSelector(({ user }) => user);
  const [controller] = useMaterialUIController();
  const { t } = useTranslation();
  const styles = useStyles();
  const { darkMode } = controller;

  return (
    <>
      <Paper
        elevation={3}
        style={{ ...styles.paperDriverMapLeft, backgroundColor: darkMode ? "#455a88" : "#fff" }}
      >
        <MDBox flexDirection="row" display="flex" alignItems="center" style={{ width: 140 }}>
          <CIcon name="search" />
          <MDInput
            value={driverFilter}
            variant="standard"
            style={{ color: "#000 !important" }}
            onChange={(e) => setDriverFilter(e.target.value)}
            placeholder={t("search_driver")}
            type="text"
            size="small"
          />
        </MDBox>
      </Paper>

      <Paper
        elevation={3}
        style={{
          ...styles.paperDriverMapRight,
          backgroundColor: darkMode ? "#455a88" : "#fff",
        }}
      >
        <MDBox justifyContent="center" alignSelf="center">
          <Tooltip placement="bottom-end" title={t("logoff_inactive_drivers")}>
            <IconButton
              size="medium"
              disableRipple
              color="inherit"
              onClick={onLogoffInactiveDrivers}
            >
              <CIcon name="car_crash" />
            </IconButton>
          </Tooltip>
        </MDBox>
      </Paper>

      <Paper
        elevation={3}
        style={{
          ...styles.paperDriverMapRight,
          right: 60,
          backgroundColor: darkMode ? "#455a88" : "#fff",
        }}
      >
        {onCheckIfExist(userInfo, "showDriverNumber") && (
          <MDBox justifyContent="center" alignSelf="center">
            <Tooltip placement="bottom-end" title={t("drivers")}>
              <Badge
                max={999}
                badgeContent={drivers.filter((dri) => dri.drivingStatus !== "offline").length}
                color="primary"
              >
                <IconButton
                  size="medium"
                  disableRipple
                  color="inherit"
                  onClick={() => setOpenSearch(true)}
                >
                  <CIcon name="directions_car" />
                </IconButton>
              </Badge>
            </Tooltip>
          </MDBox>
        )}
      </Paper>

      <Paper
        elevation={3}
        style={{
          ...styles.paperDriverMapRight,
          right: 115,
          backgroundColor: darkMode ? "#455a88" : "#fff"
        }}
      >
        {onCheckIfExist(userInfo, "showDriverNumber") && (
          <MDBox justifyContent="center" alignSelf="center">
            <Tooltip placement="bottom-end" title={t("drivers")}>
              <IconButton
                size="medium"
                disableRipple
                color="inherit"
                onClick={() => setOpenDriverLocation(true)}
              >
                <CIcon name="car_repair" />
              </IconButton>
            </Tooltip>
          </MDBox>
        )}
      </Paper>
    </>
  );
}
