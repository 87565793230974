import * as React from "react";
import { Input, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMaterialUIController } from "../../context";
import CPaper from "./CPaper";
import Button from "@mui/material/Button";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from "@mui/material/IconButton";
import RestoreIcon from "@mui/icons-material/Restore";

export default function CTable({
  data,
  columns,
  headerOptions,
  canSearch,
  loading,
  filterBy,
  pageSize = 50,
  height = "65vh",
  hideFooterPagination,
  hideFooter,
                                 printPdf,
}) {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [search, setSearch] = React.useState("");

  const filterOrders = (items, searchValue) => {
    const keyword = searchValue.toLocaleLowerCase();
    let reducer;
    if (filterBy) {
      reducer = (accumulator, item) => {
        let filterName = item[filterBy] ?? "";
        if (filterName.includes(keyword)) {
          accumulator.push(item);
        }
        return accumulator;
      };
    } else {
      reducer = (accumulator, item) => {
        let dNumber = item.dNumber ?? "";
        let driver = item.driver ?? "";
        let version = item.version ?? "";
        let fullName = item.fullName ? item.fullName.toLocaleLowerCase() : "";
        let from = item.from ?? "";
        let invoiceId = item.invoiceId ?? "";
        let phone = item.phone ?? "";
        let phoneNumber = item.client ? item.client.phoneNumber : "";
        let source = item.source ?? "";
        let status = item.status ?? "";
        let firstName = item.firstName ? item.firstName.toLocaleLowerCase() : "";
        let lastName = item.lastName ? item.lastName.toLocaleLowerCase() : "";
        let driverStatus = item.driverStatus ?? "";
        if (
          status.includes(keyword) ||
          driverStatus.includes(keyword) ||
          dNumber.includes(keyword) ||
          from.includes(keyword) ||
          source.includes(keyword) ||
          phoneNumber.includes(keyword) ||
          invoiceId.includes(keyword) ||
          phone.includes(keyword) ||
          driver.includes(keyword) ||
          version.includes(keyword) ||
          firstName.includes(keyword) ||
          lastName.includes(keyword) ||
          fullName.includes(keyword)
        ) {
          accumulator.push(item);
        }
        return accumulator;
      };
    }

    return items.reduce(reducer, []);
  };

  const handleChangePage = (event, newPage) => {
    setRowsPerPage(newPage);
  };

  const rows = filterOrders(data, search);

  const onPrintPdf=()=>{

  }

  return (
    <CPaper>
      <Grid
        container
        spacing={2}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems={'center'}
        p={2}
      >
        {headerOptions}
        {canSearch && (
          <Grid item xs={12} md={6} lg={2}>
            <Paper
              elevation={1}
              style={{
                backgroundColor: !darkMode ? "#fff" : "#2c3c58",
              }}
            >
              <Input
                style={{
                  backgroundColor: !darkMode ? "#fff" : "#2c3c58",
                  borderRadius: 10,
                  height: 38,
                  width: 150,

                  paddingLeft: 10,
                }}
                disableUnderline
                placeholder={t("search")}
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(currentTarget.value);
                }}
              />
            </Paper>
          </Grid>
        )}
        {/*{printPdf && data?.length > 0 && (*/}
        {/*  <Grid item xs={12} md={1} lg={1}>*/}
        {/*    <IconButton  onClick={onPrintPdf}>*/}
        {/*      <PdfIcon fontSize="large" style={{color:'#d00000'}}/>*/}
        {/*    </IconButton>*/}
        {/*  </Grid>*/}
        {/*)}*/}
      </Grid>

      <Box sx={{ height, width: "100%" }}>
        <DataGrid
          disableColumnMenu
          hideFooterSelectedRowCount
          hideFooterPagination={hideFooterPagination}
          hideFooter={hideFooter}
          disableColumnSelector
          loading={loading}
          rows={rows}
          columns={columns.map((column) => ({ ...column, headerName: t(column.headerName) }))}
          pageSize={pageSize}
          rowsPerPageOptions={[50]}
          checkboxSelection={false}
        />
      </Box>
    </CPaper>
  );
}
