import { ButtonGroup } from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";

export default function HeaderOptions({ onClick, value, addDriver, openSchedules }) {
  const { t } = useTranslation();

  return (
    <>
      <Grid item>
        <MDButton
          sx={{
            width: 125,
            marginRight: 3,
          }}
          onClick={addDriver}
          color="info"
        >
          {t("add")}
        </MDButton>

        <ButtonGroup color="white" variant="contained">
          <Button
            onClick={() => onClick("active")}
            sx={{
              borderRadius: 1,
              width: 125,
              color: "#000",
              background: value === "active" ? "rgba(62,255,140,0.5)" : "#fff",
            }}
          >
            {t("active")}
          </Button>
          <Button
            onClick={() => onClick("register")}
            sx={{
              borderRadius: 1,
              width: 125,
              color: "#000",
              background: value === "register" ? "rgba(62,255,140,0.5)" : "#fff",
            }}
          >
            {t("register")}
          </Button>
        </ButtonGroup>

        <MDButton
          sx={{
            width: 125,
            marginLeft: 3,
          }}
          onClick={openSchedules}
          color="warning"
        >
          {t("schedules")}
        </MDButton>
      </Grid>
    </>
  );
}
