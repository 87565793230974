import colors from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";
import borders from "assets/theme/base/borders";

const { white, black } = colors;
const { md } = boxShadows;
const { borderRadius } = borders;

const DataGrid = () => ({
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: 0,
        color: black,
        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
      },
      toolbarContainer: {
        paddingRight: `10px !important`,
        paddingLeft: `10px !important`,
      },

      columnHeaderCheckbox: {
        maxWidth: "58px !important",
        minWidth: "58px !important",
      },
      columnHeaderTitleContainer: {
        padding: 0,
      },
      columnHeaderTitle: {
        fontSize: "0.75rem",
        letterSpacing: "0.17px",
        textTransform: "uppercase",
      },
      columnSeparator: {
        color: black,
      },
      virtualScroller: {
        marginTop: "54px !important",
      },
      virtualScrollerRenderZone: {
        "& .MuiDataGrid-row": {
          maxHeight: "50px !important",
          minHeight: "50px !important",
        },
      },
      row: {
        "&:last-child": {
          "& .MuiDataGrid-cell": {
            borderBottom: 0,
          },
        },
      },

      cellCheckbox: {
        maxWidth: "58px !important",
        minWidth: "58px !important",
      },
      editInputCell: {
        padding: 0,
        color: black,
        "& .MuiInputBase-input": {
          padding: 0,
        },
      },
    },
    defaultProps: {
      rowHeight: 50,
      headerHeight: 54,
    },
  },
});

export default DataGrid;
