import { createStyles, makeStyles } from "@mui/styles";

export const useClassNameSelect = makeStyles(() =>
  createStyles({
    inputRoot: {
      height: `45px`,
      alignContent: "center",
    },
  })
);
