import axios from "axios";
import { CaptureException } from "../../../utilities/func";

export const getCity = (context) => {
  let city = "";
  context.forEach((c) => {
    if (c.id.indexOf("place.") === 0) city = c.text;
  });

  return city;
};

export const getState = (context) => {
  let state = "";

  context.forEach((c) => {
    if (c.id.indexOf("region.") === 0) state = c.short_code || c.text;
  });

  return state;
};

// eslint-disable-next-line consistent-return
export const getStreet = (addressArray) => {
  let st = "";
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && addressArray[i].types[0] === "route") {
      st = addressArray[i].long_name;
      return st;
    }
  }
};

// eslint-disable-next-line consistent-return
export const getStreetNumber = (addressArray) => {
  let stNo = "";
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && addressArray[i].types[0] === "street_number") {
      stNo = addressArray[i].long_name;
      return stNo;
    }
  }
};

const country = (code) => {
  switch (code) {
    case "us":
    case "USA":
      return "USA";
    case "do":
    case "DOM":
      return "DOM";
    default:
      return "USA";
  }
};

export default async function ApiMapProvider({ properties, search }) {
  try {
    let in_param;
    let hereUrl;
    let mapboxUrl;
    let response;
    let here = [];
    let mapbox = [];
    const countryCode = country(properties.autocompleteCountry);
    const at = `${properties.mapCenter.lng},${properties.mapCenter.lat}`;
    switch (properties.autocompleteType) {
      case "here":
        in_param = `bbox:${properties.mapBoxBbox}`;
        hereUrl = `https://discover.search.hereapi.com/v1/discover?limit=5&q=${search}&in=${in_param}&in=countryCode:${countryCode}&apiKey=${properties.hereApiKey}`;
        response = await axios.get(hereUrl);

        here = response.data.items.map((results) => {
          const { position, address = {} } = results;
          const {
            city,
            state,
            label: addressLabel,
            street = "",
            houseNumber: streetNumber = "",
          } = address;
          return {
            latitude: position.lat,
            longitude: position.lng,
            address: addressLabel,
            city,
            state,
            street,
            streetNumber,
          };
        });
        return here || [];

      case "mapbox":
        mapboxUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?access_token=${properties.mapBoxApiKey}&country=${properties.autocompleteCountry}&types=postcode%2Clocality%2Cplace%2Cneighborhood%2Caddress%2Cpoi&proximity=${at}&bbox=${properties.mapBoxBbox}`;
        response = await axios.get(mapboxUrl);
        mapbox = response.data.features.map((results) => {
          const { place_name, text, address, center, context } = results;
          return {
            latitude: center[1],
            longitude: center[0],
            address: place_name ?? "",
            city: getCity(context),
            state: getState(context),
            street: text ?? "",
            streetNumber: address ?? "",
          };
        });
        return mapbox || [];

      default:
        return [];
    }
  } catch (e) {
    CaptureException(e);
    return [];
  }
}
