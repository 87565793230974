export const ORDER_SELECTED = "ORDER_SELECTED";
export function success(payload) {
  return {
    type: ORDER_SELECTED,
    payload,
  };
}

export function postOrderSelected(payload) {
  return async function f(dispatch) {
    dispatch(success(payload));
  };
}
