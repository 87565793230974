import React from "react";
import { useTranslation } from "react-i18next";
import { Input, FormHelperText } from "@mui/material";
import { IMaskInput } from "react-imask";
import MDBox from "../MDBox";

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      unmask
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default function InputMaskPhone({ fullWidth, style, value, onChange, name }) {
  const { t } = useTranslation();

  return (
    <MDBox style={style}>
      <Input
        fullWidth={fullWidth}
        value={value}
        disableUnderline
        style={{
          fontSize: 14,
          paddingLeft: 10,
          borderRadius: 6,
          borderColor: "#dadada",
          borderWidth: 1,
          borderStyle: "solid",
          height: 38,
        }}
        error={false}
        placeholder={t("phoneNumber")}
        onChange={onChange}
        name={name}
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom}
      />
      {value.length > 0 && value.length !== 10 && (
        <FormHelperText error>{t("invalid_phoneNumber")}</FormHelperText>
      )}
    </MDBox>
  );
}
