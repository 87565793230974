import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CPaper from "../../../../../components/Custom/CPaper";
import TSText from "../../../../../components/Custom/TSText";
import { fromBoolToString, getCheckField } from "../../../../../utilities/func";
import MDTypography from "../../../../../components/MDTypography";

export default function Info({ data, type }) {
  const { t } = useTranslation();
  return (
    <CPaper style={{ marginTop: 10 }}>
      <MDTypography variant="h4">{t(type)}:</MDTypography>
      <Grid container>
        <Grid item xs>
          <TSText
            variant="h6"
            title={fromBoolToString(data, "has_voucher", t)}
            subtitle="voucher"
          />
        </Grid>
        <Grid item xs>
          <TSText variant="h6" title={fromBoolToString(data, "isPaid", t)} subtitle="paid" />
        </Grid>
        <Grid item xs>
          <TSText
            variant="h6"
            title={getCheckField(data, "dispatcher", "displayName")}
            subtitle="dispatcher"
          />
        </Grid>
        <Grid item xs>
          <TSText variant="h6" title={t(data.vehicleType)} subtitle="vehicle" />
        </Grid>
        <Grid item xs>
          <TSText variant="h6" title={t(data.source === "mobile" ? "yes" : "no")} subtitle="app" />
        </Grid>
        <Grid item xs>
          <TSText
            variant="h6"
            title={t(data.source === "appointment" ? "yes" : "no")}
            subtitle="appointment"
          />
        </Grid>
      </Grid>
    </CPaper>
  );
}
