import axios from "axios";
import { apiUrl } from "../config";
import { firebase } from "../firebase";

const instance = axios.create({
  baseURL: apiUrl,
  headers: { Accept: "application/json" },
});

const injectUserAuthToken = (config) =>
  firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
      return Promise.resolve(config);
    })
    .catch(() => Promise.resolve(config));

instance.interceptors.request.use(injectUserAuthToken, (error) => Promise.reject(error));

export default instance;
