import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { firestore } from "../../../../firebase";
import { AlertModel, OrderModel } from "../../../../utilities/model";
import { CaptureException, DuplicateOrder } from "../../../../utilities/func";
import { postOrderSelected } from "../../../../redux/actions";

export default function useData() {
  const dispatch = useDispatch();
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [calls, setCalls] = React.useState([]);
  const [selectedCall, setSelectedCall] = React.useState({});
  const [alertActiveCall, setAlertActiveCall] = React.useState(false);
  const [openCancelCall, setOpenCancelCall] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [activeOrderModel, setActiveOrderModel] = useState(new OrderModel());
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [openClearCall, setOpenClearCall] = React.useState(false);

  React.useEffect(() => {
    const callsOnSnapshot = firestore()
      .collection("calls")
      .limit(25)
      .where("processingStatus", "==", "pending")
      .orderBy("start")
      .onSnapshot((querySnapshot) => {
        const list = [];
        querySnapshot.docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          list.push(data);
        });
        setCalls(list);
      });
    return () => {
      callsOnSnapshot();
    };
  }, []);

  const checkActiveCall = async (call) => {
    try {
      let querySnapshot = await firestore()
        .collection("orders")
        .where("client.phoneNumber", "==", call.phone.number)
        .where("status", "in", ["assigned", "inProcess", "unassigned"])
        .orderBy("createdAt", "desc")
        .limit(1)
        .get()
        .catch((e) => {
          console.log(e)
          // setCAlert({ open: true, type: "error", ms: e.message });
          // CaptureException(e, "Error-DAC-01");
        });
      let data = {};
      querySnapshot.forEach((docs) => {
        data = docs.data();
        data.id = docs.id;
      });
      return data;
    } catch (e) {
      CaptureException(e, "Error-DAC-02");
      return null;
    }
  };

  const onSelectCall = async (call) => {
    let client = call.client ? call.client : {};
    let { blackListed = false } = client;
    if (blackListed) return;
    const { client: callClient, ...callData } = call;
    setSelectedCall(call);
    let orderModel = new OrderModel();
    orderModel.client = callClient;
    orderModel.call = callData;
    dispatch(postOrderSelected(orderModel));
    let verifiedCall = await checkActiveCall(call);
    if (verifiedCall && verifiedCall.id) {
      setActiveOrderModel(verifiedCall);
      setAlertActiveCall(true);
    }
  };

  const onCancelCallClick = (call) => {
    setSelectedCall(call);
    setOpenCancelCall(true);
  };

  const onCancelCallConfirm = () => {
    const newCall = { ...selectedCall };
    setOpenCancelCall(false);
    setLoading(true);
    let status = "cancelled";
    const data = { status, processingStatus: status };
    firestore()
      .collection("calls")
      .doc(newCall.id)
      .update(data)
      .then(() => {
        setLoadingSuccess(true);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAC-03");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDuplicateOrder = () => {
    setLoading(true);
    DuplicateOrder(activeOrderModel)
      .then(() => {
        setLoadingSuccess(true);
        setActiveOrderModel(new OrderModel());
      })
      .catch((e) => {
        CaptureException(e, "Error-DAC-02");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearCallsLog = () => {
    setLoading(true);
    setOpenClearCall(false);

    const callsRef = firestore().collection("calls");
    const batch = firestore().batch();

    callsRef
      .where("processingStatus", "==", "pending")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          batch.update(doc.ref, { processingStatus: "complete", status: "cancelled" });
        });
        return batch.commit();
      })
      .then(() => {
        setLoadingSuccess(true);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAC-06");
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  return {
    cAlert,
    setCAlert,
    calls,
    onSelectCall,
    onCancelCallClick,
    activeOrderModel,
    alertActiveCall,
    openCancelCall,
    setAlertActiveCall,
    setOpenCancelCall,
    onCancelCallConfirm,
    loading,
    onDuplicateOrder,
    loadingSuccess,
    setLoadingSuccess,
    openClearCall,
    setOpenClearCall,
    clearCallsLog,
  };
}
