import OrdersActions from "./orders.actions";
import AppointmentsActions from "./appointments.actions";
import CChipByStatus from "../../../components/Custom/CChipByStatus";

export const orderColumns = [
  {
    align: "center",
    headerAlign: "center",
    flex: 0.1,
    headerName: "actions",
    field: "actions",
    sortable: false,
    renderCell: ({ row }) => <OrdersActions {...row} onClick={row.func} />,
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "status",
    field: "status",
    renderCell: ({ row }) => <CChipByStatus status={row.status} />,
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "invoice_id",
    field: "invoiceId",
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.3,
    headerName: "date",
    field: "createdAt",
  },
  { align: "center", headerAlign: "center", flex: 0.1, headerName: "driver", field: "driver" },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "phone", field: "phone" },
  { align: "center", headerAlign: "center", flex: 0.5, headerName: "from", field: "from" },
  { align: "center", headerAlign: "center", flex: 0.1, headerName: "fare", field: "fare" },
  { align: "center", headerAlign: "center", flex: 0.1, headerName: "source", field: "source" },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "dispatcher", field: "dispatcher" },
];

export const paymentColumns = [
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "actions",
    field: "actions",
    renderCell: ({ row }) => <OrdersActions {...row} onClick={row.func} />,
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "status",
    field: "status",
    renderCell: ({ row }) => <CChipByStatus status={row.status} />,
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "invoice_id",
    field: "invoiceId",
  },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "date", field: "createdAt" },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "credit_card",
    field: "creditCardNumber",
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "amount",
    field: "paymentAmount",
  },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "driver", field: "driver" },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "from", field: "from" },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "phone", field: "phone" },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "source", field: "source" },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "payment source",
    field: "paymentSource",
  },
];

export const appointmentsColumns = [
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "actions",
    field: "actions",
    width: 20,
    sortable: false,
    renderCell: ({ row }) => <AppointmentsActions onClick={row.func} />,
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "date",
    field: "date",
    width: 20,
    renderCell: ({ row }) => row.date.toDate().toLocaleString(),
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "Client",
    field: "order.client.phoneNumber",
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "Address",
    field: "order.from.address",
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "Dispatcher",
    field: "order.createdBy.displayName",
  },
];
