import { createStyles, makeStyles } from "@mui/styles";

export const useClassName = makeStyles(() =>
  createStyles({
    headerIcon: {
      color: "white",
      background: "rgb(119,119,119)",
      position: "absolute",
      right: 0,
      top: 0,
      fontSize: 15,
      alignSelf: "flex-end",
      marginTop: -13,
      marginRight: -13,
      "&:hover,&:focus": {
        color: "#fff",
        backgroundColor: "#a9a9a9",
      },
    },
  })
);

export const useStyles = () =>
  createStyles({
    card: {
      marginTop: 10,
      height: 495,
      borderRadius: 4,
    },
    cardContent: {
      margin: 0,
      padding: 5,
      paddingBottom: 10,
      overflowY: "auto",
      overflowX: "hidden",
    },
    paperDriverMapLeft: {
      backgroundColor: "rgba(255,255,255,0.9)",
      position: "absolute",
      zIndex: 1000,
      margin: 10,
      padding: 8,
      height: 40,
    },
    paperDriverMapRight: {
      backgroundColor: "rgba(255,255,255,0.9)",
      position: "absolute",
      zIndex: 1000,
      margin: 10,
      height: 40,
      right: 5,
    },
  });
