import React from "react";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MicIcon from "@mui/icons-material/Mic";
import { useMaterialUIController } from "../../context";

export default function CListItem({
  primary,
  secondary,
  selectedDriver,
  Icon = ArrowForwardIosIcon,
  isMic,
  clicked,
  bind,
  driverId,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const onClick = () => {
    if (clicked) {
      return selectedDriver(secondary);
    }
    return null;
  };

  return (
    <ListItem onClick={onClick} style={{ cursor: clicked ? "pointer" : "default" }}>
      <ListItemText
        style={{ paddingLeft: 10 }}
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{ color: !darkMode ? "#455a88" : "#fff", fontWeight: "bold" }}
        secondaryTypographyProps={{
          color: !darkMode ? "#455a88" : "#fff",
          fontSize: 14,
        }}
      />

      {isMic && (
        <IconButton color="secondary" {...bind(driverId)}>
          <MicIcon />
        </IconButton>
      )}

      <IconButton onClick={() => selectedDriver(secondary)}>
        <Icon color="secondary" />
      </IconButton>
    </ListItem>
  );
}
