import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { auth } from "../../../../firebase";
import CAlertSnackbar from "../../../../components/Custom/CAlerts/CAlertSnackbar";
import { AlertModel } from "../../../../utilities/model";

function Session() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [cAlert, setCAlert] = React.useState(new AlertModel());

  const onLogout = () => {
    setLoading(true);
    auth()
      .signOut()
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">{t("session")}</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid item xs={12}>
          <MDBox ml="auto">
            <MDButton loading={loading} onClick={onLogout} variant="gradient" color="error">
              {t("logout")}
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
      <CAlertSnackbar alert={cAlert} close={setCAlert} />
    </Card>
  );
}

export default Session;
