import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Input, FormHelperText, CircularProgress } from "@mui/material";
import Icon from "@mui/material/Icon";
import { IMaskInput } from "react-imask";
import MDBox from "../MDBox";
import MDButton from "../MDButton";

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      unmask
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default function MaskPhone({
  onPhoneBlock,
  phoneBlockNotes,
  phoneBlock,
  onClientPhone,
  orderModel,
  loadingPhone,
  phoneNumber,
  setPhoneNumber,
  my = 1,
  onSearch,
}) {
  const { t } = useTranslation();

  return (
    <MDBox my={my}>
      <Input
        fullWidth
        value={phoneNumber}
        disableUnderline
        style={{
          fontSize: 14,
          paddingLeft: 10,
          borderRadius: 6,
          borderColor: "#dadada",
          borderWidth: 1,
          borderStyle: "solid",
          height: 38,
        }}
        error={false}
        placeholder={t("phoneNumber")}
        onBlur={() => (onClientPhone ? onClientPhone(phoneNumber) : undefined)}
        onChange={(e) => setPhoneNumber(e.target.value)}
        name="textmask"
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom}
        endAdornment={
          <>
            {loadingPhone && <CircularProgress size={30} color="success" />}
            {orderModel && orderModel.client.id ? (
              <IconButton size="medium" disableRipple onClick={onPhoneBlock}>
                <Icon color={phoneBlock ? "error" : "success"}>
                  {phoneBlock ? "lock_outlined" : "lock_open_outlined"}
                </Icon>
              </IconButton>
            ) : null}
            {onSearch ? (
              <MDButton
                disabled={phoneNumber.length !== 10}
                onClick={onSearch}
                variant="gradient"
                style={{ borderRadius: 0 }}
                size="medium"
                color="info"
              >
                {t("search")}
              </MDButton>
            ) : null}
          </>
        }
      />
      {phoneNumber.length > 0 && phoneNumber.length !== 10 && (
        <FormHelperText error>{t("invalid_phoneNumber")}</FormHelperText>
      )}
      {phoneBlockNotes && typeof phoneBlockNotes === "string" && phoneBlockNotes.length && (
        <FormHelperText style={{ fontWeight: "bold" }} error>
          {t("notes")}: {phoneBlockNotes}
        </FormHelperText>
      )}
    </MDBox>
  );
}
