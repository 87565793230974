import React from "react";
import { useTranslation } from "react-i18next";

import {
  createFilterOptions,
  Autocomplete,
  CircularProgress,
  IconButton,
  FormHelperText,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import _ from "lodash";
import MDInput from "../MDInput";
import { useClassName } from "./PlacesAutocomplete/styles";
import { getBlockAddressUtil, onBlockAddressUtil } from "../../utilities/func";
import MDBox from "../MDBox";

const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();

export default function CAutocomplete({
  value,
  disabled,
  label,
  disableClearable = true,
  size = "small",
  name,
  placeholder,
  isLabel,
  onChange,
  options = [],
  style,
  containerStyle,
  isAddressBlock,
  multiple,
                                        moreProps,
}) {
  const { t } = useTranslation();
  const classes = useClassName();
  const [loading, setLoading] = React.useState(false);
  const [isBlock, setIsBlock] = React.useState(false);
  const [blockAddressNotes, setBlockAddressNotes] = React.useState("");
  const filterOptions = (opts, state) =>
    defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);

  const onBlockAddress = async () => {
    try {
      let add = _.find(options, { id: value.id });
      add.latitude = add.coordinates.latitude;
      add.longitude = add.coordinates.longitude;
      await onBlockAddressUtil(isBlock, add, setLoading, setIsBlock, setBlockAddressNotes);
    } catch (e) {
      //
    }
  };

  const getBlockAddress = async (address) => {
    try {
      let add = { ...address };
      add.latitude = add.coordinates.latitude;
      add.longitude = add.coordinates.longitude;
      await getBlockAddressUtil(add, setLoading, setIsBlock, setBlockAddressNotes);
    } catch (e) {
      //
    }
  };

  return (
    <div style={containerStyle}>
      <MDBox alignItems="center" display="flex" flexDirection="row">
        <Autocomplete
          multiple={multiple}
          disabled={disabled}
          classes={classes}
          style={style}
          filterOptions={multiple ? undefined : filterOptions}
          options={options}
          autoComplete
          value={value || null}
          fullWidth
          disableClearable={disableClearable}
          clearOnEscape
          size={size}
          popupIcon={isAddressBlock ? "" : undefined}
          renderInput={(params) => (
            <MDInput
              {...params}
              label={isLabel ? t(placeholder) : undefined}
              placeholder={t(placeholder)}
              InputLabelProps={{ shrink: true }}
              autoComplete="off"
            />
          )}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              onChange(event, newValue, name);
            } else if (newValue) {
              if (moreProps){
                onChange(event, {...newValue, id: newValue.id, [label]: newValue[label] }, name);
              }else {
                onChange(event, { id: newValue.id, [label]: newValue[label] }, name);
              }
            } else {
              onChange(event, null, name);
            }
            getBlockAddress(newValue);
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return t(option);
            }
            return t(option[label]);
          }}
          isOptionEqualToValue={(option, newValue) => {
            if (typeof option === "string") {
              return option === newValue;
            }
            return option.id === newValue.id;
          }}
          renderOption={(props, option) => {
            if (typeof option === "string") {
              return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li key={props.id} {...props}>
                  {t(option)}
                </li>
              );
            }
            return (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...props} key={props.id}>
                {t(option[label])}
              </li>
            );
          }}
        />
        {value && isAddressBlock && (
          <>
            {loading && <CircularProgress size={25} style={{ marginLeft: 5 }} color="success" />}
            {!loading && (
              <IconButton onClick={onBlockAddress}>
                <Icon color={isBlock ? "error" : "success"}>
                  {isBlock ? "lock_outlined" : "lock_open_outlined"}
                </Icon>
              </IconButton>
            )}
          </>
        )}
      </MDBox>
      {blockAddressNotes && (
        <FormHelperText style={{ fontWeight: "bold" }} error>
          {t("notes")}: {blockAddressNotes}
        </FormHelperText>
      )}
    </div>
  );
}
