export const DASHBOARD_GRID = "DASHBOARD_GRID";
export function success(payload) {
  return {
    type: DASHBOARD_GRID,
    payload,
  };
}

export function postDashboardGrid(payload) {
  return function f(dispatch) {
    localStorage.setItem("dashboardGrid", JSON.stringify(payload));
    dispatch(success(payload));
  };
}
