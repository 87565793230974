const white = "#fff";

const DataGrid = () => ({
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: 0,
        color: white,
        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
      },
      toolbarContainer: {
        // color: white,
        paddingRight: `10px !important`,
        paddingLeft: `10px !important`,
      },
      menu: {
        color: "#000",
      },
      columnHeaderCheckbox: {
        maxWidth: "58px !important",
        minWidth: "58px !important",
      },
      columnHeader: {
        // color: white,
      },
      columnHeaderTitleContainer: {
        padding: 0,
        // color: white,
      },
      columnHeaderTitle: {
        fontSize: "0.75rem",
        letterSpacing: "0.17px",
        textTransform: "uppercase",
        // color: white,
      },
      columnSeparator: {
        // color: white,
      },
      virtualScroller: {
        marginTop: "54px !important",
      },
      virtualScrollerRenderZone: {
        "& .MuiDataGrid-row": {
          maxHeight: "50px !important",
          minHeight: "50px !important",
        },
      },
      row: {
        "&:last-child": {
          "& .MuiDataGrid-cell": {
            borderBottom: 0,
          },
        },
      },
      cell: {
        maxHeight: "50px !important",
        minHeight: "50px !important",
        lineHeight: "20px !important",
        // borderColor: white,
        // color: white,
        "&:last-of-type": {
          paddingRight: 10,
        },
        "&:focus, &:focus-within": {
          outline: "none",
        },
      },
      cellCheckbox: {
        maxWidth: "58px !important",
        minWidth: "58px !important",
      },
      paper: {
        backgroundColor: "#000",
      },
      menuIconButton: {
        // color: white,
        minHeight: "50px !important",
        borderTop: `1px solid ${white}`,
        "& .MuiTablePagination-toolbar": {
          minHeight: "50px !important",
        },
        "& .MuiTablePagination-select": {
          // color: white,
        },
      },
      editInputCell: {
        padding: 0,
        // color: white,
        "& .MuiInputBase-input": {
          padding: 0,
        },
      },
    },
    defaultProps: {
      rowHeight: 50,
      headerHeight: 54,
    },
  },
});

export default DataGrid;
