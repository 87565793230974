import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CPaper from "../../../../../components/Custom/CPaper";
import TSText from "../../../../../components/Custom/TSText";
import { getCheckField } from "../../../../../utilities/func";
import MDTypography from "../../../../../components/MDTypography";

export default function Client({ data, type }) {
  const { t } = useTranslation();
  return (
    <CPaper style={{ marginTop: 10 }}>
      <MDTypography variant="h4">{t(type)}:</MDTypography>
      <Grid container>
        <Grid item xs={12} sm={3} lg={3}>
          <TSText
            variant="h6"
            title={getCheckField(data, type, "phoneNumber")}
            subtitle="phoneNumber"
          />
        </Grid>
        <Grid item xs={12} sm={3} lg={3}>
          <TSText variant="h6" title={getCheckField(data, type, "email")} subtitle="email" />
        </Grid>
        <Grid item xs={12} sm={3} lg={3}>
          <TSText
            variant="h6"
            title={getCheckField(data, type, "firstName")}
            subtitle="firstName"
          />
        </Grid>
        <Grid item xs={12} sm={3} lg={3}>
          <TSText variant="h6" title={getCheckField(data, type, "lastName")} subtitle="lastName" />
        </Grid>
      </Grid>
    </CPaper>
  );
}
