import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import CDialog from "../../../../../components/Custom/CDialog";
import MDBox from "../../../../../components/MDBox";
import CPaper from "../../../../../components/Custom/CPaper";
import TSText from "../../../../../components/Custom/TSText";

export default function ShowEtaOrderModal({ open, close, data }) {
  const { t } = useTranslation();

  return (
    <CDialog maxWidth="xl" title="eta" close={close} open={open}>
      <CPaper style={{ marginTop: 10 }}>
        <Grid container>
          <Grid item xs={12} sm={4} lg={3}>
            <TSText variant="h6" title={data.minutes} subtitle="etaM" />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TSText variant="h6" title={data.seconds} subtitle="etaS" />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <Grid item xs={12} sm={4} lg={3}>
              <TSText variant="h6" title={data.mi} subtitle="mi" />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TSText variant="h6" title={data.km} subtitle="km" />
          </Grid>
        </Grid>
      </CPaper>
      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton onClick={() => close(false)} variant="gradient" color="error">
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
