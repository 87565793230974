import React from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, Box, Stack, FormHelperText, CircularProgress, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";
import { getStreet, getStreetNumber } from "./ApiMapProvider";
import MDInput from "../../MDInput";
import CIcon from "../CIcon";
import MDTypography from "../../MDTypography";
import { useClassName } from "./styles";
import { getBlockAddressUtil, onBlockAddressUtil } from "../../../utilities/func";
import MDBox from "../../MDBox";

export default function PlacesAutocompleteControlled(properties) {
  const {
    onSelectPlace,
    autocompleteValue,
    setAutocompleteValue,
    disabled,
    mapCenter,
    autocompleteCountry,
    gmapsApiKey,
  } = properties;

  const [loading, setLoading] = React.useState(false);
  const [isBlock, setIsBlock] = React.useState(false);
  const [blockAddressNotes, setBlockAddressNotes] = React.useState("");

  const center = { lat: mapCenter.lat, lng: mapCenter.lng };

  // Create a bounding box with sides ~10km away from the center point
  const defaultBounds = {
    north: center.lat + 0.9,
    south: center.lat - 0.9,
    east: center.lng + 0.9,
    west: center.lng - 0.9,
  };

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = useGoogle({
    apiKey: gmapsApiKey,
    options: {
      bounds: defaultBounds,
      componentRestrictions: { country: autocompleteCountry },
      strictBounds: false,
    },
  });

  const { t } = useTranslation();
  const classes = useClassName();

  const getAddressComponent = (arrAddress) => {
    let city = "";
    let state = "";
    arrAddress.forEach((address_component) => {
      const componentType = address_component.types[0];
      if (componentType === "locality") {
        city = address_component.long_name;
      }
      if (componentType === "administrative_area_level_1") {
        state = address_component.short_name;
      }
    });
    return {
      city,
      state,
    };
  };

  const getBlockAddress = async (address) => {
    await getBlockAddressUtil(address, setLoading, setIsBlock, setBlockAddressNotes);
  };

  const handleSelect = async ({ place_id }) => {
    try {
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder();
      const { results } = await geocoder.geocode({ placeId: place_id });
      const { location } = results[0].geometry;
      const { formatted_address } = results[0];
      const addressArray = results[0].address_components;

      const { city, state } = getAddressComponent(addressArray);
      const streetNumber = getStreetNumber(addressArray);
      const street = getStreet(addressArray);
      const result = {
        latitude: location.lat(),
        longitude: location.lng(),
        address: formatted_address,
        city,
        state,
        street,
        streetNumber,
        place_id,
      };

      setAutocompleteValue(result);
      onSelectPlace(result);
      getBlockAddress(result);
    } catch (error) {
      await Swal.fire("Error", error);
    }
  };

  const onBlockAddress = async () => {
    await onBlockAddressUtil(
      isBlock,
      autocompleteValue,
      setLoading,
      setIsBlock,
      setBlockAddressNotes
    );
  };

  return (
    <>
      <MDBox alignItems="center" display="flex" flexDirection="row">
        <Autocomplete
          disabled={disabled}
          classes={classes}
          filterOptions={(x) => x}
          options={placePredictions}
          autoComplete
          loading={isPlacePredictionsLoading}
          size="small"
          fullWidth
          includeInputInList
          filterSelectedOptions
          value={autocompleteValue || null}
          onChange={(event, newValue) => {
            if (newValue) {
              handleSelect(newValue);
            }
          }}
          onInputChange={(event, newInputValue) => {
            getPlacePredictions({ input: newInputValue });
          }}
          renderInput={(params) => (
            <MDInput
              {...params}
              fullWidth
              placeholder={t("address")}
              InputLabelProps={{ shrink: true }}
              autoComplete="off"
            />
          )}
          getOptionLabel={(option) => option.address || option.description}
          isOptionEqualToValue={(option, optionValue) => option.place_id === optionValue.place_id}
          renderOption={(props, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={props.id}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box sx={{ color: "text.secondary", mr: 1 }}>
                    <CIcon name="location_on" />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Stack direction="column">
                    <MDTypography style={{ fontSize: 15, fontWeight: "bold" }} variant="spam">
                      {option.structured_formatting.main_text || option.address}
                    </MDTypography>
                    <MDTypography style={{ fontSize: 14 }} variant="spam">
                      {option.structured_formatting.secondary_text || option.city}
                    </MDTypography>
                  </Stack>
                </Grid>
              </Grid>
            </li>
          )}
        />
        {autocompleteValue && (
          <>
            {loading && <CircularProgress size={25} style={{ marginLeft: 5 }} color="success" />}
            {!loading && (
              <IconButton onClick={onBlockAddress}>
                <Icon color={isBlock ? "error" : "success"}>
                  {isBlock ? "lock_outlined" : "lock_open_outlined"}
                </Icon>
              </IconButton>
            )}
          </>
        )}
      </MDBox>
      {blockAddressNotes && (
        <FormHelperText style={{ fontWeight: "bold" }} error>
          {t("notes")}: {blockAddressNotes}
        </FormHelperText>
      )}
    </>
  );
}
