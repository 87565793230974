import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "../../../context";
import { useClassNameDark, useClassNameWhite } from "./styles";

export default function CTabs({ labels, icons, value, handle }) {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const classNameDark = useClassNameDark();
  const classNameWhite = useClassNameWhite();

  return (
    <Tabs
      classes={darkMode ? classNameDark : classNameWhite}
      value={value}
      onChange={handle}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      aria-label="icon position tabs example"
    >
      {labels.map((label, index) => (
        <Tab key={label} icon={icons[index]} iconPosition="start" label={t(label)} />
      ))}
    </Tabs>
  );
}
