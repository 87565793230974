import * as React from "react";
import Menu from "@mui/material/Menu";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Typography, ListItemIcon } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useMaterialUIController } from "../../../context";

export default function ActiveActions({ onClick, data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const handleClose = (type) => {
    setAnchorEl(null);
    if (typeof type === "string" && type !== "close") {
      onClick(type);
    }
  };

  return (
    <>
      <IconButton
        style={{ padding: 0, margin: 0 }}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        aria-controls={open ? "basic-menu" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: darkMode ? "#fff" : "#000" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {data.status === "paid" && (
          <MenuItem
            onClick={() => handleClose("payment_status_change_to_pending")}
            style={{ marginBottom: 10 }}
          >
            <ListItemIcon>
              <CreditCardOffIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("payment_status_change_to_pending")}</Typography>
          </MenuItem>
        )}

        {data.status !== "paid" && (
          <MenuItem
            onClick={() => handleClose("payment_status_change_to_paid")}
            style={{ marginBottom: 10 }}
          >
            <ListItemIcon>
              <CreditScoreIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("payment_status_change_to_paid")}</Typography>
          </MenuItem>
        )}

        <MenuItem onClick={() => handleClose("change_info_driver")} style={{ marginBottom: 10 }}>
          <ListItemIcon>
            <AttachMoneyIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("amount")}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleClose("payment_record")} style={{ marginBottom: 10 }}>
          <ListItemIcon>
            <FormatListNumberedIcon
              fontSize="small"
              style={{ color: darkMode ? "#fff" : "#000" }}
            />
          </ListItemIcon>
          <Typography variant="inherit">{t("payments")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
