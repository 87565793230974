import React from "react";
import { Icon } from "@mui/material";
import { useMaterialUIController } from "../../context";

export default function CIcon({ name }) {
  const [controller] = useMaterialUIController();
  const { transparentNavbar, darkMode } = controller;

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  return <Icon sx={iconsStyle}>{name}</Icon>;
}
