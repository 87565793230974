import React from "react";
import _ from "lodash";
import { AlertModel, ProfileModel } from "../../utilities/model";
import ApiClient from "../../api";
import { firebase } from "../../firebase";
import { CaptureException, LogsUsers } from "../../utilities/func";
import { routesOption } from "../../utilities/data";

export default function useData() {
  const [tableData, setTableData] = React.useState([]);
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [loading, setLoading] = React.useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNew, setShowNew] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [model, setModel] = React.useState(new ProfileModel());

  const onReset = () => {
    setLoading(false);
    setPassword("");
    setCAlert(new AlertModel());
    setModel(new ProfileModel());
    setShowNew(false);
    setShowPassword(false);
  };

  React.useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .where("role", "in", ["admin", "dispatcher"])
      .limit(25)
      .onSnapshot((docsData) => {
        let docs = [];
        docsData.forEach((device) => {
          let data = device.data();
          data.id = device.id;
          docs.push(data);
        });
        setTableData(docs);
      });
  }, []);

  const onActions = (type, id, data) => {
    let dataModel = { ...data };
    if (!dataModel.phoneNumber) {
      dataModel.phoneNumber = "";
    }

    if (data.blockedRoutes) {
      dataModel.blockedRoutes = data.blockedRoutes.map((br) => ({
        id: br,
        label: _.find(routesOption, { key: br })?.name,
      }));
    }

    setModel(dataModel);
    switch (type) {
      case "show":
        setShowNew(true);
        break;
      case "password":
        setShowPassword(true);
        break;
      default:
        break;
    }
  };

  const onChange = (e, select, sName) => {
    const { name, value, checked, type } = e.target;
    const Value = type === "checkbox" ? checked : value;
    if (sName) {
      return setModel((prevState) => ({ ...prevState, [sName]: select }));
    }
    return setModel((prevState) => ({ ...prevState, [name]: Value }));
  };

  const onUpdateData = async () => {
    setLoading(true);
    let modelData = { ...model };

    let phoneNumber = null;
    if (modelData.phoneNumber.length) {
      if (modelData.phoneNumber.length < 10) {
        return setCAlert({ open: true, type: "error", ms: "invalid_phoneNumber" });
      }
      phoneNumber = modelData.phoneNumber.includes("+1")
        ? `${modelData.phoneNumber}`
        : `+1${modelData.phoneNumber}`;
    }

    try {
      await ApiClient.put(`users/${model.id}`, {
        ...modelData,
        blockedRoutes: model.blockedRoutes ? model.blockedRoutes.map((br) => br.id) : [],
        phoneNumber,
      });

      LogsUsers({ data: model, type: "driver" });
      onReset();
      setLoadingSuccess(true);
    } catch (e) {
      setCAlert({ open: true, type: "error", ms: e.message });
      onReset();
      CaptureException(e, "Error-DMA-05");
    }
    return true;
  };

  const onCreateNew = () => {
    let newDriver = { ...model };
    let phoneNumber = null;
    if (newDriver.phoneNumber.length) {
      if (newDriver.phoneNumber.length < 10) {
        return setCAlert({ open: true, type: "error", ms: "invalid_phoneNumber" });
      }
      phoneNumber = `+1${newDriver.phoneNumber}`;
    }
    setLoading(true);
    return ApiClient.post("users", {
      ...newDriver,
      blockedRoutes: model.blockedRoutes ? model.blockedRoutes.map((br) => br.id) : [],
      status: "active",
      phoneNumber,
    })
      .then(() => {
        setLoadingSuccess(true);
        onReset();
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
        onReset();
      });
  };

  const onActionData = () => {
    let newDriver = { ...model };
    if (newDriver.id) {
      onUpdateData();
    } else {
      onCreateNew();
    }
  };

  const changePassword = () => {
    const userId = model.id;
    setLoading(true);
    return ApiClient.post(`users/changePassword/${userId}`, {
      password,
    })
      .then(() => {
        onReset();
        setLoadingSuccess(true);
      })
      .catch((e) => {
        onReset();
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const tableDataMap = [...tableData].map((d) => {
    let { id, status, firstName, lastName, email, phoneNumber, role } = d;

    return {
      id,
      status,
      firstName: firstName ?? "----",
      lastName: lastName ?? "----",
      email: email ?? "----",
      phoneNumber: phoneNumber ?? "----",
      role: role ?? "----",
      actions: null,
      func: (type) => onActions(type, id, d),
    };
  });

  return {
    tableData: tableDataMap,
    loading,
    model,
    loadingSuccess,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    showPassword,
    onChange,
    password,
    setPassword,
    changePassword,
    showNew,
    setShowNew,
    onReset,
    onActionData,
  };
}
