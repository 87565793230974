import MDBox from "components/MDBox";
import * as React from "react";
import CMenu from "./CMenu";
import ClipboardText from "../../../../../components/Custom/ClipboardText";
import { formatPhoneNumber } from "../../../../../utilities/func";

const textStyle = {
  fontSize: 14,
  color: "#eee",
  fontWeight: "bold",
  width: 100,
};
const textHeaderStyle = {
  fontSize: 14,
  color: "#424242",
  fontWeight: "bold",
  width: 100,
};
const spaceStyle = {
  fontSize: 14,
  color: "#a1a1a1",
  fontWeight: "bold",
};

export default function RenderOrder({
  isHeader,
  background,
  streetNumber,
  street,
  city,
  phoneNumber,
  dNumber,
  time,
  onClick,
  isPaymentCard,
}) {
  return (
    <MDBox
      style={{
        background: isHeader ? "#f6f6f6" : background,
        marginTop: 5,
        borderRadius: 4,
        padding: 5,
      }}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <strong style={isHeader ? { ...textHeaderStyle, width: 40 } : { ...textStyle, width: 40 }}>
        {time}
      </strong>
      <strong style={spaceStyle}>|</strong>
      <small
        style={isHeader ? { ...textHeaderStyle, width: 250 } : { ...textStyle, width: 250 }}
      >{`${streetNumber} ${street}, ${city}`}</small>
      <strong style={spaceStyle}>|</strong>

      {isHeader ? (
        <strong style={isHeader ? textHeaderStyle : textStyle}> {phoneNumber} </strong>
      ) : (
        <ClipboardText
          text={formatPhoneNumber(phoneNumber)}
          fontSize={14}
          color="#eee"
          fontWeight="bold"
          variant="strong"
        />
      )}

      <strong style={spaceStyle}>|</strong>
      <strong style={isHeader ? textHeaderStyle : textStyle}> {dNumber} </strong>
      <CMenu
        isHeader={isHeader}
        isPaymentCard={isPaymentCard}
        idDriver={!!dNumber}
        onClick={onClick}
        title={`${time} | ${streetNumber} ${street}, ${city}`}
      />
    </MDBox>
  );
}
