import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import Swal from "sweetalert2";
import { DateTime } from "luxon";
import { firebase, firestore } from "../../firebase";

export default function useData() {
  const { t } = useTranslation();
  const driversData = useSelector(({ driverList }) => driverList);
  const [periodsData, setPeriodsData] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [paymentsRecord, setPaymentsRecord] = React.useState([]);
  const [currentPeriod, setCurrentPeriod] = React.useState(null);
  const [openStripeDetail, setOpenStripeDetail] = React.useState(false);
  const [stripeId, setStripeId] = React.useState("");
  const [stripePaymentMethodId, setStripePaymentMethodId] = React.useState("");
  const [searched, setSearched] = React.useState(0);
  const [paymentsStripeDetails, setPaymentsStripeDetails] = React.useState([]);
  const [cAlert, setCAlert] = React.useState({
    show: false,
    text: "",
    title: "",
    action: "",
  });
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [showDriverAmount, setShowDriverAmount] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [model, setModel] = React.useState({});
  const [amount, setAmount] = React.useState(0);
  const [amountDue, setAmountDue] = React.useState(0);
  const [amountDriver, setAmountDriver] = React.useState(0);
  const [amountDueDriver, setAmountDueDriver] = React.useState(0);
  const [hasPendingPayment, setHasPendingPayment] = React.useState(false);
  const [paymentsRecordModal, setPaymentsRecordModal] = React.useState(false);
  const [paymentStatusChangeConfirmation, setPaymentStatusChangeConfirmation] = React.useState({
    show: false,
    text: "",
    title: "",
  });

  const loadPeriods = () => {
    firebase
      .firestore()
      .collection("payments_periods")
      .where("newFormat", "==", true)
      .orderBy("generatedAt", "desc")
      .limit(12)
      .onSnapshot((docsData) => {
        let docs = [];
        docsData.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          data.label = `${data.start.toDate().toLocaleDateString("en-US")} - ${data.end
            .toDate()
            .toLocaleDateString("en-US")}`;
          docs.push(data);
        });
        setPeriodsData(docs);
        if (!currentPeriod) {
          setCurrentPeriod(docs.length > 0 ? docs[0] : null);
        }
      });
  };

  const loadPaymentsData = () => {
    if (currentPeriod) {
      firebase
        .firestore()
        .collection("payments_periods")
        .doc(currentPeriod.id)
        .collection("payments")
        .onSnapshot((docsData) => {
          let docs = [];
          docsData.forEach((doc) => {
            const { status, start, end, driver = {}, amountPaid } = doc.data();
            const { phoneNumber, displayName, dNumber, id } = driver;
            docs.push({
              status,
              id: doc.id,
              periodStart: start,
              periodEnd: end,
              driverId: id,
              amountPaid: amountPaid ?? "N/A",
              displayName: displayName ?? "N/A",
              dNumber: dNumber ?? "N/A",
              phoneNumber: phoneNumber ?? "N/A",
            });
          });
          setTableData(docs);
        });
    }
  };

  React.useEffect(() => {
    loadPeriods();
  }, []);

  React.useEffect(() => {
    loadPaymentsData();
  }, [currentPeriod]);

  const hidePaymentStatusChangeConfirmation = () =>
    setPaymentStatusChangeConfirmation({ show: false, text: "", title: "", action: "" });

  const onConfirmAmountPayment = (name, value) => {
    setLoading(true);
    driversData.forEach(async (d, index) => {
      await firestore()
        .collection("drivers")
        .doc(d.id)
        .update({
          [name]: Number(value),
        })
        .catch(() => {});
      if (driversData.length === index + 1) {
        setLoading(false);
        setAmount(0);
      }
    });
  };

  const updateAmountPayment = () => {
    Swal.fire({
      title: t("update amount"),
      text: t("execute_action_ms"),
      showCancelButton: true,
      confirmButtonText: t("yes, I do"),
      cancelButtonText: t("no, I don't"),
      confirmButtonColor: "#00a441",
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirmAmountPayment("payment.amount", amount);
      }
    });
  };

  const updateAmountDueDriver = () => {
    Swal.fire({
      title: t("update amount"),
      text: t("execute_action_ms"),
      showCancelButton: true,
      confirmButtonText: t("yes, I do"),
      cancelButtonText: t("no, I don't"),
      confirmButtonColor: "#00a441",
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirmAmountPayment("payment.amountDue", amountDue);
      }
    });
  };

  const onPaymentStatusChange = async (paymentStatus) => {
    let ref = firestore()
      .collection("payments_periods")
      .doc(currentPeriod.id)
      .collection("payments")
      .doc(model.id);

    try {
      await ref.set(
        {
          status: paymentStatus,
        },
        { merge: true }
      );
      setLoadingSuccess(true);
      hidePaymentStatusChangeConfirmation();
    } catch (e) {
      setLoadingSuccess(false);
    }
  };

  const getPaymentsRecord = async (id) => {
    setLoading(true);
    setShowDriverAmount(false);
    firestore()
      .collection("drivers")
      .doc(id)
      .collection("payments")
      .limit(10)
      .orderBy("createdAt", "desc")
      .get()
      .then((records) => {
        setLoading(false);
        let info = [];
        records.forEach((d) => {
          let data = d.data();
          data.createdAt = DateTime.fromSeconds(data.createdAt.seconds).toFormat("ff");

          if (data.paymentMethod === "paypal") {
            data.amount = data.purchase_units[0].amount.value;
          }

          info.push(data);
        });

        setPaymentsRecord(info);
        if (info.length) {
          setPaymentsRecordModal(true);
        }
      })
      .catch(() => {});
  };

  const onActions = (type, id, data) => {
    setModel(data);
    switch (type) {
      case "payment_status_change_to_pending":
        setPaymentStatusChangeConfirmation({
          show: true,
          action: "pending",
          title: t("payment_status_change_to_pending"),
          text: t("payment_status_change_to_pending_confirmation"),
        });
        break;
      case "payment_status_change_to_paid":
        setPaymentStatusChangeConfirmation({
          show: true,
          action: "paid",
          title: t("payment_status_change_to_paid"),
          text: t("payment_status_change_to_paid_confirmation"),
        });
        break;

      case "change_info_driver":
        // eslint-disable-next-line no-case-declarations
        const currentDriver = _.find(driversData, { id: data.driverId });
        if (currentDriver && currentDriver.payment) {
          setAmountDriver(currentDriver.payment.amount ?? 0);
          setAmountDueDriver(currentDriver.payment.amountDue ?? 0);
          setHasPendingPayment(currentDriver.hasPendingPayment ?? false);
          setModel((prevState) => ({
            ...prevState,
            amountDue: currentDriver.payment.amountDue ?? 0,
          }));
        }
        setShowDriverAmount(true);
        break;

      case "payment_record":
        getPaymentsRecord(data.driverId);
        break;
      default:
        break;
    }
  };

  const closeActionAmountPayment = () => {
    setCAlert({
      show: false,
      text: "",
      title: "",
    });
  };

  const onUpdateDriverAmount = async () => {
    setLoading(true);
    setShowDriverAmount(false);
    await firestore()
      .collection("drivers")
      .doc(model.driverId)
      .update({
        "payment.amount": Number(amountDriver),
        "payment.amountDue": Number(amountDueDriver),
        hasPendingPayment,
      })
      .then(() => {
        setModel({});
        setLoading(false);
        setAmountDriver(0);
      })
      .catch(() => {});
  };

  const searchStripeByDriver = async (data) => {
    if (data.length === 0) {
      return setLoading(false);
    }

    let driver = data[0];
    let driverId = driver.id;

    let ref =  firestore()
      .collection("drivers")
      .doc(driverId).collection("payments")

    let result
    if (stripePaymentMethodId){
      result = await ref.where("stripePaymentMethodId", "==", stripePaymentMethodId).get();
    }

    if(stripeId){
      result = await ref.where("stripeId", "==", stripeId).get();
    }
    // let result = await firestore()
    //   .collection("drivers")
    //   .doc(driverId)
    //   .collection("payments")
    //   .where("stripeId", "==", stripeId)
    //   .get();
    if (!result.empty) {
      let list = [];
      result.forEach((d) => {
        let item = d.data();
        item.id = d.id;
        item.driver = driver.dNumber;
        list.push(item);
      });
      setLoading(false);
      return setPaymentsStripeDetails(list);
    }
    data.shift();
    setSearched(data.length);
    return searchStripeByDriver(data);
  };

  const onSearchStripe = () => {
    setLoading(true);
    setPaymentsStripeDetails([]);
    searchStripeByDriver(driversData);
  };

  const onPeriodSelected = (v) => {
    const { id: periodId } = v;
    const selectedPeriod = periodsData.find((p) => p.id === periodId);
    if (selectedPeriod) {
      setCurrentPeriod(selectedPeriod);
    }
  };

  const tableDataMap = [...tableData].map((d) => ({
    ...d,
    actions: null,
    func: (type) => onActions(type, d.id || 0, d),
  }));

  return {
    tableData: tableDataMap,
    periodsData,
    currentPeriod,
    onPeriodSelected,
    loadingSuccess,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    onPaymentStatusChange,
    hidePaymentStatusChangeConfirmation,
    paymentStatusChangeConfirmation,
    amount,
    setAmount,
    updateAmountPayment,
    closeActionAmountPayment,
    onConfirmAmountPayment,
    loading,
    onUpdateDriverAmount,
    showDriverAmount,
    setShowDriverAmount,
    model,
    amountDriver,
    setAmountDriver,
    hasPendingPayment,
    setHasPendingPayment,
    amountDueDriver,
    setAmountDueDriver,
    amountDue,
    setAmountDue,
    updateAmountDueDriver,
    paymentsRecord,
    paymentsRecordModal,
    setPaymentsRecordModal,
    openStripeDetail,
    setOpenStripeDetail,
    paymentsStripeDetails,
    stripeId,
    setStripeId,
    stripePaymentMethodId,
    setStripePaymentMethodId,
    onSearchStripe,
    searched,
  };
}
