import * as React from "react";
import { useState } from "react";
import List from "@mui/material/List";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { ReactMic } from "react-mic";
import { useLongPress } from "use-long-press";
import _ from "lodash";
import CInputMic from "../../../../../components/Custom/CInputMic";
import CInputButton from "../../../../../components/Custom/CInputButton";
import MDBox from "../../../../../components/MDBox";
import CListItem from "../../../../../components/Custom/CListItem";
import { firestore, firebase } from "../../../../../firebase";
import { getUser } from "../../../../../utilities/func";
import CLoadingSnackbar from "../../../../../components/Custom/CAlerts/CLoadingSnackbar";
import CAlertSnackbar from "../../../../../components/Custom/CAlerts/CAlertSnackbar";
import { AlertModel } from "../../../../../utilities/model";

export default function Menu({ selectedDriver }) {
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [recording, setRecording] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [base64data, setBase64data] = React.useState(null);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");
  const [productFilter, setProductFilter] = React.useState([]);
  const drivers = useSelector(({ driverList }) => driverList);

  const sendGlobalMessage = () => {
    if (message !== "") {
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      firestore()
        .collection("chat")
        .add({
          driverId: "all",
          source: "admin",
          _id: Math.floor(Math.random() * 100) * Date.now(),
          text: message,
          global: true,
          user: { _id: "123456789" },
          dispatcher: getUser(),
          createdAt: timestamp,
          status: "pending",
        })
        .then(() => {
          setMessage("");
          setLoadingSuccess(true);
        })
        .catch((e) => {
          setCAlert({ open: true, type: "error", ms: e.message });
        });
    }
  };

  const filterList = (text) => {
    let size = 7;
    const results = _.sortBy([...drivers], "dNumber").filter(
      (item) =>
        item.dNumber.toString().toLowerCase().includes(text.toLowerCase()) ||
        item.displayName.toString().toLowerCase().includes(text.toLowerCase())
    );
    let items = results.slice(0, size);
    setProductFilter(items);
  };

  React.useEffect(() => {
    if (productFilter.length === 0) {
      filterList("");
    }
  }, [drivers, search]);

  const uploadAudio = () => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    setBase64data(null);
    firestore()
      .collection("audio")
      .add({
        createdAt: timestamp,
        adminName: getUser().displayName,
        userId: "all",
        source: "admin",
        audio: base64data,
      })
      .then(() => {
        setLoadingSuccess(true);
        setLoading(false);
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const record = async () => {
    setLoading(true);
    if (!recording) {
      setRecording(true);
    } else {
      setRecording(false);
    }
  };

  const onStop = ({ blob }) => {
    setLoading(false);
    try {
      setRecording(false);
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        let base64 = reader.result.replace("data:audio/wav;base64,", "");
        setBase64data(base64);
      };
    } catch (e) {
      setCAlert({ open: true, type: "error", ms: e.message });
    }
  };

  const callback = React.useCallback(() => record(), []);
  const bind = useLongPress(callback, {
    onFinish: () => setRecording(false),
    onCancel: () => setRecording(false),
    threshold: 200,
    detect: "both",
  });

  React.useMemo(() => {
    if (base64data && !recording) {
      uploadAudio();
    }
  }, [base64data, recording]);

  return (
    <MDBox display="flex" flexDirection="column">
      <div style={{ marginTop: 5 }} />
      <CInputMic
        placeholder="send_global_message"
        value={message}
        onChange={setMessage}
        bind={bind}
        onSendClick={sendGlobalMessage}
      />
      <CInputButton
        iconStart={<SearchIcon />}
        placeholder="driver"
        value={search}
        onChange={(e) => {
          setSearch(e);
          filterList(e);
        }}
        buttonLabel="search"
      />
      <List sx={{ width: "100%", bgcolor: "transparent" }} dense>
        {productFilter.map((driver) => {
          let { id, dNumber, displayName } = driver;
          return (
            <CListItem
              clicked
              key={id}
              primary={displayName}
              secondary={dNumber}
              selectedDriver={selectedDriver}
            />
          );
        })}
      </List>

      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="message_sent"
        duration={1500}
      />
      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />

      <div style={{ display: "none" }}>
        <ReactMic
          record={recording}
          className="sound-wave"
          mimeType="audio/wav"
          onStop={onStop}
          strokeColor="#000000"
          backgroundColor="#FF4081"
        />
      </div>
      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="message_sent"
        duration={1500}
      />
      <CLoadingSnackbar open={loading} title="recording_audio" />
      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />
    </MDBox>
  );
}
