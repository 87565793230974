import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import MDTypography from "../../../components/MDTypography";

export default function Header() {
  const driversData = useSelector(({ driverList }) => driverList);
  const { t } = useTranslation();

  return (
    <MDBox mb={2}>
      <Card sx={{ borderRadius: 1 }}>
        <MDBox
          py={1}
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
        >
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">{driversData.length ?? 0}</MDTypography>
            <MDTypography>{t("drivers")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">
              {driversData.filter((d) => d.status === "active").length ?? 0}
            </MDTypography>
            <MDTypography>{t("active drivers")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">
              {driversData.filter((d) => d.status !== "active").length ?? 0}
            </MDTypography>
            <MDTypography>{t("inactive drivers")}</MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  );
}
