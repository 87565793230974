import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import { ProfileModel } from "../../../utilities/model";
import CAutocomplete from "../../../components/Custom/CAutocomplete";
import { roleOptions, statusOptions, routesOption, boolOptions } from "../../../utilities/data";
import MDTypography from "../../../components/MDTypography";
import CPaper from "../../../components/Custom/CPaper";

export default function UserModal({ open, close, model = new ProfileModel(), onCreate, onChange }) {
  const { t } = useTranslation();

  const isDisabled = React.useMemo(() => {
    if (model.id) {
      return !(model.firstName.length && model.lastName.length && model.email.length);
    }
    return !(
      model.firstName.length &&
      model.lastName.length &&
      model.email.length &&
      model.password.length >= 6
    );
  }, [model]);

  return (
    <CDialog title="staff" close={close} open={open}>
      <br />
      <CPaper elevation={3} sx={{ padding: 5 }}>
        <MDTypography mb={2} variant="h5">
          {t("user information")}
        </MDTypography>
        <MDInput
          fullWidth
          value={model.firstName}
          onChange={onChange}
          name="firstName"
          style={{ marginBottom: 25 }}
          label={`${t("firstName")}*`}
          type="text"
        />

        <MDInput
          fullWidth
          value={model.lastName}
          onChange={onChange}
          name="lastName"
          style={{ marginBottom: 25 }}
          label={`${t("lastName")}*`}
          type="text"
        />

        <MDInput
          fullWidth
          value={model.email}
          onChange={onChange}
          name="email"
          style={{ marginBottom: 25 }}
          label={`${t("email")}*`}
          type="text"
        />

        <MDInput
          fullWidth
          value={model.phoneNumber}
          onChange={onChange}
          name="phoneNumber"
          style={{ marginBottom: 25 }}
          label={t("phoneNumber")}
          type="text"
        />

        <CAutocomplete
          value={model.role}
          isLabel
          size="large"
          style={{ marginBottom: 25 }}
          name="role"
          placeholder={`${t("role")}*`}
          options={roleOptions}
          onChange={(e, v, n) => onChange(e, v, n)}
        />

        {model.id && (
          <CAutocomplete
            value={model.status}
            isLabel
            size="large"
            style={{ marginBottom: 25 }}
            name="status"
            placeholder={`${t("status")}*`}
            options={statusOptions}
            onChange={(e, v, n) => onChange(e, v, n)}
          />
        )}

        {!model.id && (
          <MDInput
            fullWidth
            value={model.password}
            onChange={onChange}
            name="password"
            style={{ marginBottom: 25 }}
            label={`${t("password")}*`}
            type="text"
          />
        )}
      </CPaper>
      <br />
      <CPaper elevation={3} sx={{ padding: 5 }}>
        <MDTypography mb={2} variant="h5">
          {t("user permissions")}
        </MDTypography>

        <MDTypography mb={2} variant="h6">
          {t("blocked routes")}
        </MDTypography>

        <Autocomplete
          multiple
          value={model.blockedRoutes || []}
          style={{ marginBottom: 25 }}
          options={routesOption.map((router) => ({
            id: router.key,
            label: router.name,
          }))}
          onChange={(e, v) => onChange(e, v, "blockedRoutes")}
          getOptionLabel={(option) => t(option.label)}
          isOptionEqualToValue={(option, newValue) => {
            if (typeof option === "string") {
              return option === newValue;
            }
            return option.id === newValue.id;
          }}
          renderInput={(params) => <MDInput {...params} label={t("blocked routes")} />}
        />

        <MDTypography mb={2} variant="h6">
          {t("others")}
        </MDTypography>

        <Grid container>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              style={{ marginBottom: 25 }}
              value={model.showDriverNumber}
              onChange={(event, newValue) => {
                onChange(event, newValue, "showDriverNumber");
              }}
              options={boolOptions}
              getOptionLabel={(option) => t(option)}
              renderInput={(params) => <MDInput {...params} label={t("showDriverNumber")} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              style={{ marginBottom: 25 }}
              value={model.allowPassOrder}
              onChange={(event, newValue) => {
                onChange(event, newValue, "allowPassOrder");
              }}
              options={boolOptions}
              getOptionLabel={(option) => t(option)}
              renderInput={(params) => <MDInput {...params} label={t("allowPassOrder")} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              style={{ marginBottom: 25 }}
              value={model.allowCancelOrder}
              onChange={(event, newValue) => {
                onChange(event, newValue, "allowCancelOrder");
              }}
              options={boolOptions}
              getOptionLabel={(option) => t(option)}
              renderInput={(params) => <MDInput {...params} label={t("allowCancelOrder")} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              style={{ marginBottom: 25 }}
              value={model.allowEditDriver}
              onChange={(event, newValue) => {
                onChange(event, newValue, "allowEditDriver");
              }}
              options={boolOptions}
              getOptionLabel={(option) => t(option)}
              renderInput={(params) => <MDInput {...params} label={t("allowEditDriver")} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              style={{ marginBottom: 25 }}
              value={model.allowAssignOrder}
              onChange={(event, newValue) => {
                onChange(event, newValue, "allowAssignOrder");
              }}
              options={boolOptions}
              getOptionLabel={(option) => t(option)}
              renderInput={(params) => <MDInput {...params} label={t("allowAssignOrder")} />}
            />
          </Grid>
        </Grid>
      </CPaper>

      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton disabled={isDisabled} onClick={onCreate} variant="gradient" color="success">
          {!model.id ? t("add") : t("update")}
        </MDButton>
        <MDButton
          onClick={() => close(false)}
          variant="gradient"
          color="error"
          style={{ marginLeft: 20 }}
        >
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
