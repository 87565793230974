import React from "react";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import _ from "lodash";
import CDialog from "./Custom/CDialog";
import MDBox from "./MDBox";
import CPaper from "./Custom/CPaper";
import TSText from "./Custom/TSText";
import MDTypography from "./MDTypography";
import TSSwitch from "./Custom/TSSwitch";
import { suspendOptions } from "../utilities/data";
import MDInput from "./MDInput";
import { calculateDeactivation, isUserPermission, onCheckIfExist } from "../utilities/func";
import { firebase } from "../firebase";

export default function DriverInfo({ open, close, data, onClick, onChange, onClickDeactivation }) {
  const { t } = useTranslation();

  const dashboardSettings = useSelector(({ settings }) => settings);
  const userInfo = useSelector(({ user }) => user);
  const driversData = useSelector(({ driverList }) => driverList);
  let getDriversNumber = _.map(driversData, "dNumber");

  const allowEditDriver = onCheckIfExist(userInfo, "allowEditDriver");

  const [model2, setModel2] = React.useState({ ping: false });

  const validatedDNumber = React.useMemo(() => {
    let mySelfId = _.find(driversData, { id: data.id })?.dNumber;
    return getDriversNumber.includes(data.dNumber) && mySelfId !== data.dNumber;
  }, [driversData, data]);

  let isDisabled = React.useMemo(() => !(data.email.length && data.dNumber.length), [data]);

  const onChangePing = (e) => {
    const { name, value, checked, type } = e.target;
    const Value = type === "checkbox" ? checked : value;
    setModel2((prevState) => ({ ...prevState, [name]: Value }));
    if (value) {
      firebase
        .firestore()
        .collection("drivers")
        .doc(data.id)
        .update({ ping: true })
        .catch(() => {});
    }
  };

  return (
    <CDialog title="driver_info" subtitle={data.id} close={() => close(false)} open={open}>
      <CPaper style={{ marginTop: 10 }}>
        <MDTypography variant="h5">{t("driver")}</MDTypography>
        <Grid spacing={2} container mt={2} pb={2}>
          <Grid item xs>
            <MDInput
              error={data.firstName.length < 2}
              success={data.firstName.length > 2}
              value={data.firstName}
              onChange={onChange}
              disabled={!allowEditDriver}
              name="firstName"
              fullWidth
              label={t("firstName")}
            />
          </Grid>
          <Grid item xs>
            <MDInput
              error={data.lastName.length < 2}
              success={data.lastName.length > 2}
              value={data.lastName}
              onChange={onChange}
              disabled={!allowEditDriver}
              name="lastName"
              fullWidth
              label={t("lastName")}
            />
          </Grid>
          <Grid item xs>
            <MDInput
              error={validatedDNumber || !data.dNumber.length}
              success={data.dNumber.length > 0 && !validatedDNumber}
              value={data.dNumber}
              disabled={!allowEditDriver}
              onChange={onChange}
              name="dNumber"
              fullWidth
              label={t("dNumber")}
            />
            {validatedDNumber && (
              <MDTypography variant="small" color="error" fontWeight="bold">
                {t("A driver number with this identification already exists")}
              </MDTypography>
            )}
          </Grid>
          <Grid item xs>
            <MDInput
              error={data.phoneNumber.length < 2}
              success={data.phoneNumber.length > 2}
              value={data.phoneNumber}
              disabled={!allowEditDriver}
              onChange={onChange}
              name="phoneNumber"
              fullWidth
              label={t("phoneNumber")}
            />
          </Grid>
          <Grid item xs>
            <MDInput
              error={data.email.length < 2}
              success={data.email.length > 2}
              value={data.email}
              disabled={!allowEditDriver}
              onChange={onChange}
              name="email"
              fullWidth
              label={t("email")}
            />
          </Grid>
        </Grid>
      </CPaper>
      <br />

      <CPaper>
        <MDTypography variant="h5" fontWeight={false}>
          {t("current_device")}
        </MDTypography>
        {data.currentDevice ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={4} lg={3}>
              <TSText title={data.currentDevice?.brand} subtitle="brand" />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <TSText title={data.currentDevice?.model} subtitle="model" />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <TSText title={data.currentDevice?.app} subtitle="version" />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <TSText title={data.currentDevice?.isTable ? t("yes") : t("no")} subtitle="table" />
            </Grid>
          </Grid>
        ) : null}
        <br />
        <Grid container>
          <Grid item xs={12} sm={4} lg={4}>
            <TSText title={data.appState} subtitle="appState" />
          </Grid>
          <Grid item xs={12} sm={4} lg={4}>
            <TSText title={data.automatic} subtitle="automatic" />
          </Grid>
          <Grid item xs={12} sm={4} lg={4}>
            <TSText title={data.fcmToken ? t("yes") : t("no")} subtitle="push_notification" />
          </Grid>
        </Grid>
      </CPaper>
      <br />

      <CPaper>
        <MDTypography variant="h5" fontWeight={false}>
          {t("coordinates status")}
        </MDTypography>
        <MDBox
          py={2}
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
        >
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h5">{data.bgPermission ?? ''}</MDTypography>
            <MDTypography>{t("geolocation_status")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h5">{data.interval ?? 0}</MDTypography>
            <MDTypography>{t("interval")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h5">{data.coordinatesQty ?? 0}</MDTypography>
            <MDTypography>{t("coordinatesQty")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h5">{data.coordinatesDate ?? ""}</MDTypography>
            <MDTypography>{t("coordinatesDate")}</MDTypography>
          </MDBox>
        </MDBox>
      </CPaper>

      <br />
      <CPaper>
        <MDTypography variant="h5">{t("order")}</MDTypography>
        <Grid container>
          <Grid item xs={12} sm={4} lg={4}>
            <TSText variant="h5" title={data.lastOrderDate} subtitle="last_order" />
          </Grid>
          <Grid item xs={12} sm={4} lg={4}>
            <TSText variant="h5" title={data.lastAssignedOrderDate} subtitle="last_assigned" />
          </Grid>
          <Grid item xs={12} sm={4} lg={4}>
            <TSText
              variant="h5"
              title={data.retainSpot ? t("yes") : t("no")}
              subtitle="retain_spot"
            />
          </Grid>
        </Grid>
      </CPaper>

      <br />
      <MDTypography variant="h5">{t("actions")}</MDTypography>

      {allowEditDriver && (
        <CPaper>
          <MDTypography variant="h5">{t("generals")}</MDTypography>
          <Grid container>
            <Grid item xs={12} sm={4} lg={3}>
              <TSSwitch
                subtitle="auto_mark_paid"
                name="autoMarkedAsPaid"
                value={data.autoMarkedAsPaid}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <TSSwitch
                subtitle="with_schedule"
                name="withSchedule"
                value={data.withSchedule}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <TSSwitch
                subtitle="credit_card"
                name="hasPayment"
                value={data.hasPayment}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <TSSwitch
                subtitle="force_update"
                name="isVisibleUpdated"
                value={data.isVisibleUpdated}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid item xs={12} sm={3} lg={3}>
              <TSSwitch
                subtitle="hasAutoOrders"
                name="hasAutoOrders"
                value={data.hasAutoOrders}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <TSSwitch
                subtitle="turn_off_driver"
                name="isDrivingStatus"
                value={data.isDrivingStatus}
                onChange={onChange}
              />
            </Grid>

            {isUserPermission(dashboardSettings, userInfo, "changeDriverStatus", false) && (
              <Grid item xs={12} sm={3} lg={3}>
                <TSSwitch
                  subtitle="block_account"
                  name="isStatus"
                  value={data.isStatus}
                  onChange={onChange}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={3} lg={3}>
              <TSSwitch
                subtitle="Ping"
                name="ping"
                value={model2.ping || false}
                onChange={onChangePing}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid item xs={12} sm={3} lg={3}>
              <TSSwitch
                subtitle="credit_card_reader"
                name="hasPaymentReader"
                value={data.hasPaymentReader}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <TSSwitch
                subtitle="isGeofence"
                name="isGeofence"
                value={data.isGeofence}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <br />
        </CPaper>
      )}

      {isUserPermission(dashboardSettings, userInfo, "changeDriverStatus", false) &&
        allowEditDriver && (
          <>
            <br />
            <CPaper>
              {data.deactivation ? (
                <>
                  <MDTypography mb={1} variant="h5">
                    {t("lock_time_active")}
                  </MDTypography>
                  <MDTypography variant="h5" color="error">
                    {t("time_left")}: {calculateDeactivation(data.deactivation, t)}
                  </MDTypography>
                </>
              ) : (
                <>
                  <MDTypography mb={2} variant="h5">
                    {t("add_lock_time")}
                  </MDTypography>
                  <Autocomplete
                    fullWidth
                    value={data.suspendTime || null}
                    onChange={(event, newValue) => {
                      onChange(event, newValue, "suspendTime");
                    }}
                    size="small"
                    options={suspendOptions}
                    getOptionLabel={(option) => t(`${option}s`)}
                    renderInput={(params) => <MDInput {...params} placeholder={t("time")} />}
                  />
                  <MDButton
                    disabled={!data.suspendTime}
                    style={{ marginRight: 20, marginTop: 15 }}
                    onClick={onClickDeactivation}
                    variant="gradient"
                    color="warning"
                  >
                    {t("block_account")}
                  </MDButton>
                </>
              )}
            </CPaper>
          </>
        )}

      {data.workingHours && typeof data.workingHours === "object" && (
        <>
          <br />
          <CPaper>
            <MDTypography variant="h5">{t("schedule")}</MDTypography>
            <Grid container>
              {[0, 1, 2, 3, 4, 5, 6, 7].map(
                (days) =>
                  data.workingHours[days] && (
                    <Grid key={days} item xs>
                      <TSText
                        variant="h6"
                        title={`${data.workingHours[days][0].substr(0, 5)} / ${data.workingHours[
                          days
                        ][1].substr(0, 5)}`}
                        subtitle={DateTime.fromFormat((days + 1).toString(), "E").toFormat("EEEE")}
                      />
                    </Grid>
                  )
              )}
            </Grid>
          </CPaper>
        </>
      )}

      <br />

      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        {allowEditDriver && (
          <MDButton
            disabled={isDisabled}
            style={{ marginRight: 20 }}
            onClick={onClick}
            variant="gradient"
            color="success"
          >
            {t("save")}
          </MDButton>
        )}
        <MDButton onClick={() => close(false)} variant="gradient" color="error">
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
