export const columns = [
  { Header: "actions", accessor: "actions" },
  { Header: "date", accessor: "createdAt" },
  { Header: "status", accessor: "ticketStatus" },
  { Header: "invoice_id", accessor: "invoiceId" },
  { Header: "fare", accessor: "fare" },
  { Header: "driver", accessor: "driver" },
  { Header: "client", accessor: "client" },
  { Header: "phone", accessor: "phone" },
  { Header: "from", accessor: "from" },
];
