import React from "react";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import AdminLayout from "./layouts/dashboards";
import AuthLayout from "./layouts/authentication";
import CSessionLoading from "./components/Custom/CSesionLoading";
import { firebase, firestore } from "./firebase";
import {
  getDriverList,
  getSettings,
  postDashboardGrid,
  getTickets,
  getUserInfo,
  getSchedulesList
} from "./redux/actions";

export default function MainApp() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState(false);
  const [isUserVerified, setIsUserVerified] = React.useState(false);

  firebase.auth().onAuthStateChanged(async (currentUser) => {
    if (currentUser) {
      localStorage.setItem("token", currentUser.uid);
      setUser(true);
    } else {
      setLoading(false);
      localStorage.removeItem("token");
      setUser(false);
    }
  });

  React.useEffect(() => {
    const language = localStorage.getItem("language");
    const dashboardGridString = localStorage.getItem("dashboardGrid");

    if (dashboardGridString) {
      let dashboardGrid = JSON.parse(dashboardGridString);
      dispatch(postDashboardGrid(dashboardGrid));
    }
    if (language) {
      i18next.changeLanguage(language).catch(() => {});
    }
    if (user) {
      dispatch(getDriverList());
      dispatch(getSettings());
      dispatch(getTickets());
      dispatch(getSchedulesList());
    }
  }, [user]);

  React.useEffect(() => {
    if (user && !isUserVerified) {
      let { uid } = firebase.auth().currentUser;
      firestore()
        .collection("users")
        .doc(uid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            let userInfo = doc.data();
            userInfo.id = doc.id;
            setLoading(false);
            if (
              (userInfo.role === "admin" || userInfo.role === "dispatcher") &&
              userInfo.status === "active"
            ) {
              dispatch(getUserInfo(userInfo));
              setIsUserVerified(true);
            } else {
              firebase
                .auth()
                .signOut()
                .then(() => {
                  setUser(false);
                })
                .catch(() => {});
            }
          }
        });
    }
  }, [user, isUserVerified]);

  if (loading) {
    return <CSessionLoading loading={loading} />;
  }

  return <>{user && isUserVerified ? <AdminLayout /> : <AuthLayout />}</>;
}
