import * as React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, FormGroup, FormControlLabel, Grid, IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import CAutocomplete from "../../../../../components/Custom/CAutocomplete";
import CIcon from "../../../../../components/Custom/CIcon";
import { vehicleTypeOptions } from "../../../../../utilities/data";
import { onCheckIfExist } from "../../../../../utilities/func";

export default function OrderOptions({
  isDestination,
  setIsDestination,
  isAppointment,
  setIsAppointment,
  onChangeOrder,
  orderModel,
  drivers,
}) {
  const { t } = useTranslation();
  const dashboardSettings = useSelector(({ settings }) => settings);
  const userInfo = useSelector(({ user }) => user);

  const allowAssignOrder = onCheckIfExist(userInfo, "allowAssignOrder");

  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs>
          <CAutocomplete
            value={orderModel.vehicleType}
            label="label"
            name="vehicleType"
            placeholder="vehicle"
            options={vehicleTypeOptions}
            onChange={onChangeOrder}
          />
        </Grid>

        {dashboardSettings.hasAssignOrder && allowAssignOrder && (
          <>
            <div style={{ padding: 2 }} />
            <Grid item xs>
              <CAutocomplete
                // disabled={isAppointment || !!orderModel.id}
                value={orderModel.driver}
                label="dNumber"
                name="driver"
                placeholder="drivers"
                options={drivers}
                onChange={onChangeOrder}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={8}>
          <FormGroup row style={{ paddingLeft: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={orderModel.has_voucher}
                  name="has_voucher"
                  onChange={onChangeOrder}
                  size="small"
                />
              }
              label={t("voucher")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={orderModel.isPaid}
                  name="isPaid"
                  onChange={onChangeOrder}
                  size="small"
                />
              }
              label={t("paid")}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="bottom-end" title={t("destination")}>
            <IconButton
              size="medium"
              disableRipple
              onClick={() => setIsDestination(!isDestination)}
            >
              <CIcon name="directions" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="bottom-end" title={t("appointment")}>
            <IconButton
              size="medium"
              disableRipple
              onClick={() => setIsAppointment(!isAppointment)}
            >
              <CIcon name="event" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}
