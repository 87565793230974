import * as Sentry from "@sentry/react";
import { firestore } from "../../firebase";
import {
  getCheckField,
  getCurrentDevice,
  getDateAp,
  getFullDate,
  getMinutes,
} from "../../utilities/func";
import { DateTime } from "luxon";

export const DRIVER_LIST_SUCCESS = "DRIVER_LIST_SUCCESS";
export const DRIVER_DEVICES_SUCCESS = "DRIVER_DEVICES_SUCCESS";

export function DriverListSuccess(payload) {
  return {
    type: DRIVER_LIST_SUCCESS,
    payload,
  };
}

export function DriverDevicesSuccess(payload) {
  return {
    type: DRIVER_DEVICES_SUCCESS,
    payload,
  };
}


const getDateDifference = (date) => {
  if (date && date.seconds) {
    const from = DateTime.fromSeconds(date.seconds)
    const to = DateTime.now()
    return to.diff(from).as('minutes').toFixed(0)
  }
}

export function getDriverList() {
  return async function f(dispatch) {
    try {
      firestore()
        .collection("drivers")
        .orderBy("createdAt", "desc")
        .onSnapshot((querySnapshot) => {
          const AllDriver = [];
          const AllDriverDevices = [];
          querySnapshot.docs.forEach((doc) => {
            const data = doc.data();

            data.id = doc.id;
            data.driverId = doc.id;

            AllDriverDevices.push({
              driverId: data.id,
              id: data.id,
              devices: data.devices ?? [],
            });

            AllDriver.push({
              driverId: data.id,
              id: data.id,
              coordinates: data.coordinates,
              dNumber: data.dNumber ? data.dNumber : "N/A",
              drivingStatus: data.drivingStatus,
              phoneNumber: data.profile.phoneNumber ?? "",
              fcmToken: data.fcmToken || "",
              status: data.status,
              displayName: `${data.profile?.firstName} ${data.profile?.lastName}`,
              email: data.profile.email,
              workingHours: data.workingHours ?? null,
              deactivation: data.deactivation,
              currentDevice: getCurrentDevice(data.devices),
              firstName: data.profile.firstName,
              lastName: data.profile.lastName,
              retainSpot: data.retainSpot ?? false,
              bgEnabled: data.bgEnabled ?? false,
              amount: data.payment ? data.payment.amount ?? 0 : 0,
              hasPendingPayment: data.hasPendingPayment ?? false,
              hasAutoOrders: data.hasAutoOrders ?? false,
              isGeofence: data.isGeofence ?? false,
              currentGeofence: data.currentGeofence ?? null,
              interval: data.interval? data.interval / 1000 : 0,
              bgPermission: data.bgPermission ??'',
              coordinatesDate: data.coordinatesDate ? getDateAp(data.coordinatesDate) : "N/A",
              lastCoordinatesDate: data.coordinatesDate ? getDateDifference(data.coordinatesDate) : "N/A",
              coordinatesQty: data.coordinatesQty ?? 0,
              lastOrderDate: getFullDate(data, "lastOrder", "createdAt"),
              lastAssignedOrderDate: getFullDate(data, "lastAssignedOrder", "assignedAt")
                ? getFullDate(data, "lastAssignedOrder", "assignedAt")
                : "--:--",
              automatic: data.automatic ? getMinutes(data.automatic) : "N/A",
              license: data.license ?? "",
              licenseState: data.licenseState ?? "",
              vehicleType: getCheckField(data, "vehicle", "type"),
              withSchedule: data.withSchedule ? data.withSchedule : false,
              deviceInfo: data.deviceInfo ? data.deviceInfo.uniqueId : 0,
              autoMarkedAsPaid: data.autoMarkedAsPaid ? data.autoMarkedAsPaid : false,
              credit_card_payment: data.credit_card_payment ? data.credit_card_payment : false,
              hasPayment: data.hasPayment ? data.hasPayment : false,
              hasPaymentReader: data.hasPaymentReader ? data.hasPaymentReader : false,
              isVisibleUpdated: data.isVisibleUpdated ? data.isVisibleUpdated : false,
              isStatus: data.status === "disabled",
              appState: data.appState ?? "N/A",
              isDrivingStatus: data.drivingStatus === "offline",
              payment: data.payment || {},
            });
          });
          dispatch(DriverListSuccess(AllDriver));
          dispatch(DriverDevicesSuccess(AllDriverDevices));
        });
    } catch (e) {
      Sentry.captureException(e);
    }
  };
}
