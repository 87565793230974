import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import MDTypography from "../../../components/MDTypography";

export default function Header({ analytics }) {
  const { t } = useTranslation();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <MDBox mb={2}>
      <Card sx={{ borderRadius: 1 }}>
        <MDBox
          py={1}
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
        >
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">{formatter.format(analytics.money)}</MDTypography>
            <MDTypography>{t("money")}</MDTypography>
          </MDBox>

          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">{analytics.system + analytics.app}</MDTypography>
            <MDTypography>{t("orders")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">{analytics.app}</MDTypography>
            <MDTypography>{t("mobile")}</MDTypography>
          </MDBox>
          <MDBox alignItems="center" display="flex" flexDirection="column">
            <MDTypography variant="h3">{analytics.system}</MDTypography>
            <MDTypography>{t("system")}</MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  );
}
