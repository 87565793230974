import React from "react";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Snackbar from "@mui/material/Snackbar";
import { useTranslation } from "react-i18next";
import { AlertModel } from "../../../utilities/model";

export default function CAlertSnackbar({ alert, close, duration = 3000 }) {
  const { t } = useTranslation();

  return alert.open ? (
    <Backdrop sx={{ color: "#fff", zIndex: 6000 }} open={alert.open}>
      <Snackbar
        style={{ zIndex: 90000 }}
        autoHideDuration={duration}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert.open}
        onClose={() => close(new AlertModel())}
      >
        <Alert onClose={close} severity={alert.type} sx={{ width: "100%" }}>
          {t(alert.ms)}
        </Alert>
      </Snackbar>
    </Backdrop>
  ) : null;
}
