import React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useSelector } from "react-redux";
import { Card, CardContent } from "@mui/material";
import CreateOrders from "./components/CreateOrders";
import ActiveCalls from "./components/ActiveCalls";
import RequestAlert from "./components/RequestAlert";
import ActiveOrders from "./components/ActiveOrders/index";
import OrderHistory from "./components/OrderHistory";
import MiniChat from "./components/MiniChat";
import CCardHeader from "../../components/Custom/CCardHeader";
import { useStyles } from "./styles";
import DriverMap from "./components/DriverMap";
import { StripeProvider } from "../../provider/StripeProvider";
import CPayments from "../../components/Custom/payments";

function Dashboard() {
  const DashboardGrid = useSelector(({ dashboardGrid }) => dashboardGrid);
  const [openPayment, setOpenPayment] = React.useState(false);

  const styles = useStyles();
  return (
    <DashboardLayout>
      <DashboardNavbar isDashboard setOpenPayment={setOpenPayment} />
      <MDBox>
        <Grid container spacing={3}>
          {DashboardGrid.isCreateOrder && (
            <Grid item xs={12} sm={6} lg={6}>
              <Card style={styles.card}>
                <CCardHeader value="isCreateOrder" title="create_order" />
                <CardContent style={styles.cardContent}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={5}>
                      <CreateOrders />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={7}>
                      <DriverMap />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {DashboardGrid.isActiveCalls && (
            <Grid item xs={12} sm={6} lg={3}>
              <ActiveCalls />
            </Grid>
          )}
          {DashboardGrid.isActiveOrders && (
            <Grid item xs={12} sm={6} lg={3}>
              <ActiveOrders />
            </Grid>
          )}
          {DashboardGrid.isOrderHistory && (
            <Grid item xs={12} sm={6} lg={6}>
              <OrderHistory />
            </Grid>
          )}
          {DashboardGrid.isMiniChat && (
            <Grid item xs={12} sm={6} lg={3}>
              <MiniChat />
            </Grid>
          )}
          {DashboardGrid.isRequestAlert && (
            <Grid item xs={12} sm={6} lg={3}>
              <RequestAlert />
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />

      {openPayment && (
        <StripeProvider>
          <CPayments open={openPayment} close={setOpenPayment} title="payment_with_card" noOrder />
        </StripeProvider>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
