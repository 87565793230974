import * as React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";
import InputMaskPhone from "../../../components/Custom/InputMaskPhone";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";

export default function HeaderOptions({
  addDriver,
  filterValues,
  onChangeFilter,
  onFilterData,
  totalData,
}) {
  const { t } = useTranslation();

  return (
    <>
      {window.screen.width >= 859 && (
        <MDBox
          style={{ position: "absolute", left: 50 }}
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <MDTypography>
            {t("total")}: {totalData}
          </MDTypography>
        </MDBox>
      )}

      <Grid item>
        <MDBox alignItems="center" display="flex" flexDirection="row">
          <InputMaskPhone
            fullWidth
            style={{ marginRight: 5 }}
            placeholder={t("phoneNumber")}
            name="phoneNumber"
            value={filterValues.phoneNumber}
            onChange={({ target }) => onChangeFilter(target.value, target.name)}
          />

          <MDInput
            size="small"
            value={filterValues.email}
            style={{ marginRight: 5 }}
            onChange={({ target }) => onChangeFilter(target.value, target.name)}
            name="email"
            placeholder={`${t("email")}`}
            type="text"
          />

          <MDButton
            onClick={onFilterData}
            style={{ width: 150, marginRight: 5 }}
            variant="gradient"
            size="medium"
            color="success"
          >
            {t("search")}
          </MDButton>

          <MDButton
            sx={{
              width: 125,
            }}
            onClick={addDriver}
            color="info"
          >
            {t("add")}
          </MDButton>
        </MDBox>
      </Grid>
    </>
  );
}
