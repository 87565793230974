import * as React from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import CPaper from "../../../components/Custom/CPaper";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import { RiderAppModel } from "../../../utilities/model";
import MDTypography from "../../../components/MDTypography";

import { autocompleteTypeOptions, boolOptions, timesOptions } from "../../../utilities/data";
import { useClassNameSelect } from "./styles";

const input = {
  style: {
    marginBottom: 25,
  },
  inputProps: {
    autoComplete: "",
  },
  fullWidth: true,
};

export default function RiderApp({ data = new RiderAppModel(), onChange, onClick }) {
  const { t } = useTranslation();
  const classes = useClassNameSelect();

  return (
    <>
      <CPaper style={{ marginTop: 20, padding: 25 }}>
        <MDTypography variant="h4" fontWeight={false}>
          {t("general")}
        </MDTypography>
        <br />

        <Autocomplete
          fullWidth
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.hasEmailVerified}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasEmailVerified", "riderApp");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("hasEmailVerified")} />}
        />

        <Autocomplete
          fullWidth
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.hasPhoneVerified}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasPhoneVerified", "riderApp");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("hasPhoneVerified")} />}
        />

        <Autocomplete
          fullWidth
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.hasPenaltyOrders}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasPenaltyOrders", "riderApp");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("hasPenaltyOrders")} />}
        />

        {data.hasPenaltyOrders && (
          <Autocomplete
            fullWidth
            style={{ marginBottom: 25 }}
            classes={classes}
            value={data.penaltyOrdersTime}
            onChange={(event, newValue) => {
              onChange(event, newValue, "penaltyOrdersTime", "riderApp");
            }}
            options={timesOptions}
            getOptionLabel={(option) => t(`${option}s`)}
            renderInput={(params) => <MDInput {...params} label={t("penaltyOrdersTime")} />}
          />
        )}

        <MDInput
          {...input}
          value={data.policyUrl}
          onChange={(e) => onChange(e, null, null, "riderApp")}
          name="policyUrl"
          label={t("policyUrl")}
        />
      </CPaper>
      <CPaper style={{ marginTop: 20, padding: 25 }}>
        <MDTypography variant="h4" fontWeight={false}>
          {t("map")}
        </MDTypography>
        <br />
        <MDBox
          style={{ marginBottom: 25 }}
          flexDirection="row"
          display="flex"
          justifyContent="space-between"
        >
          <Autocomplete
            fullWidth
            classes={classes}
            value={data.apiKeyActive}
            onChange={(event, newValue) => {
              onChange(event, newValue, "apiKeyActive", "riderApp");
            }}
            options={autocompleteTypeOptions}
            getOptionLabel={(option) => t(option)}
            renderInput={(params) => <MDInput {...params} label={t("autocompleteType")} />}
          />
          <div style={{ marginLeft: 10 }} />
        </MDBox>

        {data.apiKeyActive === "here" && (
          <MDInput
            {...input}
            value={data.hereApiKey}
            onChange={(e) => onChange(e, null, null, "riderApp")}
            name="hereApiKey"
            label={t("hereApiKey")}
          />
        )}

        {data.apiKeyActive === "mapbox" && (
          <MDInput
            {...input}
            value={data.mapBoxApiKey}
            onChange={(e) => onChange(e, null, null, "riderApp")}
            name="mapBoxApiKey"
            label={t("mapBoxApiKey")}
          />
        )}

        {data.apiKeyActive === "geoapify" && (
          <MDInput
            {...input}
            value={data.geoapifyApiKey}
            onChange={(e) => onChange(e, null, null, "riderApp")}
            name="geoapifyApiKey"
            label={t("geoapifyApiKey")}
          />
        )}

        {data.apiKeyActive === "gmaps" && (
          <MDInput
            {...input}
            value={data.gmapsApiKey}
            onChange={(e) => onChange(e, null, null, "riderApp")}
            name="gmapsApiKey"
            label={t("gmapsApiKey")}
          />
        )}

        <MDInput
          {...input}
          type="number"
          value={data.circleRadio}
          onChange={(e) => onChange(e, null, null, "riderApp")}
          name="circleRadio"
          label={t("circleRadio")}
        />

        <MDInput
          {...input}
          type="number"
          value={data.circleRadioTo}
          onChange={(e) => onChange(e, null, null, "riderApp")}
          name="circleRadioTo"
          label={t("circleRadioTo")}
        />

        <MDInput
          {...input}
          value={data.autocompleteLimit}
          type="number"
          onChange={(e) => onChange(e, null, null, "riderApp")}
          name="autocompleteLimit"
          label={t("autocompleteLimit")}
        />

        <MDInput
          {...input}
          value={data.mapBoxBbox}
          onChange={(e) => onChange(e, null, null, "riderApp")}
          name="mapBoxBbox"
          label={t("mapBoxBbox")}
        />

        <MDTypography variant="h6" fontWeight={false}>
          {t("map_center")}
        </MDTypography>

        <MDBox mt={3} flexDirection="row" display="flex" justifyContent="space-between">
          <MDInput
            {...input}
            value={data.lat}
            type="number"
            onChange={(e) => onChange(e, null, null, "riderApp")}
            name="lat"
            label={t("lat")}
          />
          <div style={{ marginLeft: 10 }} />
          <MDInput
            {...input}
            value={data.lng}
            type="number"
            onChange={(e) => onChange(e, null, null, "riderApp")}
            name="lng"
            label={t("lng")}
          />
        </MDBox>
      </CPaper>
      <MDButton
        style={{ marginTop: 40 }}
        size="large"
        fullWidth
        onClick={onClick}
        variant="gradient"
        color="info"
      >
        {t("update")}
      </MDButton>
    </>
  );
}
