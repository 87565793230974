import { Icon } from "@mui/material";
import * as React from "react";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import { getCheckStatsField } from "../../../../../utilities/func";

export default function Stats({ data }) {
  return (
    <MDBox my={1} flexDirection="row" display="flex" justifyContent="space-between">
      <MDBox flexDirection="row" display="flex" alignItems="center">
        <Icon fontSize="1" color="info">
          phone
        </Icon>
        <MDTypography variant="h6">{getCheckStatsField(data, "calls")}</MDTypography>
      </MDBox>
      <MDBox flexDirection="row" display="flex" alignItems="center">
        <Icon fontSize="1" color="success">
          check
        </Icon>
        <MDTypography variant="h6">{getCheckStatsField(data, "completed_orders")}</MDTypography>
      </MDBox>
      <MDBox flexDirection="row" display="flex" alignItems="center">
        <Icon fontSize="1" color="error">
          clear
        </Icon>
        <MDTypography variant="h6">{getCheckStatsField(data, "cancelled_orders")}</MDTypography>
      </MDBox>
    </MDBox>
  );
}
