import React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { AlertModel, FilterModel, ProfileModel } from "../../utilities/model";
import ApiClient from "../../api";
import { firebase, firestore } from "../../firebase";
import { CaptureException, LogsUsers } from "../../utilities/func";

export default function useData() {
  const { t } = useTranslation();
  const [tableData, setTableData] = React.useState([]);
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [loading, setLoading] = React.useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNew, setShowNew] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [totalData, setTotalData] = React.useState(0);

  const [model, setModel] = React.useState(new ProfileModel());
  const [filterValues, setFilterValues] = React.useState(new FilterModel());

  const onChangeFilter = (value, name) => {
    setFilterValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const onReset = () => {
    setLoading(false);
    setPassword("");
    setCAlert(new AlertModel());
    setModel(new ProfileModel());
    setShowNew(false);
    setShowPassword(false);
  };

  const filterRef = (ref) => {
    let { phoneNumber, email } = filterValues;
    let phone = `+1${phoneNumber}`;

    if (phone.length > 4) {
      return ref.where("phoneNumber", "==", phone);
    }

    if (email.length > 0) {
      return ref.where("email", "==", email);
    }
    return ref;
  };

  const onFilterData = () => {
    setLoading(true);
    setTableData([]);
    let ref = firestore().collection("clients");
    ref = filterRef(ref);
    ref
      .get()
      .then((querySnapshot) => {
        setLoading(false);
        const list = [];
        querySnapshot.docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          list.push(data);
        });
        setTableData(list);
      })
      .catch((e) => {
        setLoading(false);
        CaptureException(e, "Error-CL-01");
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  React.useEffect(() => {
    firebase
      .firestore()
      .collection("stats")
      .doc("users")
      .get()
      .then((docsData) => {
        let data = docsData.data();
        if (docsData.exists) {
          setTotalData(data.client ?? 0);
        }
      });
  }, []);

  const blockUnblockClient = (blocked, id, modelData) => {
    setLoading(true);
    firestore()
      .collection("clients")
      .doc(id)
      .set({ blocked: Boolean(blocked), blackListed: Boolean(blocked) }, { merge: true })
      .then(async () => {
        let copyTableData = [...tableData];
        let dataToChange = copyTableData.find((d) => d.id === id);

        await ApiClient.put(`users/${id}`, {
          ...modelData,
          status: blocked ? "disabled" : "active",
          role: "client",
        });

        dataToChange.blocked = blocked;
        dataToChange.blackListed = blocked;
        dataToChange.status = blocked ? "disabled" : "active";
        copyTableData.splice(
          tableData.findIndex((x) => x.id === id),
          1,
          dataToChange
        );
        setTableData(copyTableData);
        onReset();
        setLoadingSuccess(true);
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
        onReset();
        CaptureException(e, "Error-CL-02");
      });
  };

  const onActions = (type, id, data) => {
    let dataModel = { ...data };
    if (!dataModel.phoneNumber) {
      dataModel.phoneNumber = "";
    }
    setModel(dataModel);

    switch (type) {
      case "show":
        setShowNew(true);
        break;
      case "block":
        blockUnblockClient(true, id, dataModel);
        break;
      case "unblock":
        blockUnblockClient(false, id, dataModel);
        break;
      case "password":
        setShowPassword(true);
        break;
      default:
        break;
    }
  };

  const onChange = (e, select, sName) => {
    const { name, value, checked, type } = e.target;
    const Value = type === "checkbox" ? checked : value;
    if (sName) {
      return setModel((prevState) => ({ ...prevState, [sName]: select }));
    }
    return setModel((prevState) => ({ ...prevState, [name]: Value }));
  };

  const onUpdateData = async () => {
    setLoading(true);
    let modelData = { ...model };

    let phoneNumber = null;
    if (modelData.phoneNumber.length) {
      if (modelData.phoneNumber.length < 10) {
        return setCAlert({ open: true, type: "error", ms: "invalid_phoneNumber" });
      }
      phoneNumber = modelData.phoneNumber.includes("+1")
        ? `${modelData.phoneNumber}`
        : `+1${modelData.phoneNumber}`;
    }

    try {
      await firestore()
        .collection("clients")
        .doc(model.id)
        .update({
          ...modelData,
          phoneNumber,
        });

      await ApiClient.put(`users/${model.id}`, {
        ...modelData,
        phoneNumber,
        role: "client",
      });

      let copyTableData = [...tableData];
      let dataToChange = copyTableData.find((d) => d.id === modelData.id);
      dataToChange = { ...dataToChange, ...modelData };
      dataToChange.phoneNumber = phoneNumber;
      copyTableData.splice(
        tableData.findIndex((x) => x.id === modelData.id),
        1,
        dataToChange
      );
      setTableData(copyTableData);

      LogsUsers({ data: model, type: "driver" });
      onReset();
      setLoadingSuccess(true);
    } catch (e) {
      setCAlert({ open: true, type: "error", ms: e.message });
      onReset();
      CaptureException(e, "Error-CL-05");
    }
    return true;
  };

  const onCreateNew = () => {
    let newDriver = { ...model };
    let phoneNumber = null;
    if (newDriver.phoneNumber.length) {
      if (newDriver.phoneNumber.length < 10) {
        return setCAlert({ open: true, type: "error", ms: "invalid_phoneNumber" });
      }
      phoneNumber = `+1${newDriver.phoneNumber}`;
    }
    setLoading(true);
    return firestore()
      .collection("clients")
      .add({
        ...newDriver,
        status: "active",
        role: "client",
        phoneNumber,
      })
      .then(() => {
        setLoadingSuccess(true);
        onReset();
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
        onReset();
      });
  };

  const onActionData = () => {
    let newDriver = { ...model };
    if (newDriver.id) {
      onUpdateData();
    } else {
      onCreateNew();
    }
  };

  const changePassword = () => {
    const userId = model.id;
    setLoading(true);
    return ApiClient.post(`users/changePassword/${userId}`, {
      password,
    })
      .then(() => {
        onReset();
        setLoadingSuccess(true);
      })
      .catch((e) => {
        onReset();
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const tableDataMap = [...tableData].map((d) => {
    let { id, status, firstName, lastName, email, phoneNumber, blackListed } = d;

    let date = d.createdAt ? DateTime.fromSeconds(d.createdAt.seconds).toFormat("D") : "----";

    return {
      id,
      status,
      date,
      firstName: firstName ?? "----",
      lastName: lastName ?? "----",
      email: email ?? "----",
      phoneNumber: phoneNumber ?? "----",
      blackListed: blackListed ? t("yes") : t("no"),
      isBlock: blackListed ?? false,
      actions: null,
      func: (type) => onActions(type, id, d),
    };
  });

  return {
    tableData: tableDataMap,
    loading,
    model,
    loadingSuccess,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    showPassword,
    onChange,
    password,
    setPassword,
    changePassword,
    showNew,
    setShowNew,
    onReset,
    onActionData,
    filterValues,
    onChangeFilter,
    onFilterData,
    totalData,
  };
}
