import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as React from "react";
import { useSelector } from "react-redux";
import MapWithMarkers from "./components/MapWithMarkers";

function Map() {
  const drivers = useSelector(({ driverList }) => driverList);
  const dashboardSettings = useSelector(({ settings }) => settings);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {dashboardSettings.mapBoxApiKeyForMap && (
        <MapWithMarkers
          drivers={drivers}
          center={dashboardSettings.mapCenter}
          mapBoxApiKey={dashboardSettings.mapBoxApiKeyForMap}
        />
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Map;
