import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MDInput from "../../../../../components/MDInput";
import MDBox from "../../../../../components/MDBox";
import CAutocomplete from "../../../../../components/Custom/CAutocomplete";
import PlacesAutocomplete from "../../../../../components/Custom/PlacesAutocomplete";

export default function InputAddress({
  footer = true,
  orderModel,
  onChangeOrder,
  lastAddress,
  onChangeLastAddress,
  onSelectPlace,
  type,
  autocompleteValue,
  setAutocompleteValue,
  onChangeAddress,
}) {
  const { t } = useTranslation();

  const dashboardSettings = useSelector(({ settings }) => settings);

  return (
    <MDBox flexDirection="column" display="flex">
      {dashboardSettings.orderCreationType === "zone" && <></>}

      {dashboardSettings.orderCreationType !== "zone" && (
        <>
          <PlacesAutocomplete
            onSelectPlace={(e) => onSelectPlace(e, type)}
            autocompleteValue={autocompleteValue}
            setAutocompleteValue={setAutocompleteValue}
          />
        </>
      )}

      <MDBox my={1} flexDirection="row" display="flex" justifyContent="space-between">
        <MDInput
          fullWidth
          value={orderModel[type].streetNumber}
          onChange={(e) => onChangeAddress(e, type)}
          name="streetNumber"
          style={{ marginRight: 3 }}
          placeholder={t("street_number")}
          type="text"
          size="small"
        />
        <MDInput
          style={{ marginRight: 3 }}
          value={orderModel[type].street}
          onChange={(e) => onChangeAddress(e, type)}
          name="street"
          fullWidth
          placeholder={t("street")}
          type="text"
          size="small"
        />
        <MDInput
          value={orderModel[type].city}
          onChange={(e) => onChangeAddress(e, type)}
          name="city"
          fullWidth
          placeholder={t("city")}
          type="text"
          size="small"
        />
      </MDBox>

      {orderModel.client.lastAddresses && (
        <CAutocomplete
          isAddressBlock
          containerStyle={{ marginBottom: 8 }}
          size="small"
          name="lastAddressSelected"
          value={lastAddress}
          label="label"
          placeholder="client_address"
          options={orderModel.client.lastAddresses.map((x, idx) => {
            const { address = "" } = x;
            return { ...x, id: idx, label: address };
          })}
          onChange={onChangeLastAddress}
        />
      )}

      {footer && (
        <MDBox
          mb={1}
          flexDirection="row"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDInput
            onChange={onChangeOrder}
            value={orderModel.notes}
            style={{ marginRight: 3 }}
            fullWidth
            name="notes"
            placeholder={t("notes")}
            type="text"
            size="small"
          />
          <MDInput
            value={orderModel.fare || ""}
            onChange={onChangeOrder}
            placeholder={t("price")}
            name="fare"
            type="number"
            size="small"
          />
        </MDBox>
      )}
    </MDBox>
  );
}
