import { createStyles, makeStyles } from "@mui/styles";

export const useClassName = makeStyles(() =>
  createStyles({
    headerIcon: {
      color: "white",
      background: "rgb(119,119,119)",
      position: "absolute",
      right: 0,
      top: 0,
      fontSize: 15,
      alignSelf: "flex-end",
      marginTop: -13,
      marginRight: -13,
      "&:hover,&:focus": {
        color: "#fff",
        backgroundColor: "#a9a9a9",
      },
    },
  })
);

export const useStyles = () =>
  createStyles({
    paperDriverMapLeft: {
      backgroundColor: "rgba(255,255,255,0.9)",
      position: "absolute",
      zIndex: 1000,
      margin: 10,
      top: 25,
      padding: 8,
      // height: 50,
      // width: 200,
    },
    paperMapLeft: {
      borderRadius: 10,
      display: "flex",
      flexDirection: "row",
      backgroundColor: "rgba(255,255,255,0.5)",
      position: "absolute",
      zIndex: 1000,
      margin: 10,
      top: 25,
      padding: 8,
    },
    paperDriverMapRight: {
      backgroundColor: "rgba(255,255,255,0.9)",
      position: "absolute",
      justifyContent: "center",
      borderRadius: 100,
      zIndex: 1000,
      margin: 10,
      top: 25,
      height: 50,
      width: 50,
      right: 20,
    },
  });
