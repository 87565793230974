import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import FormField from "../../../../components/Custom/FormField";
import { languageOptions } from "../../../../utilities/data";
import { firestore, auth } from "../../../../firebase";
import { ProfileModel } from "../../../../utilities/model";

function BasicInfo() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = React.useState(languageOptions[0]);
  const [profileData, setProfileData] = React.useState(new ProfileModel());

  React.useEffect(() => {
    firestore()
      .collection("users")
      .doc(auth().currentUser.uid)
      .get()
      .then((docs) => {
        const data = docs.data();
        setProfileData(data);
      });

    const cLanguage = localStorage.getItem("language");
    if (cLanguage) {
      const selectedLang = [...languageOptions].find((lang) => lang.id === cLanguage);
      setLanguage(selectedLang);
    }
  }, []);

  const changeLanguage = async (e, value) => {
    setLanguage(value);
    if (value) {
      localStorage.setItem("language", value.id);
      await i18n.changeLanguage(value.id);
    }
  };

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">{t("basic_info")}</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("firstName")}
              value={profileData.firstName}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("lastName")}
              value={profileData.lastName}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("email")}
              value={profileData.email}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("phoneNumber")}
              value={profileData.phoneNumber}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={language}
              onChange={changeLanguage}
              options={languageOptions}
              getOptionLabel={(option) => t(option.label)}
              renderInput={(params) => (
                <FormField {...params} label={t("language")} InputLabelProps={{ shrink: true }} />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
