import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import CDialog from "../../../../../components/Custom/CDialog";
import MDBox from "../../../../../components/MDBox";
import DataTable from "../../../../../examples/Tables/DataTable";
import CPaper from "../../../../../components/Custom/CPaper";
import TSText from "../../../../../components/Custom/TSText";
import TSTextClipboard from "../../../../../components/Custom/TSTextClipboard";
import { findToArray } from "../../../../../utilities/func";

export default function DriverOrderModal({ open, close, data }) {
  const { t } = useTranslation();

  const [info = { invoiceId: "", startedAt: "", selected_driver: "", orderId: "" }] = [...data];

  return (
    <CDialog
      subtitle={info.orderId}
      maxWidth="xl"
      title="subscribed_drivers"
      close={close}
      open={open}
    >
      <CPaper style={{ marginTop: 10 }}>
        <Grid container>
          <Grid item xs={12} sm={4} lg={3}>
            <TSTextClipboard text={info.invoiceId} copy={info.orderId} subtitle="invoice_id" />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TSText title={info.startedAt} subtitle="start_process" />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TSText title={info.selected_driver} subtitle="selected_driver" />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TSText
              title={findToArray(data, info.selected_driver, "eta", "dNumber")}
              subtitle="eta"
            />
          </Grid>
        </Grid>
      </CPaper>
      <br />
      <CPaper>
        <DataTable
          noEndBorder
          PageSize={20}
          entriesPerPage={false}
          showTotalEntries={false}
          table={{
            columns: [
              { Header: "driver", accessor: "dNumber" },
              { Header: "subscribedAt", accessor: "subscribedAt" },
              { Header: "eta", accessor: "eta" },
              { Header: "lastAssigned", accessor: "lastAssigned" },
              { Header: "is_turn", accessor: "retainSpot" },
              { Header: "reason", accessor: "discardReason" },
            ],
            rows: data,
          }}
        />
      </CPaper>

      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton onClick={() => close(false)} variant="gradient" color="error">
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
