import * as React from "react";
import Stats from "./components/Stats";
import InputAddress from "./components/InputAddress";
import InputAppointment from "./components/InputAppointment";
import OrderOptions from "./components/OrderOptions";
import Footer from "./components/Footer";
import MaskPhone from "../../../../components/Custom/MaskPhone";
import useData from "./data";
import ActiveOrderModal from "./components/ActiveOrderModal";
import CAlertSnackbar from "../../../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../../../components/Custom/CAlerts/CLoadingSnackbar";
import CAutocomplete from "../../../../components/Custom/CAutocomplete";
import { vehicleTypeOptions } from "../../../../utilities/data";

export default function CreateOrders() {
  const {
    onCreate,
    onCancel,
    onClientPhone,
    activeOrderDialog,
    isPhoneBlock,
    setActiveOrderDialog,
    activeOrderModel,
    onDuplicateOrder,
    cAlert,
    setCAlert,
    loading,
    orderModel,
    loadingPhone,
    onChangeOrder,
    onChangeFromLastAddress,
    lastFromAddress,
    onSelectPlace,
    autocompleteFromValue,
    setAutocompleteFromValue,
    autocompleteToValue,
    setAutocompleteToValue,
    onChangeAddress,
    onChangeToLastAddress,
    lastToAddress,
    drivers,
    isDestination,
    setIsDestination,
    isAppointment,
    setIsAppointment,
    appointmentDate,
    setAppointmentDate,
    appointmentOffset,
    setAppointmentOffset,
    phoneNumber,
    setPhoneNumber,
    loadingSuccess,
    setLoadingSuccess,
    onBlockUnblockClient,
    onDeleteActiveOrder,
    phoneBlockNotes,
    servicesList,
  } = useData();

  return (
    <>
      <Stats data={orderModel} />
      <MaskPhone
        phoneBlockNotes={phoneBlockNotes}
        onPhoneBlock={onBlockUnblockClient}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        loadingPhone={loadingPhone}
        orderModel={orderModel}
        onClientPhone={onClientPhone}
        phoneBlock={isPhoneBlock}
      />
      <InputAddress
        type="from"
        onChangeAddress={onChangeAddress}
        onSelectPlace={onSelectPlace}
        orderModel={orderModel}
        onChangeOrder={onChangeOrder}
        onChangeLastAddress={onChangeFromLastAddress}
        lastAddress={lastFromAddress}
        autocompleteValue={autocompleteFromValue}
        setAutocompleteValue={setAutocompleteFromValue}
      />
      <OrderOptions
        drivers={drivers}
        orderModel={orderModel}
        onChangeOrder={onChangeOrder}
        isAppointment={isAppointment}
        setIsAppointment={setIsAppointment}
        isDestination={isDestination}
        setIsDestination={setIsDestination}
      />

      {Array.isArray(servicesList) && servicesList.length > 0 && (
        <CAutocomplete
          value={orderModel.service}
          label="description"
          name="service"
          placeholder="services"
          moreProps={true}
          options={servicesList.map((x, idx) => {
            const { description = "" } = x;
            return { ...x, id: idx, label: description };
          })}
          onChange={onChangeOrder}
        />
      )}

      {isDestination && (
        <InputAddress
          type="to"
          onChangeAddress={onChangeAddress}
          onSelectPlace={onSelectPlace}
          onChangeOrder={onChangeOrder}
          onChangeLastAddress={onChangeToLastAddress}
          lastAddress={lastToAddress}
          autocompleteValue={autocompleteToValue}
          setAutocompleteValue={setAutocompleteToValue}
          orderModel={orderModel}
          footer={false}
        />
      )}
      {isAppointment && (
        <InputAppointment
          appointmentDate={appointmentDate}
          setAppointmentDate={setAppointmentDate}
          appointmentOffset={appointmentOffset}
          setAppointmentOffset={setAppointmentOffset}
        />
      )}
      {activeOrderDialog && (
        <ActiveOrderModal
          onDelete={onDeleteActiveOrder}
          onClick={onDuplicateOrder}
          open={activeOrderDialog}
          close={setActiveOrderDialog}
          data={activeOrderModel}
        />
      )}

      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />
      <CLoadingSnackbar open={loading} />
      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />
      <Footer
        loading={loading}
        onCancel={onCancel}
        onCreate={onCreate}
        isDestination={isDestination}
        isAppointment={isAppointment}
        appointmentDate={appointmentDate}
        phoneNumber={phoneNumber}
        orderModel={orderModel}
      />
    </>
  );
}
