import * as React from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { useDispatch, useSelector } from "react-redux";
import RestoreIcon from "@mui/icons-material/Restore";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import EditIcon from "@mui/icons-material/Edit";
import PeopleIcon from "@mui/icons-material/People";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import AddCardIcon from "@mui/icons-material/AddCard";
import Directions from "@mui/icons-material/Directions";
import DifferenceIcon from "@mui/icons-material/Difference";
import Button from "@mui/material/Button";
import ClipboardText from "../../../../../components/Custom/ClipboardText";
import MDBox from "../../../../../components/MDBox";
import IconBadge from "../../../../../components/Custom/IconBadge";
import CMenu from "../../../../../components/Custom/CMenu";
import IconBadge2 from "../../../../../components/Custom/IconBadge2";
import {
  formatPhoneNumber,
  getTime,
  getCheckField,
  getCheckStatsField,
  getColorReached,
  getDateAp,
  isReadyToCancel,
} from "../../../../../utilities/func";
import MDButton from "../../../../../components/MDButton";
import { getOriginMarkerPosition } from "../../../../../redux/actions";

const g_color = "#e5e5e5";
const textStyle = { fontSize: 14, color: g_color, fontWeight: "bold" };

export default function OrderDetail({
  orders = [],
  color,
  onActions,
  isAppointment,
  orderErrors,
  onOrderError,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dashboardSettings = useSelector(({ settings }) => settings);

  const viewOnMap = (data) => {
    dispatch(
      getOriginMarkerPosition({
        lat: data.from.coordinates.latitude,
        lng: data.from.coordinates.longitude,
      })
    );
  };

  return orders.map((data) => {
    let order = isAppointment ? data.order : data;
    let appointment = isAppointment ? data.date : null;
    let createdAt = isAppointment ? getDateAp(appointment) : getTime(order);
    let isReadyCancellable = isReadyToCancel(order);
    let street = getCheckField(order, "from", "street");
    let streetNumber = getCheckField(order, "from", "streetNumber");
    let city = getCheckField(order, "from", "city");
    let dNumber = getCheckField(order, "assignedDriver", "dNumber");
    let phoneNumber = getCheckField(order, "client", "phoneNumber");
    let num_cancelled = getCheckStatsField(order, "cancelled_orders");
    let num_completed = getCheckStatsField(order, "completed_orders");
    let { status, max_retries_reached = false, isCancellable = false } = order;

    let id = order.id || data.id;

    let background = getColorReached(status, max_retries_reached, color, isAppointment);

    return (
      <MDBox
        key={id}
        style={{
          background,
          paddingLeft: 5,
          paddingRight: 5,
          borderRadius: 4,
          marginBottom: 10,
          position: "relative",
          minHeight: 80,
        }}
      >
        <MDBox
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <MDBox
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="space-around"
          >
            <strong style={textStyle}>{createdAt}</strong>
            {phoneNumber ? (
              <>
                <b style={textStyle}>&nbsp;|&nbsp;</b>
                <ClipboardText
                  fontSize={14}
                  color="white"
                  variant="text"
                  text={formatPhoneNumber(phoneNumber)}
                />
              </>
            ) : null}
          </MDBox>
          {num_cancelled || num_completed ? (
            <MDBox display="flex" flexDirection="row" justifyContent="center" alignItems="center">
              {num_cancelled ? (
                <IconBadge
                  onClick={() => onActions("clientRecord", id)}
                  badge={num_cancelled}
                  color="warning"
                >
                  <PersonRemoveIcon style={{ color: g_color, transform: "rotateY(180deg)" }} />
                </IconBadge>
              ) : null}
              <div style={{ paddingRight: 25 }} />
              {num_completed ? (
                <IconBadge
                  onClick={() => onActions("clientRecord", id)}
                  badge={num_completed}
                  color="success"
                >
                  <PersonAddIcon style={{ color: g_color }} />
                </IconBadge>
              ) : null}
            </MDBox>
          ) : null}

          {order.subscribed ? (
            <IconBadge2 onClick={() => onActions("drivers", id)} badge={order.subscribed}>
              <DirectionsCarIcon style={{ color: g_color }} />
            </IconBadge2>
          ) : null}

          <CMenu
            isPaymentCard={dashboardSettings.isPaymentCard}
            isAppointment={isAppointment}
            title={`${createdAt} | ${streetNumber} ${street}`}
            onClick={(type) => onActions(type, id, appointment)}
            isCancellable={isCancellable}
            status={status}
            isSubscribed={order.subscribed}
          />
        </MDBox>

        <MDBox
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Button
            size="small"
            variant="text"
            onClick={() => viewOnMap(data)}
            style={{ ...textStyle, margin: 0, padding: 0 }}
          >{`${streetNumber} ${street}, ${city}`}</Button>

          {data.source === "mobile" && <PhoneAndroidIcon style={{ color: g_color }} />}
          {data.source === "appointment" && <AccessTimeIcon style={{ color: g_color }} />}
          {dNumber ? (
            <small style={textStyle}>
              {t("driver")}: <strong>{dNumber}</strong>
            </small>
          ) : null}
        </MDBox>

        {dashboardSettings?.withServices && order?.service ? (
          <small style={{ ...textStyle, margin: 0, padding: 0  }}>
            {t("service")}: <strong>{order?.service.description}</strong>
          </small>
        ) : null}

        {dashboardSettings.showOrderActions && data.source !== "appointment" && (
          <MDBox
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            {status === "unassigned" && (isCancellable || isReadyCancellable) && (
              <Tooltip placement="bottom-end" title={t("restore")}>
                <IconButton onClick={() => onActions("restore", id)}>
                  <RestoreIcon style={{ color: g_color }} />
                </IconButton>
              </Tooltip>
            )}

            {status !== "unassigned" &&  (
              <Tooltip placement="bottom-end" title={t("reassign")}>
                <IconButton onClick={() => onActions("reassign", id)}>
                  <CarRepairIcon style={{ color: g_color }} />
                </IconButton>
              </Tooltip>
            )}

            {dashboardSettings.isDuplicateIcon && (
              <Tooltip placement="bottom-end" title={t("duplicate_order")}>
                <IconButton onClick={() => onActions("duplicate", id)}>
                  <DifferenceIcon fontSize="small" style={{ color: g_color }} />
                </IconButton>
              </Tooltip>
            )}

            {dashboardSettings.isPaymentIcon &&
              dashboardSettings.isPaymentCard &&
              status !== "unassigned" && (
                <Tooltip placement="bottom-end" title={t("payment_with_card")}>
                  <IconButton onClick={() => onActions("payment", id)}>
                    <AddCardIcon style={{ color: g_color }} />
                  </IconButton>
                </Tooltip>
              )}

            {(isCancellable || isReadyCancellable) && (
              <Tooltip placement="bottom-end" title={t("edit")}>
                <IconButton onClick={() => onActions("edit", id)}>
                  <EditIcon style={{ color: g_color }} />
                </IconButton>
              </Tooltip>
            )}

            {order.subscribed && (
              <Tooltip placement="bottom-end" title={t("drivers")}>
                <IconButton onClick={() => onActions("drivers", id)}>
                  <PeopleIcon style={{ color: g_color }} />
                </IconButton>
              </Tooltip>
            )}

            {status === "assigned" && (
              <Tooltip placement="bottom-end" title={t("eta")}>
                <IconButton onClick={() => onActions("eta", id)}>
                  <Directions fontSize="small" style={{ color: g_color }} />
                </IconButton>
              </Tooltip>
            )}

            {(isCancellable || isReadyCancellable) && (
              <Tooltip placement="bottom-end" title={t("cancel_order")}>
                <IconButton onClick={() => onActions("delete", id)}>
                  <DeleteIcon style={{ color: g_color }} />
                </IconButton>
              </Tooltip>
            )}
          </MDBox>
        )}

        {orderErrors && orderErrors[id] && (
          <MDBox
            alignItems="center"
            display="flex"
            flexDirection="row"
            style={{
              borderRadius: 4,
              height: "100%",
              left: 0,
              bottom: 0,
              background: "rgba(0,0,0,0.7)",
              width: "100%",
              position: "absolute",
            }}
            justifyContent="space-around"
          >
            <MDButton color="info" onClick={() => onOrderError(data, "duplicate")}>
              {t("duplicate")}
            </MDButton>

            <MDButton color="error" onClick={() => onOrderError(data, "delete")}>
              {t("delete")}
            </MDButton>
          </MDBox>
        )}
      </MDBox>
    );
  });
}
