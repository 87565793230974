import React from "react";
import { useClassName } from "./styles";

export default function MessageBlock({ isLeft, data }) {
  const className = useClassName();

  if (isLeft) {
    return (
      <div className={className.chatRowLeft}>
        <div className={className.chatLeft}>
          <strong className={className.chatText}>{data.text}</strong>
          <small className={className.chatDate}>{data.createdAt}</small>
        </div>
      </div>
    );
  }
  return (
    <div className={className.chatRowRight}>
      <div className={className.chatRight}>
        <strong className={className.chatText}>{data.text}</strong>
        <small className={className.chatDate}>{data.createdAt}</small>
      </div>
    </div>
  );
}
