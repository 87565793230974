import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { fbconfig as config } from "../config";

const rrfConfig = {
  useFirestoreForProfile: true,
  enableClaims: true,
};

firebase.initializeApp(config);

const { auth, firestore, storage } = firebase;

export { firebase, config, rrfConfig, auth, firestore, storage };
