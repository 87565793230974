import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Footer from "../../examples/Footer";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Header from "./components/Header";
import useData from "./data";
import Body from "./components/Body";
import Workdays from "./components/Workdays";
import DispatcherWorkdays from "./components/DispatcherWorkdays";
import Orders from "./components/Orders";
import Voucher from "./components/Voucher";
import CreditCard from "./components/CreditCard";
import CAlertSnackbar from "../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../components/Custom/CAlerts/CLoadingSnackbar";

function Reports() {
  const {
    orders,
    filterValues,
    onChangeFilter,
    onFilterData,
    cAlert,
    setCAlert,
    loading,
    loadingSuccess,
    setLoadingSuccess,
    setType,
    type,
  } = useData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header
        filterValues={filterValues}
        onChangeFilter={onChangeFilter}
        onFilterData={onFilterData}
      />

      {orders.length ? (
        <Body type={type} setType={setType} filterValues={filterValues}>
          {type === "workedDays" && <Workdays data={orders} filterValues={filterValues} />}
          {type === "orders" && <Orders data={orders} filterValues={filterValues} />}
          {type === "voucher" && <Voucher data={orders} filterValues={filterValues} />}
          {type === "creditCard" && <CreditCard data={orders} filterValues={filterValues} />}
          {type === "dispatcher" && (
            <DispatcherWorkdays data={orders} filterValues={filterValues} />
          )}
        </Body>
      ) : (
        <>
          <Skeleton style={{ marginTop: 20 }} width="100%" height={500} variant="rectangular" />
        </>
      )}

      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />

      <CLoadingSnackbar open={loading} />

      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />

      <Footer />
    </DashboardLayout>
  );
}

export default Reports;
