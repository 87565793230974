import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import MDTypography from "../../../../../components/MDTypography";

const tabStyle = {
  borderRadius: 4,
  borderWidth: 1,
  borderStyle: "solid",
};

export default function CTab({ value, handleChange, options = [], badges }) {
  const { t } = useTranslation();
  return (
    <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: "transparent" }}>
      <Tabs
        style={{ backgroundColor: "transparent" }}
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable auto tabs example"
      >
        {options.map((option, index) => (
          <Tab
            key={option}
            style={{
              ...tabStyle,
              borderColor: value === index ? "#344767" : "transparent",
            }}
            label={
              <MDTypography variant="strong">
                {t(option)}
                <b style={{ color: "red", paddingLeft: 5 }}>{badges[index] ? badges[index] : ""}</b>
              </MDTypography>
            }
          />
        ))}
      </Tabs>
    </Box>
  );
}
