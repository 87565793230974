import * as React from "react";
import { Grid, Icon } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useTranslation } from "react-i18next";
import CPaper from "../../../../../components/Custom/CPaper";
import MDBox from "../../../../../components/MDBox";
import IconBadge from "../../../../../components/Custom/IconBadge";
import { formatPhoneNumber } from "../../../../../utilities/func";
import MDTypography from "../../../../../components/MDTypography";

const getCallColor = (callStatus) => {
  if (callStatus === "processing") return "#b97a00";
  if (callStatus === "active") return "#002162";
  return "#006204";
};

const textStyle = { fontSize: 14, color: "white" };

export default function RenderCall({ data, handleClickOpen, handleClickCancel }) {
  const { t } = useTranslation();
  let client = data.client ? data.client : { stats: {}, lastAddresses: [] };
  const {
    lastAddresses: last_call = [],
    stats: { completed_orders = 0, cancelled_orders = 0 },
    blackListed = false,
    blocked = false,
  } = client;

  let phone = data.phone.number;
  let clientBlocked = blackListed || blocked;

  let street = last_call[0] ? last_call[0].street : "";
  let streetNumber = last_call[0] ? last_call[0].streetNumber : "";
  let city = last_call[0] ? last_call[0].city : "";

  return (
    <CPaper
      style={{
        backgroundColor: clientBlocked ? "#9b9b9b" : getCallColor(data.status),
        padding: 0,
        paddingLeft: 5,
        paddingRight: 5,
        margin: 2,
        marginBottom: 10,
      }}
    >
      <MDBox alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
        <Grid onClick={() => handleClickOpen(data)} item xs={9} style={{ cursor: "pointer" }}>
          <MDBox
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="text" style={{ color: "white", fontSize: 16 }}>
              {formatPhoneNumber(phone)}
            </MDTypography>

            {cancelled_orders > 0 ? (
              <IconBadge disabled badge={cancelled_orders} color="warning">
                <PersonRemoveIcon style={{ color: "#fff", transform: "rotateY(180deg)" }} />
              </IconBadge>
            ) : null}

            {completed_orders > 0 ? (
              <IconBadge disabled badge={completed_orders} color="success">
                <PersonAddIcon style={{ color: "#fff" }} />
              </IconBadge>
            ) : null}
          </MDBox>
          {city ? (
            <p style={textStyle}>{`${streetNumber} ${street}, ${city}`}</p>
          ) : (
            <p style={textStyle}>{t("new_client")}</p>
          )}
        </Grid>

        <MDBox alignItems="center" display="flex">
          <IconButton onClick={() => handleClickCancel(data)}>
            <Icon style={{ color: "#fff" }} fontSize="large">
              clear
            </Icon>
          </IconButton>
        </MDBox>
      </MDBox>
    </CPaper>
  );
}
