import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Typography, ListItemIcon } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import PeopleIcon from "@mui/icons-material/People";
import { useTranslation } from "react-i18next";
// import AddCardIcon from "@mui/icons-material/AddCard";
// import CarRepairIcon from "@mui/icons-material/CarRepair";
// import DoneAllIcon from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMaterialUIController } from "../../../context";

export default function AppointmentsActions({ onClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const handleClose = (type) => {
    setAnchorEl(null);
    if (typeof type === "string" && type !== "close") {
      onClick(type);
    }
  };

  return (
    <>
      <IconButton
        style={{ padding: 0, margin: 0 }}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        aria-controls={open ? "basic-menu" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: darkMode ? "#fff" : "#000" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* {isPaymentCard && status !== "unassigned" && ( */}
        {/*  <MenuItem onClick={() => handleClose("payment")} disableRipple> */}
        {/*    <ListItemIcon> */}
        {/*      <AddCardIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} /> */}
        {/*    </ListItemIcon> */}
        {/*    <Typography variant="inherit">{t("payment_with_card")}</Typography> */}
        {/*  </MenuItem> */}
        {/* )} */}

        <MenuItem onClick={() => handleClose("delete")}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("cancel_order")}</Typography>
        </MenuItem>

        {/* <MenuItem onClick={() => handleClose("show")}> */}
        {/*  <ListItemIcon> */}
        {/*    <VisibilityIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} /> */}
        {/*  </ListItemIcon> */}
        {/*  <Typography variant="inherit">{t("show")}</Typography> */}
        {/* </MenuItem> */}

        <MenuItem onClick={() => handleClose("close")}>
          <ListItemIcon>
            <CloseIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("close")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
