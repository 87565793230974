import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import CPaper from "./CPaper";

export default function CInputButton({
  value,
  onChange,
  onSendClick,
  placeholder,
  marginBottom = 5,
  bind,
}) {
  const { t } = useTranslation();
  return (
    <CPaper style={{ padding: 0, marginBottom }} elevation={2}>
      <Input
        value={value}
        onChange={({ target }) => onChange(target.value)}
        fullWidth
        disableUnderline
        style={{
          height: 40,
          borderRadius: 0,
          paddingLeft: 15,
          fontSize: 14,
        }}
        error={false}
        placeholder={t(placeholder)}
        endAdornment={
          <>
            <IconButton color="secondary" {...bind()}>
              <MicIcon />
            </IconButton>
            <IconButton onClick={onSendClick} color="secondary">
              <SendIcon />
            </IconButton>
          </>
        }
      />
    </CPaper>
  );
}
