import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import CAutocomplete from "../../../components/Custom/CAutocomplete";
import { dateOptions } from "../../../utilities/data";

export default function HeaderOptions({
  filterValues,
  onChangeFilter,
  onFilterData,
  filtersOn,
  onCancelFilter,
}) {
  const { t } = useTranslation();
  const drivers = useSelector(({ driverList }) => driverList);

  const marginRight = 10;

  const isDisabled = React.useMemo(() => {
    if (filterValues.range) {
      return !(filterValues.to && filterValues.from);
    }
    return !filterValues.date;
  }, [filterValues]);

  return (
    <>
      <MDBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
      >
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterValues.range}
                onChange={(e) => onChangeFilter(e.target.checked, "range")}
              />
            }
            label={t("range")}
          />
        </FormGroup>

        {filterValues.range ? (
          <>
            <TextField
              style={{ marginRight }}
              size="small"
              value={filterValues.from}
              onChange={(e) => onChangeFilter(e.target.value, "from")}
              label={t("from")}
              type="datetime-local"
              sx={{ width: 150 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              style={{ marginRight }}
              size="small"
              value={filterValues.to}
              onChange={(e) => onChangeFilter(e.target.value, "to")}
              label={t("to")}
              type="datetime-local"
              sx={{ width: 150 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </>
        ) : (
          <div style={{ width: 125, marginRight }}>
            <CAutocomplete
              value={filterValues.date}
              isLabel
              name="date"
              placeholder="date"
              options={dateOptions}
              onChange={(e, v, n) => onChangeFilter(v, n)}
            />
          </div>
        )}

        <div style={{ width: 125, marginRight }}>
          <CAutocomplete
            disableClearable={false}
            value={filterValues.driver}
            isLabel
            label="dNumber"
            name="driver"
            placeholder="drivers"
            options={drivers}
            onChange={(e, v, n) => onChangeFilter(v, n)}
          />
        </div>

        <MDButton
          disabled={isDisabled}
          onClick={onFilterData}
          style={{ width: 125 }}
          variant="gradient"
          size="medium"
          color="info"
        >
          {t("accept")}
        </MDButton>

        {filtersOn && (
          <MDButton
            onClick={onCancelFilter}
            style={{ width: 125, marginLeft: marginRight }}
            variant="gradient"
            size="medium"
            color="error"
          >
            {t("cancel")}
          </MDButton>
        )}
      </MDBox>
    </>
  );
}
