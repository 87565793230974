import {
  APP_SETTINGS,
  DASHBOARD_GRID,
  DRIVER_LIST_SUCCESS,
  DRIVER_DEVICES_SUCCESS,
  SETTINGS_SUCCESS,
  ORIGIN_MARKER_POSITION,
  ORDER_SELECTED,
  TICKETS_SUCCESS,
  USER_INFO_SUCCESS,
  SCHEDULE_SUCCESS,
} from "../actions";
import { SettingsModel, OrderModel } from "../../utilities/model";

export const INITIAL_STATE = {
  session: {
    user: null,
  },
  user: null,
  dashboardGrid: {
    isCreateOrder: true,
    isActiveCalls: true,
    isOrderHistory: true,
    isMiniChat: true,
    isRequestAlert: true,
    isActiveOrders: true,
    isMute: false,
  },
  driverList: [],
  driverDevices: [],
  settings: new SettingsModel(),
  originMarkerPosition: null,
  orderSelected: new OrderModel(),
  isTickets: false,
  schedule: null,
};

export default function Reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case APP_SETTINGS:
      return { ...state, appSettings: action.payload };
    case DASHBOARD_GRID:
      return { ...state, dashboardGrid: action.payload };
    case SCHEDULE_SUCCESS:
      return { ...state, schedule: action.payload };
    case DRIVER_LIST_SUCCESS:
      return { ...state, driverList: action.payload };
    case DRIVER_DEVICES_SUCCESS:
      return { ...state, driverDevices: action.payload };
    case SETTINGS_SUCCESS:
      return { ...state, settings: action.payload };
    case ORIGIN_MARKER_POSITION:
      return { ...state, originMarkerPosition: action.payload };
    case ORDER_SELECTED:
      return { ...state, orderSelected: action.payload };
    case TICKETS_SUCCESS:
      return { ...state, isTickets: action.payload };
    case USER_INFO_SUCCESS:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}
