import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { firestore } from "../../firebase";
import { CaptureException, getDate, onlyCurrentDay } from "../../utilities/func";
import OrdersActions from "./components/orders.actions";
import { AlertModel, FilterModel } from "../../utilities/model";
import { RangeDateData } from "../../utilities/data";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default function useData() {
  const { t } = useTranslation();
  const [orders, setOrders] = React.useState([]);
  const [ordersFilters, setOrdersFilters] = React.useState([]);
  const dashboardSettings = useSelector(({ settings }) => settings);
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [filterValues, setFilterValues] = React.useState(new FilterModel());
  const [loading, setLoading] = React.useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [orderSelected, setOrderSelected] = React.useState({});
  const [showOrder, setShowOrder] = React.useState(false);
  const [isSearchActive, setIsSearchActive] = React.useState(false);
  const [filtersOn, setFiltersOn] = React.useState(false);
  const [columnsType, setColumnsType] = React.useState("orders");
  const [analytics, setAnalytics] = React.useState({
    money: 0,
    system: 0,
    app: 0,
  });

  const orderResult = (querySnapshot, isFilters) => {
    const list = [];
    let money = 0;
    let system = 0;
    let app = 0;
    querySnapshot.docs.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;

      if (data.paymentAmount) {
        money += Number(data.paymentAmount);
      }

      if (data.source === "mobile") {
        app += 1;
      }

      if (data.source !== "mobile") {
        system += 1;
      }

      list.push(data);
    });
    setAnalytics({
      system,
      app,
      money,
    });
    if (isFilters) {
      setOrdersFilters(list);
    } else {
      setOrders(list);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    const orderOnSnapshot = firestore()
      .collection("credit_card_payments")
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", onlyCurrentDay())
      .onSnapshot((querySnapshot) => {
        setLoading(false);
        orderResult(querySnapshot);
      });

    return () => {
      orderOnSnapshot();
    };
  }, []);

  const onSelectType = (type) => {
    setColumnsType(type);
  };

  const getOrder = async (id) => {
    let d = await firestore().collection("orders").doc(id).get();
    if (d.exists) {
      return d.data();
    }
    return {};
  };

  const onActions = async (type, selected) => {
    const { from = null, to = null } = await getOrder(selected.orderId);
    switch (type) {
      case "receipt":
        setOrderSelected({ ...selected, from, to });
        setShowOrder(true);
        break;
      default:
        break;
    }
  };

  const onSearchValue = (searchValue) => {
    setIsSearchActive(!!searchValue);
  };

  const onChangeFilter = (value, name) => {
    setFilterValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const filterRef = (ref) => {
    let from;
    let to;
    let { driver, date, range } = filterValues;

    if (range) {
      from = new Date(filterValues.from);
      to = new Date(filterValues.to);
    } else {
      from = RangeDateData(date).from;
      to = RangeDateData(date).to;
    }

    if (driver) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .where("driverNumber", "==", driver.dNumber)
        .orderBy("createdAt", "desc");
    }

    if (from && to) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .orderBy("createdAt", "desc");
    }
    return ref;
  };

  const onFilterData = () => {
    setLoading(true);
    let ref = firestore().collection("credit_card_payments");
    ref = filterRef(ref);
    ref
      .get()
      .then((querySnapshot) => {
        setFiltersOn(true);
        setLoading(false);
        orderResult(querySnapshot, true);
      })
      .catch((e) => {
        setLoading(false);
        CaptureException(e, "Error-DAO-07");
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const onCancelFilter = () => {
    setFiltersOn(false);
    setFilterValues(new FilterModel());
  };

  const orderDataForTable = () => {
    let data = filtersOn ? ordersFilters : orders;
    return data.map((order) => {
      const {
        paymentAmount,
        creditCardNumber,
        phoneNumber,
        invoiceId,
        driverNumber,
        paymentSource,
      } = order;
      let createdAt = getDate(order);
      let title = `${createdAt}`;

      return {
        fare: paymentAmount,
        invoiceId,
        createdAt,
        phone: phoneNumber,
        creditCardNumber,
        paymentAmount:
          paymentAmount && typeof paymentAmount === "number"
            ? formatter.format(paymentAmount)
            : "$0.00",
        driver: driverNumber,
        paymentSource: paymentSource ? t(paymentSource) : "",
        actions: (
          <OrdersActions
            isPaymentCard={dashboardSettings.isPaymentCard}
            data={order}
            title={title}
            onClick={(type) => onActions(type, order)}
          />
        ),
      };
    });
  };

  const orderDataSearchable = React.useMemo(
    () => orderDataForTable(),
    [orders, isSearchActive, ordersFilters, filtersOn]
  );
  const orderData = React.useMemo(() => orderDataForTable(), [orders, ordersFilters, filtersOn]);

  return {
    orders: isSearchActive ? orderDataSearchable : orderData,
    onSearchValue,
    loading,
    loadingSuccess,
    setLoading,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    analytics,
    onSelectType,
    columnsType,
    filterValues,
    onChangeFilter,
    onFilterData,
    filtersOn,
    onCancelFilter,
    showOrder,
    setShowOrder,
    orderSelected,
  };
}
