import * as React from "react";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "../../../../../components/MDBox";
import MessageBlock from "./message";
import { firebase, firestore } from "../../../../../firebase";
import { getTime, getUser } from "../../../../../utilities/func";
import CInputButton from "../../../../../components/Custom/CInputButton";
import CLoadingSnackbar from "../../../../../components/Custom/CAlerts/CLoadingSnackbar";
import CAlertSnackbar from "../../../../../components/Custom/CAlerts/CAlertSnackbar";
import { AlertModel } from "../../../../../utilities/model";
import CMicAudio from "../../../../../components/Custom/CMicAudio";

export default function Chat({ onCloseChat, driverId }) {
  const [messagesData, setMessagesData] = React.useState([]);
  const [message, setMessage] = useState("");
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);

  React.useEffect(() => {
    let startOfDay = new Date();
    let endOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    endOfDay.setHours(23, 59, 59, 59);
    if (!driverId) {
      return;
    }
    const subscriptionChat = firestore()
      .collection("chat")
      .limit(15)
      .where("driverId", "==", driverId)
      .where("createdAt", ">=", startOfDay)
      .where("createdAt", "<=", endOfDay)
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        let chatData = [];
        if (!querySnapshot) {
          return;
        }
        querySnapshot.docs.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          data.createdAt = getTime(data);
          chatData.push(data);
        });
        setMessagesData(chatData.reverse());
      });
    // eslint-disable-next-line consistent-return
    return () => {
      subscriptionChat();
    };
  }, [driverId]);

  const sendGlobalMessage = () => {
    if (message !== "") {
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      firestore()
        .collection("chat")
        .add({
          driverId,
          source: "admin",
          _id: Math.floor(Math.random() * 100) * Date.now(),
          text: message,
          global: false,
          user: { _id: "123456789" },
          dispatcher: getUser(),
          createdAt: timestamp,
          status: "pending",
        })
        .then(() => {
          setMessage("");
          setLoadingSuccess(true);
        })
        .catch((e) => {
          setCAlert({ open: true, type: "error", ms: e.message });
        });
    }
  };

  React.useEffect(() => {
    messagesData.forEach((chat) => {
      if (chat.driverId === driverId) {
        if (chat.status === "pending") {
          firestore()
            .collection("chat")
            .doc(chat.id)
            .update({
              status: "read",
            })
            .catch(() => {});
        }
      }
    });
  }, [messagesData, driverId]);

  return (
    <MDBox display="flex" flexDirection="column">
      <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <CMicAudio driverId={driverId} />
        <IconButton color="secondary" onClick={onCloseChat}>
          <CloseIcon />
        </IconButton>
      </MDBox>
      <div style={{ overflow: "auto", height: 350, marginBottom: 5 }}>
        {messagesData.map((info) => (
          // eslint-disable-next-line no-underscore-dangle
          <MessageBlock key={info.id} isLeft={info.user._id !== "123456789"} data={info} />
        ))}
      </div>
      <CInputButton
        marginBottom={0}
        placeholder="send_global_message"
        value={message}
        onChange={setMessage}
        icon2={<SendIcon />}
        icon2Click={sendGlobalMessage}
        buttonLabel="send"
      />
      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="message_sent"
        duration={1500}
      />
      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />
    </MDBox>
  );
}
