import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as React from "react";
import useData from "./data";
import { paymentColumns } from "./components/columns";
import DataTable from "../../examples/Tables/DataTable";
import CAlertSnackbar from "../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../components/Custom/CAlerts/CLoadingSnackbar";
import Header from "./components/Header";
import HeaderOptions from "./components/HeaderOptions";
import ShowReceiptModal from "../../components/Custom/receipt/ShowReceiptModal";

export default function CreditCardOrderPayment() {
  const {
    orders,
    onSearchValue,
    appointments,
    loading,
    loadingSuccess,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    analytics,
    onSelectType,
    columnsType,
    filterValues,
    onChangeFilter,
    onFilterData,
    filtersOn,
    onCancelFilter,
    showOrder,
    setShowOrder,
    orderSelected,
    setLoading,
  } = useData();

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Header analytics={analytics} />

        <DataTable
          showTotalEntries={false}
          PageSize={25}
          headerOptions={
            <HeaderOptions
              onCancelFilter={onCancelFilter}
              filtersOn={filtersOn}
              onFilterData={onFilterData}
              onClick={onSelectType}
              value={columnsType}
              filterValues={filterValues}
              onChangeFilter={onChangeFilter}
            />
          }
          noEndBorder
          canSearch
          table={{
            columns: paymentColumns,
            rows: columnsType === "orders" ? orders : appointments,
          }}
          onSearchValue={onSearchValue}
        />
        <Footer />
      </DashboardLayout>

      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />

      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />
      {showOrder && (
        <ShowReceiptModal
          type="payment"
          open={showOrder}
          close={setShowOrder}
          model={orderSelected}
          setLoading={setLoading}
        />
      )}
      <CLoadingSnackbar open={loading} />
    </>
  );
}
