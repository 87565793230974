import React from "react";
import { DateTime } from "luxon";
import { firestore } from "../../firebase";
import { CaptureException, getCheckField, getDate, getUser } from "../../utilities/func";
import OrdersActions from "./components/orders.actions";
import { AlertModel, FilterModel } from "../../utilities/model";
import { RangeDateData } from "../../utilities/data";
import { businessName } from "../../config";

export default function useData() {
  const [orders, setOrders] = React.useState([]);
  const [ticketListMessage, setTicketListMessage] = React.useState([]);
  const [ordersFilters, setOrdersFilters] = React.useState([]);
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [filterValues, setFilterValues] = React.useState(new FilterModel());
  const [loading, setLoading] = React.useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [orderSelected, setOrderSelected] = React.useState({});
  const [showOrder, setShowOrder] = React.useState(false);
  const [isSearchActive, setIsSearchActive] = React.useState(false);
  const [filtersOn, setFiltersOn] = React.useState(false);

  const orderResult = (querySnapshot, isFilters) => {
    const list = [];
    querySnapshot.docs.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;
      list.push(data);
    });
    if (isFilters) {
      setOrdersFilters(list);
    } else {
      setOrders(list);
    }
  };

  const sendMail = () => {
    firestore()
      .collection("mail")
      .add({
        to: orderSelected.client.email,
        message: {
          subject: businessName,
          html: `<div style="line-height: 160%; text-align: left; word-wrap: break-word;">
                                <p style="font-size: 14px; line-height: 160%;"><span style="font-size: 22px; line-height: 35.2px;">Thanks for contacting ${businessName}</span></p>
                                <p style="font-size: 14px; line-height: 160%;"><span style="font-size: 18px; line-height: 25.6px;">We have responded to your request, please see the information in the app.</span></p>
                              </div>`,
        },
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    setLoading(true);
    let from = DateTime.now().startOf("month").minus({ months: 1 }).toJSDate();
    const orderOnSnapshot = firestore()
      .collection("orders")
      .orderBy("createdAt", "desc")
      .where("ticketStatus", "==", "open")
      .where("createdAt", ">=", from)
      .onSnapshot((querySnapshot) => {
        setLoading(false);
        orderResult(querySnapshot);
      });

    // const ticketOnSnapshot = firestore()
    //   .collection("tickets")
    //   .orderBy("createdAt", "desc")
    //   .where("ticketStatus", "==", "open")
    //   .where("createdAt", ">=", from)
    //   .onSnapshot((querySnapshot) => {
    //     setLoading(false);
    //     orderResult(querySnapshot);
    //   });

    return () => {
      orderOnSnapshot();
    };
  }, []);

  const onReset = () => {
    setShowOrder(false);
    setLoading(false);
    setOrderSelected({});
  };

  const getTicketMessage = (id) => {
    firestore()
      .collection("orders")
      .doc(id)
      .collection("tickets")
      .orderBy("createdAt", "desc")
      .get()
      .then((querySnapshot) => {
        setLoading(false);
        const list = [];
        querySnapshot.docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          data.createdAt = getDate(data);
          list.push(data);
        });
        setTicketListMessage(list);
      });
  };

  const onActions = (type, selected) => {
    setOrderSelected(selected);
    switch (type) {
      case "show":
        setShowOrder(true);
        setLoading(true);
        getTicketMessage(selected.id);
        break;
      default:
        break;
    }
  };

  const onSearchValue = (searchValue) => {
    setIsSearchActive(!!searchValue);
  };

  const onChangeFilter = (value, name) => {
    setFilterValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const filterRef = (ref) => {
    let from;
    let to;
    let { date, invoiceId, email, phoneNumber } = filterValues;

    from = RangeDateData(date).from;
    to = RangeDateData(date).to;

    if (phoneNumber.length) {
      return ref
        .where("ticketStatus", "in", ["open", "closed"])
        .where("client.phoneNumber", "==", phoneNumber);
    }

    if (email.length) {
      return ref.where("ticketStatus", "in", ["open", "closed"]).where("client.email", "==", email);
    }

    if (invoiceId.length) {
      return ref
        .where("ticketStatus", "in", ["open", "closed"])
        .where("invoiceId", "==", invoiceId);
    }

    if (from && to) {
      return ref
        .where("ticketStatus", "in", ["open", "closed"])
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .orderBy("createdAt", "desc");
    }
    return ref;
  };

  const onFilterData = () => {
    setLoading(true);
    let ref = firestore().collection("orders");
    ref = filterRef(ref);
    ref
      .get()
      .then((querySnapshot) => {
        setFiltersOn(true);
        setLoading(false);
        orderResult(querySnapshot, true);
      })
      .catch((e) => {
        setLoading(false);
        CaptureException(e, "Error-DAO-07");
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const onChange = (e) => {
    const { name, value, checked, type } = e.target;
    const Value = type === "checkbox" ? checked : value;
    return setOrderSelected((prevState) => ({ ...prevState, [name]: Value }));
  };

  const onResponseTicket = async () => {
    try {
      setLoading(true);
      setShowOrder(false);
      let data = {
        companyName: businessName,
        createdAt: firestore.FieldValue.serverTimestamp(),
        orderId: orderSelected.id,
        invoiceId: orderSelected.invoiceId,
        createdBy: getUser(),
        source: "admin",
        photos: null,
        message: orderSelected.message,
        client: orderSelected.client,
        status: "open",
      };

      await firestore().collection("orders").doc(orderSelected.id).collection("tickets").add(data);
      await firestore()
        .collection("orders")
        .doc(orderSelected.id)
        .set({ ticketStatus: orderSelected.ticketStatus, ticketBy: "admin" }, { merge: true });

      sendMail();

      setLoading(false);
      setLoadingSuccess(true);
      setOrderSelected({});
    } catch (e) {
      setLoading(false);
      CaptureException(e, "Error-DAO-10");
      setCAlert({ open: true, type: "error", ms: e.message });
    }
  };

  const onCancelFilter = () => {
    setFiltersOn(false);
    setFilterValues(new FilterModel());
  };

  const orderDataForTable = () => {
    let data = filtersOn ? ordersFilters : orders;
    return data.map((order) => {
      const { fare, invoiceId, ticketStatus } = order;
      let createdAt = getDate(order);
      let street = getCheckField(order, "from", "street");
      let streetNumber = getCheckField(order, "from", "streetNumber");
      let city = getCheckField(order, "from", "city");
      let title = `${createdAt} | ${streetNumber} ${street}, ${city}`;
      let from = `${streetNumber} ${street}, ${city}`;
      let dNumber = getCheckField(order, "assignedDriver", "dNumber");
      let firstName = getCheckField(order, "client", "firstName");
      let lastName = getCheckField(order, "client", "lastName");
      let phoneNumber = getCheckField(order, "client", "phoneNumber");

      return {
        fare,
        invoiceId,
        createdAt,
        from,
        ticketStatus,
        client: `${firstName} ${lastName}`,
        phone: phoneNumber,
        driver: dNumber,
        actions: (
          <OrdersActions data={order} title={title} onClick={(type) => onActions(type, order)} />
        ),
      };
    });
  };

  const orderDataSearchable = React.useMemo(
    () => orderDataForTable(),
    [orders, isSearchActive, ordersFilters, filtersOn]
  );
  const orderData = React.useMemo(() => orderDataForTable(), [orders, ordersFilters, filtersOn]);

  return {
    orders: isSearchActive ? orderDataSearchable : orderData,
    onSearchValue,
    loading,
    loadingSuccess,
    setLoading,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    filterValues,
    onChangeFilter,
    onFilterData,
    filtersOn,
    onCancelFilter,
    showOrder,
    setShowOrder,
    orderSelected,
    ticketListMessage,
    onResponseTicket,
    onChange,
    onReset,
  };
}
