import { Card, Radio, FormControl, FormControlLabel, Input, RadioGroup } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";
import CPaper from "../../../../../components/Custom/CPaper";
import { getCheckField, formatPhoneNumber } from "../../../../../utilities/func";
import { useMaterialUIController } from "../../../../../context";

const labelOrder = (orders) => {
  if (orders.requestCancellation) {
    return "cancel";
  }
  if (orders.requestPassOrder) {
    return "pass_order";
  }

  if (orders.requestCallClient) {
    return "call";
  }
  return "";
};

const nameOrder = (orders) => {
  if (orders.requestCancellation) {
    return "requestCancellationResponse";
  }
  if (orders.requestPassOrder) {
    return "requestPassOrderResponse";
  }

  if (orders.requestCallClient) {
    return "requestCallClientResponse";
  }
  return "";
};

const colorOrder = (orders) => {
  if (orders.requestCancellation) {
    return "#690000";
  }
  if (orders.requestPassOrder) {
    return "#056700";
  }

  if (orders.requestCallClient) {
    return "#00004d";
  }
  return "#000";
};

const call = ["One moment, please", "We are talking to the client", "Customer is still waiting"];
const pass = ["One moment, please"];

const Request = ({ data, onChange, onCancel, onRequestAction, loadingRequest }) => {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [value, setValue] = useState("");
  let street = getCheckField(data, "from", "street");
  let streetNumber = getCheckField(data, "from", "streetNumber");
  let city = getCheckField(data, "from", "city");
  let dNumber = getCheckField(data, "assignedDriver", "dNumber");
  let phoneNumber = getCheckField(data, "client", "phoneNumber");
  let label = labelOrder(data);
  let name = nameOrder(data);
  let { id } = data;
  return (
    <>
      <CPaper style={{ backgroundColor: colorOrder(data), padding: 5, margin: 5, marginTop: 12 }}>
        <MDBox
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={1}
          style={{ flexWrap: "wrap" }}
        >
          <MDButton size="small" color="secondary">
            {`${t("driver")}: ${dNumber}`}
          </MDButton>

          <MDButton
            size="small"
            color="warning"
            onClick={() => onRequestAction(data, label)}
            loading={loadingRequest}
          >
            {t(label)}
          </MDButton>

          {label === "call" && (
            <MDButton
              size="small"
              color="error"
              onClick={() => onRequestAction(data, "cancel")}
              loading={loadingRequest}
            >
              {t("cancel")}
            </MDButton>
          )}

          <MDButton onClick={() => onCancel(id)} variant="gradient" size="small" color="error">
            {t("hide")}
          </MDButton>
        </MDBox>

        <MDTypography variant="h6" color="white">
          {`${streetNumber} ${street}, ${city}`} | {formatPhoneNumber(phoneNumber)}
        </MDTypography>
        <MDTypography variant="h6" color="white">
          Quick response to know the drivers
        </MDTypography>

        <Card style={{ padding: 4, borderRadius: 4 }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {label === "call" &&
                call.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    onChange={(e) => onChange(name, e.target.value, id)}
                    control={<Radio />}
                    label={item}
                  />
                ))}
              {label === "pass_order" &&
                pass.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    onChange={(e) => onChange(name, e.target.value, id)}
                    control={<Radio />}
                    label={item}
                  />
                ))}
              {label === "cancel" &&
                call.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    onChange={(e) => onChange(name, e.target.value, id)}
                    control={<Radio />}
                    label={item}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </Card>
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          disableUnderline
          style={{
            marginTop: 5,
            fontSize: 14,
            paddingLeft: 10,
            backgroundColor: darkMode ? "#2c3c58" : "#fff",
          }}
          error={false}
          placeholder={t("message")}
          endAdornment={
            <MDButton
              disabled={!value.length}
              onClick={() => {
                setValue("");
                onChange(name, value, id);
              }}
              variant="gradient"
              style={{ borderRadius: 0 }}
              size="medium"
              color="success"
            >
              {t("send")}
            </MDButton>
          }
        />
      </CPaper>
    </>
  );
};

export default function RenderRequest({
  request1 = [],
  request2 = [],
  onChange,
  onCancel,
  onRequestAction,
  loadingRequest,
}) {
  return [...request1, ...request2].map((item) =>
    item.isNoVisibleRequest ? (
      <div key={item.id} />
    ) : (
      <Request
        key={item.id}
        data={item}
        onChange={onChange}
        onCancel={onCancel}
        onRequestAction={onRequestAction}
        loadingRequest={loadingRequest}
      />
    )
  );
}
