import * as Sentry from "@sentry/react";
import { firebase, firestore } from "../../firebase";

export const SCHEDULE_SUCCESS = "SCHEDULE_SUCCESS";

export function ScheduleSuccess(payload) {
  return {
    type: SCHEDULE_SUCCESS,
    payload,
  };
}

export function getSchedulesList() {
  return async function f(dispatch) {
    try {
      firebase
        .firestore()
        .collection("schedules")
        .where("isDefault", "==", true)
        .onSnapshot((docsData) => {
          docsData.forEach((device) => {
            let data = device.data();
            data.id = device.id;
            dispatch(ScheduleSuccess(data));
          });

        });

    } catch (e) {
      Sentry.captureException(e);
    }
  };
}
