import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { useMaterialUIController } from "../../context";
import MDTypography from "../MDTypography";
import MDBox from "../MDBox";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function CDialog({
  open,
  title,
  close,
  subtitle,
  children,
  maxWidth = "xl",
  fullScreen = false,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();

  const iconsStyle = ({ palette: { dark, white } }) => ({
    color: () => (darkMode ? white.main : dark.main),
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      disableEscapeKeyDown
      keepMounted
      maxWidth={maxWidth}
      fullWidth
      open={open}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent style={{ backgroundColor: darkMode ? "#344767" : "#f0f2f5" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end">
          <MDTypography variant="h5">{t(title)}</MDTypography>
          <IconButton size="medium" disableRipple color="inherit" onClick={close}>
            <Icon sx={iconsStyle}>close</Icon>
          </IconButton>
        </MDBox>

        {subtitle && <MDTypography variant="text">Id: {t(subtitle)}</MDTypography>}
        {children}
      </DialogContent>
    </Dialog>
  );
}
