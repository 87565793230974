export const paymentColumns = [
  { Header: "actions", accessor: "actions" },
  { Header: "date", accessor: "createdAt" },
  { Header: "invoice_id", accessor: "invoiceId" },
  { Header: "credit_card", accessor: "creditCardNumber" },
  { Header: "amount", accessor: "paymentAmount" },
  { Header: "driver", accessor: "driver" },
  { Header: "phone", accessor: "phone" },
  { Header: "payment source", accessor: "paymentSource" },
];
