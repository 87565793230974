import * as React from "react";
import { Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import CCardHeader from "../../../../components/Custom/CCardHeader";
import { useStyles } from "../../styles";
import CTab from "./components/CTab";
import RenderRequest from "./components/RenderRequest";
import RenderAlerts from "./components/RenderAlerts";
import RenderHistory from "./components/RenderHistory";
import useData from "./data";
import CAlertSnackbar from "../../../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../../../components/Custom/CAlerts/CLoadingSnackbar";
import CAlert from "../../../../components/Custom/CAlerts/CAlert";

export default function RequestAlert() {
  const styles = useStyles();
  const {
    cAlert,
    setCAlert,
    loading,
    loadingSuccess,
    setLoadingSuccess,
    phoneNumber,
    setPhoneNumber,
    getClientHistory,
    clientData,
    valueTab,
    handleChangeTab,
    alertData,
    onCheckAlert,
    panic,
    setPanic,
    request1,
    request2,
    onResponseRequest,
    onCancelRequest,
    onRequestAction,
    loadingRequest,
    setOpenCAlert,
    openCAlert,
    cAlertInfo,
    onConfirmAction,
  } = useData();
  const { t } = useTranslation();
  return (
    <Card style={styles.card}>
      <CCardHeader value="isRequestAlert" title="request_alert" />
      <CardContent style={styles.cardContent}>
        <CTab
          options={["request", "alerts", "history"]}
          badges={[request1.length + request2.length, alertData.length, 0]}
          handleChange={handleChangeTab}
          value={valueTab}
        />
        <div style={{ marginBottom: 5 }} />
        {valueTab === 0 && (
          <RenderRequest
            request1={request1}
            request2={request2}
            onChange={onResponseRequest}
            onCancel={onCancelRequest}
            onRequestAction={onRequestAction}
            loadingRequest={loadingRequest}
          />
        )}
        {valueTab === 1 && <RenderAlerts data={alertData} onCheckAlert={onCheckAlert} />}
        {valueTab === 2 && (
          <RenderHistory
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            onSearch={getClientHistory}
            clientData={clientData}
          />
        )}

        <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />
        <CLoadingSnackbar open={loading} />
        <CLoadingSnackbar
          isSuccess
          open={loadingSuccess}
          close={setLoadingSuccess}
          title="success"
          duration={1500}
        />
        <CAlert
          close={setPanic}
          open={panic}
          title={`${t("driver")}: ${
            alertData[0] ? `${alertData[0].dNumber} / ${alertData[0].displayName}` : ""
          }`}
          subtitle="driver_danger_ms"
          isConfirm={() => onCheckAlert(alertData[0].id)}
        />

        <CAlert
          close={setOpenCAlert}
          open={openCAlert}
          title={cAlertInfo.title}
          subtitle={cAlertInfo.subtitle}
          isConfirm={onConfirmAction}
        />
      </CardContent>
    </Card>
  );
}
