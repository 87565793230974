import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import { DriverRegisterModel } from "../../../utilities/model";
import CAutocomplete from "../../../components/Custom/CAutocomplete";
import { vehicleTypeOptions } from "../../../utilities/data";
import InputMaskPhone from "../../../components/Custom/InputMaskPhone";
import MDTypography from "../../../components/MDTypography";

export default function AddDriverModal({
  open,
  close,
  model = new DriverRegisterModel(),
  onCreate,
  onChange,
}) {
  const { t } = useTranslation();

  const driversData = useSelector(({ driverList }) => driverList);
  let getDriversNumber = _.map(driversData, "dNumber");

  const validatedDNumber = React.useMemo(
    () => getDriversNumber.includes(model.dNumber),
    [driversData, model]
  );

  return (
    <CDialog title="create driver" close={close} open={open}>
      <br />
      <MDInput
        fullWidth
        value={model.firstName}
        onChange={onChange}
        name="firstName"
        style={{ marginBottom: 25 }}
        label={`${t("firstName")}*`}
        type="text"
      />

      <MDInput
        fullWidth
        value={model.lastName}
        onChange={onChange}
        name="lastName"
        style={{ marginBottom: 25 }}
        label={`${t("lastName")}*`}
        type="text"
      />

      <MDInput
        fullWidth
        value={model.email}
        onChange={onChange}
        name="email"
        style={{ marginBottom: 25 }}
        label={`${t("email")}*`}
        type="text"
      />

      <InputMaskPhone
        fullWidth
        style={{ marginBottom: 25 }}
        label={t("phoneNumber")}
        name="phoneNumber"
        value={model.phoneNumber}
        onChange={onChange}
      />

      <MDInput
        fullWidth
        value={model.license}
        onChange={onChange}
        name="license"
        style={{ marginBottom: 25 }}
        label={t("license")}
        type="text"
      />

      <MDBox style={{ marginBottom: 25 }}>
        <MDInput
          error={validatedDNumber || !model.dNumber.length}
          success={model.dNumber.length > 0 && !validatedDNumber}
          fullWidth
          value={model.dNumber}
          onChange={onChange}
          name="dNumber"
          label={`${t("dNumber")}*`}
          type="text"
        />

        {validatedDNumber && (
          <MDTypography variant="small" color="error" fontWeight="bold">
            {t("A driver number with this identification already exists")}
          </MDTypography>
        )}
      </MDBox>

      <MDInput
        fullWidth
        value={model.password}
        onChange={onChange}
        name="password"
        style={{ marginBottom: 25 }}
        label={`${t("password")}*`}
        type="text"
      />

      <CAutocomplete
        isLabel
        value={model.vehicleType}
        label="label"
        name="vehicleType"
        placeholder="vehicle"
        options={vehicleTypeOptions}
        onChange={onChange}
      />

      <br />
      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton
          disabled={
            !(
              model.firstName.length &&
              model.lastName.length &&
              model.email.length &&
              model.dNumber.length &&
              model.password.length >= 6
            )
          }
          onClick={onCreate}
          variant="gradient"
          color="success"
        >
          {t("add")}
        </MDButton>
        <MDButton
          onClick={() => close(false)}
          variant="gradient"
          color="error"
          style={{ marginLeft: 20 }}
        >
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
