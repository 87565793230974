import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Checkbox } from "@mui/material";
import MDTypography from "../MDTypography";

export default function TSCheck({ subtitle, value, name, onChange, row }) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction={row ? "row" : "column"}
      justifyContent="flex-start"
      alignItems="center"
    >
      <Checkbox
        inputProps={{ "aria-label": `controlled-${name}` }}
        checked={value}
        name={name}
        onChange={onChange}
      />

      <MDTypography variant="text">{t(subtitle)}</MDTypography>
    </Grid>
  );
}
