import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import CDialog from "../CDialog";
import MDBox from "../../MDBox";
import PaymentReceipt from "../../../html_templates/payment/invoice_en";
import OrderReceipt from "../../../html_templates/order/invoice_en";
import { businessEmail, businessName } from "../../../config";
import img1 from "../../../html_templates/img/c.png";
import { logoCompany } from "../../../utilities/func";

export default function ShowReceiptModal({ open, close, model, setLoading, type }) {
  const { t } = useTranslation();

  React.useEffect(() => {
    setTimeout(() => {
      let companyId = document.getElementById("company");
      let invoiceNumberId = document.getElementById("invoiceNumber");
      let invoiceDateId = document.getElementById("invoiceDate");
      let amountChargedId = document.getElementById("amountCharged");
      let email_companyId = document.getElementById("email_company");
      let from_addressId = document.getElementById("from_address");
      let isInvoiceNumberId = document.getElementById("isInvoiceNumber");
      let to_addressId = document.getElementById("to_address");
      let isToAddressId = document.getElementById("isToAddress");
      let creditCardNumberId = document.getElementById("creditCardNumber");
      let isCreditCardNumber = document.getElementById("isCreditCardNumber");
      let dNumberId = document.getElementById("dNumber");
      let logoId = document.getElementById("logo");
      let img1Id = document.getElementById("img1");

      logoId.src = logoCompany();
      img1Id.src = img1;

      if (from_addressId && model.from) {
        from_addressId.innerText = model.from.address;
      }

      if (to_addressId) {
        if (model.to && model.to.address) {
          to_addressId.innerText = model.to.address;
        } else {
          isToAddressId.style.display = "none";
        }
      }

      if (amountChargedId) {
        amountChargedId.innerText = `Amount charged: $${
          type === "order" ? model.fare : model.paymentAmount
        }`;
      }
      if (companyId) {
        companyId.innerText = `Thanks for using ${businessName}`;
      }
      if (invoiceNumberId) {
        if (model.invoiceId) {
          invoiceNumberId.innerText = `Invoice: ${model.invoiceId}`;
        } else {
          isInvoiceNumberId.style.display = "none";
        }
      }

      if (creditCardNumberId && type === "order" && model.payment) {
        creditCardNumberId.innerText = `Credit Card: ${model.payment.creditCardNumber}`;
      }

      if (creditCardNumberId && type === "payment" && model.creditCardNumber) {
        creditCardNumberId.innerText = `Credit Card: ${model.creditCardNumber}`;
      }

      if (!model.payment && !model.creditCardNumber) {
        isCreditCardNumber.style.display = "none";
      }

      if (invoiceDateId) {
        invoiceDateId.innerText = `Date: ${DateTime.fromSeconds(model.createdAt.seconds).toFormat(
          "DDD, t"
        )}`;
      }
      if (email_companyId) {
        email_companyId.innerText = businessEmail;
      }

      if (dNumberId) {
        dNumberId.innerText = type === "order" ? model.assignedDriver.dNumber : model.driverNumber;
      }
    }, 500);
  }, [model, type]);

  const onPrint = () => {
    setLoading(true);
    const input = document.getElementById("divToPrint");
    html2canvas(input, { scale: 3, dpi: 144 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF({
        compress: true,
        orientation: "portrait",
        format: "a4",
      });
      setLoading(false);
      close(false);
      pdf.addImage(imgData, "JPEG", -10, 30, canvas.width / 15, canvas.height / 15);
      pdf.save("invoice.pdf");
    });
  };

  const onShare = () => {
    setLoading(true);
    const input = document.getElementById("divToPrint");
    html2canvas(input, { scale: 3, dpi: 144 }).then((canvas) => {
      canvas.toBlob(async (blob) => {
        setLoading(false);
        const file = new File([blob], "share.jpg", { type: blob.type });

        if (navigator.share) {
          await navigator
            .share({
              title: "Receipt of payment",
              text: `Thank you for choosing us`,
              to: model.client.email,
              files: [file],
            })
            .then(() => {})
            .catch(() => {});
        }
      });
    });
  };

  return (
    <CDialog title="receipt" close={() => close(false)} open={open}>
      <br />
      <div id="divToPrint">
        {type === "payment" && <PaymentReceipt />}
        {type === "order" && <OrderReceipt />}
      </div>

      <br />
      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton onClick={onPrint} variant="gradient" color="success">
          {t("print")}
        </MDButton>
        <MDButton onClick={onShare} variant="gradient" color="info" style={{ marginLeft: 20 }}>
          {t("share")}
        </MDButton>
        <MDButton
          onClick={() => close(false)}
          variant="gradient"
          color="error"
          style={{ marginLeft: 20 }}
        >
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
