import * as React from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: -5,
    top: 13,
    border: `2px solid #d2d2d2`,
    padding: "0 2px",
  },
}));

export default function IconBadge2({ children, badge, color, disabled, onClick }) {
  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      aria-label="cart"
      style={{ padding: 0, margin: 0 }}
    >
      <StyledBadge max={999} badgeContent={badge} color={color}>
        {children}
      </StyledBadge>
    </IconButton>
  );
}
