import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import CDialog from "../../../../../components/Custom/CDialog";
import MDBox from "../../../../../components/MDBox";
import CAutocomplete from "../../../../../components/Custom/CAutocomplete";

export default function SearchDriverModal({ open, close, onClick, value, onChange, options }) {
  const { t } = useTranslation();
  return (
    <CDialog
      open={open}
      title="search_driver"
      close={() => {
        close(false);
      }}
      maxWidth="sm"
    >
      <br />
      <CAutocomplete
        value={value}
        label="dNumber"
        placeholder="drivers"
        options={options}
        onChange={onChange}
      />
      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton style={{ marginRight: 20 }} onClick={onClick} variant="gradient" color="success">
          {t("search")}
        </MDButton>
        <MDButton onClick={() => close(false)} variant="gradient" color="error">
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
