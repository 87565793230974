import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CCardHeader from "../../../../components/Custom/CCardHeader";
import { useStyles } from "../../styles";
import useData from "./data";
import { getCheckField, getTime } from "../../../../utilities/func";
import RenderOrder from "./components/RenderOrder";
import CAlertSnackbar from "../../../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../../../components/Custom/CAlerts/CLoadingSnackbar";
import CAlert from "../../../../components/Custom/CAlerts/CAlert";
import DriverOrderModal from "../ActiveOrders/components/DriverOrderModal";
import ShowOrderModal from "../ActiveOrders/components/ShowOrderModal";
import { StripeProvider } from "../../../../provider/StripeProvider";
import Index from "../../../../components/Custom/payments";

export default function OrderHistory() {
  const {
    orders,
    onActions,
    cAlert,
    setCAlert,
    loading,
    loadingSuccess,
    setLoadingSuccess,
    setOpenCAlert,
    openCAlert,
    onConfirmAction,
    cAlertInfo,
    driversOrder,
    openDriversOrder,
    onReset,
    showOrder,
    orderSelected,
    openPayment,
    setOpenPayment,
  } = useData();
  const { t } = useTranslation();
  const styles = useStyles();
  const dashboardSettings = useSelector(({ settings }) => settings);
  return (
    <Card style={styles.card}>
      <CCardHeader value="isOrderHistory" title="order_history" />
      <MDBox style={{ overflow: "auto", height: 516.7, padding: 5 }}>
        <RenderOrder
          isHeader
          streetNumber={`${t("street_number")},`}
          dNumber={t("driver")}
          street={t("street")}
          phoneNumber={t("phone")}
          city={t("city")}
          time={t("time")}
        />
        {orders.map((order) => {
          let street = getCheckField(order, "from", "street");
          let createdAt = getTime(order);
          let streetNumber = getCheckField(order, "from", "streetNumber");
          let city = getCheckField(order, "from", "city");
          let dNumber = getCheckField(order, "assignedDriver", "dNumber");
          let phoneNumber = getCheckField(order, "client", "phoneNumber");
          let background = order.status === "completed" ? "#057900" : "darkred";
          let { id } = order;

          return (
            <RenderOrder
              key={id}
              isPaymentCard={dashboardSettings.isPaymentCard}
              onClick={(type) => onActions(type, id)}
              streetNumber={streetNumber}
              dNumber={dNumber}
              street={street}
              background={background}
              phoneNumber={phoneNumber || "(###) ### ####"}
              city={city}
              time={createdAt}
            />
          );
        })}
      </MDBox>
      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />
      <CLoadingSnackbar open={loading} />
      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />
      <CAlert
        close={setOpenCAlert}
        open={openCAlert}
        title={cAlertInfo.title}
        subtitle={cAlertInfo.subtitle}
        isConfirm={onConfirmAction}
      />
      {openDriversOrder && (
        <DriverOrderModal data={driversOrder} open={openDriversOrder} close={onReset} />
      )}
      {showOrder && <ShowOrderModal data={orderSelected} open={showOrder} close={onReset} />}

      {openPayment && (
        <StripeProvider>
          <Index
            open={openPayment}
            close={setOpenPayment}
            title="payment_with_card"
            subtitle={orderSelected.invoiceId}
            order={orderSelected}
          />
        </StripeProvider>
      )}
    </Card>
  );
}
