import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "App";
import { MaterialUIControllerProvider } from "context";
import { Provider } from "react-redux";
import { businessName } from "./config";
import packageInfo  from "../package.json";
import "./utilities/translations/i18n";
import "./assets/custom.css";
import "mapbox-gl/dist/mapbox-gl.css";
import store from "./redux/config";

const container = document.getElementById("root");
const root = createRoot(container);

Sentry.init({
  dsn: "https://29c959fb7e874c3f9c57d91d3cb4076f@o1115766.ingest.sentry.io/6370241",
  release: `${businessName}-${packageInfo.version}`,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
