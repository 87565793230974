import React from "react";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import MDTypography from "../MDTypography";

export default function ClipboardText({
  text,
  copy,
  variant,
  color = undefined,
  fontSize = undefined,
  fontWeight = undefined,
}) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const copyTextToClipboard = async (textValue) => {
    if ("clipboard" in navigator) {
      setOpen(true);
      return navigator.clipboard.writeText(textValue);
    }
    return document.execCommand("copy", true, textValue);
  };

  return (
    <>
      <Tooltip title={t("copy_clipboard")}>
        <MDTypography
          variant={variant}
          onClick={() => copyTextToClipboard(copy || text)}
          style={{ color, cursor: "pointer", fontSize, fontWeight }}
        >
          {text}
        </MDTypography>
      </Tooltip>

      <Snackbar
        autoHideDuration={1500}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={() => setOpen(false)}
        message={t("copy_clipboard_ms")}
      />
    </>
  );
}
