import React from "react";
import { useTranslation } from "react-i18next";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

export default function TSText({ title = "", subtitle = "", variant = "h4" }) {
  const { t } = useTranslation();
  return (
    <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <MDTypography style={{ textTransform: "capitalize" }} variant={variant}>
        {title}
      </MDTypography>
      <MDTypography style={{ textTransform: "capitalize" }} variant="text">
        {t(subtitle)}
      </MDTypography>
    </MDBox>
  );
}
