import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CPaper from "./CPaper";

export default function CInputButton({
  value,
  onChange,
  icon2Click,
  icon2,
  iconStart,
  placeholder,
  marginBottom = 5,
}) {
  const { t } = useTranslation();
  return (
    <CPaper style={{ padding: 0, marginBottom }} elevation={2}>
      <Input
        value={value}
        onChange={({ target }) => onChange(target.value)}
        fullWidth
        disableUnderline
        style={{
          height: 40,
          borderRadius: 0,
          paddingLeft: iconStart ? 0 : 15,
          fontSize: 14,
        }}
        error={false}
        placeholder={t(placeholder)}
        startAdornment={
          iconStart ? (
            <IconButton color="secondary">
              <SearchIcon />
            </IconButton>
          ) : null
        }
        endAdornment={
          <>
            {icon2 ? (
              <IconButton onClick={icon2Click} color="secondary">
                {icon2}
              </IconButton>
            ) : null}
          </>
        }
      />
    </CPaper>
  );
}
