import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import "../../../assets/calendar.css";
import CAutocomplete from "../../../components/Custom/CAutocomplete";

export default function AddScheduleToDriverModal({
  open,
  close,
  onClick,
  scheduleList,
  model,
  onChange,
}) {
  const { t } = useTranslation();

  return (
    <CDialog title="schedule" open={open} close={close}>
      <br />
      <CAutocomplete
        isLabel
        value={model.schedule}
        label="label"
        name="schedule"
        placeholder="schedule"
        options={scheduleList}
        onChange={onChange}
      />
      <br />
      <MDBox
        sx={{ marginTop: 1, marginBottom: 1 }}
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <MDButton sx={{ width: 125 }} onClick={onClick} variant="gradient" color="info">
          {t("add")}
        </MDButton>
        <MDButton
          sx={{ width: 125 }}
          onClick={() => {
            close(false);
          }}
          variant="gradient"
          color="error"
          style={{ marginLeft: 20 }}
        >
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
