import { Dialog, DialogContent, Grid } from "@mui/material";
import React from "react";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import TextField from "@mui/material/TextField";
import { useMaterialUIController } from "../../../context";
import MDTypography from "../../MDTypography";
import { businessName, businessEmail } from "../../../config";
import ApiClient from "../../../api";
import { firestore } from "../../../firebase";
import CAlertSnackbar from "../CAlerts/CAlertSnackbar";
import { AlertModel } from "../../../utilities/model";
import { formatPhoneNumber, getCheckField, getUser } from "../../../utilities/func";
import MDBox from "../../MDBox";
import MDButton from "../../MDButton";
import MDInput from "../../MDInput";
import CLoadingSnackbar from "../CAlerts/CLoadingSnackbar";
import SearchOrder from "./components/SearchOrder";
import { TextMaskCustom } from "../ImputComponents";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default function CPayments({ open, close, title, subtitle, order, noOrder }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [loadingBtn, setLoadingBtn] = React.useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [checkoutError, setCheckoutError] = React.useState();
  const stripe = useStripe();
  const elements = useElements();
  const [price, setPrice] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [driverNumber, setDriverNumber] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [searchOrder, setSearchOrder] = React.useState({});

  React.useEffect(() => {
    if (order && order.id) {
      let dNumber = getCheckField(order, "assignedDriver", "dNumber");
      let phone = getCheckField(order, "client", "phoneNumber");
      setPhoneNumber(formatPhoneNumber(phone));
      setDriverNumber(dNumber);
    }
  }, [order]);

  React.useEffect(() => {
    if (searchOrder && searchOrder.id) {
      let dNumber = getCheckField(searchOrder, "assignedDriver", "dNumber");
      let phone = getCheckField(searchOrder, "client", "phoneNumber");
      setPhoneNumber(formatPhoneNumber(phone));
      setDriverNumber(dNumber);
    }
  }, [searchOrder]);

  const onClear = () => {
    setPrice("");
    setPhoneNumber("");
    setDriverNumber("");
    setNotes("");
    setLoadingBtn(false);
    setIsValid(false);
  };

  const handleCardDetailsChange = (ev) => {
    setIsValid(ev.complete);
    if (ev.error) {
      setCheckoutError(ev.error.message);
      setLoadingBtn(false);
    } else {
      setCheckoutError("");
      setLoadingBtn(false);
    }
  };

  const onPayment = async (ev) => {
    ev.preventDefault();

    if (elements == null) {
      return;
    }

    setLoadingBtn(true);

    const billingDetails = {
      name: businessName,
      email: businessEmail,
    };

    const cardElement = elements.getElement("card");
    try {
      const { data: clientSecret } = await ApiClient.post("stripe", {
        amount: price * 100,
      });
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails,
      });
      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setLoadingBtn(false);
        return;
      }

      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (error) {
        setCheckoutError(error.message);
        setLoadingBtn(false);
        return;
      }
      close(false);
      setIsPaymentSuccess(true);

      const timestamp = firestore.FieldValue.serverTimestamp();

      let payment = {};
      let isSearched = false;
      payment.amount = Number(price);
      payment.creditCardNumber = paymentMethodReq.paymentMethod.card.last4;
      payment.stripeStatus = paymentIntent.status;
      payment.stripeId = paymentIntent.id;
      payment.stripePaymentMethodId = paymentIntent.payment_method;

      if (order && order.id) {
        await firestore()
          .collection("orders")
          .doc(order.id)
          .update({
            paymentAt: timestamp,
            paymentBy: getUser(),
            creditCard: true,
            paymentSource: "system",
            payment,
            fare: Number(price),
          });
      }

      if (searchOrder && searchOrder.id) {
        isSearched = true;
        await firestore()
          .collection("orders")
          .doc(searchOrder.id)
          .update({
            paymentAt: timestamp,
            paymentBy: getUser(),
            creditCard: true,
            paymentSource: "system",
            payment,
            fare: Number(price),
          });
      }

      let phone = phoneNumber ? `+1${phoneNumber}` : "";

      let orderId = null;
      let invoiceId = null;

      if (order && order.id) {
        orderId = order.id ?? null;
        invoiceId = order.invoiceId ?? null;
      }

      if (searchOrder && searchOrder.id) {
        orderId = searchOrder.id ?? null;
        invoiceId = searchOrder.invoiceId ?? null;
      }

      await firestore()
        .collection("credit_card_payments")
        .add({
          createdAt: timestamp,
          createdBy: getUser(),
          paymentAmount: price ? Number(price) : 0,
          paymentDetail: paymentIntent,
          creditCardNumber: paymentMethodReq.paymentMethod.card.last4,
          notes,
          paymentSource: "system",
          phoneNumber: phone,
          driverNumber,
          orderId,
          invoiceId,
          isSearched,
        });

      setIsPaymentSuccess(true);
      if (phone.length) {
        await ApiClient.post("twilio/messages", {
          to: `+1${phoneNumber}`,
          body: `An amount has been charged to your card in the amount of ${price} per service of ${businessName}`,
        }).catch(() => {});
      }
      cardElement.clear();
      onClear();
    } catch (err) {
      setLoadingBtn(false);
      setCAlert({ open: true, type: "error", ms: err.message });
    }
  };

  const iframeStyles = {
    base: {
      color: !darkMode ? "#344767" : "#f0f2f5",
      fontSize: "16px",
      iconColor: !darkMode ? "#344767" : "#f0f2f5",
      "::placeholder": {
        color: !darkMode ? "#344767" : "#f0f2f5",
      },
    },
    invalid: {
      fontFamily: "Arial, sans-serif",
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true,
  };

  const onSelectOrder = (o) => {
    setSearchOrder(o);
  };

  return (
    <>
      <Dialog
        disableEscapeKeyDown
        keepMounted
        maxWidth="xl"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        onClose={() => close(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ backgroundColor: darkMode ? "#344767" : "#f0f2f5" }}>
          <MDTypography variant="h5">{t(title)}</MDTypography>
          {subtitle && (
            <>
              <MDTypography variant="text">
                {t("invoice_id")}: {t(subtitle)}
              </MDTypography>
              <br />
            </>
          )}
          <br />
          {noOrder && <SearchOrder onSelect={onSelectOrder} />}
          {checkoutError && <strong style={{ margin: 10 }}>{checkoutError}</strong>}
          {order && order.payment && (
            <strong style={{ margin: 10, color: "red" }}>
              {t("payment_active")} {formatter.format(order.payment.amount)}
            </strong>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div style={{ backgroundColor: "rgb(0,153,255,0.1)", padding: 10 }}>
                <CardElement options={cardElementOpts} onChange={handleCardDetailsChange} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <MDInput
                fullWidth
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder={t("amount")}
                label={t("amount")}
                name="amount"
                type="number"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <MDInput
                fullWidth
                value={driverNumber}
                onChange={(e) => setDriverNumber(e.target.value)}
                placeholder={t("driver")}
                label={t("driver")}
                name="driver"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                fullWidth
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder={`${t("phoneNumber")} (${t("confirmation_by_sms")})`}
                label={`${t("phoneNumber")} (${t("confirmation_by_sms")})`}
                name="phoneNumber"
                size="small"
                InputProps={{
                  inputComponent: TextMaskCustom,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                fullWidth
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder={t("notes")}
                label={t("notes")}
                name="notes"
                size="small"
              />
            </Grid>
          </Grid>

          <MDBox mt={5} display="flex" justifyContent="flex-end" alignItems="flex-end">
            <MDButton
              loading={loadingBtn}
              disabled={!price || Number(price) < 1 || !isValid}
              style={{ marginRight: 20 }}
              onClick={onPayment}
              variant="gradient"
              size="medium"
              color="info"
            >
              {t("accept")} ${price}
            </MDButton>

            <MDButton onClick={() => close(false)} variant="gradient" size="medium" color="error">
              {t("close")}
            </MDButton>
          </MDBox>
        </DialogContent>
      </Dialog>

      <CLoadingSnackbar
        isSuccess
        open={isPaymentSuccess}
        close={setIsPaymentSuccess}
        title="success"
        duration={1500}
      />
      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />
    </>
  );
}
