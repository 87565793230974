import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import { DriverRegisterModel } from "../../../utilities/model";
import MDTypography from "../../../components/MDTypography";

export default function DriverRegisterModal({
  open,
  close,
  model = new DriverRegisterModel(),
  onUpdate,
  onChange,
}) {
  const { t } = useTranslation();

  return (
    <CDialog title="info_updated" close={close} open={open}>
      <Grid spacing={1} container mt={2} pb={2}>
        <Grid item xs={6}>
          <MDInput
            fullWidth
            value={model.firstName}
            onChange={onChange}
            name="firstName"
            style={{ marginBottom: 25 }}
            label={`${t("firstName")}*`}
            type="text"
          />
        </Grid>

        <Grid item xs={6}>
          <MDInput
            fullWidth
            value={model.lastName}
            onChange={onChange}
            name="lastName"
            style={{ marginBottom: 25 }}
            label={`${t("lastName")}*`}
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <MDInput
            fullWidth
            value={model.email}
            onChange={onChange}
            name="email"
            style={{ marginBottom: 25 }}
            label={`${t("email")}*`}
            type="text"
          />
        </Grid>

        <Grid item xs={6}>
          <MDInput
            fullWidth
            style={{ marginBottom: 25 }}
            label={t("phoneNumber")}
            name="phoneNumber"
            value={model.phoneNumber}
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={6}>
          <MDInput
            fullWidth
            value={model.licenseState || ""}
            onChange={onChange}
            name="licenseState"
            style={{ marginBottom: 25 }}
            label={t("licenseState")}
            type="text"
          />
        </Grid>

        <Grid item xs={6}>
          <MDInput
            fullWidth
            value={model.registration || ""}
            onChange={onChange}
            name="registration"
            style={{ marginBottom: 25 }}
            label={t("registration")}
            type="text"
          />
        </Grid>

        <Grid item xs={6}>
          <MDInput
            fullWidth
            value={model.insurance || ""}
            onChange={onChange}
            name="insurance"
            style={{ marginBottom: 25 }}
            label={t("insurance")}
            type="text"
          />
        </Grid>
      </Grid>

      <Grid spacing={1} container pb={4}>
        {model.driverLicensePhoto && (
          <Grid item xs={5}>
            <MDTypography style={{ textAlign: "center" }} variant="h5">
              {t("driver license")}
            </MDTypography>
            <img
              style={{ objectFit: "contain" }}
              src={model.driverLicensePhoto}
              width="100%"
              height={600}
              alt="driverLicensePhoto"
            />
          </Grid>
        )}
        <Grid item xs={1} />
        {model.driverLicensePhoto && (
          <Grid item xs={5}>
            <MDTypography style={{ textAlign: "center" }} variant="h5">
              {t("license plate")}
            </MDTypography>
            <img
              style={{ objectFit: "contain" }}
              src={model.licensePlatePhoto}
              width="100%"
              height={600}
              alt="driverLicensePhoto"
            />
          </Grid>
        )}
      </Grid>

      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton onClick={onUpdate} variant="gradient" color="success">
          {t("update")}
        </MDButton>
        <MDButton
          onClick={() => close(false)}
          variant="gradient"
          color="error"
          style={{ marginLeft: 20 }}
        >
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
