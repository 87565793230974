import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/login-1.jpg";
import { useTranslation } from "react-i18next";
import { firebase } from "../../../firebase";
import CAlert from "../../../components/Custom/CAlerts/CAlert";

export default function Login() {
  const [rememberMe, setRememberMe] = useState(false);
  const [model, setModel] = React.useState({ email: "", password: "" });
  const [alertMs, setAlertMs] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const { t } = useTranslation();
  const handleSetRememberMe = () => {
    setRememberMe(!rememberMe);
    if (!rememberMe) {
      localStorage.setItem("rememberMe", "true");
    } else {
      localStorage.removeItem("rememberMe");
    }
  };

  React.useEffect(() => {
    const emailLocalStorage = localStorage.getItem("email");
    const passwordLocalStorage = localStorage.getItem("password");
    const rememberMeStorage = localStorage.getItem("rememberMe");
    if (emailLocalStorage && rememberMeStorage) {
      setRememberMe(true);
      setModel((prevState) => ({
        ...prevState,
        email: emailLocalStorage,
        password: passwordLocalStorage,
      }));
    }
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setModel((prevState) => ({ ...prevState, [name]: value }));
  };

  const onUserLogin = () => {
    if (model.email !== "" && model.password !== "") {
      if (rememberMe) {
        localStorage.setItem("email", model.email);
        localStorage.setItem("password", model.password);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
      setLoading(true);
      firebase
        .auth()
        .signInWithEmailAndPassword(model.email, model.password)
        .catch((e) => {
          setOpenAlert(true);
          setAlertMs(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <IllustrationLayout
      title={process.env.REACT_APP_BUSINESS_NAME}
      description={t("login_description")}
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            value={model.email}
            type="email"
            label={t("email")}
            name="email"
            fullWidth
            onChange={onChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={model.password}
            onChange={onChange}
            name="password"
            type="password"
            label={t("password")}
            fullWidth
          />
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;{t("rememberMe")}
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            loading={loading}
            onClick={onUserLogin}
            variant="gradient"
            color="info"
            size="large"
            fullWidth
          >
            {t("login")}
          </MDButton>
        </MDBox>
      </MDBox>
      <CAlert title="error" subtitle={alertMs} open={openAlert} close={setOpenAlert} />
    </IllustrationLayout>
  );
}
