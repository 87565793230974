import * as React from "react";
import { useTranslation } from "react-i18next";
import { Grid, TableRow, IconButton, TableHead, TableContainer, Stack } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import DeleteIcon from "@mui/icons-material/Delete";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import CPaper from "../../../components/Custom/CPaper";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { useMaterialUIController } from "../../../context";
import CLoadingSnackbar from "../../../components/Custom/CAlerts/CLoadingSnackbar";
import { firebase, firestore } from "../../../firebase";
import TSSwitch from "../../../components/Custom/TSSwitch";
import { useColorSelected } from "../../../utilities/func";

const input = {
  inputProps: {
    autoComplete: "",
  },
  fullWidth: true,
};

export default function PriceList({ showTaxiRates }) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      from: "",
      to: "",
      price: 0,
    },
  });

  const [isActive, setIsActive] = React.useState(showTaxiRates);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const getData = async () => {
    const ref = firestore().collection("settings").doc("taxiRates");
    const get = await ref.get();
    if (get.exists) {
      const docs = get.data();
      setData(docs.data.reverse());
    } else {
      ref.set({ data: [] }).catch(() => {
        // console.log(e);
      });
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const onSubmit = (model) => {
    setLoading(true);
    firestore()
      .collection("settings")
      .doc("taxiRates")
      .update({
        data: firebase.firestore.FieldValue.arrayUnion(model),
      })
      .then(() => {
        setLoading(false);
        reset();
        const newData = _.uniqWith([model].concat(data), _.isEqual);
        setData(newData);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onDelete = (item) => {
    firestore()
      .collection("settings")
      .doc("taxiRates")
      .update({
        data: firebase.firestore.FieldValue.arrayRemove(item),
      })
      .then(() => {
        getData();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onActive = (value) => {
    setIsActive(value.target.checked);
    firestore()
      .collection("settings")
      .doc("driver")
      .update({
        showTaxiRates: value.target.checked,
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <CPaper style={{ marginTop: 20, padding: 25 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Controller
            name="from"
            rules={{ required: true }}
            control={control}
            render={({ field }) => <MDInput {...input} {...field} label={t("from")} />}
          />
          {errors.from?.type === "required" && <b role="alert">{t("field_required")}</b>}
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name="to"
            rules={{ required: true }}
            control={control}
            render={({ field }) => <MDInput {...input} {...field} label={t("to")} />}
          />
          {errors.to?.type === "required" && <b role="alert">{t("field_required")}</b>}
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name="price"
            rules={{
              required: true,
              min: 1,
            }}
            control={control}
            render={({ field }) => (
              <MDInput {...input} {...field} type="number" label={t("price")} />
            )}
          />
          {errors.price?.type === "required" && <b role="alert">{t("field_required")}</b>}
          {errors.price?.type === "min" && <b role="alert">{t("field_required")}</b>}
        </Grid>
        <Grid item xs={12} md={3}>
          <Stack direction="row" alignItems="flex-start" justifyContent="center">
            <MDButton onClick={handleSubmit(onSubmit)} variant="gradient" size="large" color="info">
              {t("add")}
            </MDButton>
            <TSSwitch direction="row" subtitle="active" value={isActive} onChange={onActive} />
          </Stack>
        </Grid>
      </Grid>

      <TableContainer style={{ marginTop: 35 }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ color: useColorSelected(darkMode, false, false, true) }}
                align="left"
              >
                {t("from")}
              </TableCell>
              <TableCell
                style={{ color: useColorSelected(darkMode, false, false, true) }}
                align="left"
              >
                {t("to")}
              </TableCell>
              <TableCell
                style={{ color: useColorSelected(darkMode, false, false, true) }}
                align="left"
              >
                {t("price")}
              </TableCell>
              <TableCell
                style={{ color: useColorSelected(darkMode, false, false, true) }}
                align="left"
              >
                {t("delete")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={row.from + row.to + row.price}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  style={{ color: useColorSelected(darkMode, false, false, true) }}
                  align="left"
                >
                  {row.from}
                </TableCell>
                <TableCell
                  style={{ color: useColorSelected(darkMode, false, false, true) }}
                  align="left"
                >
                  {row.to}
                </TableCell>
                <TableCell
                  style={{ color: useColorSelected(darkMode, false, false, true) }}
                  align="left"
                >
                  {row.price}
                </TableCell>
                <TableCell
                  style={{ color: useColorSelected(darkMode, false, false, true) }}
                  align="left"
                >
                  <IconButton onClick={() => onDelete(row, index)} aria-label="delete">
                    <DeleteIcon style={{ color: useColorSelected(darkMode, false, false, true) }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CLoadingSnackbar open={loading} />
    </CPaper>
  );
}
