import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import CPaper from "../../../components/Custom/CPaper";
import DataTable from "../../../examples/Tables/DataTable";

export default function PaymentsRecord({ open, close, data, model }) {
  const { t } = useTranslation();

  return (
    <CDialog title="payments" subtitle={model.dNumber} close={() => close(false)} open={open}>
      <br />
      <br />
      <CPaper style={{ marginBottom: 25 }}>
        <DataTable
          noEndBorder
          PageSize={20}
          entriesPerPage={false}
          showTotalEntries={false}
          table={{
            columns: [
              { Header: "createdAt", accessor: "createdAt" },
              { Header: "amount", accessor: "amount" },
              { Header: "payment method", accessor: "paymentMethod" },
              { Header: "stripeId", accessor: "stripeId" },
              { Header: "stripe Payment MethodId", accessor: "stripePaymentMethodId" },
              { Header: "stripe status", accessor: "stripeStatus" },
            ],
            rows: data,
          }}
        />
      </CPaper>
      <br />
      <br />

      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton
          onClick={() => close(false)}
          variant="gradient"
          color="error"
          style={{ marginLeft: 20 }}
        >
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
