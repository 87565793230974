// eslint-disable-next-line max-classes-per-file
class AlertModel {
  constructor() {
    this.open = false;
    this.type = "info";
    this.ms = "";
  }
}

export class AnalyticsModel {
  constructor() {
    this.completed = 0;
    this.reassigned = 0;
    this.cancelled = 0;
    this.money = 0;
    this.assigned = 0;
    this.unassigned = 0;
    this.inProcess = 0;
    this.passOrder = 0;
    this.app = 0;
    this.system = 0;
  }
}

export class FilterModel {
  constructor() {
    this.driver = null;
    this.date = null;
    this.credit = false;
    this.voucher = false;
    this.range = false;
    this.allDrivers = true;
    this.isPassOrder = false;
    this.from = "";
    this.to = "";
    this.phoneNumber = "";
    this.email = "";
    this.invoiceId = "";
  }
}

class ProfileModel {
  constructor() {
    this.id = null;
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.phoneNumber = "";
    this.status = "";
    this.email = "";
    this.password = "";
    this.role = "";
    this.blockedRoutes = [];
    this.showDriverNumber = false;
    this.allowPassOrder = false;
    this.allowCancelOrder = false;
    this.allowEditDriver = false;
    this.allowAssignOrder = false;
  }
}

export class DriverRegisterModel {
  constructor() {
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.phoneNumber = "";
    this.dNumber = "";
    this.password = "";
    this.vehicleType = "car";
    this.licenseState = "";
    this.registration = "";
    this.insurance = "";
  }
}

export class CompanyModel {
  constructor() {
    this.companyName = "";
    this.address = "";
    this.city = "";
    this.country = "";
    this.email = "";
  }
}

export class SelectionModel {
  constructor() {
    this.max_active_orders = 0;
    this.retain_spot_always = false;
    this.times = [];
  }
}

class DriverModel {
  constructor() {
    this.id = null;
    this.driverId = null;
    this.coordinates = null;
    this.currentDevice = null;
    this.lastOrderDate = null;
    this.isAutoOrder = null;
    this.lastAssignedOrderDate = null;
    this.automatic = "";
    this.vehicleType = "";
    this.dNumber = "";
    this.label = "";
    this.firstName = "";
    this.status = "";
    this.drivingStatus = "";
    this.paymentStatus = "N/A";
    this.fcmToken = "";
    this.lastName = "";
    this.phoneNumber = "";
    this.driverLicense = "";
    this.licenseState = "";
    this.licensePlate = "";
    this.license = "";
    this.email = "";
    this.isVisibleUpdated = false;
    this.hasPayment = false;
    this.retainSpot = false;
    this.geolocationStatus = 0;
    this.withSchedule = false;
    this.autoMarkedAsPaid = false;
    this.credit_card_payment = false;
    this.hasPaymentReader = false;
    this.hasPayment = false;
    this.isVisibleUpdated = false;
    this.isStatus = false;
    this.isDrivingStatus = false;
    this.isGeofence = false;
    this.hasAutoOrders = false;
    this.hasPendingPayment = false;
    this.payment = {};
  }
}

class SettingsModel {
  constructor() {
    this.geoapifyApiKey = "";
    this.mapBoxApiKey = "";
    this.gmapsApiKey = "";
    this.mapBoxBbox = "";
    this.geoapifyFilter = "";
    this.hereApiKey = "";
    this.orderCreationType = "address";
    this.autocompleteCountry = "us";
    this.credit_card_payment = "";
    this.autocompleteLimit = 10;
    this.mapCenter = {
      lat: 39.9474147,
      lng: -75.0758626,
    };
    this.autocompleteType = "osrm";
    this.dispatcherCanAssignDriver = true;
    this.showOrderActions = false;
    this.showOrderPassAction = false;
    this.dispatcherCanAssingDriver = true;
    this.isRetainSpotPassOrder = true;
    this.isRetainSpotCancelOrder = true;
    this.hasAssignOrder = true;
    this.isPaymentCard = true;
    this.isFare = true;
    this.isDispatcherPassOrder = false;
    this.isDispatcherCancelOrder = false;
    this.isDispatcherCancelOrderByRequest = false;
    this.isDispatcherPayDriver = false;
    this.isDispatcherChangeDriverStatus = false;
    this.isDuplicateIcon = false;
    this.isPaymentIcon = false;
    this.withServices = true;
    this.version = "";
  }
}

export class RiderAppModel {
  constructor() {
    this.hasAppointment = true;
    this.hasPenaltyOrders = false;
    this.hasEmailVerified = true;
    this.hasPhoneVerified = true;
    this.penaltyOrdersTime = 0;
    this.policyUrl = "";
    this.appointmentOffset = 10;
    this.appointmentsLimit = 2;
    this.apiKeyActive = "here";
    this.countryCode = "USA";
    this.autocompleteLimit = "6";
    this.circleRadio = "5000";
    this.circleRadioTo = "15000";
    this.hereApiKey = "";
    this.geoapiFyApiKey = "";
    this.mapboxApiKey = "";
    this.mapBoxBbox = "-76.59390119393204,39.26613843962491,-74.08212601452804,40.42154387378838";
    this.mapCenter = {
      latitude: 39.9474147,
      longitude: -75.0758626,
    };
    this.lat = 39.9474147;
    this.lng = -75.0758626;
  }
}

export class DriverAppModel {
  constructor() {
    this.meters = 50;
    this.fareLimit = 8;
    this.orderSubscriptionDelay = 0;
    this.geofenceRadius = 200;
    this.etaRefreshTime = 120;
    this.isNetwork = false;

    this.hasFare = true;
    this.hasZone = false;
    this.hasAutoOrders = true;
    this.isOrderColorAfterRetry = true;

    this.hasEta = true;
    this.hasEtaRefresh = true;
    this.hasEtaSubscription = false;
    this.hasEtaOrder = false;

    this.isRequestCallClient = true;
    this.isRequestCancellation = true;
    this.isRequestPassOrder = true;
    this.isGeofence = true;

    this.timeAfterPickup = 40;
    this.timeReturnOrder = 10;
    this.timeReadyPickup = 2;
    this.hasTimeAfterPickup = true;

    this.hasMoreThanOne = true;
    this.hasPrivateNotes = false;
    this.isHiddenPhone = false;
    this.hasFullAddress = false;
    this.hasCallClient = true;
    this.hasMarkerStreet = true;
    this.hasStreet = true;
    this.hasReturnOrder = false;
    this.hasLastOrderClient = true;
    this.hasPaypalForDriverPay = false;
    this.hasStripeForDriverPay = false;

    this.hasPayCabs = false;
    this.hasPayment = true;
    this.stripePublishingKey = "";
    this.stripeMerchantId = "";

    this.mapCenter = {
      latitude: 39.9474147,
      longitude: -75.0758626,
    };
    this.lat = 39.9474147;
    this.lng = -75.0758626;
  }
}

class ClientModel {
  constructor() {
    this.displayName = "";
    this.firstName = "";
    this.lastName = "";
    this.phoneNumber = "";
    this.email = "";
    this.lastAddresses = null;
    this.stats = {};
    this.id = null;
  }
}

class AddressModel {
  constructor() {
    this.address = "";
    this.city = "";
    this.state = "";
    this.street = "";
    this.streetNumber = "";
    this.suite = "";
  }
}

class ServiceModel {
  constructor() {
    this.description = "";
    this.price = 0;

  }
}

class OrderModel {
  constructor() {
    this.status = "unassigned";
    this.call = null;
    this.fare = 0;
    this.editsCount = 0;
    this.notes = "";
    this.invoiceId = "";
    this.vehicleType = "car";
    this.has_voucher = false;
    this.isPaid = false;
    this.dispatcher = null;
    this.createdBy = null;
    this.createdAt = null;
    this.driver = null;
    this.isCancellable = false;
    this.is_cancellable = false;
    this.client = new ClientModel();
    this.from = new AddressModel();
    this.to = new AddressModel();
    this.service = new ServiceModel();
  }
}

export { AlertModel, ProfileModel, SettingsModel, DriverModel, OrderModel, AddressModel };
