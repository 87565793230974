import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import _ from "lodash";
import { getI18n } from "react-i18next";
import Swal from "sweetalert2";
import packageInfo from "../../../../../package.json";
import { firebase, firestore } from "../../../../firebase";
import { AlertModel, OrderModel } from "../../../../utilities/model";
import {
  checkActiveCallByAddress,
  checkActiveCallByPhone,
  getClientByPhone,
  maskString
} from "./func";
import { getOriginMarkerPosition, postOrderSelected } from "../../../../redux/actions";
import {
  CaptureException,
  DuplicateOrder,
  formatPhoneNumberClear,
  getCheckField,
  getCoordinates,
  getUser, onPhoneBlacklist
} from "../../../../utilities/func";

export default function useData() {
  const drivers = useSelector(({ driverList }) => driverList);
  const dashboardSettings = useSelector(({ settings }) => settings);
  const [orderModel, setOrderModel] = useState(new OrderModel());
  const [activeOrderModel, setActiveOrderModel] = useState(new OrderModel());
  const [activeOrderDialog, setActiveOrderDialog] = useState(false);
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [loading, setLoading] = React.useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [loadingPhone, setLoadingPhone] = React.useState(false);
  const [lastFromAddress, setLastFromAddress] = useState(null);
  const [lastToAddress, setLastToAddress] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [autocompleteFromValue, setAutocompleteFromValue] = React.useState(null);
  const [autocompleteToValue, setAutocompleteToValue] = React.useState(null);
  const [isPhoneBlock, setIsPhoneBlock] = useState(false);
  const [phoneBlockNotes, setPhoneBlockNotes] = useState("");
  const [isDestination, setIsDestination] = useState(false);
  const [isAppointment, setIsAppointment] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentOffset, setAppointmentOffset] = useState(10);
  const [servicesList, setServicesList] = useState([]);
  const dispatch = useDispatch();
  const OrderSelected = useSelector(({ orderSelected }) => orderSelected);

  React.useEffect(() => {
    if (OrderSelected.id || OrderSelected.call) {
      let editPhoneNumber = getCheckField(OrderSelected, "client", "phoneNumber");
      let editFrom = getCheckField(OrderSelected, "from", "address");
      let editTo = getCheckField(OrderSelected, "from", "address");
      if (editPhoneNumber) {
        setPhoneNumber(formatPhoneNumberClear(editPhoneNumber));
        getClientByPhone(editPhoneNumber).then((client) => {
          if (client) {
            setOrderModel((prevState) => ({ ...prevState, client }));
          }
        });
      }

      if (OrderSelected.driver) {
        const driverDoc = _.find(drivers, (o) => o.id === OrderSelected.driver.id);
        if (driverDoc) {
          OrderSelected.driver = {
            id: driverDoc.id,
            dNumber: driverDoc.dNumber || ""
          };
        }
      }

      if (editFrom) {
        setAutocompleteFromValue(OrderSelected.from);
      }
      if (editTo) {
        setAutocompleteToValue(OrderSelected.to);
      }

      if (OrderSelected.appointment) {
        setAppointmentDate(OrderSelected.appointment);
        setAppointmentOffset(OrderSelected.offset);
        setIsAppointment(true);
      }

      setOrderModel(OrderSelected);
    }
  }, [OrderSelected]);

  React.useEffect(() => {
    if (dashboardSettings?.withServices) {
      getServicesData();
    }
  }, [dashboardSettings?.withServices]);


  const getServicesData = async () => {
    const ref = firestore().collection("settings").doc("servicesList");
    const get = await ref.get();
    if (get.exists) {
      const docs = get.data();
      setServicesList(docs.data.reverse());
    }
  };

  const onCancel = () => {
    const model = new OrderModel();
    setOrderModel(model);
    setLastFromAddress(null);
    setLastToAddress(null);
    setAutocompleteFromValue(null);
    setAutocompleteToValue(null);
    setPhoneNumber("");
    setAppointmentDate("");
    setAppointmentOffset(10);
    setIsDestination(false);
    setIsAppointment(false);
    dispatch(getOriginMarkerPosition(null));
    dispatch(postOrderSelected(new OrderModel()));
    setActiveOrderModel(new OrderModel());
    setActiveOrderDialog(false);
  };

  const onCheckActiveCall = async ({ type, phone, address }) => {
    if (type === "phone") {
      const verifiedCall = await checkActiveCallByPhone(phone);
      if (verifiedCall && verifiedCall.id) {
        setActiveOrderModel(verifiedCall);
        setActiveOrderDialog(true);
      }
    }

    if (type === "address") {
      const verifiedCall = await checkActiveCallByAddress(address);
      if (verifiedCall && verifiedCall.id) {
        setActiveOrderModel(verifiedCall);
        setActiveOrderDialog(true);
      }
    }
  };

  const onClientPhone = async (phone) => {
    try {
      if (phone.length !== 10) return;
      const parsedPhoneNumber = `+1${phoneNumber}`;
      setLoadingPhone(true);
      await onCheckActiveCall({ type: "phone", phone: parsedPhoneNumber });
      const client = await getClientByPhone(parsedPhoneNumber);
      setLoadingPhone(false);
      setAutocompleteFromValue(null);
      setAutocompleteToValue(null);
      if (client) {
        const model = new OrderModel();
        model.client = client;
        setIsPhoneBlock(client.blocked);
        setPhoneBlockNotes(client.blockedNotes);
        setOrderModel(model);
      } else {
        const model = new OrderModel();
        setOrderModel(model);
      }
    } catch (e) {
      CaptureException(e, "Error-DCO-01");
    }
  };

  const onDuplicateOrder = () => {
    try {
      DuplicateOrder(activeOrderModel)
        .then(() => {
          setLoadingSuccess(true);
          onCancel();
        })
        .catch((e) => {
          CaptureException(e, "CO");
          setCAlert({ open: true, type: "error", ms: e.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      CaptureException(e, "AO");
      setLoading(false);
      setCAlert({ open: true, type: "error", ms: e.message });
    }
  };

  const onChangeOrder = (e, select, sName) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setOrderModel((prevState) => ({ ...prevState, [name]: checked }));
    } else if (select) {
      setOrderModel((prevState) => ({ ...prevState, [sName]: select }));
    } else {
      setOrderModel((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const onChangeFromLastAddress = (e, select) => {
    try {
      setLastFromAddress(select);
      const { lastAddresses } = orderModel.client;
      const selectedAddress = lastAddresses[select.id] || null;

      if (selectedAddress) {
        selectedAddress.latitude = selectedAddress.coordinates.latitude;
        selectedAddress.longitude = selectedAddress.coordinates.longitude;
        selectedAddress.streetNumber = selectedAddress.street_number;
        dispatch(
          getOriginMarkerPosition({
            lat: selectedAddress.latitude,
            lng: selectedAddress.longitude
          })
        );
        onCheckActiveCall({ type: "address", address: selectedAddress }).catch(() => {
        });
        setAutocompleteFromValue(selectedAddress);
        setOrderModel((prevState) => ({ ...prevState, from: { ...selectedAddress } }));
      }
    } catch (error) {
      CaptureException(error, "Error-DCO-04");
    }
  };

  const onChangeToLastAddress = (e, select) => {
    try {
      setLastToAddress(select);
      const { lastAddresses } = orderModel.client;
      const selectedAddress = lastAddresses[select.id] || null;

      if (selectedAddress) {
        selectedAddress.latitude = selectedAddress.coordinates.latitude;
        selectedAddress.longitude = selectedAddress.coordinates.longitude;
        dispatch(
          getOriginMarkerPosition({
            lat: selectedAddress.latitude,
            lng: selectedAddress.longitude
          })
        );
        selectedAddress.streetNumber = selectedAddress.street_number;
        setAutocompleteToValue(selectedAddress);
        setOrderModel((prevState) => ({ ...prevState, to: { ...selectedAddress } }));
      }
    } catch (error) {
      Sentry.captureException(error, "Error-DCO-05");
    }
  };

  const onChangeAddress = (e, type) => {
    const { name, value } = e.target;
    setOrderModel((prevState) => ({
      ...prevState,
      [type]: { ...prevState[type], [name]: value }
    }));
  };

  const onSelectPlace = async (place, name) => {
    await onCheckActiveCall({ type: "address", address: place });
    dispatch(
      getOriginMarkerPosition({
        lat: place.latitude,
        lng: place.longitude
      })
    );
    setOrderModel((prevState) => ({ ...prevState, [name]: { ...place } }));
  };

  const changeSelectedCallStatus = (call, status, processing = false) => {
    const data = processing ? { status, processingStatus: status } : { status };
    return firestore().collection("calls").doc(call.id).update(data);
  };

  const createOrder = (order) => {
    try {
      const { call } = order;
      firestore()
        .collection("orders")
        .add(order)
        .then((orderRef) =>
          call && call.id ? changeSelectedCallStatus(call, "complete", true) : orderRef
        )
        .then(() => {
          setLoading(false);
          setLoadingSuccess(true);
          onCancel();
        })
        .catch((error) => {
          setLoading(false);
          CaptureException(error, "Error-DCO-06");
          setCAlert({ open: true, type: "error", ms: error.message });
        });
    } catch (e) {
      firebase
        .firestore("errors")
        .doc("dashboard")
        .collection("logs")
        .add(JSON.stringify(order))
        .catch(() => {
        });
      CaptureException(e, "Error-DCO-06");
      setCAlert({ open: true, type: "error", ms: e.message });
    }
  };

  const editOrder = (order) => {
    const { id, call, client, editsCount, fare, createdBy, createdAt, ...orderEdits } = order;
    const newOrder = {
      editsCount: Number(editsCount) + 1,
      editedBy: getUser(),
      editedAt: firebase.firestore.FieldValue.serverTimestamp(),
      ...orderEdits
    };

    firestore()
      .collection("orders")
      .doc(id)
      .set(newOrder, { merge: true })
      .then(() => {
        setLoading(false);
        setLoadingSuccess(true);
        onCancel();
      })
      .catch((error) => {
        CaptureException(error, "Error-DCO-07");
        setLoading(false);
        setCAlert({ open: true, type: "error", ms: error.message });
      });
  };

  const createAppointment = (order) => {
    try {
      const { call } = order;
      const appointmentData = {
        offset: appointmentOffset,
        order,
        date: new Date(appointmentDate),
        status: "pending",
        createdBy: order.createdBy,
        createdAt: order.createdAt
      };

      firestore()
        .collection("appointments")
        .add(appointmentData)
        .then((orderRef) =>
          call && call.id ? changeSelectedCallStatus(call, "complete", true) : orderRef
        )
        .then(() => {
          setLoading(false);
          setLoadingSuccess(true);
          onCancel();
        })
        .catch((error) => {
          CaptureException(error, "Error-DCO-08");
          setLoading(false);
          setCAlert({ open: true, type: "error", ms: error.message });
        });
    } catch (e) {
      CaptureException(e, "Error-DCO-08");
      setCAlert({ open: true, type: "error", ms: e.message });
    }
  };

  const editAppointment = (order) => {
    let newOrder = { ...order };

    delete newOrder.appointment;
    delete newOrder.offset;

    const appointmentData = {
      offset: appointmentOffset,
      order: newOrder,
      date: new Date(appointmentDate),
      status: "pending",
      editedBy: getUser(),
      editedAt: firebase.firestore.FieldValue.serverTimestamp(),
      edited: true
    };

    firestore()
      .collection("appointments")
      .doc(newOrder.id)
      .set(appointmentData, { merge: true })
      .then(() => {
        setLoading(false);
        setLoadingSuccess(true);
        onCancel();
      })
      .catch((error) => {
        CaptureException(error, "Error-DCO-09");
        setLoading(false);
        setCAlert({ open: true, type: "error", ms: error.message });
      });
  };

  const isCall = (call) =>
    call && call.id ? { id: call.id, startedAt: call.start ? call.start : null } : null;


  const onCreate = () => {
    try {
      setLoading(true);
      const order = { ...orderModel };
      const mode = order.id ? "edit" : "create";
      const call = isCall(OrderSelected.call);
      order.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      order.createdBy = getUser();
      order.dispatcher = getUser();

      const parsedPhoneNumber = phoneNumber ? `+1${phoneNumber}` : null;

      delete order.client.lastAddresses;

      let service = null;


      if (order?.service?.description?.length) {
        service = {
          description: order.service?.description,
          price: order.service?.price
        };
      }

      const orderData = {
        ...order,
        call,
        service,
        client: {
          isNew: !!order.client.id,
          id: order.client.id,
          firstName: order.client.firstName,
          lastName: order.client.lastName,
          displayName: order.client.displayName,
          email: order.client.email,
          phoneNumber: parsedPhoneNumber
        },
        system: packageInfo.version,
        source: "system",
        isPassOrder: false,
        driver: order.driver ? { id: order.driver.id } : null,
        fare: Number(order.fare),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        from: {
          address: order.from.address,
          suite: "",
          street_number: order.from.streetNumber ?? "",
          streetNumber: order.from.streetNumber ?? "",
          maskedStreetNumber: maskString(order.from.streetNumber),
          street: order.from.street,
          city: order.from.city,
          coordinates: getCoordinates(order, "from")
        },
        to: {
          address: order.to.address,
          suite: "",
          street_number: order.to.streetNumber ?? "",
          streetNumber: order.to.streetNumber ?? "",
          street: order.to.street,
          city: order.to.city,
          coordinates: getCoordinates(order, "to")
        }
      };

      if (!isAppointment && orderData.driver) {
        let driverId = orderData.driver.id;
        const driverDoc = _.find(drivers, (o) => o.id === driverId);
        if (driverDoc) {
          orderData.assignedDriver = {
            id: driverId,
            fcmToken: driverDoc.fcmToken || "",
            dNumber: driverDoc.dNumber || "",
            displayName: driverDoc.displayName,
            phoneNumber: driverDoc.phoneNumber,
            assignedAt: firebase.firestore.FieldValue.serverTimestamp()
          };
          orderData.assignedAt = firebase.firestore.FieldValue.serverTimestamp();
          orderData.status = "assigned";
          orderData.isPassOrder = true;
        }
      }
      if (mode === "create") {
        if (isAppointment) {
          createAppointment(orderData);
        } else {
          createOrder(orderData);
        }
      }

      if (mode === "edit") {
        if (isAppointment) {
          editAppointment(orderData);
        } else {
          editOrder(orderData);
        }
      }
    } catch (e) {
      setLoading(false);
      setCAlert({ open: true, type: "error", ms: e.message });
      CaptureException(e, "Error-DCO-10");
    }
  };

  const onBlockUnblockClient = async () => {
    let clientId = orderModel.client.id;
    if (clientId) {
      let { t } = getI18n();
      const { isConfirmed, value: notes } = await Swal.fire({
        title: `${!isPhoneBlock ? t("add") : t("delete")} ${t("this phone to the blacklist")}`,
        text: !isPhoneBlock ? t("add some notes (optional)") : undefined,
        input: !isPhoneBlock ? "text" : undefined,
        inputPlaceholder: t("write something..."),
        confirmButtonColor: "#a80000",
        showCancelButton: true,
        cancelButtonText: t("close"),
        confirmButtonText: !isPhoneBlock ? t("block") : t("remove_lock"),
        allowEscapeKey: false,
        allowOutsideClick: false
      });
      if (isConfirmed) {
        setLoading(true);
        setIsPhoneBlock(!isPhoneBlock);

        setPhoneBlockNotes(notes ?? "");

        onPhoneBlacklist(orderModel.client, setLoading, t, !isPhoneBlock);

        firestore()
          .collection("clients")
          .doc(clientId)
          .set(
            { blockedNotes: notes, blocked: !isPhoneBlock, blackListed: !isPhoneBlock },
            { merge: true }
          )
          .then(() => {
            setLoadingSuccess(true);
          })
          .catch((error) => {
            CaptureException(error, "Error-DCO-11");
            setCAlert({ open: true, type: "error", ms: error.message });
          })
          .finally(() => setLoading(false));
      }
    }
  };

  const onDeleteActiveOrder = (data) => {
    const cancelledBy = getUser();
    setLoading(true);
    firestore()
      .collection("orders")
      .doc(data.id)
      .update({ status: "cancelled", cancellationSource: "dashboard", cancelledBy })
      .then(() => {
        setLoadingSuccess(true);
        setLoading(false);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-03");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
        setActiveOrderDialog(false);
      });
  };

  return {
    onCancel,
    onCreate,
    onClientPhone,
    activeOrderDialog,
    setActiveOrderDialog,
    isPhoneBlock,
    phoneBlockNotes,
    setIsPhoneBlock,
    activeOrderModel,
    onDuplicateOrder,
    cAlert,
    setCAlert,
    loading,
    orderModel,
    onChangeOrder,
    onChangeFromLastAddress,
    lastFromAddress,
    loadingPhone,
    onSelectPlace,
    onChangeAddress,
    autocompleteFromValue,
    setAutocompleteFromValue,
    autocompleteToValue,
    setAutocompleteToValue,
    onChangeToLastAddress,
    lastToAddress,
    drivers,
    isDestination,
    setIsDestination,
    isAppointment,
    setIsAppointment,
    appointmentDate,
    setAppointmentDate,
    appointmentOffset,
    setAppointmentOffset,
    phoneNumber,
    setPhoneNumber,
    loadingSuccess,
    setLoadingSuccess,
    onBlockUnblockClient,
    onDeleteActiveOrder,
    servicesList
  };
}
