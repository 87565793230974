import * as React from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import { setupIconTabs, setupLabelTabs } from "../../utilities/data";
import Company from "./components/Company";
import DriverApp from "./components/DriverApp";
import RiderApp from "./components/RiderApp";
import Dashboard from "./components/Dashboard";
import RoutingApi from "./components/RoutingApi";
import RoutingApiDriver from "./components/RoutingApiDriver";
import RoutingApiByHours from "./components/RoutingApiByHours";
import ServicesList from "./components/ServicesList";
import PriceList from "./components/PriceList";
import useData from "./data";
import CAlertSnackbar from "../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../components/Custom/CAlerts/CLoadingSnackbar";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { useTranslation } from "react-i18next";

export default function Setup() {
  const {
    cAlert,
    setCAlert,
    loading,
    loadingSuccess,
    setLoadingSuccess,
    company,
    dashboard,
    driverApp,
    riderApp,
    routingApiList,
    onChange,
    onCompanyUpdate,
    onDashboardUpdate,
    onRiderUpdate,
    onDriverUpdate,
    onRoutingApiUpdate,
    onChangeMapApi,
    dataSearchedQty,
    clearDriverDevices,
    onCreateReaderLocation,
    locationReaderList,
    selectionData
  } = useData();

  const { t } = useTranslation();

  const [tabValue, setTabValue] = React.useState(0);
  const handleTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Tabs activeTab={tabValue} onTabClick={(e, index) => setTabValue(index)}>
        {setupLabelTabs.map((label, index) => (
          <Tab style={{borderRadius:8, backgroundColor: tabValue ===index? "#fff":"#eee", color:"#000"}} key={label} icon={setupIconTabs[index]}> {t(label)}</Tab>
        ))}
      </Tabs>

      {tabValue === 0 && (
        <Company
          data={company}
          onClick={onCompanyUpdate}
          onChange={(e) => onChange(e, null, null, "company")}
        />
      )}

      {tabValue === 1 && (
        <Dashboard data={dashboard} selectionData={selectionData} onClick={onDashboardUpdate} onChange={onChange} />
      )}

      {tabValue === 2 && (
        <DriverApp
          dataSearchedQty={dataSearchedQty}
          clearDriverDevices={clearDriverDevices}
          data={driverApp}
          onClick={onDriverUpdate}
          onChange={onChange}
          onCreateReaderLocation={onCreateReaderLocation}
          locationReaderList={locationReaderList}
        />
      )}
      {tabValue === 3 && <RiderApp data={riderApp} onClick={onRiderUpdate} onChange={onChange} />}

      {tabValue === 4 && <PriceList showTaxiRates={driverApp.showTaxiRates} />}

      {tabValue === 5 && (
        <RoutingApi
          data={routingApiList}
          onClick={onRoutingApiUpdate}
          onChange={onChange}
          onChangeMapApi={onChangeMapApi}
        />
      )}

      {tabValue === 6 && (
        <RoutingApiDriver
          data={routingApiList}
          onClick={onRoutingApiUpdate}
          onChange={onChange}
          onChangeMapApi={onChangeMapApi}
        />
      )}

      {tabValue === 7 && (
        <RoutingApiByHours
          data={routingApiList}
          onClick={onRoutingApiUpdate}
          onChange={onChange}
          onChangeMapApi={onChangeMapApi}
        />
      )}

      {tabValue === 8 && (
        <ServicesList withServices={dashboard.withServices} />
      )}

      <Footer />

      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />
      <CLoadingSnackbar open={loading} />
      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />
    </DashboardLayout>
  );
}
