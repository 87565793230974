import * as React from "react";
import { useTranslation } from "react-i18next";
import { FormHelperText, TextField } from "@mui/material";
import MDInput from "../../../../../components/MDInput";
import MDBox from "../../../../../components/MDBox";

export default function InputAppointment({
  appointmentDate,
  setAppointmentDate,
  appointmentOffset,
  setAppointmentOffset,
}) {
  const { t } = useTranslation();

  const isBadDate = React.useMemo(() => {
    const appointmentDatetime = new Date(appointmentDate);
    return !(appointmentDatetime.getTime() > Date.now());
  }, [appointmentDate]);

  return (
    <MDBox my={1} flexDirection="row" display="flex" justifyContent="space-between">
      <MDBox flexDirection="column" display="flex" justifyContent="space-between">
        <TextField
          style={{ marginRight: 3 }}
          size="small"
          value={appointmentDate}
          onChange={(e) => setAppointmentDate(e.target.value)}
          placeholder={t("appointment")}
          type="datetime-local"
          sx={{ width: 250 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {!appointmentDate.length && <FormHelperText error>{t("invalid_date")}</FormHelperText>}
        {!!appointmentDate.length && isBadDate && (
          <FormHelperText error>{t("invalid_date_ms")}</FormHelperText>
        )}
      </MDBox>
      <MDInput
        value={appointmentOffset}
        onChange={(e) => setAppointmentOffset(e.target.value)}
        placeholder={t("offset")}
        type="number"
        size="small"
      />
    </MDBox>
  );
}
