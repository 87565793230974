import React, { useState } from "react";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { firestore } from "../../../../firebase";
import {
  CaptureException,
  getUser,
  isUserPermission,
  onCheckIfExist,
  playAudio,
  ReAssignOrder,
} from "../../../../utilities/func";
import { AlertModel } from "../../../../utilities/model";

export default function useData() {
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const { t } = useTranslation();
  const userInfo = useSelector(({ user }) => user);
  const dashboardSettings = useSelector(({ settings }) => settings);
  const [loading, setLoading] = React.useState(false);
  const [loadingRequest, setLoadingRequest] = React.useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [clientData, setClientData] = React.useState([]);
  const [alertData, setAlertData] = React.useState([]);
  const [request1, setRequest1] = React.useState([]);
  const [request2, setRequest2] = React.useState([]);
  const [requestSelected, setRequestSelected] = React.useState({});
  const [panic, setPanic] = React.useState(false);
  const [valueTab, setValueTab] = React.useState(0);
  const [openCAlert, setOpenCAlert] = React.useState(false);
  const [cAlertInfo, setCAlertInfo] = React.useState({ title: "", subtitle: "" });

  const allowPassOrder = onCheckIfExist(userInfo, "allowPassOrder");

  const allowCancelOrder = onCheckIfExist(userInfo, "allowCancelOrder");

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
    setClientData([]);
  };

  React.useEffect(() => {
    let driverAlerts = firestore()
      .collection("panic")
      .orderBy("createdAt", "desc")
      .where("status", "==", "pending")
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let dataDoc = doc.data();
          dataDoc.id = doc.id;
          data.push(dataDoc);
          setPanic(true);
          playAudio().catch(() => {});
        });
        setAlertData(data);
      });

    let then = DateTime.now().minus({ minutes: 120 }).toJSDate();

    let driverRequests = firestore()
      .collection("orders")
      .where("createdAt", ">=", then)
      .where("driverRequests", "==", true)
      .where("status", "in", ["assigned"])
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let dataDoc = doc.data();
          dataDoc.id = doc.id;
          data.push(dataDoc);
          playAudio().catch(() => {});
        });
        setRequest1(data);
      });

    const requestCancellation = firestore()
      .collection("orders")
      .where("createdAt", ">=", then)
      .where("requestCancellation", "==", false)
      .where("status", "in", ["assigned"])
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let dataDoc = doc.data();
          dataDoc.id = doc.id;
          if (!dataDoc.driverRequests) {
            playAudio().catch(() => {});
            data.push(dataDoc);
          }
        });
        setRequest2(data);
      });

    return () => {
      driverAlerts();
      driverRequests();
      requestCancellation();
    };
  }, []);

  const onResponseRequest = (name, response, id) => {
    firestore()
      .collection("orders")
      .doc(id)
      .update({
        [name]: response,
      })
      .catch((e) => {
        CaptureException(e, "Error-DRA-01");
      });
  };

  const onCancelRequest = (id) => {
    let items = [...request1].map((item) => {
      if (item.id === id) {
        return { ...item, isNoVisibleRequest: true };
      }
      return { ...item };
    });

    setRequest1(items);
  };

  const onPassOrder = async (data) => {
    let hasPermission = isUserPermission(dashboardSettings, userInfo, "reAssignOrder");

    let isRetainSpot = false;
    if (dashboardSettings.showOrderPassAction) {
      const result = await Swal.fire({
        title: t("would you like to keep turn of driver?"),
        showCancelButton: true,
        confirmButtonText: t("yes"),
        cancelButtonText: t("no"),
        confirmButtonColor: "#058600",
        cancelButtonColor: "#9f0000"
      });
      if (result.isConfirmed) {
        isRetainSpot = true;
      }
    }

    if (!hasPermission) {
      setLoadingRequest(false);
      return;
    }

    ReAssignOrder(data, data.id, isRetainSpot)
      .then(() => {
        setLoadingSuccess(true);
        setLoadingRequest(false);
        onCancelRequest(data.id);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-04");
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const onCancelOrder = (data) => {
    let hasPermission = isUserPermission(dashboardSettings, userInfo, "cancelOrderByRequest");
    if (!hasPermission) {
      setLoadingRequest(false);
      return;
    }

    const cancelledBy = getUser();
    setOpenCAlert(false);
    firestore()
      .collection("orders")
      .doc(data.id)
      .update({ status: "cancelled", cancellationSource: "dashboard", cancelledBy })
      .then(() => {
        setLoadingSuccess(true);
        setLoadingRequest(false);
        setRequestSelected({});
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-03");
        setCAlert({ open: true, type: "error", ms: e.message });
        setOpenCAlert(false);
      });
  };

  const onRequestAction = (data, type) => {
    if (type === "pass_order") {
      if (!allowPassOrder) {
        return Swal.fire(t("no_allow_title"), t("no_allow_subtitle"), "warning");
      }
      setLoadingRequest(true);
      onPassOrder(data);
    }

    if (type === "cancel") {
      if (!allowCancelOrder) {
        return Swal.fire(t("no_allow_title"), t("no_allow_subtitle"), "warning");
      }
      setCAlertInfo({ title: "delete_ms", subtitle: "execute_action_ms" });
      setOpenCAlert(true);
      setRequestSelected(data);
    }
    return null;
  };

  const onConfirmAction = () => {
    setLoadingRequest(true);
    onCancelOrder(requestSelected);
  };

  const getClientHistory = () => {
    if (phoneNumber.length !== 10) return;
    const parsedPhoneNumber = `+1${phoneNumber}`;
    setLoading(true);
    firestore()
      .collection("orders")
      .limit(10)
      .where("client.phoneNumber", "==", parsedPhoneNumber)
      .orderBy("createdAt", "desc")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let dataDoc = doc.data();
          dataDoc.id = doc.id;
          data.push(dataDoc);
        });
        setLoadingSuccess(true);
        setClientData(data);
      })
      .catch((e) => {
        CaptureException(e, "Error-DRA-02");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCheckAlert = (id) => {
    firestore()
      .collection("panic")
      .doc(id)
      .update({
        status: "completed",
      })
      .then(() => {
        setPanic(false);
      })
      .catch((e) => {
        CaptureException(e, "Error-DRA-03");
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  return {
    cAlert,
    setCAlert,
    loading,
    loadingSuccess,
    setLoadingSuccess,
    phoneNumber,
    setPhoneNumber,
    getClientHistory,
    clientData,
    valueTab,
    handleChangeTab,
    alertData,
    onCheckAlert,
    panic,
    setPanic,
    request1,
    request2,
    onResponseRequest,
    onCancelRequest,
    onRequestAction,
    loadingRequest,
    openCAlert,
    cAlertInfo,
    setOpenCAlert,
    onConfirmAction,
  };
}
