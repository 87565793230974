import React from "react";
import { useSelector } from "react-redux";
import { firebase, firestore } from "../../../../firebase";
import { CaptureException } from "../../../../utilities/func";

export default function useData() {
  const DashboardGrid = useSelector(({ dashboardGrid }) => dashboardGrid);
  const [chatNotification, setChatNotification] = React.useState([]);
  const [driverChat, setDriverChat] = React.useState({});
  const drivers = useSelector(({ driverList }) => driverList);
  const [valueTab, setValueTab] = React.useState(0);
  const [currentDriverChat, setCurrentDriverChat] = React.useState("");
  const [currentDriver, setCurrentDriver] = React.useState({});
  const [audioRecord, setAudioRecord] = React.useState([]);

  const checkPermissions = () => {
    const PERMISSIONS = navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    PERMISSIONS.catch(() => {});
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
    let d = { ...driverChat };
    if (newValue >= 2) {
      let keys = Object.keys(d);
      let dNumber = keys[newValue - 2];
      setDriverChat((prevState) => ({ ...prevState, [dNumber]: 0 }));
      if (dNumber) {
        setCurrentDriverChat(dNumber);
        let current = [...drivers].find((dri) => dri.dNumber === dNumber);
        setCurrentDriver(current || {});
      }
    }
    if (valueTab >= 2) {
      delete d[currentDriverChat];
      setDriverChat(d);
    }
  };

  React.useEffect(() => {
    let d = { ...driverChat };
    if (valueTab >= 2) {
      let keys = Object.keys(d);
      let index = keys.findIndex((li) => li === currentDriverChat);
      setValueTab(index + 2);
    }
  }, [driverChat]);

  const playAudio = (base64data, id) => {
    let audio = new Audio(`data:audio/wav;base64,${base64data}`);
    audio.controls = true;
    audio.volume = DashboardGrid.isMute ? 0 : 1;
    audio.play().catch(() => {});
    audio.addEventListener("ended", () => {
      firestore()
        .collection("audio")
        .doc(id)
        .set({ read: true }, { merge: true })
        .catch((e) => {
          CaptureException(e, "Error-DOH-01");
        });
    });
  };

  React.useEffect(() => {
    checkPermissions();

    let startOfDay = new Date();
    let endOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    endOfDay.setHours(23, 59, 59, 59);
    const subscriptionChat = firestore()
      .collection("chat")
      .where("createdAt", ">=", startOfDay)
      .where("createdAt", "<=", endOfDay)
      .where("status", "==", "pending")
      .where("source", "==", "driver")
      .orderBy("createdAt", "asc")
      .onSnapshot((querySnapshot) => {
        let list = [];
        if (!querySnapshot) return;
        querySnapshot.docs.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          list.push(data);
        });
        setChatNotification(list);
      });

    const subscriptionVoices = firebase
      .firestore()
      .collection("audio")
      .where("source", "==", "driver")
      .orderBy("createdAt", "desc")
      .limit(7)
      .onSnapshot((doc) => {
        if (!doc) return;
        let list = [];
        doc.docs.forEach(async (audio) => {
          let data = audio.data();
          data.id = audio.id;
          list.push(data);
        });

        let lastAudio = list[0];
        if (lastAudio && !lastAudio.read) {
          playAudio(lastAudio.audio, lastAudio.id);
        }

        setAudioRecord(list);
      });
    return () => {
      subscriptionChat();
      subscriptionVoices();
    };
  }, []);

  React.useEffect(() => {
    let d = {};
    [...chatNotification].forEach((chat) => {
      if (chat.status === "pending") {
        let info = drivers.find((dri) => dri.id === chat.driverId);
        let number = d[info.dNumber] ? d[info.dNumber] : 0;
        d[info.dNumber] = 1 + number;
      }
    });

    setDriverChat((prevState) => ({ ...prevState, ...d }));
  }, [chatNotification]);

  const selectedDriver = (dNumber) => {
    try {
      let d = { ...driverChat };
      d[dNumber] = 0;
      let keys = Object.keys(d);
      let index = keys.findIndex((li) => li === dNumber);
      setValueTab(index + 2);
      setDriverChat((prevState) => ({ ...prevState, [dNumber]: 0 }));
      setCurrentDriverChat(dNumber);
      let current = [...drivers].find((dri) => dri.dNumber === dNumber);
      setCurrentDriver(current || {});
    } catch (e) {
      CaptureException(e, "Error-DOH-02");
    }
  };

  const onCloseChat = () => {
    setValueTab(0);
    let d = { ...driverChat };
    delete d[currentDriverChat];
    setDriverChat(d);
  };

  return {
    driverChat,
    selectedDriver,
    valueTab,
    handleChangeTab,
    audioRecord,
    onCloseChat,
    currentDriverChat,
    currentDriver,
  };
}
