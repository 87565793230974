import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import TSCheck from "../../TSCheck";
import MDButton from "../../../MDButton";
import { TextMaskCustom } from "../../ImputComponents";
import { firestore } from "../../../../firebase";
import MDBox from "../../../MDBox";
import CPaper from "../../CPaper";

export default function SearchOrder({ onSelect }) {
  const [phone, setPhone] = React.useState("");
  const [invoice, setInvoice] = React.useState("");
  const [byInvoice, setByInvoice] = React.useState(false);
  const [byPhone, setByPhone] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [noData, setNoData] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [orders, setOrders] = React.useState([]);
  const { t } = useTranslation();

  const searchOrder = async (type) => {
    setLoading(true);
    let ref = firestore().collection("orders");
    let data = [];
    let result = null;
    if (type === "byInvoice") {
      result = await ref.where("invoiceId", "==", invoice).get();
    }
    if (type === "byPhone") {
      result = await ref
        .where("client.phoneNumber", "==", `+1${phone}`)
        .orderBy("createdAt", "desc")
        .limit(3)
        .get();
    }
    if (result && !result.empty) {
      result.forEach((doc) => {
        let d = doc.data();
        d.id = doc.id;
        d.phoneNumber = d.client?.phoneNumber;
        d.driver = d.assignedDriver && d.assignedDriver.dNumber ? d.assignedDriver.dNumber : "N/A";
        d.address = `${d?.from?.streetNumber} ${d?.from?.street} ${d?.from?.city}`;
        d.date = DateTime.fromSeconds(d.createdAt.seconds).toFormat("f");
        data.push(d);
      });
      setSelected(null);
      setNoData(false);
    } else {
      setNoData(true);
    }

    if (Array.isArray(data) && data.length === 1) {
      onSelect(data[0]);
      setSelected(data[0]);
    }

    setLoading(false);
    setOrders(data);
  };

  return (
    <CPaper style={{ marginBottom: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TSCheck
            row
            subtitle="search orders by phone"
            name="byInvoice"
            value={byPhone}
            onChange={(e) => {
              if (e.target.checked) {
                setByPhone(e.target.checked);
                setByInvoice(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TSCheck
            row
            subtitle="search orders by invoice"
            name="byInvoice"
            value={byInvoice}
            onChange={(e) => {
              if (e.target.checked) {
                setByInvoice(e.target.checked);
                setByPhone(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {byPhone && (
            <TextField
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder={t("phoneNumber")}
              label={t("phoneNumber")}
              name="phoneNumber"
              size="small"
              InputProps={{
                inputComponent: TextMaskCustom,
                endAdornment: (
                  <MDButton
                    disabled={phone.length < 10}
                    loading={loading}
                    onClick={() => searchOrder("byPhone")}
                    variant="gradient"
                    size="small"
                    color="success"
                  >
                    {t("search")}
                  </MDButton>
                ),
              }}
            />
          )}
          {byInvoice && (
            <TextField
              fullWidth
              value={invoice}
              onChange={(e) => setInvoice(e.target.value)}
              placeholder={t("invoice_id")}
              label={t("invoice_id")}
              name="invoice_id"
              size="small"
              InputProps={{
                endAdornment: (
                  <MDButton
                    disabled={invoice.length !== 11}
                    loading={loading}
                    onClick={() => searchOrder("byInvoice")}
                    variant="gradient"
                    size="small"
                    color="success"
                  >
                    {t("search")}
                  </MDButton>
                ),
              }}
            />
          )}
        </Grid>

        {!noData && orders && Array.isArray(orders) && orders.length !== 0 && (
          <Grid item xs={12}>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "flex-start",
                  alignContent: "flex-start",
                }}
              >
                <span style={{ width: 170, textAlign: "left" }}>Date</span>
                <span style={{ width: 150, textAlign: "left" }}>Invoice</span>
                <span style={{ width: 50, textAlign: "left" }}>Driver</span>
                <span style={{ width: 100, textAlign: "left" }}>Phone</span>
                <span style={{ width: 400, textAlign: "left" }}>Address</span>
              </div>
              {orders.map((order) => (
                <MDBox
                  key={order.id}
                  style={{
                    cursor: "pointer",
                    borderRadius: 4,
                    background: "#dadada",
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "flex-start",
                    alignContent: "flex-start",
                    marginBottom: 20,
                  }}
                  onClick={() => {
                    onSelect(order);
                    setSelected(order);
                  }}
                >
                  <span style={{ width: 170, textAlign: "left", color: "#000" }}>{order.date}</span>
                  <span style={{ width: 150, textAlign: "left", color: "#000" }}>
                    {order.invoiceId}
                  </span>
                  <span style={{ width: 50, textAlign: "left", color: "#000" }}>
                    {order.driver}
                  </span>
                  <span style={{ width: 100, textAlign: "left", color: "#000" }}>
                    {order.phoneNumber}
                  </span>
                  <span style={{ width: 400, textAlign: "left", color: "#000" }}>
                    {order.address}
                  </span>
                </MDBox>
              ))}
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          {noData && <h2 style={{ textAlign: "center", color: "red" }}>{t("no records")}</h2>}
          {selected && (
            <h2 style={{ textAlign: "center", color: "green" }}>
              {t("invoice selected")}: {selected.invoiceId}
            </h2>
          )}
        </Grid>
      </Grid>
    </CPaper>
  );
}
