import * as React from "react";
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MDInput from "../../../../components/MDInput";
import OrderDetail from "./components/OrderDetail";
import CCardHeader from "../../../../components/Custom/CCardHeader";
import CTab from "./components/CTab";
import { useStyles } from "../../styles";
import useData from "./data";
import CAlertSnackbar from "../../../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../../../components/Custom/CAlerts/CLoadingSnackbar";
import CAlert from "../../../../components/Custom/CAlerts/CAlert";
import DriverOrderModal from "./components/DriverOrderModal";
import ShowOrderModal from "./components/ShowOrderModal";
import ShowEtaOrderModal from "./components/ShowEtaOrderModal";
import ShowClientRecordModal from "./components/ShowClientRecordModal";
import { getTotal } from "./func";
import { StripeProvider } from "../../../../provider/StripeProvider";
import CPayments from "../../../../components/Custom/payments";

function ActiveOrders() {
  const {
    appointments,
    organizeOrders,
    onActions,
    cAlert,
    setCAlert,
    loading,
    loadingSuccess,
    setLoadingSuccess,
    setOpenCAlert,
    openCAlert,
    onConfirmAction,
    cAlertInfo,
    driversOrder,
    openDriversOrder,
    onReset,
    showOrder,
    orderSelected,
    etaInfo,
    showEtaOrder,
    searchOrder,
    setSearchOrder,
    tabValue,
    handleChangeTab,
    fare,
    setFare,
    actionsType,
    openPayment,
    setOpenPayment,
    orderErrors,
    setIsOrderError,
    isOrderError,
    onOrderError,
    clientRecordModal,
    clientRecord,
    setClientRecord,
    onUpdateClientRecord,
  } = useData();
  const { t } = useTranslation();
  const styles = useStyles();
  const dashboardSettings = useSelector(({ settings }) => settings);

  return (
    <>
      <Card style={{ ...styles.card, paddingBottom: 5 }}>
        <CCardHeader value="isActiveOrders" title="active_orders" />
        <MDInput
          value={searchOrder}
          onChange={({ target }) => setSearchOrder(target.value)}
          style={{ margin: 5 }}
          placeholder="Search Order"
          type="text"
          size="small"
        />

        <CTab
          value={tabValue}
          handleChange={handleChangeTab}
          assigned={getTotal(organizeOrders, "assigned")}
          inProcess={getTotal(organizeOrders, "inProcess")}
          unassigned={getTotal(organizeOrders, "unassigned")}
          all={getTotal(organizeOrders, "total")}
          appointment={appointments.length}
        />

        <MDBox
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            paddingLeft: 5,
            paddingRight: 5,
            paddingBottom: 5,
          }}
        >
          {tabValue === 0 && (
            <OrderDetail
              orderErrors={orderErrors}
              onOrderError={onOrderError}
              onActions={onActions}
              orders={organizeOrders.assigned}
              color="#935400"
            />
          )}
          {tabValue === 1 && (
            <OrderDetail
              orderErrors={orderErrors}
              onOrderError={onOrderError}
              onActions={onActions}
              orders={organizeOrders.inProcess}
              color="#8b0000"
            />
          )}
          {tabValue === 2 && (
            <OrderDetail
              orderErrors={orderErrors}
              onOrderError={onOrderError}
              onActions={onActions}
              orders={organizeOrders.unassigned}
              color="#008000"
            />
          )}
          {tabValue === 3 && (
            <OrderDetail
              orderErrors={orderErrors}
              onOrderError={onOrderError}
              onActions={onActions}
              orders={appointments}
              color="#1d8cf8"
              isAppointment
            />
          )}
          {tabValue === 4 && (
            <>
              <OrderDetail
                orderErrors={orderErrors}
                onOrderError={onOrderError}
                onActions={onActions}
                orders={organizeOrders.unassigned}
                color="#008000"
              />
              <OrderDetail
                orderErrors={orderErrors}
                onOrderError={onOrderError}
                onActions={onActions}
                orders={organizeOrders.assigned}
                color="#935400"
              />
              <OrderDetail
                orderErrors={orderErrors}
                onOrderError={onOrderError}
                onActions={onActions}
                orders={organizeOrders.inProcess}
                color="#8b0000"
              />
            </>
          )}
        </MDBox>
      </Card>

      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />
      <CLoadingSnackbar open={loading} />
      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />

      {/*<CLoadingSnackbar*/}
      {/*  isError*/}
      {/*  duration={10000}*/}
      {/*  open={isOrderError}*/}
      {/*  close={setIsOrderError}*/}
      {/*  title="action required, order with error"*/}
      {/*/>*/}

      <CAlert
        disabled={dashboardSettings.isFare && actionsType === "complete" ? !fare : false}
        close={setOpenCAlert}
        open={openCAlert}
        title={cAlertInfo.title}
        subtitle={cAlertInfo.subtitle}
        isConfirm={onConfirmAction}
      >
        <div style={{ marginTop: 5 }} />
        {dashboardSettings.isFare && actionsType === "complete" ? (
          <MDInput
            fullWidth
            value={fare}
            onChange={(e) => setFare(e.target.value)}
            placeholder={t("price")}
            name="fare"
            type="number"
            size="small"
          />
        ) : null}
      </CAlert>
      {openDriversOrder && (
        <DriverOrderModal data={driversOrder} open={openDriversOrder} close={onReset} />
      )}
      {showOrder && <ShowOrderModal data={orderSelected} open={showOrder} close={onReset} />}
      {showEtaOrder && <ShowEtaOrderModal data={etaInfo} open={showEtaOrder} close={onReset} />}
      {clientRecordModal && (
        <ShowClientRecordModal
          data={clientRecord}
          onChange={setClientRecord}
          open={clientRecordModal}
          close={onReset}
          onClick={onUpdateClientRecord}
        />
      )}

      {openPayment && (
        <StripeProvider>
          <CPayments
            open={openPayment}
            close={setOpenPayment}
            title="payment_with_card"
            subtitle={orderSelected.invoiceId}
            order={orderSelected}
          />
        </StripeProvider>
      )}
    </>
  );
}

export default ActiveOrders;
