import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as React from "react";
import CTable from "../../components/Custom/CTable";
import useData from "./data";
import { activeColumns } from "./components/columns";
import HeaderOptions from "./components/HeaderOptions";
import CAlertSnackbar from "../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../components/Custom/CAlerts/CLoadingSnackbar";
import CAlert from "../../components/Custom/CAlerts/CAlert";
import LoadingModal from "../../components/Custom/Loading";
import PaymentDetails from "./components/PaymentDetails";
import PaymentsRecord from "./components/PaymentsRecord";
import PaymentsDetails from "./components/PaymentsDetails";

function Payments() {
  const {
    tableData,
    periodsData,
    currentPeriod,
    onPeriodSelected,
    loadingSuccess,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    onPaymentStatusChange,
    hidePaymentStatusChangeConfirmation,
    paymentStatusChangeConfirmation,
    amount,
    setAmount,
    updateAmountPayment,
    closeActionAmountPayment,
    onConfirmAmountPayment,
    loading,
    onUpdateDriverAmount,
    showDriverAmount,
    setShowDriverAmount,
    model,
    amountDriver,
    setAmountDriver,
    hasPendingPayment,
    setHasPendingPayment,
    amountDueDriver,
    setAmountDueDriver,
    updateAmountDueDriver,
    amountDue,
    setAmountDue,
    paymentsRecord,
    paymentsRecordModal,
    setPaymentsRecordModal,
    openStripeDetail,
    setOpenStripeDetail,
    paymentsStripeDetails,
    stripeId,
    setStripeId,
    onSearchStripe,
    searched,
    stripePaymentMethodId,
    setStripePaymentMethodId,
  } = useData();

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <CTable
          canSearch
          columns={activeColumns}
          data={tableData}
          pageSize={25}
          headerOptions={
            <HeaderOptions
              setOpenStripeDetail={setOpenStripeDetail}
              amount={amount}
              setAmount={setAmount}
              amountDue={amountDue}
              setAmountDue={setAmountDue}
              updateAmountPayment={updateAmountPayment}
              updateAmountDueDriver={updateAmountDueDriver}
              currentPeriod={currentPeriod}
              periodsData={periodsData}
              onPeriodSelected={onPeriodSelected}
            />
          }
        />
        <Footer />
      </DashboardLayout>

      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />

      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />

      <CAlert
        open={cAlert.show}
        title={cAlert.title}
        subtitle={cAlert.text}
        close={closeActionAmountPayment}
        isConfirm={onConfirmAmountPayment}
      />

      <CAlert
        close={hidePaymentStatusChangeConfirmation}
        open={paymentStatusChangeConfirmation.show}
        title={paymentStatusChangeConfirmation.title}
        subtitle={paymentStatusChangeConfirmation.text}
        isConfirm={() => onPaymentStatusChange(paymentStatusChangeConfirmation.action)}
      />
      <LoadingModal loading={loading} />

      <PaymentDetails
        open={showDriverAmount}
        close={setShowDriverAmount}
        onUpdate={onUpdateDriverAmount}
        amount={amountDriver}
        setAmount={setAmountDriver}
        model={model}
        hasPendingPayment={hasPendingPayment}
        setHasPendingPayment={setHasPendingPayment}
        amountDueDriver={amountDueDriver}
        setAmountDueDriver={setAmountDueDriver}
      />

      <PaymentsRecord
        open={paymentsRecordModal}
        close={setPaymentsRecordModal}
        data={paymentsRecord}
        model={model}
      />

      <PaymentsDetails
        loading={loading}
        open={openStripeDetail}
        close={setOpenStripeDetail}
        data={paymentsStripeDetails}
        stripeId={stripeId}
        setStripeId={setStripeId}
        stripePaymentMethodId={stripePaymentMethodId}
        setStripePaymentMethodId={setStripePaymentMethodId}
        onSearch={onSearchStripe}
        searched={searched}
      />
    </>
  );
}

export default Payments;
