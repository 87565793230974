import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as React from "react";
import useData from "./data";
import { columns } from "./components/columns";
import DataTable from "../../examples/Tables/DataTable";
import CAlertSnackbar from "../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../components/Custom/CAlerts/CLoadingSnackbar";
import HeaderOptions from "./components/HeaderOptions";
import ShowModal from "./components/ShowModal";

export default function HelpSupport() {
  const {
    orders,
    onSearchValue,
    loading,
    loadingSuccess,
    onChange,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    filterValues,
    onChangeFilter,
    onFilterData,
    filtersOn,
    onCancelFilter,
    showOrder,
    onReset,
    orderSelected,
    ticketListMessage,
    onResponseTicket,
  } = useData();

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <DataTable
          showTotalEntries={false}
          PageSize={25}
          headerOptions={
            <HeaderOptions
              onCancelFilter={onCancelFilter}
              filtersOn={filtersOn}
              onFilterData={onFilterData}
              filterValues={filterValues}
              onChangeFilter={onChangeFilter}
            />
          }
          noEndBorder
          canSearch
          table={{
            columns,
            rows: orders,
          }}
          onSearchValue={onSearchValue}
        />
        <Footer />
      </DashboardLayout>

      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />

      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />
      {showOrder && (
        <ShowModal
          onResponseTicket={onResponseTicket}
          onChange={onChange}
          ticketListMessage={ticketListMessage}
          open={showOrder}
          close={onReset}
          model={orderSelected}
        />
      )}
      <CLoadingSnackbar open={loading} />
    </>
  );
}
