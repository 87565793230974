import { Card, CardContent } from "@mui/material";
import * as React from "react";
import CCardHeader from "../../../../components/Custom/CCardHeader";
import { useStyles } from "../../styles";
import RenderCall from "./components/RenderCall";
import useData from "./data";
import CAlert from "../../../../components/Custom/CAlerts/CAlert";
import CAlertSnackbar from "../../../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../../../components/Custom/CAlerts/CLoadingSnackbar";
import ActiveOrderModal from "../CreateOrders/components/ActiveOrderModal";

export default function ActiveCalls() {
  const {
    calls,
    cAlert,
    setCAlert,
    onSelectCall,
    onCancelCallClick,
    alertActiveCall,
    openCancelCall,
    setOpenCancelCall,
    setAlertActiveCall,
    onCancelCallConfirm,
    loading,
    onDuplicateOrder,
    activeOrderModel,
    loadingSuccess,
    setLoadingSuccess,
    openClearCall,
    setOpenClearCall,
    clearCallsLog,
  } = useData();
  const styles = useStyles();
  return (
    <>
      <Card style={styles.card}>
        <CCardHeader
          value="isActiveCalls"
          title="active_calls"
          clearCall={() => setOpenClearCall(true)}
        />
        <CardContent style={styles.cardContent}>
          {calls.map((call) => (
            <RenderCall
              key={call.id}
              data={call}
              handleClickCancel={onCancelCallClick}
              handleClickOpen={onSelectCall}
            />
          ))}
        </CardContent>
      </Card>
      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />
      <CLoadingSnackbar open={loading} />
      <CAlert
        close={setOpenCancelCall}
        open={openCancelCall}
        title="cancel_call"
        subtitle="cancel_call_ms"
        isConfirm={onCancelCallConfirm}
      />
      <CAlert
        close={setOpenClearCall}
        open={openClearCall}
        title="clear_call"
        subtitle="execute_action_ms"
        isConfirm={clearCallsLog}
      />
      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />
      {alertActiveCall && (
        <ActiveOrderModal
          onClick={onDuplicateOrder}
          open={alertActiveCall}
          close={setAlertActiveCall}
          data={activeOrderModel}
        />
      )}
    </>
  );
}
