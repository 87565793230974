import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PersonPinIcon from "@mui/icons-material/PersonPin";

const tabStyle = {
  borderRadius: 4,
  borderWidth: 1,
  borderStyle: "solid",
};

export default function CTab({
  unassigned,
  assigned,
  inProcess,
  appointment,
  all,
  value,
  handleChange,
}) {
  return (
    <div>
      <Tabs
        style={{ backgroundColor: "transparent" }}
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab
          style={{
            ...tabStyle,
            borderColor: value === 0 ? "#c67700" : "transparent",
          }}
          icon={
            <PersonPinIcon sx={{ width: 25, height: 25 }} style={{ color: "#c67700" }} scale={23} />
          }
          label={<span style={{ color: "#c67700", fontWeight: "bold" }}>{assigned}</span>}
        />
        <Tab
          style={{
            ...tabStyle,
            borderColor: value === 1 ? "#8b0000" : "transparent",
          }}
          icon={
            <PersonPinIcon sx={{ width: 25, height: 25 }} style={{ color: "#8b0000" }} scale={23} />
          }
          label={<span style={{ color: "#8b0000", fontWeight: "bold" }}>{inProcess}</span>}
        />
        <Tab
          style={{
            ...tabStyle,
            borderColor: value === 2 ? "#008000" : "transparent",
          }}
          icon={
            <PersonPinIcon sx={{ width: 25, height: 25 }} style={{ color: "#008000" }} scale={23} />
          }
          label={<span style={{ color: "#008000", fontWeight: "bold" }}>{unassigned}</span>}
        />
        <Tab
          style={{
            ...tabStyle,
            borderColor: value === 3 ? "#1d8cf8" : "transparent",
          }}
          icon={
            <PersonPinIcon sx={{ width: 25, height: 25 }} style={{ color: "#1d8cf8" }} scale={23} />
          }
          label={<span style={{ color: "#1d8cf8", fontWeight: "bold" }}>{appointment}</span>}
        />
        <Tab
          style={{
            ...tabStyle,
            borderColor: value === 4 ? "#000" : "transparent",
          }}
          icon={<PersonPinIcon sx={{ width: 25, height: 25 }} scale={23} />}
          label={<span style={{ fontWeight: "bold" }}>{all}</span>}
        />
      </Tabs>
    </div>
  );
}
