import * as React from "react";
import List from "@mui/material/List";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { ReactMic } from "react-mic";
import { useState } from "react";
import { useLongPress } from "use-long-press";
import { useSelector } from "react-redux";
import MDBox from "../../../../../components/MDBox";
import CListItem from "../../../../../components/Custom/CListItem";
import CAlertSnackbar from "../../../../../components/Custom/CAlerts/CAlertSnackbar";
import { AlertModel } from "../../../../../utilities/model";
import { getDate, getUser } from "../../../../../utilities/func";
import CLoadingSnackbar from "../../../../../components/Custom/CAlerts/CLoadingSnackbar";
import { firebase, firestore } from "../../../../../firebase";

export default function Voices({ data }) {
  const DashboardGrid = useSelector(({ dashboardGrid }) => dashboardGrid);
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [recording, setRecording] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [base64data, setBase64data] = React.useState(null);
  const [currentDriver, setCurrentDriver] = React.useState("");

  const uploadAudio = () => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    setBase64data(null);
    firestore()
      .collection("audio")
      .add({
        createdAt: timestamp,
        adminName: getUser().displayName,
        userId: currentDriver,
        source: "admin",
        audio: base64data,
      })
      .then(() => {
        setLoadingSuccess(true);
        setLoading(false);
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const record = async (e, { context }) => {
    setCurrentDriver(context);
    setLoading(true);
    if (!recording) {
      setRecording(true);
    } else {
      setRecording(false);
    }
  };

  const onStop = ({ blob }) => {
    setLoading(false);
    try {
      setRecording(false);
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        let base64 = reader.result.replace("data:audio/wav;base64,", "");
        setBase64data(base64);
      };
    } catch (e) {
      setCAlert({ open: true, type: "error", ms: e.message });
    }
  };

  React.useMemo(() => {
    if (base64data && !recording) {
      uploadAudio();
    }
  }, [base64data, recording, currentDriver]);

  const playAudio = async (base64) => {
    let audio = new Audio(`data:audio/wav;base64,${base64}`);
    audio.controls = true;
    audio.volume = DashboardGrid.isMute ? 0 : 1;
    await audio.play();
  };

  const callback = React.useCallback((e, context) => record(e, context), []);
  const bind = useLongPress(callback, {
    onFinish: () => setRecording(false),
    onCancel: () => setRecording(false),
    threshold: 200,
    detect: "both",
  });

  return (
    <MDBox display="flex" flexDirection="column">
      <List sx={{ width: "100%", bgcolor: "transparent" }} dense>
        {data.map((driver) => {
          let { id, userDisplayName, audio, driverId } = driver;
          return (
            <CListItem
              key={id}
              driverId={driverId}
              isMic
              bind={bind}
              primary={userDisplayName}
              secondary={getDate(driver)}
              selectedDriver={() => playAudio(audio)}
              Icon={PlayCircleOutlineIcon}
            />
          );
        })}
      </List>
      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />
      <div style={{ display: "none" }}>
        <ReactMic
          record={recording}
          className="sound-wave"
          mimeType="audio/wav"
          onStop={onStop}
          strokeColor="#000000"
          backgroundColor="#FF4081"
        />
      </div>
      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="message_sent"
        duration={1500}
      />
      <CLoadingSnackbar open={loading} title="recording_audio" />
      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />
    </MDBox>
  );
}
