import { firestore } from "../../../../firebase";
import ApiClient from "../../../../api";

const removeOrderPhoneBlacklistFromFirebase = (order) => {
  try {
    firestore()
      .collection("orders")
      .doc(order.id)
      .update({ status: "cancelled" })
      .catch((error) => {
        // console.log(error);
      });

    firestore()
      .collection("clients")
      .doc(order.client.id)
      .set({ blockedNotes: null, blocked: true, blackListed: true }, { merge: true })
      .catch((error) => {
        // console.log(error);
      });

    ApiClient.put(`users/${order.client.id}`, {
      status: "disabled",
    }).catch((error) => {
      // console.log(error);
    });
  } catch (e) {
    // console.log(error);
  }
};

export const filterOrders = (data, searchOrder) => {
  if (!searchOrder) return data;

  const keyword = searchOrder.toLocaleLowerCase();

  const reducer = (accumulator, order) => {
    let from = (order.from && order.from.address ? order.from.address : "").toLocaleLowerCase();
    let to = (order.to && order.to.address ? order.to.address : "").toLocaleLowerCase();
    let phoneNumber = order.client && order.client.phoneNumber ? order.client.phoneNumber : "";
    let dNumber =
      order.assignedDriver && order.assignedDriver.dNumber ? order.assignedDriver.dNumber : "";
    if (
      dNumber.includes(keyword) ||
      from.includes(keyword) ||
      to.includes(keyword) ||
      phoneNumber.includes(keyword)
    ) {
      accumulator.push(order);
    }
    return accumulator;
  };
  return data.reduce(reducer, []);
};

const removeOrderPhoneBlacklist = (item, phoneBlacklist) => {
  if (phoneBlacklist.some((pb) => pb.phone === item.client.phoneNumber)) {
    removeOrderPhoneBlacklistFromFirebase(item);
    return true;
  }
  return false;
};

const onOrganizeOrders = (data, search, phoneBlacklist) => {
  const filteredOrders = filterOrders(data, search);
  const reducer = (accumulator, orderItem) => {
    if (removeOrderPhoneBlacklist(orderItem, phoneBlacklist)) {
      return accumulator;
    }
    if (!accumulator[orderItem.status]) accumulator[orderItem.status] = [];
    accumulator[orderItem.status].push(orderItem);
    return accumulator;
  };
  const organizedOrders = filteredOrders.reduce(reducer, {});
  const totals = {};

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const orderStatus in organizedOrders) {
    totals[orderStatus] = organizedOrders[orderStatus].length;
  }
  organizedOrders.totals = totals;
  return organizedOrders;
};

const getTotal = (data, name) => {
  try {
    if (data.totals) {
      if (name === "total" && typeof data.totals === "object") {
        return Object.values(data.totals).reduce((a, b) => a + b, 0);
      }
      if (data.totals[name]) {
        return data.totals[name];
      }
      return 0;
    }
    return 0;
  } catch (e) {
    return 0;
  }
};

export { onOrganizeOrders, getTotal };
