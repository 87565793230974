import * as React from "react";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ClipboardText from "components/Custom/ClipboardText";
import MaskPhone from "../../../../../components/Custom/MaskPhone";
import CPaper from "../../../../../components/Custom/CPaper";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import { getCheckField, getCheckStatsField, getDate } from "../../../../../utilities/func";

export default function RenderRequest({ phoneNumber, setPhoneNumber, onSearch, clientData = [] }) {
  const { t } = useTranslation();
  return (
    <>
      <MaskPhone
        my={0}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        loadingPhone={false}
        onSearch={onSearch}
      />
      {clientData.map((order) => {
        let { id, status } = order;
        let createdAt = getDate(order);
        let address = getCheckField(order, "from", "address");
        let dNumber = getCheckField(order, "assignedDriver", "dNumber");
        let num_cancelled = getCheckStatsField(order, "cancelled_orders");
        let num_completed = getCheckStatsField(order, "completed_orders");
        return (
          <CPaper key={id} style={{ margin: 5, marginTop: 12 }}>
            <MDBox
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              mb={1}
            >
              <MDTypography style={{ fontSize: 15 }}>{createdAt}</MDTypography>
              <MDBox
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Chip size="small" label={num_completed} color="success" />
                <div style={{ marginLeft: 5 }} />
                <Chip size="small" label={num_cancelled} color="error" />
              </MDBox>
            </MDBox>

            <MDBox
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <ClipboardText fontSize={15} text={address} copy={id} />
              <Chip
                size="small"
                label={t(status)}
                color={status === "completed" ? "success" : "error"}
              />
            </MDBox>

            {dNumber.length !== 0 && (
              <Chip size="small" label={`${t("driver")}: ${dNumber}`} color="info" />
            )}
          </CPaper>
        );
      })}
    </>
  );
}
