import ActiveActions from "./active.actions";
import CChipByStatus from "../../../components/Custom/CChipByStatus";

export const activeColumns = [
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "actions",
    field: "actions",
    sortable: false,
    renderCell: ({ row }) => <ActiveActions onClick={row.func} />,
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "status",
    field: "status",
    renderCell: ({ row }) => <CChipByStatus status={row.status} />,
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "firstName",
    field: "firstName",
  },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "lastName", field: "lastName" },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "email", field: "email" },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "phoneNumber",
    field: "phoneNumber",
  },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "role", field: "role" },
];
