import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import CDialog from "../../../../../components/Custom/CDialog";
import MDBox from "../../../../../components/MDBox";
import RenderDriverLocation from "../../../../../components/Custom/RenderDriverLocation";

export default function DriversLocation({ open, close, options }) {
  const { t } = useTranslation();
  return (
    <CDialog
      open={open}
      title="time of driver last location"
      close={() => {
        close(false);
      }}
      maxWidth="xl"
    >
      <br />
      <MDBox style={{ overflow: "auto", height: 516.7, padding: 5 }}>
        <RenderDriverLocation
          isHeader
          name={t("full_name")}
          dNumber={t("driver")}
          time={t("minutes")}
          bgPermission={t("geolocation_status")}
          appState={t("appState")}
          geofenceDistance={t("customer distance")}
          geofenceIsInside={t("inside the client")}
          version={t("version")}
        />
        {options.filter(driver=>driver.drivingStatus !=='offline' && driver.lastCoordinatesDate > 5).map((driver) => {

          const dNumber = driver.dNumber
          const displayName = driver.displayName
          const coordinatesDate = driver.lastCoordinatesDate
          const bgPermission = driver.bgPermission
          const appState = driver.appState
          const version = driver.currentDevice?.app
          const geofenceDistance = driver?.currentGeofence?.distance
          const geofenceIsInside = driver?.currentGeofence?.isInside ? t("yes") : t("no")

          return (
            <RenderDriverLocation
              key={driver.id}
              name={displayName}
              dNumber={dNumber}
              time={coordinatesDate}
              bgPermission={bgPermission}
              appState={appState}
              version={version}
              geofenceDistance={geofenceDistance}
              geofenceIsInside={geofenceIsInside}
            />
          );
        })}
      </MDBox>
      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton onClick={() => close(false)} variant="gradient" color="error">
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
