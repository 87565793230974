import React from "react";
import { useSelector } from "react-redux";
import MapWithMarkers from "./components/MapWithMarkers";

export default function DriverMap() {
  const drivers = useSelector(({ driverList }) => driverList);
  const dashboardSettings = useSelector(({ settings }) => settings);

  return (
    <>
      {dashboardSettings.mapBoxApiKeyForMap && (
        <MapWithMarkers
          drivers={drivers}
          center={dashboardSettings.mapCenter}
          mapBoxApiKey={dashboardSettings.mapBoxApiKeyForMap}
        />
      )}
    </>
  );
}
