import React from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { firebase, firestore } from "../../../../firebase";
import {
  CaptureException,
  DuplicateOrder,
  fromBoolToString,
  fromEtaToMinutes,
  getCheckField,
  getDiffTime, getDiscardReason,
  getHours,
  getSeconds,
  getTimeSubscribe,
  getUser,
  onChangeDriverStatus,
  onlyCurrentDay
} from "../../../../utilities/func";
import { AlertModel } from "../../../../utilities/model";

export default function useData() {
  const [orders, setOrders] = React.useState([]);
  const { t } = useTranslation();
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [loading, setLoading] = React.useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [openCAlert, setOpenCAlert] = React.useState(false);
  const [cAlertInfo, setCAlertInfo] = React.useState({ title: "", subtitle: "" });
  const [actionsType, setActionsType] = React.useState("");
  const [orderSelected, setOrderSelected] = React.useState({});
  const [driversOrder, setDriversOrder] = React.useState([]);
  const [openDriversOrder, setOpenDriversOrder] = React.useState(false);
  const [showOrder, setShowOrder] = React.useState(false);
  const [openPayment, setOpenPayment] = React.useState(false);

  React.useEffect(() => {
    setOrders([]);
    const OrderSnapshot = firestore()
      .collection("orders")
      .limit(11)
      .where("createdAt", ">=", onlyCurrentDay())
      .where("status", "in", ["cancelled", "completed"])
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let dataDoc = doc.data();
          dataDoc.id = doc.id;
          data.push(dataDoc);
        });
        setOrders(data);
      });
    return () => {
      OrderSnapshot();
    };
  }, []);

  const onReset = () => {
    setActionsType("");
    setOrderSelected({});
    setCAlertInfo({ title: "", subtitle: "" });
    setOpenCAlert(false);
    setLoading(false);
    setOpenDriversOrder(false);
    setShowOrder(false);
    setDriversOrder([]);
  };

  const onDriverOrder = (order, id) => {
    firestore()
      .collection("orders")
      .doc(id)
      .collection("subscribed_drivers")
      .orderBy("createdAt", "asc")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let dataDoc = doc.data();
          let obj = {};
          let { lastAssignedOrder = {} } = dataDoc;

          let selected_driver = getCheckField(order, "assignedDriver", "dNumber");

          let assignedAt = getSeconds(lastAssignedOrder, "assignedAt");
          obj.subscribedAt = getTimeSubscribe(dataDoc);
          obj.lastAssigned = assignedAt ? getDiffTime(order, assignedAt) : "--:--";
          obj.orderId = id;
          obj.invoiceId = order.invoiceId;
          obj.dNumber = dataDoc.dNumber;
          obj.discardReason = dataDoc?.discardReason;
          obj.retainSpot = fromBoolToString(dataDoc, "retainSpot", t);
          obj.isRetainSpot = dataDoc?.retainSpot ?? false;
          obj.startedAt = getHours(order, "selectionStartedAt");
          obj.eta = fromEtaToMinutes(dataDoc);
          obj.etaS = dataDoc.eta ? dataDoc.eta : "--:--";
          obj.selected_driver = selected_driver
          obj.selected= order?.assignedDriver ?? null
          obj.isDriver = dataDoc.driverID === getCheckField(order, "assignedDriver", "id");
          data.push(obj);
        });

        let driversOrderWithDiscardReason = data.map(drivers=>{
          let selected_driver = drivers.selected
          let findSelected_driver = data.find(findDriver=>findDriver.dNumber === selected_driver?.dNumber)
          if (findSelected_driver){
            selected_driver = {...selected_driver, ...findSelected_driver}
          }
          selected_driver = {...selected_driver, ...findSelected_driver}
          return {...drivers, discardReason:getDiscardReason(selected_driver, drivers)}
        })

        setOpenDriversOrder(true);
        setDriversOrder(driversOrderWithDiscardReason);
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
        CaptureException(e, "Error-DOH-01");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDuplicateOrder = () => {
    console.log('orderSelected', orderSelected)
    try {
      DuplicateOrder(orderSelected)
        .then(() => {
          setLoadingSuccess(true);
          onReset();
        })
        .catch((e) => {
          CaptureException(e);
          setCAlert({ open: true, type: "error", ms: e.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      CaptureException(e);
      setLoading(false);
      setCAlert({ open: true, type: "error", ms: e.message });
    }
  };

  const onBackToDriver = () => {
    try {
      const order = { ...orderSelected };
      firestore()
        .collection("orders")
        .doc(order.id)
        .update({
          backToDriverBy: getUser(),
          backToDriverAt: firestore.FieldValue.serverTimestamp(),
          cancellationSource: firestore.FieldValue.delete(),
          cancelledBy: firestore.FieldValue.delete(),
          noDriver: firestore.FieldValue.delete(),
          active: true,
          inProcessAt: firestore.FieldValue.serverTimestamp(),
          status: "assigned",
        })
        .then(async () => {
          let { id } = order.assignedDriver;
          onChangeDriverStatus(id, "pickup");
          onReset();
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((e) => {
          CaptureException(e);
          setCAlert({ open: true, type: "error", ms: e.message });
        });
    } catch (e) {
      CaptureException(e);
      setLoading(false);
      setCAlert({ open: true, type: "error", ms: e.message });
    }
  };

  const onBlockPhone = (order) => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    if (!order.client) {
      return Swal.fire("Error", "Client no found");
    }
    setLoading(true);
    const { email, phoneNumber } = order.client;
    return firestore()
      .collection("phone_blacklist")
      .doc(phoneNumber)
      .set({
        phone: phoneNumber,
        email,
        orderId: order.id,
        createdAt: timestamp,
      })
      .then(() => {
        setLoading(false);
        Swal.fire(t("successful_operation"));
      })
      .catch((e) => {
        setLoading(false);
        Swal.fire("Error", e);
      });
  };

  const onActions = async (type, id) => {
    let selected;
    let action;
    selected = orders.find((order) => order.id === id);
    selected.id = id;
    setActionsType(type);
    setOrderSelected(selected);
    switch (type) {
      case "backToDriver":
        setCAlertInfo({ title: "back_to_driver", subtitle: "execute_action_ms" });
        setOpenCAlert(true);
        break;
      case "duplicate":
        setCAlertInfo({ title: "duplicate_order", subtitle: "execute_action_ms" });
        setOpenCAlert(true);
        break;
      case "drivers":
        setLoading(true);
        onDriverOrder(selected, id);
        break;
      case "block_phone":
        action = await Swal.fire({
          title: t("block_phone"),
          text: t("execute_action_ms"),
          showCancelButton: true,
          cancelButtonColor: "#c9c9c9",
          confirmButtonColor: "#2bb400",
        });
        if (action.isConfirmed) {
          onBlockPhone(selected);
        }

        break;
      case "show":
        setShowOrder(true);
        break;
      case "payment":
        setOpenPayment(true);
        break;
      default:
        break;
    }
  };

  const onConfirmAction = () => {
    switch (actionsType) {
      case "backToDriver":
        setLoading(true);
        onBackToDriver();
        setOpenCAlert(false);
        break;
      case "duplicate":
        setLoading(true);
        onDuplicateOrder();
        setOpenCAlert(false);
        break;
      default:
        break;
    }
  };

  return {
    orders,
    onActions,
    cAlert,
    setCAlert,
    loading,
    loadingSuccess,
    setLoadingSuccess,
    setOpenCAlert,
    openCAlert,
    onConfirmAction,
    cAlertInfo,
    driversOrder,
    openDriversOrder,
    onReset,
    showOrder,
    orderSelected,
    openPayment,
    setOpenPayment,
  };
}
