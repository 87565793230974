import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createStyles } from "@mui/styles";
import img from "../../assets/lo2.jpeg";

const useStyles = (backgroundImage) =>
  createStyles({
    root: {
      backgroundColor: "#000",
      backgroundImage: `url(${backgroundImage})`,
      height: "100%",
      width: "100%",
      zIndex: 9000,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      // filter: "blur(2px)",
    },
    bg: {
      backgroundColor: "#000",
      opacity: 0.4,
      zIndex: 10000,
      position: "absolute",
      height: "100%",
      width: "100%",
    },
    bgLoading: {
      zIndex: 11000,
      position: "absolute",
      justifyContent: "center",
    },
  });

export default function LoadingModal({ loading }) {
  const styles = useStyles(img);
  return (
    <Backdrop sx={{ color: "#000", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
      <div style={styles.bg} />
      <div style={styles.root} />
      <div style={styles.bgLoading}>
        <CircularProgress style={{ color: "#fff" }} />
      </div>
    </Backdrop>
  );
}
