import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { firebase, firestore } from "../../firebase";
import {
  CaptureException,
  CompleteOrder,
  DeleteOrder,
  fromBoolToString,
  fromEtaToMinutes,
  getCheckField,
  getDate,
  getDiffTime, getDiscardReason,
  getHours,
  getSeconds,
  getTimeSubscribe,
  isUserPermission,
  onlyCurrentDay, onPhoneBlacklist,
  ReAssignOrder
} from "../../utilities/func";
import { AlertModel, AnalyticsModel, FilterModel } from "../../utilities/model";
import { RangeDateData } from "../../utilities/data";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default function useData() {
  const { t } = useTranslation();
  const [orders, setOrders] = React.useState([]);
  const userInfo = useSelector(({ user }) => user);
  const dashboardSettings = useSelector(({ settings }) => settings);
  const [appointments, setAppointments] = React.useState([]);
  const [navigationData, setNavigationData] = React.useState([]);
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [filterValues, setFilterValues] = React.useState(new FilterModel());
  const [loading, setLoading] = React.useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [openCAlert, setOpenCAlert] = React.useState(false);
  const [openPayment, setOpenPayment] = React.useState(false);
  const [cAlertInfo, setCAlertInfo] = React.useState({ title: "", subtitle: "" });
  const [actionsType, setActionsType] = React.useState("");
  const [orderSelected, setOrderSelected] = React.useState({});
  const [appointmentSelected, setAppointmentSelected] = React.useState({});
  const [driversOrder, setDriversOrder] = React.useState([]);
  const [openDriversOrder, setOpenDriversOrder] = React.useState(false);
  const [showOrder, setShowOrder] = React.useState(false);
  const [fareModal, setFareModal] = React.useState(false);
  const [fare, setFare] = React.useState("");
  const [showReceipt, setShowReceipt] = React.useState(false);
  const [showNavigation, setShowNavigation] = React.useState(false);
  const [columnsType, setColumnsType] = React.useState("orders");
  const [analytics, setAnalytics] = React.useState(new AnalyticsModel());

  const orderResult = (querySnapshot) => {
    const list = [];
    let completed = 0;
    let money = 0;
    let reassigned = 0;
    let cancelled = 0;
    let assigned = 0;
    let unassigned = 0;
    let inProcess = 0;
    let passOrder = 0;
    let app = 0;
    querySnapshot.docs.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;

      if (data.fare) {
        money += Number(data.fare);
      }

      if (data.status === "completed") {
        completed += 1;
      }

      if (data.source === "mobile") {
        app += 1;
      }

      if (data.status === "reassigned") {
        reassigned += 1;
      }
      if (data.status === "assigned") {
        assigned += 1;
      }
      if (data.status === "unassigned") {
        unassigned += 1;
      }
      if (data.status === "inProcess") {
        inProcess += 1;
      }
      if (data.status === "cancelled") {
        cancelled += 1;
      }

      if (data.isPassOrder){
        passOrder +=1;
      }

      list.push(data);
    });
    setOrders(list);
    setAnalytics({
      completed,
      reassigned,
      cancelled,
      assigned,
      unassigned,
      inProcess,
      passOrder,
      app,
      money,
    });
  };

  React.useEffect(() => {
    setLoading(true);
    firestore()
      .collection("orders")
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", onlyCurrentDay())
      .get()
      .then((querySnapshot) => {
        setLoading(false);
        orderResult(querySnapshot);
      });

    const appointmentsOnSnapshot = firestore()
      .collection("appointments")
      .limit(50)
      .where("status", "==", "pending")
      .onSnapshot((querySnapshot) => {
        const list = [];
        querySnapshot.docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          list.push(data);
        });
        setAppointments(list);
      });

    return () => {
      appointmentsOnSnapshot();
    };
  }, []);

  const onReset = () => {
    setActionsType("");
    setAppointmentSelected({});
    setOrderSelected({});
    setCAlertInfo({ title: "", subtitle: "" });
    setOpenCAlert(false);
    setLoading(false);
    setOpenDriversOrder(false);
    setShowOrder(false);
    setShowReceipt(false);
    setShowNavigation(false);
    setFareModal(false);
    setFare("");
    setDriversOrder([]);
    setNavigationData([]);
  };

  const onSelectType = (type) => {
    setColumnsType(type);
  };

  const onDriverOrder = (order, id) => {
    firestore()
      .collection("orders")
      .doc(id)
      .collection("subscribed_drivers")
      .orderBy("createdAt", "asc")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let dataDoc = doc.data();
          let obj = {};
          let { lastAssignedOrder = {} } = dataDoc;

          let selected_driver = getCheckField(order, "assignedDriver", "dNumber");

          let assignedAt = getSeconds(lastAssignedOrder, "assignedAt");
          obj.subscribedAt = getTimeSubscribe(dataDoc);
          obj.lastAssigned = assignedAt ? getDiffTime(order, assignedAt) : "--:--";
          obj.orderId = id;
          obj.invoiceId = order.invoiceId;
          obj.dNumber = dataDoc.dNumber;
          obj.discardReason = dataDoc?.discardReason ?? "";
          obj.retainSpot = fromBoolToString(dataDoc, "retainSpot", t);
          obj.startedAt = getHours(order, "selectionStartedAt");
          obj.eta = fromEtaToMinutes(dataDoc);
          obj.etaS = dataDoc.eta ? dataDoc.eta : "--:--";
          obj.selected_driver = selected_driver
          obj.selected= order?.assignedDriver ?? null
          obj.isDriver = dataDoc.driverID === getCheckField(order, "assignedDriver", "id");
          data.push(obj);
        });

        let driversOrderWithDiscardReason = data.map(drivers=>{
          let selected_driver = drivers.selected
          let findSelected_driver = data.find(findDriver=>findDriver.dNumber === selected_driver?.dNumber)
          if (findSelected_driver){
            selected_driver = {...selected_driver, ...findSelected_driver}
          }
          selected_driver = {...selected_driver, ...findSelected_driver}
          return {...drivers, discardReason:getDiscardReason(selected_driver, drivers)}
        })

        setOpenDriversOrder(true);
        setDriversOrder(driversOrderWithDiscardReason);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-06");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onNavigationOrder = (order, id) => {
    firestore()
      .collection("orders")
      .doc(id)
      .collection("navigation")
      .orderBy("createdAt", "asc")
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let dataDoc = doc.data();
          data.push(dataDoc);
        });
        setNavigationData(data);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-10");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onBlockPhone = (order) => {
    onPhoneBlacklist(order?.client, setLoading, t, true)
  };

  const onActions = async (type, id, collection, selected) => {
    let current;
    let action;
    if (collection === "order") {
      current = selected;
      current.id = id;
      setOrderSelected(selected);
    }
    if (collection === "appointment") {
      current = appointments.find((appointment) => appointment.id === id);
      current.id = id;
      setAppointmentSelected(selected);
    }

    setActionsType(`${type}-${collection}`);
    switch (`${type}-${collection}`) {
      case "reassign-order":
        setCAlertInfo({ title: "reassign_ms", subtitle: "execute_action_ms" });
        setOpenCAlert(true);
        break;
      case "complete-order":
        setCAlertInfo({ title: "complete_ms", subtitle: "execute_action_ms" });
        setOpenCAlert(true);
        break;
      case "payment-order":
        setOpenPayment(true);
        break;
      case "drivers-order":
        setLoading(true);
        onDriverOrder(current, id);
        break;
      case "delete-order":
        setCAlertInfo({ title: "delete_ms", subtitle: "execute_action_ms" });
        setOpenCAlert(true);
        break;
      case "block_phone-order":
        action = await Swal.fire({
          title: t("block_phone"),
          text: t("execute_action_ms"),
          showCancelButton: true,
          cancelButtonColor: "#c9c9c9",
          confirmButtonColor: "#2bb400",
        });
        if (action.isConfirmed) {
          onBlockPhone(selected);
        }
        break;
      case "show-order":
        setShowOrder(true);
        break;
      case "fare-order":
        setFareModal(true);
        break;
      case "navigation-order":
        onNavigationOrder(current, id);
        setShowNavigation(true);
        break;
      case "receipt-order":
        setShowReceipt(true);
        break;
      case "delete-appointment":
        setCAlertInfo({ title: "delete_ms", subtitle: "execute_action_ms" });
        setOpenCAlert(true);
        break;
      default:
        break;
    }
  };

  const onDeleteOrder = (data, id, isAppointment) => {
    let hasPermission = isUserPermission(dashboardSettings, userInfo, "cancelOrder");
    if (!hasPermission) {
      setLoading(false);
      return;
    }

    DeleteOrder(data, id, isAppointment)
      .then(() => {
        setLoadingSuccess(true);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-02");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        onReset();
      });
  };

  const onReAssignOrder = async (data, id) => {
    let hasPermission = isUserPermission(dashboardSettings, userInfo, "reAssignOrder");

    let isRetainSpot = false
    if (dashboardSettings.showOrderPassAction){
      const result = await Swal.fire({
        title: t('would you like to keep turn of driver?'),
        showCancelButton: true,
        confirmButtonText: t('yes'),
        cancelButtonText: t('no'),
        confirmButtonColor:'#058600',
        cancelButtonColor:'#9f0000',
      })
      if (result.isConfirmed) {
        isRetainSpot = true
      }
    }

    if (!hasPermission) {
      setLoading(false);
      return;
    }

    ReAssignOrder(data, id, isRetainSpot)
      .then(() => {
        setLoadingSuccess(true);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-04");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => onReset());
  };

  const onCompleteOrder = (data, id) => {
    CompleteOrder(data, id, fare)
      .then(() => {
        const newArr = [...orders].map((obj) => {
          if (obj.id === id) {
            return { ...obj, fare: fare ? Number(fare) : 0, status: "completed" };
          }
          return obj;
        });
        setOrders(newArr);
        setLoadingSuccess(true);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-05");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => onReset());
  };

  const onChangeFare = (data, id) => {
    firestore()
      .collection("orders")
      .doc(id)
      .update({
        fare: fare ? Number(fare) : 0,
        fareAt: firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        setLoadingSuccess(true);
        const newArr = [...orders].map((obj) => {
          if (obj.id === id) {
            return { ...obj, fare: fare ? Number(fare) : 0 };
          }
          return obj;
        });
        setOrders(newArr);
      })
      .catch((e) => {
        CaptureException(e, "Error-DAO-05");
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => onReset());
  };

  const onConfirmAction = () => {
    let selected = { ...orderSelected };
    switch (actionsType) {
      case "reassign-order":
        setLoading(true);
        onReAssignOrder(selected, selected.id);
        setOpenCAlert(false);
        break;
      case "complete-order":
        setLoading(true);
        onCompleteOrder(selected, selected.id);
        setOpenCAlert(false);
        break;
      case "fare-order":
        setLoading(true);
        onChangeFare(selected, selected.id);
        break;
      case "delete-order":
        setLoading(true);
        onDeleteOrder(selected, selected.id, false);
        setOpenCAlert(false);
        break;
      case "delete-appointment":
        setLoading(true);
        onDeleteOrder(appointmentSelected, appointmentSelected.id, true);
        setOpenCAlert(false);
        break;
      default:
        break;
    }
  };

  const onChangeFilter = (value, name) => {
    setFilterValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const filterRef = (ref) => {
    let from;
    let to;
    let { driver, credit, date, voucher, range, isPassOrder } = filterValues;

    if (range) {
      from = new Date(filterValues.from);
      to = new Date(filterValues.to);
    } else {
      from = RangeDateData(date).from;
      to = RangeDateData(date).to;
    }

    if (isPassOrder) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .where("isPassOrder", "==", true)
        .orderBy("createdAt", "desc");
    }

    if (voucher) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .where("has_voucher", "==", true)
        .orderBy("createdAt", "desc");
    }

    if (credit && driver) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .where("creditCard", "==", true)
        .where("assignedDriver.id", "==", driver.id)
        .orderBy("createdAt", "desc");
    }

    if (driver) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .where("assignedDriver.id", "==", driver.id)
        .orderBy("createdAt", "desc");
    }

    if (credit) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .where("creditCard", "==", true)
        .orderBy("createdAt", "desc");
    }

    if (from && to) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .orderBy("createdAt", "desc");
    }
    return ref;
  };

  const onFilterData = () => {
    setLoading(true);
    let ref = firestore().collection("orders");
    ref = filterRef(ref);
    ref
      .get()
      .then((querySnapshot) => {
        setLoading(false);
        orderResult(querySnapshot);
      })
      .catch((e) => {
        setLoading(false);
        CaptureException(e, "Error-DAO-07");
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const onCancelFilter = () => {
    setFilterValues(new FilterModel());
  };

  const orderMap = (order) => {
    let street = getCheckField(order, "from", "street");
    let createdAt = getDate(order);
    let streetNumber = getCheckField(order, "from", "streetNumber");
    let city = getCheckField(order, "from", "city");
    let creditCardNumber = getCheckField(order, "payment", "creditCardNumber");
    let paymentAmount = getCheckField(order, "payment", "amount");
    let dNumber = getCheckField(order, "assignedDriver", "dNumber");
    let dispatcher = getCheckField(order, "dispatcher", "displayName");
    let phoneNumber = getCheckField(order, "client", "phoneNumber");
    let title = `${createdAt} | ${streetNumber} ${street}, ${city}`;
    let from = `${streetNumber} ${street}, ${city}`;
    let { id, status, isCancellable, subscribed, invoiceId, fare: fareNumber } = order;

    return {
      id,
      status,
      invoiceId,
      createdAt,
      from,
      phone: phoneNumber,
      creditCardNumber,
      fare: fareNumber && typeof fareNumber === "number" ? formatter.format(fareNumber) : "$0.00",
      paymentAmount:
        paymentAmount && typeof paymentAmount === "number"
          ? formatter.format(paymentAmount)
          : "$0.00",
      driver: dNumber,
      dispatcher: dispatcher,
      source: t(order.source),
      isPaymentCard: dashboardSettings.isPaymentCard,
      isCancellable,
      isSubscribed: subscribed,
      title,
      actions: null,
      func: (type) => onActions(type, id, "order", order),
    };
  };

  const orderData = orders.map(orderMap);

  const appointmentsData = appointments.map((appointment) => ({
    ...appointment,
    actions: null,
    func: (type) => onActions(type, appointment.id, "appointment", appointment),
  }));

  return {
    orders: orderData,
    appointments: appointmentsData,
    loading,
    setLoading,
    loadingSuccess,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    openCAlert,
    setOpenCAlert,
    cAlertInfo,
    driversOrder,
    openDriversOrder,
    showOrder,
    orderSelected,
    fare,
    setFare,
    actionsType,
    openPayment,
    setOpenPayment,
    onReset,
    onConfirmAction,
    analytics,
    onSelectType,
    columnsType,
    filterValues,
    onChangeFilter,
    onFilterData,
    onCancelFilter,
    showReceipt,
    showNavigation,
    navigationData,
    fareModal,
    setFareModal,
  };
}
