import React from "react";
import { jsPDF } from "jspdf";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import CsvDownloader from "react-csv-downloader";
import { DateTime } from "luxon";
import autoTable from "jspdf-autotable";
import ReportsLineChart from "../../../examples/Charts/LineCharts/ReportsLineChart";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import { RangeDateData } from "../../../utilities/data";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function createHeaders(keys) {
  let result = [];
  for (let i = 0; i < keys.length; i += 1) {
    result.push({
      id: keys[i],
      dataKey: keys[i],
      name: keys[i],
      prompt: keys[i],
      width: 65,
      align: "center",
      padding: 0,
    });
  }
  return result;
}

const dateFormat = (date) => DateTime.fromFormat(date, "yLLdd").toFormat("LLL dd");
const getDayFormat = (date) => DateTime.fromJSDate(date).toFormat("dd");
const getMonthAndYear = (date) => DateTime.fromJSDate(date).toFormat("LLL y");

export default function Workdays({ data = [], filterValues }) {
  const { t } = useTranslation();

  const PdfFile = ({ downloadData }) => {
    try {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        floatPrecision: 16,
        compress: true,
      });

      let from;
      let to;

      if (filterValues.range) {
        from = new Date(filterValues.from);
        to = new Date(filterValues.to);
      } else {
        from = RangeDateData(filterValues.date).from;
        to = RangeDateData(filterValues.date).to;
      }

      let fromLabel = getDayFormat(from);
      let toLabel = getDayFormat(to);
      let monthLabel = getMonthAndYear(to);

      doc.text(
        `${t("workedDays")}: ${toLabel} - ${fromLabel} ${monthLabel}`,
        105,
        10,
        null,
        null,
        "center"
      );

      let parserData = downloadData.map((d) => ({
        driver: d.driver,
        orders: d.qty.toString(),
        gain: formatter.format(d.fare),
        "20%": formatter.format(d.percentage),
        ...d,
      }));

      let parserDataRemovingSomeKeys = parserData.map((item) => {
        // eslint-disable-next-line no-param-reassign
        delete item.dates;
        // eslint-disable-next-line no-param-reassign
        delete item.driverId;
        // eslint-disable-next-line no-param-reassign
        delete item.qty;
        // eslint-disable-next-line no-param-reassign
        delete item.percentage;
        // eslint-disable-next-line no-param-reassign
        delete item.fare;
        return item;
      });

      let dataSummary = [
        {
          Orders: _.sumBy(downloadData, (o) => o.qty).toString(),
          Gain: formatter.format(_.sumBy(downloadData, (o) => o.fare)),
          "20%": formatter.format(_.sumBy(downloadData, (o) => o.percentage)),
        },
      ];

      const headersSummary = createHeaders(["Orders", "Gain", "20%"]);

      let h = Object.keys(parserDataRemovingSomeKeys[0]);

      const headers = createHeaders(h);

      doc.autoTable({
        body: dataSummary,
        columns: headersSummary,
        head: [["Orders", "Gain", "20%"]],
        tableWidth: "wrap",
      });

      doc.autoTable({
        body: parserDataRemovingSomeKeys,
        columns: headers,
        head: [h],
        startY: 35,
        tableWidth: "wrap",
      });

      doc.save(`workedDays.pdf`);
    } catch (e) {
      //
    }
  };

  const reducerDriver = (info) => {
    const result = Object.values(info.filter((x) => x.driver !== "")).reduce(
      (acc, { driverId, driver, fare, date }) => {
        acc[driverId] = {
          driverId,
          driver,
          fare: (acc[driverId] ? acc[driverId].fare : 0) + fare,
          percentage: (acc[driverId] ? acc[driverId].percentage : 0) + fare * 0.2,
          qty: (acc[driverId] ? acc[driverId].qty || 0 : 0) + 1,
          dates: {
            [dateFormat(date)]: "X",
            ...(acc[driverId] ? acc[driverId].dates : {}),
          },
          ...(acc[driverId] ? acc[driverId].dates : {}),
        };
        return acc;
      },
      {}
    );
    return _.orderBy(Object.values(result), ["driver"], ["asc"]);
  };

  const driversResult = React.useMemo(() => {
    let driverFilter = data.filter((x) => x.driver !== "");

    const result = Object.values(driverFilter).reduce((acc, { driverId, fare, driver }) => {
      acc[driverId] = {
        driverId,
        fare: (acc[driverId] ? acc[driverId].fare : 0) + fare,
        driver,
      };
      return acc;
    }, {});

    let groupByDriver = _.groupBy(driverFilter, "driver");

    let getDays = _.uniqBy(data, "date").map((d) => ({
      id: dateFormat(d.date),
      displayName: dateFormat(d.date),
    }));

    let dateToObject = _.fromPairs(_.map(getDays, (i) => [i.id, "-"]));

    let getGroupByDriverByDate = Object.entries(groupByDriver).map((r) => ({
      driver: r[0],
      ...dateToObject,
      ...reducerDriver(r[1])[0],
    }));

    const columns = [
      { id: "driver", displayName: "Drivers" },
      { id: "qty", displayName: "Orders" },
      { id: "fare", displayName: "Gain" },
      { id: "percentage", displayName: "20%" },
      ...getDays,
    ];

    return {
      chartData: _.orderBy(Object.values(result), ["driver"], ["asc"]),
      downloadData: getGroupByDriverByDate.map((r) => ({
        ...r,
      })),
      columns,
    };
  }, [data]);

  return (
    <>
      <ReportsLineChart
        cDescription="error"
        progress={100}
        title="workedDays"
        description="It's recommended that this report have a maximum of 7 days so that the information can be appreciated when it is downloaded as a pdf."
        chart={{
          labels: driversResult ? _.map(driversResult.chartData, "driver") : [],
          datasets: {
            label: t("fare"),
            data: driversResult ? _.map(driversResult.chartData, "fare") : [],
          },
        }}
      />

      <MDBox m={1} mt={5} display="flex" flexDirection="row" justifyContent="flex-end">
        <MDButton
          onClick={() => PdfFile(driversResult)}
          style={{ width: 200 }}
          variant="gradient"
          size="medium"
          color="error"
        >
          {t("download")} PDF
        </MDButton>

        <CsvDownloader
          bom={false}
          filename={t("workedDays")}
          columns={driversResult.columns}
          datas={driversResult.downloadData ?? []}
          prefix={false}
          suffix={false}
        >
          <MDButton
            style={{ width: 200, marginLeft: 10 }}
            variant="gradient"
            size="medium"
            color="success"
          >
            {t("download")} Excel
          </MDButton>
        </CsvDownloader>
      </MDBox>
    </>
  );
}
