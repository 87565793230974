import * as React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";

export default function HeaderOptions({ addDriver, openSchedules }) {
  const { t } = useTranslation();

  return (
    <>
      <Grid item>
        <MDButton
          sx={{
            width: 125,
          }}
          onClick={addDriver}
          color="info"
        >
          {t("add")}
        </MDButton>
      </Grid>
    </>
  );
}
