import * as Sentry from "@sentry/react";
import { DateTime } from "luxon";
import { firestore } from "../../firebase";

export const TICKETS_SUCCESS = "TICKETS_SUCCESS";

export function Success(payload) {
  return {
    type: TICKETS_SUCCESS,
    payload,
  };
}

export function getTickets() {
  return async function f(dispatch) {
    try {
      let from = DateTime.now().startOf("month").minus({ months: 1 }).toJSDate();
      firestore()
        .collection("orders")
        .orderBy("createdAt", "desc")
        .where("ticketStatus", "==", "open")
        .where("createdAt", ">=", from)
        .limit(1)
        .onSnapshot((querySnapshot) => {
          dispatch(Success(!querySnapshot.empty));
        });
    } catch (e) {
      Sentry.captureException(e);
    }
  };
}
