import * as React from "react";
import { IconButton } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default function DeviceActions({ onClick, data }) {
  const onHandle = (type) => {
    onClick(type);
  };

  return (
    <>
      <IconButton
        disabled={data.isDisabled}
        size="large"
        style={{ padding: 0, margin: 0 }}
        onClick={() => onHandle("add")}
      >
        <CheckCircleOutlineIcon style={{ color: "green" }} />
      </IconButton>

      <IconButton
        size="large"
        style={{ padding: 0, margin: 0, marginLeft: 20 }}
        onClick={() => onHandle("remove")}
      >
        <DeleteOutlineIcon style={{ color: "red" }} />
      </IconButton>
    </>
  );
}
