import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import CDialog from "../../../../../components/Custom/CDialog";
import MDBox from "../../../../../components/MDBox";
import CPaper from "../../../../../components/Custom/CPaper";
import MDInput from "../../../../../components/MDInput";

export default function ShowClientRecordModal({ open, close, data, onClick, onChange }) {
  const { t } = useTranslation();

  return (
    <CDialog maxWidth="xl" title="client" close={close} open={open}>
      <CPaper style={{ marginTop: 10, padding: 30 }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              value={data.completed_orders}
              onChange={(e) =>
                onChange((prevState) => ({ ...prevState, completed_orders: e.target.value }))
              }
              name="completed_orders"
              style={{ marginRight: 3 }}
              placeholder={t("completed")}
              label={t("completed")}
              type="number"
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              value={data.cancelled_orders}
              onChange={(e) =>
                onChange((prevState) => ({ ...prevState, cancelled_orders: e.target.value }))
              }
              name="cancelled_orders"
              style={{ marginRight: 3 }}
              placeholder={t("cancelled")}
              label={t("cancelled")}
              type="number"
              size="small"
            />
          </Grid>
        </Grid>
      </CPaper>
      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton style={{ marginRight: 20 }} onClick={onClick} variant="gradient" color="success">
          {t("save")}
        </MDButton>
        <MDButton onClick={() => close(false)} variant="gradient" color="error">
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
