import * as React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import CAutocomplete from "../../../components/Custom/CAutocomplete";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

export default function HeaderOptions({
  currentPeriod,
  periodsData,
  onPeriodSelected,
  updateAmountPayment,
  updateAmountDueDriver,
  amount,
  setAmount,
  amountDue,
  setAmountDue,
  setOpenStripeDetail,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Grid style={{ width: 350 }} item xs={12} md={2}>
        <MDInput
          size="small"
          fullWidth
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          name="lastName"
          label={`${t("amount of dues")}`}
          type="text"
          style={{}}
          InputProps={{
            endAdornment: (
              <MDButton
                style={{ width: 200 }}
                size="small"
                onClick={updateAmountPayment}
                variant="gradient"
                color="error"
              >
                {t("update")}
              </MDButton>
            ),
          }}
        />
      </Grid>
      <Grid style={{ width: 350 }} item xs={12} md={2}>
        <MDInput
          size="small"
          fullWidth
          value={amountDue}
          onChange={(e) => setAmountDue(e.target.value)}
          name="lastName"
          label={`${t("amount_due")}`}
          type="text"
          style={{}}
          InputProps={{
            endAdornment: (
              <MDButton
                style={{ width: 200 }}
                size="small"
                onClick={updateAmountDueDriver}
                variant="gradient"
                color="error"
              >
                {t("update")}
              </MDButton>
            ),
          }}
        />
      </Grid>
      <Grid style={{ width: 150 }} item xs={12} md={1}>
        <MDButton
          size="medium"
          fullWidth
          onClick={() => setOpenStripeDetail(true)}
          variant="gradient"
          color="success"
        >
          {t("stripe")}
        </MDButton>
      </Grid>
      <Grid style={{ width: 250 }} item xs={12} md={2}>
        <CAutocomplete
          value={currentPeriod}
          isLabel
          label="label"
          name="period"
          placeholder="payment_period"
          options={periodsData}
          onChange={(e, v) => onPeriodSelected(v)}
        />
      </Grid>
    </>
  );
}
