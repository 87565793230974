import * as React from "react";
import { useTranslation } from "react-i18next";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";

import CPaper from "../../../components/Custom/CPaper";

const ReportTypes = ["workedDays", "orders", "voucher", "creditCard", "dispatcher"];

export default function Body({ children, type, setType }) {
  const { t } = useTranslation();

  return (
    <CPaper style={{ marginTop: 25 }}>
      <MDBox my={2} mb={8} display="flex" flexDirection="row" justifyContent="space-around">
        {ReportTypes.map((ReportType) => (
          <MDButton
            key={ReportType}
            onClick={() => setType(ReportType)}
            style={{ width: 150 }}
            variant="gradient"
            size="medium"
            color={type === ReportType ? "info" : "secondary"}
          >
            {t(ReportType)}
          </MDButton>
        ))}
      </MDBox>
      {children}
    </CPaper>
  );
}
