import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Typography, ListItemIcon } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { useMaterialUIController } from "../../../context";

export default function ActiveActions({ onClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const handleClose = (type) => {
    setAnchorEl(null);
    if (typeof type === "string" && type !== "close") {
      onClick(type);
    }
  };

  return (
    <>
      <IconButton
        style={{ padding: 0, margin: 0 }}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        aria-controls={open ? "basic-menu" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: darkMode ? "#fff" : "#000" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleClose("show")} style={{ marginBottom: 10 }}>
          <ListItemIcon>
            <EditIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("edit")}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleClose("password")} style={{ marginBottom: 10 }}>
          <ListItemIcon>
            <LockIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("password")}</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleClose("close")} style={{ marginBottom: 10 }}>
          <ListItemIcon>
            <CloseIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("close")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
