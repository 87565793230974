import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Typography, ListItemIcon } from "@mui/material";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PeopleIcon from "@mui/icons-material/People";
import { useTranslation } from "react-i18next";
import AddCardIcon from "@mui/icons-material/AddCard";
import PhoneLockedIcon from "@mui/icons-material/PhoneLocked";
import { useMaterialUIController } from "../../../../../context";

export default function CMenu({ onClick, title, idDriver, isPaymentCard, isHeader }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const handleClose = (type) => {
    setAnchorEl(null);
    if (typeof type === "string" && type !== "close") {
      onClick(type);
    }
  };

  return (
    <>
      <IconButton
        disabled={isHeader}
        style={{ padding: 0, margin: 0 }}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        aria-controls={open ? "basic-menu" : undefined}
        onClick={(e) => (onClick ? handleClick(e) : undefined)}
      >
        <MoreVertIcon style={{ color: isHeader ? "#f6f6f6" : "#fff" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem disableRipple divider>
          <Typography variant="inherit">{title}</Typography>
        </MenuItem>

        {idDriver && (
          <MenuItem onClick={() => handleClose("backToDriver")} disableRipple>
            <ListItemIcon>
              <DriveEtaIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("back_to_driver")}</Typography>
          </MenuItem>
        )}

        {isPaymentCard && (
          <MenuItem onClick={() => handleClose("payment")} disableRipple>
            <ListItemIcon>
              <AddCardIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
            </ListItemIcon>
            <Typography variant="inherit">{t("payment_with_card")}</Typography>
          </MenuItem>
        )}

        <MenuItem onClick={() => handleClose("block_phone")}>
          <ListItemIcon>
            <PhoneLockedIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("block_phone")}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleClose("duplicate")} disableRipple>
          <ListItemIcon>
            <ControlPointDuplicateIcon
              fontSize="small"
              style={{ color: darkMode ? "#fff" : "#000" }}
            />
          </ListItemIcon>
          <Typography variant="inherit">{t("duplicate_order")}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleClose("drivers")}>
          <ListItemIcon>
            <PeopleIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("drivers")}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleClose("show")}>
          <ListItemIcon>
            <VisibilityIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("show")}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleClose("close")}>
          <ListItemIcon>
            <CloseIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
          </ListItemIcon>
          <Typography variant="inherit">{t("close")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
