import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";

import InputMaskPhone from "../../../components/Custom/InputMaskPhone";

export default function ModalDeviceName({ open, close, model, onCreate, onChange }) {
  const { t } = useTranslation();

  return (
    <CDialog title={`${model.brand} - ${model.model}`} close={close} open={open}>
      <br />
      <MDInput
        fullWidth
        value={model.displayName ?? ""}
        onChange={onChange}
        name="displayName"
        style={{ marginBottom: 25 }}
        placeholder={t("full_name")}
        type="text"
      />

      <InputMaskPhone
        fullWidth
        style={{ marginBottom: 25 }}
        label={t("phoneNumber")}
        name="phoneNumber"
        value={model.phoneNumber ?? ""}
        onChange={onChange}
      />

      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton onClick={onCreate} variant="gradient" color="success">
          {t("add")}
        </MDButton>
        <MDButton
          onClick={() => close(false)}
          variant="gradient"
          color="error"
          style={{ marginLeft: 20 }}
        >
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
