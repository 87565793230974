import * as React from "react";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import CAutocomplete from "./CAutocomplete";
import { autocompleteTypeDriverOptions } from "../../../utilities/data";
import { useMaterialUIController } from "../../../context";
import CLoadingSnackbar from "../../../components/Custom/CAlerts/CLoadingSnackbar";
import { memo } from "react";
import InputApiKey from "./InputApiKey";
import CPaper from "../../../components/Custom/CPaper";

const RoutingApiMemo = memo(function RoutingApi({ data, onChange, onClick, onChangeMapApi }) {

  const { t } = useTranslation();

  const [validList, setValidList] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const checkApi = async (apiKey) => {
    setLoading(true);
    let activeApi = data.active_dashboard;

    let latitude = 39.953818;
    let longitude = -75.044645;
    let latitudeC = 39.957831;
    let longitudeC = -75.037103;
    let time = 0;

    if (activeApi === "Here") {
      try {
        const here_url = `https://router.hereapi.com/v8/routes?transportMode=car&origin=${latitude},${longitude}&destination=${latitudeC},${longitudeC}&return=summary&apiKey=${apiKey}`;
        const hereRequest = await fetch(here_url).catch(() => {
        });
        let tripDataTime = await hereRequest.json();
        let here = tripDataTime.routes[0];
        time = here ? here.sections[0].summary.duration : 0;
      } catch (e) {
        time = 0;
      }
    }

    if (activeApi === "Geoapify") {
      try {
        const geoapify_url = `https://api.geoapify.com/v1/routing?waypoints=${latitude},${longitude}|${latitudeC},${longitudeC}&mode=drive&apiKey=${apiKey}`;
        const geoapifyRequest = await fetch(geoapify_url).catch(() => {
        });
        let tripDataTime = await geoapifyRequest.json();
        let geoapify = tripDataTime.features[0].properties;
        time = geoapify ? geoapify.time : 0;
      } catch (e) {
        time = 0;
      }
    }

    if (activeApi === "Mapbox") {
      try {
        const mapbox_url = `https://api.mapbox.com/directions-matrix/v1/mapbox/driving/-74.09453138709068,41.14129339810859;-74.242774,40.720739?access_token=${apiKey}`;
        const mapboxRequest = await fetch(mapbox_url).catch(() => {
        });
        let tripDataTime = await mapboxRequest.json();
        time = tripDataTime?.code ==='Ok'? 1 : 0
      } catch (e) {
        time = 0;
      }
    }

    setValidList((prevState) => ({ ...prevState, [apiKey]: time }));
    setLoading(false);
  };

  return (
    <CPaper style={{ marginTop: 20, padding: 25 }}>
      <CAutocomplete
        value={data.active}
        onChange={(event, newValue) => {
          onChange(event, newValue, "active", "mapApi");
        }}
        options={autocompleteTypeDriverOptions}
        label="Map Api Key"
      />

      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[0]}  index={0}  onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[1]}  index={1}  onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[2]}  index={2}  onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[3]}  index={3}  onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[4]}  index={4}  onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[5]}  index={5}  onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[6]}  index={6}  onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[7]}  index={7}  onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[8]}  index={8}  onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[9]}  index={9}  onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[10]} index={10} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[11]} index={11} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[12]} index={12} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[13]} index={13} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[14]} index={14} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[15]} index={15} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[16]} index={16} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[17]} index={17} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[18]} index={18} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[19]} index={19} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[20]} index={20} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[21]} index={21} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[22]} index={22} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[23]} index={23} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[24]} index={24} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[25]} index={25} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[26]} index={26} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[27]} index={27} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[28]} index={28} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[29]} index={29} onChangeMapApi={onChangeMapApi} validList={validList}/>
      <InputApiKey listType={"list"} checkApi={checkApi} apis={data?.list[30]} index={30} onChangeMapApi={onChangeMapApi} validList={validList}/>

      <MDBox mt={3} display="flex" justifyContent="flex-end">
        <MDButton onClick={onClick} variant="gradient" size="medium" color="info">
          {t("update")}
        </MDButton>
      </MDBox>
      <CLoadingSnackbar open={loading} />
      <Snackbar
        autoHideDuration={1500}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={() => setOpen(false)}
        message={t("copy_clipboard_ms")}
      />
    </CPaper>
  );
});

export default RoutingApiMemo;
