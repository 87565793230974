import React from "react";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";

export default function CChipByStatus({ status }) {
  const { t } = useTranslation();

  const color = React.useMemo(() => {
    switch (status) {
      case "assigned":
      case "pickup":
        return "#de4800";
      case "reassigned":
        return "#2a3eb1";
      case "paid":
      case "completed":
      case "active":
      case "free":
        return "#09a900";
      case "unassigned":
        return "#008394";
      case "pending":
      case "cancelled":
        return "#ea0000";
      case "inProcess":
      case "disabled":
      case "busy":
        return "#b71c1c";
      case "unaccepted":
      case "dispatch_failed":
      default:
        return "#37474f";
    }
  }, [status]);

  return (
    <Chip
      label={t(status)}
      style={{ backgroundColor: color, color: "#fff", fontWeight: "bold", fontSize: 14 }}
    />
  );
}
