import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Grid } from "@mui/material";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import { firestore } from "../../../firebase";
import CPaper from "../../../components/Custom/CPaper";
import DataTable from "../../../examples/Tables/DataTable";
import TSTextClipboard from "../../../components/Custom/TSTextClipboard";
import TSText from "../../../components/Custom/TSText";
import { fromBoolToString, fromEtaToMinutes, getDiffTime, getSeconds, getTimeSubscribe } from "../../../utilities/func";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

export default function OrderSubscriptionModal({ open, close, model }) {
  const { t } = useTranslation();
  const [list, setList] = React.useState([]);
  const [quantity, setQuantity] = React.useState(25);
  const [loading, setLoading] = React.useState(false);

  const getOrders = async () => {
    setLoading(true);
    firestore().collectionGroup("subscribed_drivers")
      .where("driverID", "==", model.id)
      .orderBy("createdAt", "desc")
      .limit(quantity)
      .get()
      .then(docs => {
        let items = [];
        docs && docs.forEach(async doc => {
          let data = {}
          data.currentDriver = doc.data()
          data.currentDriver.subscribedAt = getTimeSubscribe(doc.data()) ?? "";
          let { lastAssignedOrder = {} } = doc.data();
          let assignedAt = getSeconds(lastAssignedOrder, "assignedAt");
          data.currentDriver.lastAssigned = assignedAt ? getDiffTime(doc.data(), assignedAt) : "--:--";
          data.currentDriver.retainSpot = fromBoolToString(doc.data(), "retainSpot", t) ?? "";
          data.currentDriver.eta = fromEtaToMinutes(doc.data() ?? "")

          data.id = doc?.ref?.parent?.parent?.id ?? null
          data.ref = doc.ref.parent.parent;
          items.push(data);
        });

        getOrderList(items).then(result => {
          setList(result);
          setLoading(false);
        }).catch(e => {console.log(e)});
      }).catch(err => {
      console.log(err);
    });
  };

  const getOrderList = async (data) => {
    return Promise.all(data.map(async (item) => {
      const doc = await item.ref.get();
      const date = DateTime.fromSeconds(doc.data().createdAt.seconds).toFormat("dd/LL/yyyy");

      let selectionStartedAt = DateTime.fromSeconds(doc.data().selectionStartedAt.seconds).toFormat("HH:mm")

      let obj = {
        ...item,
        date,
        from: doc.data().from,
        invoiceId: doc.data().invoiceId,
        selectionStartedAt
      };
      obj.driverSelected = null
      if (doc.data().assignedDriver){
        const assignedDriver = await firestore().collection('orders').doc(item.id).collection("subscribed_drivers").doc(doc.data().assignedDriver.id).get();
        obj.driverSelected = assignedDriver.data();
        obj.driverSelected.subscribedAt = getTimeSubscribe(assignedDriver.data()) ?? "";
        let { lastAssignedOrder = {} } = assignedDriver.data();
        let assignedAt = getSeconds(lastAssignedOrder, "assignedAt");
        obj.driverSelected.lastAssigned = assignedAt ? getDiffTime(doc.data(), assignedAt) : "--:--";
        obj.driverSelected.retainSpot = fromBoolToString(assignedDriver.data(), "retainSpot", t) ?? "";
        obj.driverSelected.eta = fromEtaToMinutes(assignedDriver.data() ?? "")
      }
      return obj;
    }));
  };

  const toExport = () => {
    try {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        orientation: "l",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        floatPrecision: 16,
        compress: true,
      });


      list.map(info => {
        if (info.driverSelected && info.currentDriver) {
          autoTable(doc, {
            head: [
              [`Invoice\n${info.invoiceId}`, `Date\n${info.date}`, `Start\n${info.selectionStartedAt}`, `Selected\n${info.driverSelected.dNumber}`, "", ""],
              ["Driver", "Subscribed", "Time", "Last order", "Have a turn", "Reason"]
            ],
            body: [
              [info.currentDriver.dNumber, info.currentDriver.subscribedAt, info.currentDriver.eta, info.currentDriver.lastAssigned, info.currentDriver.retainSpot, info.currentDriver.discardReason],
              [info.driverSelected.dNumber, info.driverSelected.subscribedAt, info.driverSelected.eta, info.driverSelected.lastAssigned, info.driverSelected.retainSpot, info.driverSelected.discardReason]
            ],
          });
        }
      });

      doc.save(`the_fight.pdf`);
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <CDialog fullScreen title="order subscription" close={close} open={open}>
      <CPaper style={{ marginTop: 10, marginBottom: 20, paddingTop: 20, paddingBottom: 20 }}>
        <label htmlFor="">Last orders subscribed</label> <br/>
        <MDInput
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 2);
          }}
          fullWidth
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          name="quantity"
          style={{ marginBottom: 25 }}
          label={`${t("quantity")}`}
          type="number"
        />

        <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">

          <MDButton
            loading={loading}
            disabled={loading}
            onClick={getOrders}
            variant="gradient"
            color="info"
          >
            {t("search")}
          </MDButton>

          <MDButton
            disabled={list.length ===0}
            onClick={toExport}
            variant="gradient"
            color="success"
            style={{ marginLeft: 20 }}
          >
            {t("export")}
          </MDButton>

          <MDButton
            onClick={() => close(false)}
            variant="gradient"
            color="error"
            style={{ marginLeft: 20, marginRight: 20 }}
          >
            {t("close")}
          </MDButton>

        </MDBox>

        <br />
        <br />

        {list.map(({ invoiceId, id, selectionStartedAt,date, driverSelected, currentDriver }) => {
          if (driverSelected && currentDriver) {
            return (
              <div key={id}>
                <CPaper style={{ marginBottom: 25 }}>
                  <Grid container>
                    <Grid item xs>
                      <TSTextClipboard
                        text={invoiceId}
                        copy={id}
                        subtitle="invoice_id"
                      />
                    </Grid>
                    <Grid item xs>
                      <TSText title={date} subtitle="date" />
                    </Grid>
                    <Grid item xs>
                      <TSText title={selectionStartedAt} subtitle="start_process" />
                    </Grid>
                    <Grid item xs>
                      <TSText title={driverSelected.dNumber} subtitle="selected_driver" />
                    </Grid>
                    <Grid item xs>
                      <TSText title={driverSelected?.eta ?? "N/A"} subtitle="eta" />
                    </Grid>
                  </Grid>

                  <DataTable
                    noEndBorder
                    PageSize={20}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    table={{
                      columns: [
                        { Header: "driver", accessor: "dNumber" },
                        { Header: "subscribedAt", accessor: "subscribedAt" },
                        { Header: "eta", accessor: "eta" },
                        { Header: "lastAssigned", accessor: "lastAssigned" },
                        { Header: "is_turn", accessor: "retainSpot" },
                        { Header: "reason", accessor: "discardReason" }
                      ],
                      rows: [driverSelected, currentDriver]
                    }}
                  />
                </CPaper>
              </div>
            );
          }
          return <div key={id} />;
        })}
      </CPaper>

    </CDialog>
  );
}
