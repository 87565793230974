import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { IconButton, Skeleton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import CTabs from "../../../components/Custom/tabs";
import { devicesIconTabs, devicesLabelTabs } from "../../../utilities/data";
import { devicesColumns } from "./columns";
import TSText from "../../../components/Custom/TSText";
import MDTypography from "../../../components/MDTypography";
import CPaper from "../../../components/Custom/CPaper";
import CTable from "../../../components/Custom/CTable";

const DeviceSkeleton = ({ data, t, deleteDevice, index }) => (
  <>
    <MDTypography style={{ textTransform: "capitalize" }} variant="text">
      {t("identifier")} : {data.uniqueId}
    </MDTypography>
    <CPaper>
      <MDBox display="flex" justifyContent="space-around" alignItems="center">
        <TSText variant="h6" title={data.brand} subtitle="brand" />
        <TSText variant="h6" title={data.model} subtitle="model" />
        <TSText variant="h6" title={data.isTablet ? t("yes") : t("no")} subtitle="isTablet" />
        <TSText variant="h6" title={`${data.platform} - v${data.app}`} subtitle="platform" />
        <TSText variant="h6" title={data.active ? t("yes") : t("no")} subtitle="active" />
        {data.displayName ? (
          <TSText variant="h6" title={data.displayName} subtitle="full_name" />
        ) : null}
        {data.phoneNumber ? (
          <TSText variant="h6" title={data.phoneNumber} subtitle="phoneNumber" />
        ) : null}

        <IconButton
          size="large"
          style={{ padding: 0, margin: 0 }}
          onClick={() => deleteDevice(data, index)}
        >
          <DeleteOutlineIcon style={{ color: "red" }} />
        </IconButton>
      </MDBox>
    </CPaper>
  </>
);

const CustomSkeleton = () => (
  <>
    <Skeleton
      variant="rectangular"
      width={225}
      height={15}
      style={{ borderRadius: 4, marginBottom: 5 }}
    />
    <Skeleton variant="rectangular" width="100%" height={80} style={{ borderRadius: 4 }} />
  </>
);

export default function DevicesList({ open, close, devices, model, deleteDevice }) {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);
  const handleTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const filterByStatus = (data = [], status) => data.filter((val) => val.status === status);

  return (
    <CDialog title="devices" close={close} open={open}>
      <br />
      <CTabs
        labels={devicesLabelTabs}
        icons={devicesIconTabs}
        handle={handleTab}
        value={tabValue}
      />
      <br />
      {devices.length > 0 && model.devices && model.devices.length >= 2 && (
        <h3 style={{ color: "red", marginBottom: 10 }}>{t("no availability for more device")}</h3>
      )}
      {tabValue === 0 && (
        <CTable
          canSearch={false}
          columns={devicesColumns}
          data={filterByStatus(devices, "pending")}
          pageSize={5}
          hideFooterPagination
          hideFooter
          height={300}
        />
      )}

      {tabValue === 1 &&
        Array(model.devices.length)
          .fill(1)
          .map((d, n) => (
            <React.Fragment key={Math.random()}>
              {model.devices && model.devices[n] ? (
                <DeviceSkeleton
                  index={n}
                  t={t}
                  data={model.devices[n]}
                  deleteDevice={deleteDevice}
                />
              ) : (
                <CustomSkeleton />
              )}
              <br />
            </React.Fragment>
          ))}

      <br />
      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton
          style={{ marginLeft: 25 }}
          onClick={() => close(false)}
          variant="gradient"
          color="error"
        >
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
