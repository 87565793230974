import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { Calendar, Views, luxonLocalizer } from "react-big-calendar";
import { DateTime, Settings } from "luxon";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import "../../../assets/calendar.css";
import CAlert from "../../../components/Custom/CAlerts/CAlert";

Settings.defaultLocale = "en";
const localizer = luxonLocalizer(DateTime);

export default function AddScheduleModal({ open, close, onCreate }) {
  const { t } = useTranslation();

  const [events, setEvents] = React.useState([]);
  const [showAlert, setShowAlert] = React.useState(false);
  const [selectEvent, setSelectEvent] = React.useState("");

  let formats = {
    dayFormat: (date) => DateTime.fromJSDate(date).toFormat("EEEE"),
  };

  const onConfirm = () => {
    let filtered = [...events].filter((event) => event.title !== selectEvent.title);
    setEvents(filtered);
    setShowAlert(false);
  };

  const confirmSchedule = () => {
    let workingHours = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    };

    let sortHours = [...events].sort((a, b) => new Date(a.start) - new Date(b.start));

    sortHours.forEach((event) => {
      let day = DateTime.fromJSDate(event.start).toFormat("EEEE");
      let start = DateTime.fromJSDate(event.start).toFormat("HH:mm:ss");
      let end = DateTime.fromJSDate(event.end).toFormat("HH:mm:ss");
      if (day === "Sunday") {
        workingHours["0"].push(start);
        workingHours["0"].push(end);
      }
      if (day === "Monday") {
        workingHours["1"].push(start);
        workingHours["1"].push(end);
      }
      if (day === "Tuesday") {
        workingHours["2"].push(start);
        workingHours["2"].push(end);
      }
      if (day === "Wednesday") {
        workingHours["3"].push(start);
        workingHours["3"].push(end);
      }
      if (day === "Thursday") {
        workingHours["4"].push(start);
        workingHours["4"].push(end);
      }
      if (day === "Friday") {
        workingHours["5"].push(start);
        workingHours["5"].push(end);
      }
      if (day === "Saturday") {
        workingHours["6"].push(start);
        workingHours["6"].push(end);
      }
    });
    if (!workingHours["0"].length) {
      workingHours["0"] = null;
    }
    if (!workingHours["1"].length) {
      workingHours["1"] = null;
    }
    if (!workingHours["2"].length) {
      workingHours["2"] = null;
    }
    if (!workingHours["3"].length) {
      workingHours["3"] = null;
    }
    if (!workingHours["4"].length) {
      workingHours["4"] = null;
    }
    if (!workingHours["5"].length) {
      workingHours["5"] = null;
    }
    if (!workingHours["6"].length) {
      workingHours["6"] = null;
    }
    onCreate(workingHours);
  };

  const onSelectEvent = (e) => {
    setShowAlert(true);
    setSelectEvent(e);
  };

  const handleSelect = ({ start, end }) => {
    let hours_start = DateTime.fromJSDate(start).toFormat("HH:mm");
    let end_start = DateTime.fromJSDate(end).toFormat("HH:mm");
    const title = `Schedule:  ${hours_start} - ${end_start}`;
    if (title)
      setEvents([
        ...events,
        {
          start,
          end,
          title,
        },
      ]);
  };

  return (
    <CDialog title="add schedule" close={close} open={open} fullScreen>
      <MDBox
        sx={{ marginTop: 1, marginBottom: 1 }}
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <MDButton sx={{ width: 125 }} onClick={confirmSchedule} variant="gradient" color="info">
          {t("add")}
        </MDButton>
        <MDButton
          sx={{ width: 125 }}
          onClick={() => close(false)}
          variant="gradient"
          color="error"
          style={{ marginLeft: 20 }}
        >
          {t("close")}
        </MDButton>
      </MDBox>
      <Calendar
        defaultView={Views.WEEK}
        toolbar={false}
        selectable
        views={{
          week: true,
        }}
        localizer={localizer}
        formats={formats}
        onSelectEvent={onSelectEvent}
        events={events}
        onSelectSlot={handleSelect}
        scrollToTime={new Date(1970, 1, 1, 6)}
        date={new Date(1970, 1, 1, 6)}
        onNavigate={() => {}}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100vh" }}
      />
      <CAlert
        close={setShowAlert}
        open={showAlert}
        title="delete"
        subtitle="execute_action_ms"
        isConfirm={onConfirm}
      />
    </CDialog>
  );
}
