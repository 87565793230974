import React from "react";

import Snackbar from "@mui/material/Snackbar";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";

export default function CLoadingSnackbar({
  open,
  title = "processing",
  duration = 6000,
  isSuccess,
  isError,
  close,
}) {
  const { t } = useTranslation();

  return (
    <Snackbar
      color="success"
      open={open}
      onClose={() => {
        if (close) {
          close(false);
        }
      }}
      sx={{ zIndex: 9000 }}
      autoHideDuration={duration}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={t(title)}
    >
      {!isSuccess ? (
        <Alert
          style={{
            backgroundColor: isError ? "red" : "#2c3c58",
            color: "#fff",
            paddingRight: 20,
            paddingLeft: 20,
          }}
          variant="filled"
          severity={isError ? "error" : "info"}
        >
          {t(title)}
        </Alert>
      ) : (
        <Alert
          style={{ backgroundColor: "#2e7d32", color: "#fff", minWidth: 150 }}
          severity="success"
          variant="filled"
        >
          {t(title)}
        </Alert>
      )}
    </Snackbar>
  );
}
