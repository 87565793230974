import MDBox from "components/MDBox";
import * as React from "react";

const textHeaderStyle = {
  fontSize: 14,
  color: "#000",
  fontWeight: "bold",
  width: 200
};
export default function RenderDriverLocation({
                                               isHeader,
                                               name,
                                               dNumber,
                                               time,
                                               bgPermission,
                                               appState,
                                               version,
                                               geofenceDistance,
                                               geofenceIsInside,
                                             }) {
  return (
    <MDBox
      style={{
        background: isHeader ? "#f6f6f6" : "#eee",
        marginTop: 5,
        borderRadius: 4,
        padding: 5
      }}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <small style={textHeaderStyle}>{name}</small>
      <strong style={textHeaderStyle}>{dNumber}</strong>
      <strong style={textHeaderStyle}>{time}</strong>
      <strong style={textHeaderStyle}>{bgPermission}</strong>
      <strong style={textHeaderStyle}>{appState}</strong>
      <strong style={textHeaderStyle}>{geofenceDistance??"---"}</strong>
      <strong style={textHeaderStyle}>{geofenceIsInside??"---"}</strong>
      <strong style={textHeaderStyle}>{version}</strong>
    </MDBox>
  );
}
