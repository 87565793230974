export const APP_SETTINGS = "APP_SETTINGS";
export function success(payload) {
  return {
    type: APP_SETTINGS,
    payload,
  };
}

export function postAppSettings(payload) {
  return async function f(dispatch) {
    dispatch(success(payload));
  };
}
