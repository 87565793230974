import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as React from "react";
import CTable from "../../components/Custom/CTable";
import useData from "./data";
import { activeColumns, registerColumns } from "./components/columns";
import HeaderOptions from "./components/HeaderOptions";
import CAlertSnackbar from "../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../components/Custom/CAlerts/CLoadingSnackbar";
import Header from "./components/Header";
import DriverInfo from "../../components/DriverInfo";
import ChangePassword from "./components/ChangePassword";
import DevicesList from "./components/DevicesList";
import AddDriverModal from "./components/AddDriverModal";
import DriverRegisterModal from "./components/DriverRegisterModal";
import AddScheduleToDriverModal from "./components/AddScheduleToDriverModal";
import ScheduleModal from "./components/ScheduleModal";
import AddScheduleModal from "./components/AddScheduleModal";
import ModalDeviceName from "./components/ModalDeviceName";
import OrderSubscriptionModal from "./components/OrderSubscriptionModal";
import CAlert from "../../components/Custom/CAlerts/CAlert";

function Drivers() {
  const {
    drivers,
    registers,
    loading,
    loadingSuccess,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    onSelectType,
    showPassword,
    columnsType,
    showDriver,
    driverModel,
    onChange,
    saveDriverData,
    onClickDeactivation,
    password,
    setPassword,
    changePassword,
    showDevices,
    devices,
    showNewDriver,
    setShowNewDriver,
    addNewDriver,
    driverRegisterModel,
    onChangeDriverRegister,
    setOpenSchedules,
    openSchedules,
    setOpenAddSchedules,
    openAddSchedules,
    scheduleList,
    onCreateSchedule,
    openAddSchedulesToDrive,
    onReset,
    saveScheduleToDriver,
    loadingRestart,
    onRestart,
    loadingPaymentStatusChange,
    onPaymentStatusChange,
    hidePaymentStatusChangeConfirmation,
    paymentStatusChangeConfirmation,
    addDeviceToDriverModal,
    deviceSelected,
    onChangeDeviceSelected,
    onAddDevice,
    setAddDeviceToDriverModal,
    openOrderSubscriptionModal,
    onDeleteDevice,
    showDriverRegister,
    onUpdateDriverRegister,
  } = useData();

  const columns = React.useMemo(() => {
    if (columnsType === "register") {
      return registerColumns;
    }
    if (columnsType === "active") {
      return activeColumns;
    }
    return activeColumns;
  }, [columnsType]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Header />
        <CTable
          canSearch
          filterBy="dNumber"
          columns={columns}
          data={columnsType === "active" ? drivers : registers}
          pageSize={25}
          headerOptions={
            <HeaderOptions
              onClick={onSelectType}
              value={columnsType}
              addDriver={() => setShowNewDriver(true)}
              openSchedules={() => setOpenSchedules(true)}
            />
          }
        />
        <Footer />
      </DashboardLayout>

      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />

      <CLoadingSnackbar open={loading} />

      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />

      {showDriver && (
        <DriverInfo
          canEdit
          onClickDeactivation={onClickDeactivation}
          open={showDriver}
          close={onReset}
          data={driverModel}
          onChange={onChange}
          onClick={saveDriverData}
          loadingRestart={loadingRestart}
          onRestart={onRestart}
          loadingPaymentStatusChange={loadingPaymentStatusChange}
          onPaymentStatusChange={onPaymentStatusChange}
        />
      )}
      <ChangePassword
        open={showPassword}
        close={onReset}
        password={password}
        setPassword={setPassword}
        onClick={changePassword}
      />

      {showDevices && (
        <DevicesList
          model={driverModel}
          open={showDevices}
          close={onReset}
          devices={devices}
          deleteDevice={onDeleteDevice}
        />
      )}

      {showNewDriver && (
        <AddDriverModal
          close={onReset}
          open={showNewDriver}
          model={driverRegisterModel}
          onChange={onChangeDriverRegister}
          onCreate={addNewDriver}
        />
      )}

      {showDriverRegister && (
        <DriverRegisterModal
          close={onReset}
          open={showDriverRegister}
          model={driverRegisterModel}
          onChange={onChangeDriverRegister}
          onUpdate={onUpdateDriverRegister}
        />
      )}

      {openSchedules && (
        <ScheduleModal
          open={openSchedules}
          close={onReset}
          scheduleList={scheduleList}
          addSchedule={() => {
            setOpenAddSchedules(true);
            setOpenSchedules(false);
          }}
        />
      )}

      {openAddSchedules && (
        <AddScheduleModal open={openAddSchedules} close={onReset} onCreate={onCreateSchedule} />
      )}

      {addDeviceToDriverModal && (
        <ModalDeviceName
          close={() => setAddDeviceToDriverModal(false)}
          open={addDeviceToDriverModal}
          onChange={onChangeDeviceSelected}
          model={deviceSelected}
          onCreate={onAddDevice}
        />
      )}

      {openAddSchedulesToDrive && (
        <AddScheduleToDriverModal
          model={driverModel}
          onChange={onChange}
          scheduleList={scheduleList}
          open={openAddSchedulesToDrive}
          close={onReset}
          onClick={saveScheduleToDriver}
        />
      )}

      {openOrderSubscriptionModal && (
        <OrderSubscriptionModal
          model={driverModel}
          open={openOrderSubscriptionModal}
          close={onReset}
        />
      )}

      <CAlert
        close={hidePaymentStatusChangeConfirmation}
        open={paymentStatusChangeConfirmation.show}
        title={paymentStatusChangeConfirmation.title}
        subtitle={paymentStatusChangeConfirmation.text}
        isConfirm={() => onPaymentStatusChange(paymentStatusChangeConfirmation.action)}
      />
    </>
  );
}

export default Drivers;
