import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import Swal from "sweetalert2";
import { AlertModel, DriverModel, DriverRegisterModel } from "../../utilities/model";
import ApiClient from "../../api";
import { auth, firebase, firestore } from "../../firebase";
import { CaptureException, getDateAp, LogsUsers } from "../../utilities/func";
import _ from "lodash";

export default function useData() {
  const { t } = useTranslation();
  const driversData = useSelector(({ driverList }) => driverList);
  const driversDeviceData = useSelector(({ driverDevices }) => driverDevices);
  const Schedule = useSelector(({ schedule }) => schedule);
  const [devices, setDevices] = React.useState([]);
  const [driversRegister, setDriversRegister] = React.useState([]);
  const [scheduleList, setScheduleList] = React.useState([]);
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [loading, setLoading] = React.useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [showDriver, setShowDriver] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showDevices, setShowDevices] = React.useState(false);
  const [showNewDriver, setShowNewDriver] = React.useState(false);
  const [showDriverRegister, setShowDriverRegister] = React.useState(false);
  const [openSchedules, setOpenSchedules] = React.useState(false);
  const [openAddSchedules, setOpenAddSchedules] = React.useState(false);
  const [openAddSchedulesToDrive, setOpenAddSchedulesToDrive] = React.useState(false);
  const [loadingRestart, setLoadingRestart] = React.useState(false);
  const [loadingPaymentStatusChange, setLoadingPaymentStatusChange] = React.useState(false);
  const [addDeviceToDriverModal, setAddDeviceToDriverModal] = React.useState(false);
  const [openOrderSubscriptionModal, setOpenOrderSubscriptionModal] = React.useState(false);
  const [deviceSelected, setDeviceSelected] = React.useState({});
  const [password, setPassword] = React.useState("");
  const [driverModel, setDriverModel] = React.useState(new DriverModel());
  const [driverRegisterModel, setDriverRegisterModel] = React.useState(new DriverRegisterModel());
  const [paymentStatusChangeConfirmation, setPaymentStatusChangeConfirmation] = React.useState({
    show: false,
    text: "",
    title: "",
    action: ""
  });

  const onReset = () => {
    setLoading(false);
    setLoadingPaymentStatusChange(false);
    setAddDeviceToDriverModal(false);
    setPassword("");
    setDevices([]);
    setCAlert(new AlertModel());
    setDriverRegisterModel(new DriverRegisterModel());
    setDriverModel(new DriverModel());
    setDeviceSelected({});
    setShowDriver(false);
    setShowPassword(false);
    setShowDriverRegister(false);
    setShowDevices(false);
    setShowNewDriver(false);
    setOpenSchedules(false);
    setOpenAddSchedules(false);
    setOpenAddSchedulesToDrive(false);
    setOpenOrderSubscriptionModal(false);
  };

  React.useEffect(() => {
    firebase
      .firestore()
      .collection("drivers_register")
      .where("registrationStatus", "==", "pending")
      .limit(25)
      .get()
      .then((docsData) => {
        let docs = [];
        docsData.forEach((device) => {
          let data = device.data();
          data.id = device.id;
          data.createdAt = DateTime.fromSeconds(data.createdAt.seconds).toFormat("ff");
          docs.push(data);
        });
        setDriversRegister(docs);
      });

    firebase
      .firestore()
      .collection("schedules")
      .orderBy("createdAt", "desc")
      .limit(25)
      .onSnapshot((docsData) => {
        let docs = [];
        docsData.forEach((device) => {
          let data = device.data();
          data.id = device.id;
          data.label = `schedule - ${docs.length + 1}`;
          docs.push(data);
        });
        setScheduleList(docs);
      });
  }, []);

  const [columnsType, setColumnsType] = React.useState("active");

  const onSelectType = (type) => {
    setColumnsType(type);
  };

  const getDevices = (id, driver) => {
    firebase
      .firestore()
      .collection("drivers")
      .doc(id)
      .collection("devices")
      .where("status", "==", "pending")
      .limit(10)
      .get()
      .then((docsData) => {
        let docs = [];
        docsData.forEach((device) => {
          let data = device.data();
          data.id = device.id;
          if (driver.currentDevice) {
            data.active =
              data.uniqueId === driver.currentDevice.uniqueId ? driver.currentDevice.active : false;
          }
          docs.push(data);
        });
        const sortedAsc = docs.sort(
          (objA, objB) => Number(objB.createdAt.seconds) - Number(objA.createdAt.seconds)
        );
        setDevices(sortedAsc);
        setShowDevices(true);
      });
  };

  const hidePaymentStatusChangeConfirmation = () =>
    setPaymentStatusChangeConfirmation({ show: false, text: "", title: "", action: "" });

  const onPaymentStatusChange = async (paymentStatus) => {
    setLoadingPaymentStatusChange(true);

    let ref = firestore().collection("drivers").doc(driverModel.id);

    try {
      await ref.set(
        {
          payment: { status: paymentStatus }
        },
        { merge: true }
      );
      setDriverModel((prevState) => ({ ...prevState, paymentStatus }));
      setLoadingSuccess(true);
      hidePaymentStatusChangeConfirmation();
    } catch (e) {
      setLoadingSuccess(false);
    } finally {
      setLoadingPaymentStatusChange(false);
    }
  };

  const createDriver = (id, dNumber) => {
    setLoading(true);
    ApiClient.post(`drivers/registration/${id}/accept`, {dNumber:dNumber})
      .then(() => {
        setLoadingSuccess(true);
        onReset();
      })
      .catch((e) => {
        setLoading(false);
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const onActions = (type, id, driver) => {
    setDevices([]);
    let currentDevices = driversDeviceData.find((d) => d.id === id);

    setDriverModel((prevState) => {
      const { payment = {} } = driver;
      const { status: paymentStatus = "N/A" } = payment;
      return { ...prevState, ...driver, paymentStatus, devices: currentDevices.devices };
    });

    switch (type) {
      case "show":
        setShowDriver(true);
        break;
      case "password":
        setShowPassword(true);
        break;
      case "devices":
        getDevices(id, driver);
        break;
      case "schedule":
        setOpenAddSchedulesToDrive(true);
        break;
      case "orderSubscription":
        setOpenOrderSubscriptionModal(true);
        break;
      case "payment_status_change_to_pending":
        setPaymentStatusChangeConfirmation({
          show: true,
          action: "pending",
          title: t("payment_status_change_to_pending"),
          text: t("payment_status_change_to_pending_confirmation")
        });
        break;
      case "payment_status_change_to_paid":
        setPaymentStatusChangeConfirmation({
          show: true,
          action: "paid",
          title: t("payment_status_change_to_paid"),
          text: t("payment_status_change_to_paid_confirmation")
        });
        break;
      default:
        break;
    }
  };




  const onActionsRegister = async (type, driver) => {
    try {
      let action;
      switch (type) {
        case "edit":
          setDriverRegisterModel(driver);
          setShowDriverRegister(true);
          break;
        case "approve":
          action = await Swal.fire({
            title: t("create driver"),
            text: t("execute_action_ms"),
            input: "text",
            inputPlaceholder: t("dNumber"),
            inputLabel: t("dNumber"),
            inputAutoFocus:true,
            inputValidator:(value)=>{
              if (!value) {
                return "You need to write something!";
              }
            },
            inputAttributes: {
              autocapitalize: "off",
              maxlength: "10",
              autocorrect: "off"
            },
            showCancelButton: true,
            cancelButtonColor: "#c9c9c9",
            confirmButtonColor: "#2bb400",
            allowOutsideClick: false,
            preConfirm: async (value) => {
              try {
                let getDriversNumber = _.map(driversData, "dNumber");
                let isDriver = getDriversNumber.includes(value)

                if (isDriver){
                return  Swal.showValidationMessage(t("A driver number with this identification already exists"));
                }

                return value
              } catch (error) {
                Swal.showValidationMessage(`Request failed: ${error}`);
              }
            }
          });


          if (action.isConfirmed) {
            createDriver(driver.id, action.value);
          }
          break;
        case "delete":
          action = await Swal.fire({
            title: t("delete"),
            text: t("execute_action_ms"),
            showCancelButton: true,
            cancelButtonColor: "#c9c9c9",
            confirmButtonColor: "#2bb400"
          });
          if (action.isConfirmed) {
            await firestore()
              .collection("drivers_register")
              .doc(driver.id)
              .update({ registrationStatus: "unapproved" });
            setDriversRegister(driversRegister.filter((rd) => rd.id !== driver.id));
          }
          break;
        default:
          break;
      }
    } catch (e) {
      Swal.fire("Error", e);
    }
  };

  const onAddDevice = () => {
    setLoading(true);
    setAddDeviceToDriverModal(false);
    let devicesList = [...devices];
    const { id, devices: Devices = [] } = driverModel;
    const driversCollection = firebase.firestore().collection("drivers").doc(id);
    let devicesOnDriver = [...Devices];
    let { length } = Devices;

    let devicesData = {
      platform: deviceSelected.platform,
      brand: deviceSelected.brand,
      system: deviceSelected.system,
      app: deviceSelected.app,
      isTablet: deviceSelected.isTablet,
      buildNumber: deviceSelected.buildNumber,
      model: deviceSelected.model,
      uniqueId: deviceSelected.uniqueId
    };

    if (deviceSelected.displayName) {
      devicesData.displayName = deviceSelected.displayName;
    }
    if (deviceSelected.phoneNumber) {
      devicesData.phoneNumber = deviceSelected.phoneNumber;
    }
    if (length === 0) {
      devicesOnDriver[0] = devicesData;
    } else {
      devicesOnDriver[1] = devicesData;
    }

    setDriverModel((prevState) => ({ ...prevState, devices: devicesOnDriver }));
    setDevices(devicesList.filter((d) => d.uniqueId !== devicesData.uniqueId));

    driversCollection
      .update({
        devices: devicesOnDriver
      })
      .then(() => {
        setLoadingSuccess(true);
        setLoading(false);
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      });

    driversCollection
      .collection("devices")
      .doc(devicesData.uniqueId)
      .delete()
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const onDeleteDevice = (data, index) => {
    try {
      setLoading(true);
      const { id, devices: Devices = [] } = driverModel;
      const driversCollection = firebase.firestore().collection("drivers").doc(id);
      let devicesOnDriver = [...Devices];
      if (index === 0) {
        devicesOnDriver.shift();
      } else {
        devicesOnDriver.pop();
      }

      setDriverModel((prevState) => ({ ...prevState, devices: devicesOnDriver }));

      driversCollection
        .update({
          devices: devicesOnDriver
        })
        .then(() => {
          setLoading(false);
          setLoadingSuccess(true);
        })
        .catch((e) => {
          setCAlert({ open: true, type: "error", ms: e.message });
        });
    } catch (e) {
      setLoading(false);
      setCAlert({ open: true, type: "error", ms: e.message });
    }
  };

  const onChange = (e, select, sName) => {
    const { name, value, checked, type } = e.target;
    const Value = type === "checkbox" ? checked : value;
    if (sName) {
      return setDriverModel((prevState) => ({ ...prevState, [sName]: select }));
    }
    return setDriverModel((prevState) => ({ ...prevState, [name]: Value }));
  };

  const onChangeDriverRegister = (e, select, sName) => {
    const { name, value } = e.target;
    if (sName) {
      return setDriverRegisterModel((prevState) => ({ ...prevState, [sName]: select }));
    }
    return setDriverRegisterModel((prevState) => ({ ...prevState, [name]: value }));
  };

  const onChangeDeviceSelected = (e) => {
    const { name, value } = e.target;
    return setDeviceSelected((prevState) => ({ ...prevState, [name]: value }));
  };

  const saveScheduleToDriver = () => {
    let driver = { ...driverModel };
    setLoading(true);
    let scheduleInfo = scheduleList.find((schedule) => schedule.id === driver.schedule.id);
    firestore()
      .collection("drivers")
      .doc(driver.id)
      .set(
        {
          workingHours: scheduleInfo.schedule,
          withSchedule: true
        },
        { merge: true }
      )
      .then(() => {
        onReset();
        setLoadingSuccess(true);
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
        onReset();
      });
  };

  const saveDriverData = async () => {
    setLoading(true);
    setShowDriver(false);

    let newData = {};

    if (driverModel.withSchedule) {
      if (Schedule) {
        newData.workingHours = Schedule.schedule;
      }
    }

    try {
      await ApiClient.put(`drivers/${driverModel.id}`, {
        dNumber: driverModel.dNumber,
        driverId: driverModel.driverId,
        email: driverModel.email,
        firstName: driverModel.firstName,
        lastName: driverModel.lastName,
        license: driverModel.license,
        licenseState: driverModel.licenseState,
        phoneNumber: driverModel.phoneNumber,
        status: driverModel.isStatus ? "disabled" : "active",
        vehicleType: driverModel.vehicleType
      });
      const status = driverModel.isStatus ? "offline" : driverModel.drivingStatus;
      await firestore()
        .collection("drivers")
        .doc(driverModel.id)
        .update({
          updatedBy: auth().currentUser.uid,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          drivingStatus: driverModel.isDrivingStatus ? "offline" : status,
          autoMarkedAsPaid: driverModel.autoMarkedAsPaid,
          hasAutoOrders: driverModel.hasAutoOrders,
          withSchedule: driverModel.withSchedule,
          hasPayment: driverModel.hasPayment,
          hasPaymentReader: driverModel.hasPaymentReader,
          isGeofence: driverModel.isGeofence,
          isVisibleUpdated: driverModel.isVisibleUpdated,
          "payment.amount": driverModel.amount ? Number(driverModel.amount) : 0,
          hasPendingPayment: driverModel.hasPendingPayment ?? false,
          ...newData
        });
      LogsUsers({ data: driverModel, type: "driver" });
      onReset();
      setLoadingSuccess(true);
    } catch (e) {
      setCAlert({ open: true, type: "error", ms: e.message });
      onReset();
      CaptureException(e, "Error-DMA-05");
    }
  };

  const onRestart = async () => {
    setLoadingRestart(true);
    let ref = firestore().collection("drivers").doc(driverModel.id);
    await ref.update({
      restartBy: auth().currentUser.uid,
      isRestart: true
    });

    setLoadingSuccess(true);
    setLoadingRestart(false);
  };

  const onClickDeactivation = async () => {
    setLoading(true);
    setShowDriver(false);
    await ApiClient.post(`drivers/${driverModel.id}/deactivate`, {
      activateAfter: driverModel.suspendTime
    })
      .then(() => {
        LogsUsers({ data: driverModel, type: "deactivate" });
        onReset();
        setLoadingSuccess(true);
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
        onReset();
        CaptureException(e, "Error-DMA-06");
      });
  };

  const onCreateSchedule = (workingHours) => {
    setLoading(true);
    firestore()
      .collection("schedules")
      .add({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        schedule: workingHours
      })
      .then(() => {
        onReset();
        setLoadingSuccess(true);
      })
      .catch((e) => {
        onReset();
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const addNewDriver = () => {
    let newDriver = { ...driverRegisterModel };
    let phoneNumber = null;
    if (newDriver.phoneNumber.length) {
      if (newDriver.phoneNumber.length < 10) {
        return setCAlert({ open: true, type: "error", ms: "invalid_phoneNumber" });
      }
      phoneNumber = `+1${newDriver.phoneNumber}`;
    }

    setLoading(true);
    return ApiClient.post("drivers", {
      ...newDriver,
      phoneNumber
    })
      .then(() => {
        setLoadingSuccess(true);
        onReset();
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
        onReset();
      });
  };

  const changePassword = () => {
    const userId = driverModel.id;
    setLoading(true);
    return ApiClient.post(`users/changePassword/${userId}`, {
      password
    })
      .then(() => {
        onReset();
        setLoadingSuccess(true);
      })
      .catch((e) => {
        onReset();
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const removeDevices = (cDevice) => {
    setLoading(true);
    firebase
      .firestore()
      .collection("drivers")
      .doc(driverModel.id)
      .collection("devices")
      .doc(cDevice.id)
      .delete()
      .then(() => {
        let newDe = devices.filter((d) => d.id !== cDevice.id);
        setDevices(newDe);
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onUpdateDriverRegister = () => {
    setLoading(true);
    setShowDriverRegister(false);
    let model = { ...driverRegisterModel };
    delete model.createdAt;
    firestore()
      .collection("drivers_register")
      .doc(model.id)
      .update(model)
      .then(() => {
        setDriversRegister(
          driversRegister.map((rd) => {
            if (rd.id === model.id) {
              return { ...rd, ...driversRegister };
            }
            return rd;
          })
        );
        Swal.fire(t("successful_operation"));
      })
      .catch((e) => {
        Swal.fire("Error", e);
      })
      .finally(() => {
        onReset();
      });
  };

  const onDeviceActions = (type, device) => {
    if (type === "add") {
      setAddDeviceToDriverModal(true);
      setDeviceSelected(device);
    }
    if (type === "remove") {
      removeDevices(device);
    }
  };

  const onScheduleActions = async (type, item) => {
    if (type === "make_default") {
      setLoading(true);

      let newDe = scheduleList.map((d) => {
        if (d.id === item.id) {
          return { ...d, isDefault: true };
        } else {
          return { ...d, isDefault: false };
        }
      });

      newDe.forEach(x => {
        firebase
          .firestore()
          .collection("schedules")
          .doc(x.id)
          .update({ isDefault: x.isDefault })
          .catch(() => {
          });
      });

      setLoading(false);
      setScheduleList(newDe);
    }

    if (type === "remove") {
      setLoading(true);
      firebase
        .firestore()
        .collection("schedules")
        .doc(item.id)
        .delete()
        .then(() => {
          let newDe = scheduleList.filter((d) => d.id !== item.id);
          setScheduleList(newDe);
        })
        .catch(() => {
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const driverData = [...driversData].map((driver) => {
    let {
      id,
      status,
      dNumber,
      drivingStatus,
      currentDevice,
      autoMarkedAsPaid,
      withSchedule,
      hasPayment,
      isVisibleUpdated,
      deviceInfo,
      displayName,
      payment = {}
    } = driver;

    const { status: paymentStatus = "N/A" } = payment;

    let version = currentDevice ? currentDevice.app : "----";
    return {
      id,
      status,
      paymentStatus,
      driverStatus: drivingStatus,
      dNumber: dNumber ?? "----",
      fullName: displayName ?? "----",
      version,
      withSchedule: withSchedule ? t("yes") : t("no"),
      autoMarkedAsPaid: autoMarkedAsPaid ? t("yes") : t("no"),
      hasPayment: hasPayment ? t("yes") : t("no"),
      isVisibleUpdated: isVisibleUpdated ? t("yes") : t("no"),
      old: deviceInfo,
      actions: null,
      func: (type) => onActions(type, id, driver)
    };
  });

  const driversRegisterData = [...driversRegister].map((driver_r) => ({
    ...driver_r,
    func: (action) => onActionsRegister(action, driver_r)
  }));

  const devicesData = [...devices].map((device) => ({
    ...device,
    date: getDateAp(device.createdAt),
    isTablet: device.isTablet ? t("yes") : t("no"),
    active: device.active ? t("yes") : t("no"),
    actions: null,
    // isDisabled: driverModel.devices ? driverModel.devices.length >= 2 : false,
    func: (type) => onDeviceActions(type, device)
  }));

  const scheduleListData = [...scheduleList].map((schedules) => ({
    ...schedules,
    actions: null,
    isDefault: schedules.isDefault ? "Yes" : "No",
    func: (type) => onScheduleActions(type, schedules)
  }));

  return {
    drivers: driverData,
    registers: driversRegisterData,
    devices: devicesData,
    loading,
    loadingSuccess,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    onSelectType,
    showPassword,
    columnsType,
    showDriver,
    driverModel,
    onChange,
    saveDriverData,
    onClickDeactivation,
    password,
    setPassword,
    changePassword,
    showDevices,
    showNewDriver,
    setShowNewDriver,
    addNewDriver,
    driverRegisterModel,
    onChangeDriverRegister,
    setOpenSchedules,
    openSchedules,
    setOpenAddSchedules,
    openAddSchedules,
    scheduleList: scheduleListData,
    onCreateSchedule,
    openAddSchedulesToDrive,
    onReset,
    saveScheduleToDriver,
    loadingRestart,
    onRestart,
    loadingPaymentStatusChange,
    onPaymentStatusChange,
    hidePaymentStatusChangeConfirmation,
    paymentStatusChangeConfirmation,
    addDeviceToDriverModal,
    setAddDeviceToDriverModal,
    deviceSelected,
    onChangeDeviceSelected,
    onAddDevice,
    onDeleteDevice,
    openOrderSubscriptionModal,
    showDriverRegister,
    onUpdateDriverRegister
  };
}
