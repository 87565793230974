import * as React from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Stack } from "@mui/material";
import CPaper from "../../../components/Custom/CPaper";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import { SettingsModel, SelectionModel } from "../../../utilities/model";
import MDTypography from "../../../components/MDTypography";
import { countryOptions, selectionTypeOptions, boolOptions } from "../../../utilities/data";
import { useClassNameSelect } from "./styles";
import CAutocomplete from "./CAutocomplete";

const input = {
  style: {
    marginBottom: 25,
  },
  inputProps: {
    autoComplete: "",
  },
  fullWidth: true,
};

export default function Dashboard({ data = new SettingsModel(), selectionData=new SelectionModel(), onChange,  onClick }) {
  const { t } = useTranslation();
  const classes = useClassNameSelect();


  console.log(data.showOrderPassAction)

  React.useEffect(()=>{
    let times = selectionData?.times;
    if (times){
      times = setTimes(times);
      onChange({ target: { name: "times", value: times } }, null, null, "selection");
    }
  },[])

  const onChangeTime = (e) => {
    const index = Number(e.target.name);
    const value = e.target.value;
    let times = selectionData.times;
    times[index] = Number(value);

    times = setTimes(times);

    onChange({ target: { name: "times", value: times } }, null, null, "selection");
  };

  const setTimes = (time) => {
    let times = time
    if (times[0] === 0) {
      times = [0];
    } else if (times[0] > 0 && (times[1] <= 0 || !times[1])) {
      times = [times[0], 0];
    } else if (times[1] > 0 && (times[2] <= 0 || !times[2])) {
      times = [times[0], times[1], 0];
    }
    return times;
  };

  return (
    <>
      <CPaper style={{ marginTop: 20, padding: 25 }}>
        <MDTypography variant="h4" fontWeight={false}>
          {t("general")}
        </MDTypography>
        <br />
        <Autocomplete
          fullWidth
          disableClearable
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.hasAssignOrder}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasAssignOrder", "dashboard");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("hasAssignOrder")} />}
        />

        <Autocomplete
          fullWidth
          disableClearable
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.isPaymentCard}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isPaymentCard", "dashboard");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("isPaymentCard")} />}
        />

        <Autocomplete
          fullWidth
          disableClearable
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.isFare}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isFare", "dashboard");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("fare")} />}
        />

        <Autocomplete
          fullWidth
          disableClearable
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.orderCreationType}
          onChange={(event, newValue) => {
            onChange(event, newValue, "orderCreationType", "dashboard");
          }}
          options={selectionTypeOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("driverSelection")} />}
        />

        <MDInput
          {...input}
          value={data.version}
          onChange={(e) => onChange(e, null, null, "dashboard")}
          name="version"
          label={t("version")}
        />
      </CPaper>

      <CPaper style={{ marginTop: 20, padding: 25 }}>
        <MDTypography variant="h4" fontWeight={false}>
          {t("order")}
        </MDTypography>
        <br />

        <Autocomplete
          fullWidth
          disableClearable
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.showOrderActions}
          onChange={(event, newValue) => {
            onChange(event, newValue, "showOrderActions", "dashboard");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("show order actions")} />}
        />


        <Autocomplete
          fullWidth
          disableClearable
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.showOrderPassAction}
          onChange={(event, newValue) => {
            onChange(event, newValue, "showOrderPassAction", "dashboard");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("show alert for keep turn when order is passed")} />}
        />

        <Autocomplete
          fullWidth
          disableClearable
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.isDuplicateIcon}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isDuplicateIcon", "dashboard");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("duplicate icon")} />}
        />

        <Autocomplete
          fullWidth
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.isPaymentIcon}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isPaymentIcon", "dashboard");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("payment icon")} />}
        />
      </CPaper>

      <CPaper style={{ marginTop: 20, padding: 25 }}>
        <MDTypography variant="h4" fontWeight={false}>
          {t("driverSelection")}
        </MDTypography>
        <br />


        {selectionData.retain_spot_always && (
          <Autocomplete
            fullWidth
            disableClearable
            style={{ marginBottom: 25 }}
            classes={classes}
            value={selectionData.retain_spot_always}
            onChange={(event, newValue) => {
              onChange(event, newValue, "retain_spot_always", "selection");
            }}
            options={boolOptions}
            getOptionLabel={(option) => t(option)}
            renderInput={(params) => <MDInput {...params} label={t("retain_spot_always")} />}
          />

        )}


        <MDTypography variant="h5" fontWeight={false} style={{marginBottom: 15}} >
          {t("times")}
        </MDTypography>

        {Array.isArray(selectionData?.times) && selectionData?.times.map((time, index) => {
            return (
              <MDInput
                key={index}
                {...input}
                value={selectionData.times[index]}
                onChange={onChangeTime}
                name={index}
                type={"number"}
                label={t("time") + " - " + (index + 1)}
              />
            );
        })}

      </CPaper>

      <CPaper style={{ marginTop: 20, padding: 25 }}>
        <MDTypography variant="h4" fontWeight={false}>
          {t("Dispatcher")}
        </MDTypography>
        <br />

        <Autocomplete
          fullWidth
          disableClearable
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.isDispatcherPassOrder}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isDispatcherPassOrder", "dashboard");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("isDispatcherPassOrder")} />}
        />
        <Autocomplete
          fullWidth
          disableClearable
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.isDispatcherCancelOrder}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isDispatcherCancelOrder", "dashboard");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("isDispatcherCancelOrder")} />}
        />

        <Autocomplete
          fullWidth
          disableClearable
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.isDispatcherCancelOrderByRequest}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isDispatcherCancelOrderByRequest", "dashboard");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => (
            <MDInput {...params} label={t("isDispatcherCancelOrderByRequest")} />
          )}
        />

        <Autocomplete
          fullWidth
          disableClearable
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.isDispatcherPayDriver}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isDispatcherPayDriver", "dashboard");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => <MDInput {...params} label={t("isDispatcherPayDriver")} />}
        />

        <Autocomplete
          fullWidth
          disableClearable
          style={{ marginBottom: 25 }}
          classes={classes}
          value={data.isDispatcherChangeDriverStatus}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isDispatcherChangeDriverStatus", "dashboard");
          }}
          options={boolOptions}
          getOptionLabel={(option) => t(option)}
          renderInput={(params) => (
            <MDInput {...params} label={t("isDispatcherChangeDriverStatus")} />
          )}
        />
      </CPaper>

      <CPaper style={{ marginTop: 20, padding: 25 }}>
        <MDTypography variant="h4" fontWeight={false}>
          {t("map")}
        </MDTypography>
        <br />

        <MDTypography variant="h5" fontWeight={false}>
          {t("map view")}
        </MDTypography>
        <br />

        <Stack style={{ marginBottom: 25 }} direction="column" spacing={3} display="flex">
          <Autocomplete
            fullWidth
            disableClearable
            classes={classes}
            value={data.mapType}
            onChange={(event, newValue) => {
              onChange(event, newValue, "mapType", "dashboard");
            }}
            options={["mapbox"]}
            getOptionLabel={(option) => t(option)}
            renderInput={(params) => <MDInput {...params} label={t("map type")} />}
          />

          <MDInput
            {...input}
            value={data.mapBoxApiKeyForMap}
            onChange={(e) => onChange(e, null, null, "dashboard")}
            name="mapBoxApiKeyForMap"
            label={t("mapBoxApiKey")}
          />
        </Stack>

        <MDTypography variant="h5" fontWeight={false}>
          {t("autocomplete")}
        </MDTypography>
        <br />

        <MDBox
          style={{ marginBottom: 25 }}
          flexDirection="row"
          display="flex"
          justifyContent="space-between"
        >
          <Autocomplete
            fullWidth
            disableClearable
            classes={classes}
            value={data.autocompleteType}
            onChange={(event, newValue) => {
              onChange(event, newValue, "autocompleteType", "dashboard");
            }}
            options={["gmaps", "here", "mapbox"]}
            getOptionLabel={(option) => t(option)}
            renderInput={(params) => <MDInput {...params} label={t("autocompleteType")} />}
          />
          <div style={{ marginLeft: 10 }} />
          <Autocomplete
            fullWidth
            disableClearable
            value={data.autocompleteCountry}
            onChange={(event, newValue) => {
              onChange(event, newValue, "autocompleteCountry", "dashboard");
            }}
            classes={classes}
            size="small"
            options={countryOptions}
            getOptionLabel={(option) => t(option)}
            renderInput={(params) => <MDInput {...params} label={t("autocompleteCountry")} />}
          />
        </MDBox>

        {data.autocompleteType === "here" && (
          <MDInput
            {...input}
            value={data.hereApiKey}
            onChange={(e) => onChange(e, null, null, "dashboard")}
            name="hereApiKey"
            label={t("hereApiKey")}
          />
        )}

        {data.autocompleteType === "mapbox" && (
          <MDInput
            {...input}
            value={data.mapBoxApiKey}
            onChange={(e) => onChange(e, null, null, "dashboard")}
            name="mapBoxApiKey"
            label={t("mapBoxApiKey")}
          />
        )}

        {data.autocompleteType === "gmaps" && (
          <MDInput
            {...input}
            value={data.gmapsApiKey}
            onChange={(e) => onChange(e, null, null, "dashboard")}
            name="gmapsApiKey"
            label={t("gmapsApiKey")}
          />
        )}

        {data.autocompleteType !== "gmaps" && (
          <MDInput
            {...input}
            value={data.mapBoxBbox}
            onChange={(e) => onChange(e, null, null, "dashboard")}
            name="mapBoxBbox"
            label={t("mapBoxBbox")}
          />
        )}

        <MDTypography variant="h5" fontWeight={false}>
          {t("map_center")}
        </MDTypography>

        <MDBox mt={3} flexDirection="row" display="flex" justifyContent="space-between">
          <MDInput
            {...input}
            value={data.lat}
            type="number"
            onChange={(e) => onChange(e, null, null, "dashboard")}
            name="lat"
            label={t("lat")}
          />
          <div style={{ marginLeft: 10 }} />
          <MDInput
            {...input}
            value={data.lng}
            type="number"
            onChange={(e) => onChange(e, null, null, "dashboard")}
            name="lng"
            label={t("lng")}
          />
        </MDBox>
      </CPaper>
      <MDButton
        style={{ marginTop: 40 }}
        size="large"
        fullWidth
        onClick={onClick}
        variant="gradient"
        color="info"
      >
        {t("update")}
      </MDButton>
    </>
  );
}
