import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import { Icon } from "@mui/material";
import { useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import MDTypography from "../../../../../components/MDTypography";

const tabStyle = {
  borderRadius: 0,
  borderBottomWidth: 3,
  borderStyle: "solid",
};

export default function CTabs({ value, handleChange, driversChat = {} }) {
  const { t } = useTranslation();
  const drivers = useSelector(({ driverList }) => driverList);

  const options = React.useMemo(() => {
    let values = Object.values(driversChat);
    let keys = Object.keys(driversChat);

    return { values, keys };
  }, [drivers, driversChat]);

  return (
    <Tabs
      style={{
        backgroundColor: "transparent",
        display: "inline-flex",
      }}
      value={value}
      onChange={handleChange}
    >
      <Tab
        style={{
          ...tabStyle,
          borderBottomColor: value === 0 ? "#344767" : "transparent",
          width: 65,
        }}
        label={
          <div style={{ display: "flex" }}>
            <Icon fontSize="medium">menu</Icon>
          </div>
        }
      />
      <Tab
        style={{
          ...tabStyle,
          borderBottomColor: value === 1 ? "#344767" : "transparent",
          width: 65,
        }}
        label={
          <div style={{ display: "flex" }}>
            <Icon fontSize="medium">record_voice_over</Icon>
          </div>
        }
      />
      {options.keys.map((option, index) => (
        <Tab
          key={option}
          style={{
            ...tabStyle,
            borderBottomColor: value === index + 2 ? "#344767" : "transparent",
            width: 65,
          }}
          label={
            <MDTypography
              variant="strong"
              style={{ flexDirection: "row", display: "flex", alignItems: "center" }}
            >
              {t(option)}
              {options.values[index] ? (
                <Chip color="error" size="small" label={options.values[index]} />
              ) : null}
            </MDTypography>
          }
        />
      ))}
    </Tabs>
  );
}
