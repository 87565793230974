import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useData from "./data";
import { orderColumns, appointmentsColumns, paymentColumns } from "./components/columns";
import CAlertSnackbar from "../../components/Custom/CAlerts/CAlertSnackbar";
import CLoadingSnackbar from "../../components/Custom/CAlerts/CLoadingSnackbar";
import CAlert from "../../components/Custom/CAlerts/CAlert";
import MDInput from "../../components/MDInput";
import DriverOrderModal from "../dashboard/components/ActiveOrders/components/DriverOrderModal";
import ShowOrderModal from "../dashboard/components/ActiveOrders/components/ShowOrderModal";
import { StripeProvider } from "../../provider/StripeProvider";
import Header from "./components/Header";
import HeaderOptions from "./components/HeaderOptions";
import ShowNavigationModal from "./components/ShowNavigationModal";
import ShowReceiptModal from "../../components/Custom/receipt/ShowReceiptModal";
import CTable from "../../components/Custom/CTable";
import CPayments from "../../components/Custom/payments";

function Orders() {
  const {
    orders,
    appointments,
    loading,
    setLoading,
    loadingSuccess,
    setLoadingSuccess,
    cAlert,
    setCAlert,
    openCAlert,
    setOpenCAlert,
    cAlertInfo,
    driversOrder,
    openDriversOrder,
    showOrder,
    orderSelected,
    fare,
    setFare,
    actionsType,
    openPayment,
    setOpenPayment,
    onReset,
    onConfirmAction,
    analytics,
    onSelectType,
    columnsType,
    filterValues,
    onChangeFilter,
    onFilterData,
    onCancelFilter,
    showReceipt,
    showNavigation,
    navigationData,
    fareModal,
    setFareModal,
  } = useData();
  const dashboardSettings = useSelector(({ settings }) => settings);
  const { t } = useTranslation();

  const columns = React.useMemo(() => {
    if (filterValues.credit) {
      return paymentColumns;
    }
    if (columnsType === "orders") {
      return orderColumns;
    }
    if (columnsType === "appointment") {
      return appointmentsColumns;
    }
    return orderColumns;
  }, [filterValues.credit, columnsType]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Header analytics={analytics} />

        <CTable
          canSearch
          columns={columns}
          data={columnsType === "orders" ? orders : appointments}
          pageSize={25}
          printPdf={true}
          headerOptions={
            <HeaderOptions
              onCancelFilter={onCancelFilter}
              onFilterData={onFilterData}
              onClick={onSelectType}
              value={columnsType}
              filterValues={filterValues}
              onChangeFilter={onChangeFilter}
            />
          }
        />

        <Footer />
      </DashboardLayout>

      <CAlertSnackbar alert={cAlert} close={setCAlert} duration={8000} />

      <CLoadingSnackbar open={loading} />

      <CLoadingSnackbar
        isSuccess
        open={loadingSuccess}
        close={setLoadingSuccess}
        title="success"
        duration={1500}
      />

      <CAlert
        disabled={dashboardSettings.isFare && actionsType === "complete-order" ? !fare : false}
        close={setOpenCAlert}
        open={openCAlert}
        title={cAlertInfo.title}
        subtitle={cAlertInfo.subtitle}
        isConfirm={onConfirmAction}
      >
        <div style={{ marginTop: 5 }} />
        {dashboardSettings.isFare && actionsType === "complete-order" ? (
          <MDInput
            fullWidth
            value={fare}
            onChange={(e) => setFare(e.target.value)}
            placeholder={t("price")}
            name="fare"
            type="number"
            size="small"
          />
        ) : null}
      </CAlert>

      <CAlert
        close={setFareModal}
        open={fareModal}
        title={cAlertInfo.title}
        subtitle={cAlertInfo.subtitle}
        isConfirm={onConfirmAction}
      >
        <div style={{ marginTop: 5 }} />
        <MDInput
          fullWidth
          value={fare}
          onChange={(e) => setFare(e.target.value)}
          placeholder={t("price")}
          name="fare"
          type="number"
          size="small"
        />
      </CAlert>

      {openDriversOrder && (
        <DriverOrderModal data={driversOrder} open={openDriversOrder} close={onReset} />
      )}

      {showOrder && <ShowOrderModal data={orderSelected} open={showOrder} close={onReset} />}
      {showNavigation && (
        <ShowNavigationModal
          data={orderSelected}
          navigationData={navigationData}
          open={showNavigation}
          close={onReset}
        />
      )}

      {showReceipt && (
        <ShowReceiptModal
          type="order"
          open={showReceipt}
          close={onReset}
          model={orderSelected}
          setLoading={setLoading}
        />
      )}

      {openPayment && (
        <StripeProvider>
          <CPayments
            open={openPayment}
            close={setOpenPayment}
            title="payment_with_card"
            subtitle={orderSelected.invoiceId}
            order={orderSelected}
          />
        </StripeProvider>
      )}
    </>
  );
}

export default Orders;
