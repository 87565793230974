import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import { ProfileModel } from "../../../utilities/model";

export default function UserModal({ open, close, model = new ProfileModel(), onCreate, onChange }) {
  const { t } = useTranslation();

  const isDisabled = React.useMemo(() => {
    if (model.id) {
      return !(model.firstName.length && model.lastName.length && model.email.length);
    }
    return !(
      model.firstName.length &&
      model.lastName.length &&
      model.email.length &&
      model.password.length >= 6
    );
  }, [model]);

  return (
    <CDialog title="staff" close={close} open={open}>
      <br />
      <MDInput
        fullWidth
        value={model.firstName}
        onChange={onChange}
        name="firstName"
        style={{ marginBottom: 25 }}
        label={`${t("firstName")}*`}
        type="text"
      />

      <MDInput
        fullWidth
        value={model.lastName}
        onChange={onChange}
        name="lastName"
        style={{ marginBottom: 25 }}
        label={`${t("lastName")}*`}
        type="text"
      />

      <MDInput
        fullWidth
        value={model.email}
        onChange={onChange}
        name="email"
        style={{ marginBottom: 25 }}
        label={`${t("email")}*`}
        type="text"
      />

      <MDInput
        fullWidth
        value={model.phoneNumber}
        onChange={onChange}
        name="phoneNumber"
        style={{ marginBottom: 25 }}
        label={t("phoneNumber")}
        type="text"
      />

      {!model.id && (
        <MDInput
          fullWidth
          value={model.password}
          onChange={onChange}
          name="password"
          style={{ marginBottom: 25 }}
          label={`${t("password")}*`}
          type="text"
        />
      )}
      <br />
      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton disabled={isDisabled} onClick={onCreate} variant="gradient" color="success">
          {!model.id ? t("add") : t("update")}
        </MDButton>
        <MDButton
          onClick={() => close(false)}
          variant="gradient"
          color="error"
          style={{ marginLeft: 20 }}
        >
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
