import { createStyles, makeStyles } from "@mui/styles";

export const useClassName = makeStyles(() =>
  createStyles({
    inputRoot: {
      height: `38px`,
      // width: "100%",
      alignContent: "center",
      alignItems: "center",
    },
  })
);
