import React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { firestore } from "../../firebase";
import { AlertModel, FilterModel } from "../../utilities/model";
import { RangeDateData } from "../../utilities/data";
import { CaptureException, getCheckField, getDate } from "../../utilities/func";

export default function useData() {
  const { t } = useTranslation();
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [filterValues, setFilterValues] = React.useState(new FilterModel());
  const [loading, setLoading] = React.useState(false);
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);

  const [orders, setOrders] = React.useState([]);
  const [type, setType] = React.useState("workedDays");

  const onChangeFilter = (value, name) => {
    setFilterValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const filterRef = (ref) => {
    let from;
    let to;
    let { driver, credit, date, voucher, range, allDrivers } = filterValues;

    if (allDrivers) {
      driver = null;
    }

    if (range) {
      from = new Date(filterValues.from);
      to = new Date(filterValues.to);
    } else {
      from = RangeDateData(date).from;
      to = RangeDateData(date).to;
    }
    if (voucher) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .where("has_voucher", "==", true)
        .orderBy("createdAt", "desc");
    }

    if (credit && driver) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .where("creditCard", "==", true)
        .where("assignedDriver.id", "==", driver.id)
        .orderBy("createdAt", "desc");
    }

    if (driver) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .where("assignedDriver.id", "==", driver.id)
        .orderBy("createdAt", "desc");
    }

    if (credit) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .where("creditCard", "==", true)
        .orderBy("createdAt", "desc");
    }

    if (from && to) {
      return ref
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .orderBy("createdAt", "desc");
    }
    return ref;
  };

  const onFilterData = () => {
    setLoading(true);
    setOrders([]);
    let ref = firestore().collection("orders");
    ref = filterRef(ref);
    ref
      .get()
      .then((querySnapshot) => {
        setLoading(false);
        const list = [];
        querySnapshot.docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          list.push(data);
        });
        setOrders(list);
      })
      .catch((e) => {
        setLoading(false);
        CaptureException(e, "Error-DRE-01");
        setCAlert({ open: true, type: "error", ms: e.message });
      });
  };

  const orderMap = (order) => {
    let streetNumber = getCheckField(order, "from", "streetNumber");
    let street = getCheckField(order, "from", "street");
    let city = getCheckField(order, "from", "city");

    let streetNumberTo = getCheckField(order, "to", "streetNumber");
    let streetTo = getCheckField(order, "to", "street");
    let cityTo = getCheckField(order, "to", "city");

    let creditCardNumber = getCheckField(order, "payment", "creditCardNumber");
    let paymentAmount = getCheckField(order, "payment", "amount");
    let driverName = getCheckField(order, "assignedDriver", "displayName");
    let dNumber = getCheckField(order, "assignedDriver", "dNumber");
    let dId = getCheckField(order, "assignedDriver", "id");
    let phoneNumber = getCheckField(order, "client", "phoneNumber");
    let client = getCheckField(order, "client", "displayName");
    let dispatcher = getCheckField(order, "dispatcher", "displayName");
    let dispatcherId = getCheckField(order, "dispatcher", "id");
    let from = `${streetNumber} ${street} ${city}`;
    let to = `${streetNumberTo} ${streetTo} ${cityTo}`;
    let { id, status, invoiceId, fare: fareNumber, has_voucher, creditCard } = order;

    let date = DateTime.fromSeconds(order.createdAt.seconds).toFormat("yLLdd");
    let createdAt = DateTime.fromSeconds(order.createdAt.seconds).toFormat("D");
    let createdAtFullName = DateTime.fromSeconds(order.createdAt.seconds).toFormat("ff");

    return {
      id,
      createdAt,
      created:createdAtFullName,
      has_voucher: has_voucher ?? false,
      has_creditCard: creditCard ?? false,
      date,
      driverName,
      dispatcher: dispatcher ?? dispatcherId,
      dispatcherId,
      fare: fareNumber ? Number(fareNumber) : 0,
      paymentAmount: paymentAmount ? Number(paymentAmount) : 0,
      status,
      invoiceId,
      from,
      to,
      phone: phoneNumber,
      client,
      creditCard: creditCardNumber,
      driver: dNumber,
      source: t(order.source),
    };
  };

  const orderData = orders.map(orderMap);

  return {
    orders: orderData,
    filterValues,
    onChangeFilter,
    onFilterData,
    cAlert,
    setCAlert,
    setLoadingSuccess,
    loading,
    loadingSuccess,
    setType,
    type,
  };
}
