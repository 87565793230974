import * as React from "react";
import { Button } from "@mui/material";

export default function ScheduleActions({ onClick }) {
  const onHandle = (type) => {
    onClick(type);
  };

  return (
    <>
      <Button
        style={{ padding: 0, margin: 0, color:"gray"}}
        onClick={() => onHandle("make_default")}
      >
        Make default
      </Button>

      <Button
        style={{ padding: 0, margin: 0, marginLeft: 20, color:"red" }}
        onClick={() => onHandle("remove")}
      >
        Remove
      </Button>
    </>
  );
}
