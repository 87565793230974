import React from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import CDialog from "../../../components/Custom/CDialog";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import TSCheck from "../../../components/Custom/TSCheck";

export default function PaymentDetails({
  open,
  close,
  onUpdate,
  amount,
  setAmount,
  model,
  hasPendingPayment,
  setHasPendingPayment,
  amountDueDriver,
  setAmountDueDriver,
}) {
  const { t } = useTranslation();

  return (
    <CDialog title="driver" subtitle={model.dNumber} close={() => close(false)} open={open}>
      <br />
      <br />
      <MDInput
        size="small"
        fullWidth
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        name="lastName"
        label={`${t("amount of dues")}`}
        type="text"
      />
      <br />
      <br />
      <MDInput
        size="small"
        fullWidth
        value={amountDueDriver}
        onChange={(e) => setAmountDueDriver(e.target.value)}
        label={`${t("amount_due")}`}
        type="text"
      />
      <br />
      <br />

      <TSCheck
        row
        subtitle="show pending amount alert"
        name="hasPendingPayment"
        value={hasPendingPayment}
        onChange={(e) => setHasPendingPayment(e.target.checked)}
      />

      <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end">
        <MDButton onClick={onUpdate} variant="gradient" color="info" style={{ marginLeft: 20 }}>
          {t("update")}
        </MDButton>
        <MDButton
          onClick={() => close(false)}
          variant="gradient"
          color="error"
          style={{ marginLeft: 20 }}
        >
          {t("close")}
        </MDButton>
      </MDBox>
    </CDialog>
  );
}
