import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useTranslation } from "react-i18next";
import { auth } from "../../../../firebase";
import CAlertSnackbar from "../../../../components/Custom/CAlerts/CAlertSnackbar";
import { AlertModel } from "../../../../utilities/model";

function ChangePassword() {
  const passwordRequirements = [
    "one_special_characters",
    "min_6_characters",
    "one_number_recommended",
    "change_it_often",
  ];

  const { t } = useTranslation();
  const [model, setModel] = React.useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [cAlert, setCAlert] = React.useState(new AlertModel());

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {t(item)}
        </MDTypography>
      </MDBox>
    );
  });

  const reauthenticate = () => {
    const user = auth().currentUser;
    const cred = auth.EmailAuthProvider.credential(user.email, model.currentPassword);
    return user.reauthenticateWithCredential(cred);
  };

  const updatedProfile = () => {
    setLoading(true);
    reauthenticate()
      .then(() => {
        const user = auth().currentUser;
        user
          .updatePassword(model.password)
          .then(() => {
            setCAlert({ open: true, type: "success", ms: "info_updated" });
          })
          .catch((e) => {
            setCAlert({ open: true, type: "error", ms: e.message });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
        setLoading(false);
      });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setModel((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">{t("change_password")}</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              error={model.currentPassword.length < 6}
              success={model.currentPassword.length >= 6}
              value={model.currentPassword}
              onChange={onChange}
              name="currentPassword"
              fullWidth
              label={t("current_password")}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              error={model.password.length < 6}
              success={model.password.length >= 6}
              value={model.password}
              onChange={onChange}
              name="password"
              fullWidth
              label={t("new_password")}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              error={
                !(model.password !== model.confirmPassword && model.confirmPassword.length >= 6)
              }
              success={
                model.password === model.confirmPassword && model.confirmPassword.length >= 6
              }
              value={model.confirmPassword}
              onChange={onChange}
              name="confirmPassword"
              fullWidth
              label={t("confirm_new_password")}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
        </Grid>
        <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">{t("password_requirements")}</MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="body2" color="text">
            {t("please_follow_this_guide_for_a_strong_password")}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </MDBox>
          <MDBox ml="auto">
            <MDButton
              disabled={
                !(model.password === model.confirmPassword && model.confirmPassword.length >= 6)
              }
              fullWidth
              loading={loading}
              onClick={updatedProfile}
              variant="gradient"
              color="dark"
            >
              {t("update_password")}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <CAlertSnackbar alert={cAlert} close={setCAlert} />
    </Card>
  );
}

export default ChangePassword;
