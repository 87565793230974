import * as React from "react";
import { useTranslation } from "react-i18next";
import CPaper from "../../../components/Custom/CPaper";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import { CompanyModel } from "../../../utilities/model";

const input = {
  style: {
    marginBottom: 25,
  },
  inputProps: {
    autoComplete: "",
  },
  fullWidth: true,
};

export default function Company({ data = new CompanyModel(), onChange, onClick }) {
  const { t } = useTranslation();

  return (
    <CPaper style={{ marginTop: 20, padding: 25 }}>
      <MDInput
        {...input}
        value={data.companyName}
        onChange={onChange}
        name="companyName"
        label={t("company_name")}
      />
      <MDInput
        {...input}
        value={data.address}
        onChange={onChange}
        name="address"
        label={t("address")}
      />
      <MDInput {...input} value={data.city} onChange={onChange} name="city" label={t("city")} />
      <MDInput
        {...input}
        value={data.country}
        onChange={onChange}
        name="country"
        label={t("country")}
      />
      <MDInput {...input} value={data.email} onChange={onChange} name="email" label={t("email")} />
      <MDBox mt={3} display="flex" justifyContent="flex-end">
        <MDButton onClick={onClick} variant="gradient" size="medium" color="info">
          {t("update")}
        </MDButton>
      </MDBox>
    </CPaper>
  );
}
