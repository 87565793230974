import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Dialog, DialogContent, IconButton, Paper } from "@mui/material";
import Icon from "@mui/material/Icon";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { DateTime } from "luxon";
import { useMaterialUIController } from "../../../context";
import { useStyles } from "../styles";
import mapboxgl from "!mapbox-gl";
import { CaptureException } from "../../../utilities/func";
import TSText from "../../../components/Custom/TSText";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ShowNavigationModal({ open, close, data, navigationData }) {
  const { t } = useTranslation();
  const styles = useStyles();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [loading, setLoading] = React.useState(true);
  const { mapCenter: center, mapBoxApiKey } = useSelector(({ settings }) => settings);
  const map = React.useRef(null);
  const navigationCoords = React.useRef({});

  const onLoad = () => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      accessToken: mapBoxApiKey,
      attributionControl: false,
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [center.lng, center.lat],
      zoom: 12,
    });
    map.current.on("load", () => {
      setLoading(false);
    });
  };

  const lineToMap = (coordinates) => {
    map.current.addSource("route", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coordinates.map((d) => [d.coordinates.longitude, d.coordinates.latitude]),
        },
      },
    });
    map.current.addLayer({
      id: "route",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#888",
        "line-width": 8,
      },
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      onLoad();
    }, 2000);
  }, []);

  React.useEffect(() => {
    try {
      if (navigationData.length && navigationCoords.current && !loading) {
        lineToMap(navigationData);

        let startCoord = navigationData[0];
        if (startCoord) {
          let time = DateTime.fromSeconds(startCoord.createdAt.seconds).toFormat("f");
          let popup = `<h1 style="color: #455a88">Start: <br><br> ${time}</h1>`;
          const start = new mapboxgl.Marker({
            color: "#0054b4",
            zIndex: 2000,
          })
            .setLngLat([startCoord.coordinates.longitude, startCoord.coordinates.latitude])
            .setPopup(new mapboxgl.Popup().setHTML(popup))
            .addTo(map.current);
          start.togglePopup();
        }

        let pickupCoord = navigationData.find((pc) => pc.type === "pickup");
        if (pickupCoord) {
          let time = DateTime.fromSeconds(pickupCoord.createdAt.seconds).toFormat("f");
          let popup = `<h1 style="color: #455a88">Pickup: <br><br> ${time}</h1>`;

          const pickup = new mapboxgl.Marker({
            color: "#ff0000",
            zIndex: 2000,
          })
            .setLngLat([pickupCoord.coordinates.longitude, pickupCoord.coordinates.latitude])
            .setPopup(new mapboxgl.Popup().setHTML(popup))
            .addTo(map.current);
          pickup.togglePopup();
        }

        let completedCoord = navigationData.find((pc) => pc.type === "completed");
        if (completedCoord) {
          let time = DateTime.fromSeconds(completedCoord.createdAt.seconds).toFormat("f");
          let popup = `<h1 style="color: #455a88">Completed: <br><br> ${time}</h1>`;
          const pickup = new mapboxgl.Marker({
            color: "#0bab00",
            zIndex: 2000,
          })
            .setLngLat([completedCoord.coordinates.longitude, completedCoord.coordinates.latitude])
            .setPopup(new mapboxgl.Popup().setHTML(popup))
            .addTo(map.current);
          pickup.togglePopup();
        }
      }
    } catch (e) {
      CaptureException(e, "Error-DMA-04");
    }
  }, [navigationCoords.current, navigationData.length, loading]);

  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown
      keepMounted
      fullWidth
      open={open}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent id="map2" style={{ backgroundColor: darkMode ? "#344767" : "#f0f2f5" }}>
        <div style={styles.paperMapLeft}>
          <Paper
            elevation={3}
            style={{
              backgroundColor: darkMode ? "#455a88" : "#fff",
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            <TSText variant="h5" subtitle={t("invoice_id")} title={data.invoiceId} />
          </Paper>

          <Paper
            elevation={3}
            style={{
              marginLeft: 10,
              paddingRight: 10,
              paddingLeft: 10,
              backgroundColor: darkMode ? "#455a88" : "#fff",
            }}
          >
            <TSText variant="h5" title={data.from.address} subtitle="address" />
          </Paper>
        </div>

        <Paper
          elevation={3}
          style={{ ...styles.paperDriverMapRight, backgroundColor: darkMode ? "#455a88" : "#fff" }}
        >
          <IconButton size="large" disableRipple color="inherit" onClick={close}>
            <Icon sx={{ color: darkMode ? "#fff" : "#455a88" }}>close</Icon>
          </IconButton>
        </Paper>

        {loading && (
          <Backdrop invisible open={loading}>
            <CircularProgress style={{ color: darkMode ? "#fff" : "#455a88" }} />
          </Backdrop>
        )}
        <div id="map" style={{ height: "95vh", width: "100%", borderRadius: 10 }} />
      </DialogContent>
    </Dialog>
  );
}
