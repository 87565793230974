import { createStyles, makeStyles } from "@mui/styles";

export const useClassName = makeStyles(() =>
  createStyles({
    chatRowLeft: {
      marginBottom: 5,
      flexWrap: "nowrap",
      display: "flex",
    },
    chatRowRight: {
      marginBottom: 5,
      flexWrap: "nowrap",
      display: "flex",
      flexDirection: "row-reverse",
    },
    chatLeft: {
      // maxWidth: "50%",
      // minWidth: 100,
      marginTop: 8,
      paddingBottom: 7,
      paddingTop: 7,
      lineHeight: 1.1,
      paddingLeft: 12,
      paddingRight: 12,
      backgroundColor: "#00598B",
      borderBottomLeftRadius: 0,
      borderRadius: 10,
    },
    chatRight: {
      // maxWidth: "50%",
      // minWidth: 100,
      marginTop: 8,
      paddingBottom: 7,
      paddingTop: 7,
      lineHeight: 1.1,
      paddingLeft: 12,
      paddingRight: 12,
      backgroundColor: "#43a047",
      borderBottomRightRadius: 0,
      borderRadius: 10,
    },
    chatText: {
      lineHeight: 0,
      // height: "auto",
      color: "#fff",
      textAlign: "left",
      fontSize: 14,
    },

    chatDate: {
      bottom: -5,
      left: 5,
      position: "relative",
      fontSize: 13,
      color: "#d5d5d5",
    },
  })
);
