import { createStyles, makeStyles } from "@mui/styles";

export const useClassName = makeStyles(() =>
  createStyles({
    btnClose: {
      color: "white",
      background: "#a8a8a8",
      position: "absolute",
      right: 0,
      top: 0,
      fontSize: 18,
      alignSelf: "flex-end",
      marginTop: -18,
      marginRight: -18,
      "&:hover,&:focus": {
        color: "#fff",
        backgroundColor: "#777777FF",
      },
    },
  })
);

export const useStyles = () =>
  createStyles({
    main: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      minWidth: "400px",
      minHeight: "140px",
      padding: 30,
      color: "white",
      background: "rgba(0,0,0,0.80)",
      zIndex: 11,
      borderRadius: "4px",
      boxShadow: "0 5px 20px 0 rgba(0, 0, 0, 0.04)",
    },
  });
